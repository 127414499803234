import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MyService {
  api = inject(ApiService);

  constructor() { }

  getMyResponses() {
    return this.api.get(`${this.api.endpoints.my}/responses`);
  }

  getMyResponse(id : string) {
    return this.api.get(`${this.api.endpoints.my}/responses/${id}`);
  }

  getMySurveys(survey_ids : string[]) {
    return this.api.post(this.api.endpoints.my + '/surveys', { survey_ids });
  }
}
