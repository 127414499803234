<section class="mt-5 my-10 pt-10">
  <div class="w-full text-right">
    <button class="ml-5 form-button" color="primary" [disabled]="!canSave()" (click)="saveSettings()"><fa-icon [icon]="faSave"></fa-icon></button>
  </div> 
</section>

<section>
  <h3 class="my-5">Profile Information</h3>
  <form [formGroup]="frmProfile">
    <div class="w-full flex pr-1">
      <div class="w-1/2 pr-5">
        <mat-label class="font-bold">First name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="w-1/2 pl-5">
        <mat-label class="font-bold">Last name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="w-full">
      <mat-label class="font-bold">Your email address</mat-label>
      <mat-form-field appearance=outline class="w-full">
        <input matInput formControlName="email">
      </mat-form-field>
    </div>
  </form>
  </section>

  <section>
    <h3 class="my-5">Company Information</h3>
    <form [formGroup]="frmCompany" class="flex flex-wrap">
      <div class="flex flex-wrap w-full">
        <div class="w-full"><mat-label class="font-bold">Your company name</mat-label></div>
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput formControlName="name">
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 lg:pr-5">
        <div class="w-full"><mat-label class="font-bold w-full">Company email</mat-label></div>
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput formControlName="email">
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 lg:pl-5">
        <div class="w-full"><mat-label class="font-bold w-full">Website</mat-label></div>
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput formControlName="website">
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 lg:pr-5">
        <div class="w-full"><mat-label class="font-bold w-full">Address</mat-label></div>
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput formControlName="address" required>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 lg:pl-5">
        <mat-label class="font-bold w-full">City</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="city" required>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap w-1/2 pr-5">
        <mat-label class="font-bold w-full">State/Province</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="state" required>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap w-1/2 pl-5">
        <mat-label class="font-bold w-full">Zip/Postal Code</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="postal" required>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 lg:pr-5">
        <mat-label class="font-bold w-full">Country</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <mat-select formControlName="country">
            @for (country of countries; track country) {
              <mat-option [value]="country.id">{{ country.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </form>
</section>

<!-- <section>
  <h3 class="my-5">Preferences</h3>
  <form [formGroup]="frmPreferences">
    <div class="grid grid-cols-12">
      <div class="col-span-6">
        <mat-label class="font-bold">Best time to call your contacts</mat-label>
        <span class="flex items-center">
          <mat-form-field appearance=outline class="">
            <mat-select formControlName="startTime">
              @for (hour of hours; track hour) {
            <mat-option [value]="hour">{{hour}}</mat-option>
          }
            </mat-select>
          </mat-form-field>
          <div class="px-5 mb-[1em]">to</div>
          <mat-form-field appearance=outline class="">
            <mat-select formControlName="endTime">
              @for (hour of hours; track hour) {
            <mat-option [value]="hour">{{hour}}</mat-option>
          }
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="col-span-6 pr-1">
        <mat-label class="font-bold">Which timezone are you in?</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput [matAutocomplete]="auto" formControlName="timezone">
          <fa-icon matSuffix class="pr-5" [icon]="faCaretDown"></fa-icon>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            @for (tz of filteredTzs | async; track tz) {
              <mat-option [value]="tz">{{tz}}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </form>
</section> -->

<section>
  <h3 class="my-5">Integration</h3>
  <p class="mb-10">When using one of our integration partners, you may be asked to supply an API key.  Click the <fa-icon [icon]="faEye"></fa-icon> to reveal the key.  This will allow access to some of your account information, so please keep this information secure.</p>
  <div class="w-1/2 pr-5">
    <mat-label class="font-bold">API key</mat-label>
    <div class="italic">Please treat this key like a password</div>
    <mat-form-field appearance=outline class="w-full">
      <input matInput class="cursor-pointer" [type]="showApikey ? 'text':'password'" [value]="user?.api_key" readonly="true" (click)="copyApikey()">
      <fa-icon matSuffix class="pr-5" [icon]="showApikey ? faEyeSlash:faEye" (click)="toggleApikeyVisibility()"></fa-icon>
    </mat-form-field>
  </div>
</section>