import { Component } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public frmResetPassword : FormGroup;

  constructor(
    private auth : Auth,
    private snackbar : MatSnackBar
  ) { 
    this.frmResetPassword = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  public submit() {
    if (this.frmResetPassword.valid) {
      console.log(this.frmResetPassword.value);
      sendPasswordResetEmail(this.auth, this.frmResetPassword.value.email).then(
        () => {
          this.snackbar.open('Password reset email sent. If you have an account, please check your email for further instructions', 'Close', {
            duration: 5000
          });
        }
      ).catch(
        (err) => {
          console.error(err);
          this.snackbar.open('Error sending password reset email', 'Close', {
            duration: 5000
          });
        }
      );
    }
  }
}
