<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">Please Confirm</h3>
  <div class="my-5 pr-5">
    <div>We're sorry to see you go!  Please provide some feedback so that we can serve you better next time.</div>
    <form [formGroup]="frmCancel" class="mt-5">
      <label id="feedback-label">What was your primary reason for your decision?</label>
      <mat-radio-group class="flex flex-col mt-5 mx-5" aria-labelledby="feedback-label" formControlName="feedback">
        @for (option of feedbackOptions; track option) {
          <mat-radio-button [value]="option.value">{{option.label}}</mat-radio-button>
        }
      </mat-radio-group>
      <mat-form-field appearance=outline class="w-full mt-5">
        <mat-label>Would you like to share some additional feedback? Enter it below</mat-label>
        <textarea matInput formControlName="comment" rows="5"></textarea>
      </mat-form-field>
      <section class="delete-account">
        <p><mat-checkbox formControlName="close_account">Also close my account</mat-checkbox></p>
        <p class="text-red-400" *ngIf="closeAccountSelected()">Warning: This cancel your subscription and delete all of your records. <b>This action cannot be undone</b></p>
      </section>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="mx-3 mb-3">
    <button class="btn-secondary-outline mr-3 cursor-pointer" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-warn-outline" type="button" [disabled]="frmCancel.invalid" (click)="handleAction()">{{ returnActionButtonLabel() }}</button>
  </div>
</mat-dialog-actions>