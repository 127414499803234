<div class="auth-content-box">
  <!-- Left Panel -->
  <div class="xs:px-10 sm:w-full md:w-full lg:w-1/2">
    <div class="lg:max-w-md center">
      <div class="max-w-md mx-auto text-center py-10">
        <form [formGroup]="frmSignin" class="w-full">
          <img class="center mb-10" src="../assets/SurveyPoint-logo.png" alt="">
          <h3 class="mb-12 text-3xl font-semibold font-heading">Welcome Back!</h3>
          <alert-box type="warn" *ngIf="showForgotPassword">
            Did you forget your password? <a href="/reset-password">Click here to reset it</a>
          </alert-box>
          <div class="relative flex flex-wrap mb-6">
            <mat-label class="font-bold">Email Address</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput placeholder="email@domain.com" formControlName="email">
            </mat-form-field>
          </div>
          <div class="relative flex flex-wrap mb-6">
            <mat-label class="font-bold">Password</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput type="password" formControlName="password">
            </mat-form-field>
          </div>
          <button (click)="signin()" class="w-full mb-4 font-bold">Sign In</button>
          <p class="text-sm text-gray-500 font-bold mb-3">No account yet?</p>
          <button [routerLink]="'/signup'" class="w-full btn-secondary-outline text-white">Get Started</button>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Panel -->
  <div class="hidden lg:block lg:w-1/2 login-splash-image">
    
  </div>
</div>