<div *ngIf="!hasContent()">
  <div class="bg-gray-200 m-3 px-5 py-5 rounded-lg text-center">
    No response to display
  </div>
</div>
<div *ngIf="hasContent()">
  <div class="w-full my-3">
    <h3 class="text-center">{{ getSurveyTitle() }}</h3>
  </div>
  <!-- Results -->
  <section class="px-3" *ngIf="canShow('score')">
    <div class="w-full bg-gray-200 rounded-lg h-8">
      <div class="text-center cursor rounded-lg h-8 bg-blue-400 text-white font-bold" [matTooltip]="setTooltip()" [style]="setProgressBarWidth()">{{calculateScore() | number: '1.0-2' }}%</div>
    </div>
  </section>

  <section class="px-3" *ngIf="canShow('responses')">
    <div class="flex mt-10 mb-5">
      <div class="flex flex-grow">
        <div class="block">
          <div class="font-bold uppercase">Question</div>
        </div>
      </div>
      <div class="flex" *ngIf="isScored()">
        <div class="block">
          <div class="font-bold uppercase">Score</div>
        </div>
      </div>
    </div>
    <div class="mb-3 pt-3 border-t-2" *ngFor="let rc of _response.content">
      <div class="flex">
        <div class="flex flex-grow">
          <div class="block text-left">
            <div class="font-bold" [innerHTML]="getQuestion(rc)"></div>
            <div>{{rc.answer}}</div>
            <div *ngIf="canShow('tips')" class="italic">{{ getQuestionTipMessage(rc) }}</div>
          </div>
        </div>
        <div class="flex" *ngIf="isScored()">
          <div class="block font-bold">
            <div>{{ renderScore(rc) }}</div>
          </div>
        </div>
      </div>
  </div>
</section>