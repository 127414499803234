<section>
  <div class="mx-5 my-10 w-full">
    <form [formGroup]="frmPaymentSettings" class="w-full">
      <div class="flex flex-wrap items-center w-full mb-20 pr-5">
        <span class="flex"><mat-slide-toggle class="!mt-0 scale-150" formControlName="payments_enable" (change)="updateSurveyModel('payments_enable', $event)" color="text-primary"></mat-slide-toggle></span>
        <span class="flex pl-18"><mat-label class="w-full font-bold scale-150" [matTooltip]="tooltips.payments_enable">Require users to pay?</mat-label></span>
      </div>
      <div class="flex flex-wrap w-full">
        <!-- <h3 class="w-full mb-5">Payment Details</h3> -->
        <div class="sm:w-full lg:w-1/2 lg:pr-5">
          <div class="w-full">
            <mat-label class="font-bold" [matTooltip]="tooltips.payment_timing">When should we ask for payment?</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <mat-select name="payment_timing" formControlName="payment_timing"
                [aria-label]="tooltips.payment_timing" hideSingleSelectionIndicator="false"
                (selectionChange)="updateSurveyModel('payment_timing', $event)">
                <mat-option value="start"> At the beginning </mat-option>
                <mat-option value="end"> At the end, before showing the results </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="sm:w-full lg:w-1/2">
          <div class="w-full">
            <mat-label class="w-full font-bold" [matTooltip]="tooltips.payment_survey_access">Which product is this assigned to?</mat-label>
            <div class="w-full">
              <mat-form-field appearance=outline class="w-full">
                <mat-select name="payment_survey_access" formControlName="payment_survey_access"
                  [aria-label]="tooltips.payment_survey_access" hideSingleSelectionIndicator="false"
                  (selectionChange)="updateSurveyModel('payment_survey_access', $event)">
                  <mat-option value="once"> Only once </mat-option>
                  <mat-option value="duration"> A specific duration </mat-option>
                  <mat-option value="always"> Forever </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="flex flex-nowrap sm:w-full lg:w-2/3" *ngIf="showDurationOptions()">
            <div class="w-1/2 sm:ml-0 lg:ml-4">
              <mat-label class="font-bold"></mat-label>
              <mat-form-field appearance=outline class="w-full">
                <input matInput type="number" aria-label="Access duration" name="payment_survey_access_duration" formControlName="payment_survey_access_duration" (change)="updateSurveyModel('payment_survey_access_duration', $event)">
              </mat-form-field>
            </div>
            <div class="w-1/2 ml-4">
              <div class="w-full"><mat-label class="font-bold"></mat-label></div>
              <mat-form-field appearance=outline class="w-full">
                <mat-select name="payment_survey_access_duration_unit" formControlName="payment_survey_access_duration_unit" aria-label="Access duration unit" hideSingleSelectionIndicator="false"
                  (selectionChange)="updateSurveyModel('payment_survey_access_duration_unit', $event)">
                  <mat-option value="hours"> hours </mat-option>
                  <mat-option value="days"> days </mat-option>
                  <mat-option value="weeks"> weeks </mat-option>
                  <mat-option value="months"> months </mat-option>
                  <mat-option value="years"> years </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
        </div>
      </div>
      <div class="w-full mt-5">
        <mat-label class="font-bold">
          Message to display to users to encourage them to pay
          <fa-icon [matTooltip]="tooltips.payment_message" [icon]="faCircleQuestion"
            class="mr-3 text-blue-500"></fa-icon>
        </mat-label>
        <!-- <editor [init]="editorConfig" id="editor-welcome" formControlName="welcome"></editor> -->
        <quill-editor class="w-full" (onContentChanged)="updateSurveyModel('payment_message', $event)" formControlName="payment_message"></quill-editor>
      </div>
      <div class="w-full mt-5">
        <mat-label class="font-bold">
          Message to display to users after a successful payment
          <fa-icon [matTooltip]="tooltips.payment_message_successful" [icon]="faCircleQuestion"
            class="mr-3 text-blue-500"></fa-icon>
        </mat-label>
        <!-- <editor [init]="editorConfig" id="editor-welcome" formControlName="welcome"></editor> -->
        <quill-editor class="w-full" (onContentChanged)="updateSurveyModel('payment_message_successful', $event)" formControlName="payment_message_successful"></quill-editor>
      </div>
      <div class="w-full mt-5">
        <mat-label class="font-bold">
          Message to display to users after an unsuccessful payment
          <fa-icon [matTooltip]="tooltips.payment_message_unsuccessful" [icon]="faCircleQuestion"
            class="mr-3 text-blue-500"></fa-icon>
        </mat-label>
        <!-- <editor [init]="editorConfig" id="editor-welcome" formControlName="welcome"></editor> -->
        <quill-editor class="w-full" (onContentChanged)="updateSurveyModel('payment_message_unsuccessful', $event)" formControlName="payment_message_unsuccessful"></quill-editor>
      </div>
    </form>
  </div>
</section>