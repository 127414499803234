import { Injectable } from '@angular/core';
import { ApiResponseModel, ApiService } from './api.service';
import { Merchants, MerchantProductModel } from '../models/tpp.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { SurveyModel } from '../models/survey.model';

@Injectable({
  providedIn: 'root'
})
export class TPPService {
  public merchants : BehaviorSubject<Merchants> = new BehaviorSubject<Merchants>(new Merchants())

  constructor(
    private api : ApiService
  ) { }

  public initiateStripeConnection() : Observable<ApiResponseModel>  {
    return this.api.post(`${this.api.endpoints.tpp}/account`, {});
  }

  public getMerchant() : Observable<ApiResponseModel>  {
    return this.api.get(`${this.api.endpoints.tpp}/merchant`);
  }

  public getMerchantProducts() : Observable<ApiResponseModel>  {
    return this.api.get(`${this.api.endpoints.tpp}/products`);
  }
  
  public updateMerchantProduct(id : string, mp : MerchantProductModel) : Observable<ApiResponseModel>  {
    return this.api.put(`${this.api.endpoints.tpp}/products/${id}`, mp);
  }

  public deleteMerchantProduct(id : string) : Observable<ApiResponseModel>  {
    return this.api.delete(`${this.api.endpoints.tpp}/products/${id}`);
  }

  public getTPPProduct(id : string) : Observable<ApiResponseModel>  {
    return this.api.get(`${this.api.endpoints.tpp}/products/${id}`);
  }

  public getStripeProducts() : Observable<ApiResponseModel>  {
    return this.api.get(`${this.api.endpoints.tpp}/stripe/products`);
  }

  public createMerchantProduct(mp : MerchantProductModel) : Observable<ApiResponseModel>  {
    return this.api.post(`${this.api.endpoints.tpp}/products`, mp);
  }

  public assignSurveyProduct(mp : MerchantProductModel, survey : SurveyModel) : Observable<ApiResponseModel> {
    return this.api.post(`${this.api.endpoints.tpp}/survey`, { merchant_product_id : mp.id, survey_id : survey.id }); ;
  }

  public unassignSurveyProduct(mp : MerchantProductModel, survey : SurveyModel) : Observable<ApiResponseModel> {
    return this.api.post(`${this.api.endpoints.tpp}/survey`, { merchant_product_id : null, survey_id : survey.id }); ;
  }
}
