import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, input } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
  cdref = inject(ChangeDetectorRef);
  loading = inject(LoadingService);

  public _isLoading : boolean = true;
  public _loadingMessage : string = 'Loading...';

  constructor() { 
  }

  ngOnInit() {
    this.loading.isLoading.subscribe(loading => {
      this._isLoading = loading;
      this.cdref.detectChanges();
    });

    this.loading.message.subscribe(message => {
      this._loadingMessage = message;
      this.cdref.detectChanges();
    });
  }

  ngOnDestroy() {
    // if(this.loading.isLoading) this.loading.isLoading.unsubscribe();
    // if(this.loading.message) this.loading.message.unsubscribe();
  }

  isLoading() {
    return this._isLoading;
  }

}
