import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { faSave, faCaretDown, faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import * as timezones from '../../../json/timezoneRegions.json';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { UsersModel } from 'src/app/models/users.model';
import { CompaniesModel, CompanyPreferences } from 'src/app/models/companies.model';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
dayjs.extend(timezone);
const TXFEE = "1.00";

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrl: './general-settings.component.scss'
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
  api = inject(ApiService);
  auth = inject(AuthService);
  companyService = inject(CompanyService);
  dss = inject(DataSharingService);
  snackbar = inject(MatSnackBar);
  
  faSave = faSave;
  faCaretDown = faCaretDown;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircleCheck = faCircleCheck;

  public currentTimezone : string = dayjs.tz.guess();
  public showApikey : boolean = false;
  public user : UsersModel | null = null;
  private user$ : any;
  private company$ : any;

  // Forms
  public frmProfile = new FormGroup({
    'firstName' : new FormControl('', [Validators.required]),
    'lastName' : new FormControl('', [Validators.required]),
    'email' : new FormControl('', [Validators.required, Validators.email]),
  });

  public company : CompaniesModel | null = null;
  public frmCompany = new FormGroup({
    name : new FormControl('', Validators.required),
    email : new FormControl(''),
    website : new FormControl(''),
    address : new FormControl('', Validators.required),
    city : new FormControl('', Validators.required),
    state : new FormControl('', Validators.required),
    postal : new FormControl('', Validators.required),
    country : new FormControl('', Validators.required),
  });

  public frmPreferences = new FormGroup({
    'timezone' : new FormControl('', [Validators.required]),
    'startTime' : new FormControl('', [Validators.required]),
    'endTime' : new FormControl('', [Validators.required]),
  });

  // public tzs : string[] = [];
  // public filteredTzs : Observable<string[]> | undefined;
  
  // public hours : string[] = [
  //   '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00 ', '08:00', '09:00', '10:00',
  //   '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
  //   '21:00', '22:00', '23:00', '24:00'
  // ];

  countries = [
    { id : 'ca', name : 'Canada' },
    { id : 'us', name : 'United States' },
  ];

  constructor() { 
    // this.tzs = Array.from(timezones);
    this.frmProfile.get('email')?.disable();
  }

  get timezone() { return this.frmPreferences.get('timezone'); }

  ngOnInit(): void {
    this.user$ = this.auth.user.subscribe({
      next : (user : UsersModel | null) => {
        this.user = user;
      },
      error : (err : any) => {
        console.error(err);
      }
    });

    this.company$ = this.companyService.company.subscribe({
      next : (company : CompaniesModel | null) => {
      this.company = company;
      },
      error : (err : any) => {
        console.error(err);
      }
    });

    this.frmProfile.patchValue({
      'firstName' : this.user?.first_name,
      'lastName' : this.user?.last_name,
      'email' : this.user?.email,
    });

    this.frmCompany.patchValue({
      'name' : this.company?.name,
      'email' : this.company?.email,
      'website' : this.company?.website,
      'address' : this.company?.address,
      'city' : this.company?.city,
      'state' : this.company?.province,
      'postal' : this.company?.postal_code,
      'country' : this.company?.country,
    });

    if(!this.company!.preferences) {
      this.company!.preferences = { timezone : this.currentTimezone, start_time : '09:00', end_time : '17:00' };
      this.saveCompany();
    }

    this.frmPreferences.patchValue({
      'timezone' : this.company?.preferences?.timezone,
      'startTime' : this.company?.preferences?.start_time,
      'endTime' : this.company?.preferences?.end_time,
    });
  }

  ngOnDestroy(): void {
    // this.user$.unsubscribe();
    // this.company$.unsubscribe();
  }

  saveSettings() {
    if(this.frmProfile.valid && this.frmProfile.dirty) {
      this.saveProfile();
    }

    if(this.frmCompany.valid && this.frmCompany.dirty) {
      this.company!.name = this.frmCompany.get('name')?.value || null;
      this.company!.email = this.frmCompany.get('email')?.value || null;
      this.company!.website = this.frmCompany.get('website')?.value || null;
      this.company!.address = this.frmCompany.get('address')?.value || null;
      this.company!.city = this.frmCompany.get('city')?.value || null;
      this.company!.province = this.frmCompany.get('state')?.value || null;
      this.company!.postal_code = this.frmCompany.get('postal')?.value || null;
      this.company!.country = this.frmCompany.get('country')?.value || null;
      this.saveCompany();
    }

    if(this.frmPreferences.valid && this.frmPreferences.dirty) {
      let prefs : CompanyPreferences = {
        timezone : this.frmPreferences.get('timezone')?.value,
        start_time : this.frmPreferences.get('startTime')?.value,
        end_time : this.frmPreferences.get('endTime')?.value,
      };
  
      this.company!.preferences = prefs;

      this.saveCompany();
    }
  }

  /**
   * This method saves the profile
   */
  saveProfile() {
    try {
      console.log('Save profile');
      this.user!.first_name = this.frmProfile.get('firstName')?.value || null;
      this.user!.last_name = this.frmProfile.get('lastName')?.value || null;
  
      this.api.put(this.api.endpoints.users + '/me', this.user!).subscribe({
        next : (response) => {
          this.auth.saveUser(this.user!);
          this.frmProfile.markAsPristine();
        },
        error : (err) => {
          console.error('general-settings.component:338498', err);
          this.snackbar.open('There was an error saving the settings. Please try again', 'Dismiss', { duration : 3000 });
        }
      });
    } catch(err) {
      console.error('general-settings.component:173652', err);
      this.snackbar.open('There was an error saving the settings. Please try again', 'Dismiss', { duration : 3000 });
    }
  }

  /**
   * This method saves the company 
   */
  saveCompany() {
    try {
      if(!this.company) { return; }

      this.companyService.update(this.company).subscribe({
        next : (response) => {
          if(response.payload) {
            this.companyService.save(this.company!);
            this.frmCompany.markAsPristine();
            this.frmPreferences.markAsPristine();
            
          }
        },
        error : (err) => {
          console.error('general-settings.component:045251', err);
        }
      });
    } catch(err) {
      console.error('general-settings.component:173652', err);
      this.snackbar.open('There was an error saving the settings. Please try again', 'Dismiss', { duration : 3000 });
    }
  }

  canSave() {
    return  this.frmProfile.valid && this.frmProfile.dirty || 
            this.frmCompany.valid && this.frmCompany.dirty || 
            this.frmPreferences.valid && this.frmPreferences.dirty;
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.tzs.filter(option => option.toLowerCase().includes(filterValue));
  // }

  toggleApikeyVisibility() {
    this.showApikey = !this.showApikey;
  }

  /**
   * This method copies the apikey to the clipboard
   * Only copy the apikey if the showApikey is true
   */
  copyApikey() {
    if(this.showApikey) {
      navigator.clipboard.writeText(this.user?.api_key || '').then(() => {
        this.snackbar.open('API key copied to clipboard', 'Dismiss', { duration: 3000 });
      });
    }
  }
}
