import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, tap } from 'rxjs';
import { SubscriptionsModel } from '../models/subscription.model';
import { PlansModel } from '../models/plans.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  public credits : BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public subscription : BehaviorSubject<SubscriptionsModel | null> = new BehaviorSubject<SubscriptionsModel | null>(null);
  public stripeSubscription : BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public plans : BehaviorSubject<PlansModel[]> = new BehaviorSubject<PlansModel[]>([]);
  public isLoading : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private api : ApiService
  ) { }

  getSubscriptions() {
    this.isLoading.next(true);

    return this.api.get(this.api.endpoints.subscriptions).subscribe({
      next : (response : any) => {
        // console.log('subscription.service:783305', response);
        if(response && response.payload) {
          this.isLoading.next(false);
          this.subscription.next(response.payload.subscription);
          if(response.payload.stripeSubscription && response.payload.stripeSubscription.data) {
            this.stripeSubscription.next(response.payload.stripeSubscription.data);
          } else {
            this.stripeSubscription.next([]);
          }
          // thi1s.updateCredits(response.payload.credits);
        } else {
          this.subscription.next(null);
          // this.updateCredits(0);
        }
      },
      error : (error) => {
        console.log(error);
        this.isLoading.next(false);
      }
    });
  }

  /**
   * DEPRECATED - Use `subscribeToPlan` instead
   * @param stripe_price_id 
   * @returns 
   */
  // updateSubscription(stripe_price_id : string) {
  //   return this.api.put(this.api.endpoints.subscriptions, { stripe_price_id });
  // }

  updateCredits(credits : number) {
    if(!credits) credits = 0;
    if(credits < 0) credits = 0;
    this.credits.next(credits);
  }

  getPlans() {
    return this.api.get(this.api.endpoints.plans).pipe(
      tap((response : any) => {
        if(response && response.payload) {
          this.plans.next(response.payload);
          // console.log('subscription.service:941638', response.payload, this.plans.value);
        }
      })
    );
  }

  getPlanById(planId : string) {
    let plans = this.plans.value;
    if(!plans) return null;

    let plan = plans.find((p : PlansModel) => p.stripe_price_id === planId);
    return plan;
  }

  /**
   * Checks if the user is subscribed to a plan for the specified service
   * Looks at the plan metadata.service field to determine the service
   * 
   * @param service 'survey' or 'touchpoint'
   * @returns {boolean} True if the user has the plan, false if not
   */
  hasPlanforService(service : string) : boolean {
    if(!this.stripeSubscription) return false;

    let stripeSubscription = this.stripeSubscription.value;

    if(!stripeSubscription) return false;
    
    let plan = stripeSubscription.find((sub : any) => (sub.plan.metadata.service === service || sub.plan.metadata.service === 'custom') && sub.status === 'active');
    // console.log('subscription.service:612365', plan, stripeSubscription);
    if(plan) return true;
    return false;
  }

  /**
   * Checks in `stripeSubscription.data[].plan.id` for a plan with the given id
   * @param price_id  The plan id (price_xxxxxx) to check for; if null, checks for any plan
   * @returns {boolean} True if the user has the plan, false if not
   */
  hasPlan(price_id? : string) : boolean {
    let hasPlan = false;

    if(!this.stripeSubscription.value) return false;
    let stripeSubscription = this.stripeSubscription.value;

    // Find the plan in stripeSubscription.data[].plan.id
    stripeSubscription.forEach((sub : any) => {
      if(price_id) {
        if(sub.plan.id === price_id && sub.status === 'active') {
          hasPlan = true;
        }
      } else {
        if(sub.status === 'active') hasPlan = true;
      }
    });

    return hasPlan;
  }

  isPlanCancelled(price_id : string) : boolean {
    let cancelled = false;

    if(!this.stripeSubscription.value) return false;
    let stripeSubscription = this.stripeSubscription.value;

    stripeSubscription.forEach((sub : any) => {
      if(sub.plan.id === price_id && sub.cancel_at_period_end) {
        cancelled = true;
      }
    });

    return cancelled;
  }

  getPlanCancellationDate(price_id : string) : string {
    let canceledAt = '';

    if(!this.stripeSubscription.value) return '';
    let stripeSubscription = this.stripeSubscription.value;

    stripeSubscription.forEach((sub : any) => {
      if(sub.plan.id === price_id && sub.cancel_at_period_end) {
        // multiply by 1000 to convert to milliseconds
        let date = new Date(sub.cancel_at * 1000);
        canceledAt = date.toLocaleDateString();
      }
    });

    return canceledAt;
  }
  
  subscribeToPlan(
    paymentMethod : any, 
    billing_details : any, 
    plans : { service : string, plan_id_from: string,  price_id_from : string, plan_id_to : string, price_id_to : string }[]
  ) {
    // console.log('subscription.service:635103', paymentMethod, billing_details, plans);
    return this.api.post(this.api.endpoints.subscriptions, { pm : paymentMethod, billing_details, plans });
  }
}