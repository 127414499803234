import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ResponseContentModel, SurveyResponsesModel } from 'src/app/models/responses.model';
import { SURVEY_FINAL_RESULT_DETAIL, SurveyConfigModel, SurveyModel } from 'src/app/models/survey.model';
import { Chart, ChartConfiguration } from 'chart.js/auto';

@Component({
  selector: 'tpt-render-responses',
  templateUrl: './render-responses.component.html',
  styleUrl: './render-responses.component.scss'
})
export class RenderResponsesComponent {
  @Input() public response : SurveyResponsesModel | null = new SurveyResponsesModel();
  @Input() public survey : SurveyModel = new SurveyModel();

  SURVEY_FINAL_RESULT_DETAIL = SURVEY_FINAL_RESULT_DETAIL;

  public _response : SurveyResponsesModel = new SurveyResponsesModel();
  private config : SurveyConfigModel = new SurveyConfigModel();
  private max_score : number = 0;

  // Doughnut
  // public doughnutChartLabels: string[] = [ 'Download Sales', 'In-Store Sales', 'Mail-Order Sales' ];
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
      { data: [ 0, 1 ], label: 'Score', backgroundColor: [ '#60a5fa', '#ccc' ] },
    ];

  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: false,
    plugins: {
      title: {
        display: false,
        text: '0%',
        font: {
          size: 20
        },
        position: 'bottom',
        align: 'center'
      }
    }
  };

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(changes : SimpleChanges) {
    // Changes to response
    if(changes['response'] && !changes['response'].firstChange) {
      this._response = changes['response'].currentValue;
      this.config = this._response.config || new SurveyConfigModel();
      this.createChart();

      if(this._response.config) {
        this.config = this._response.config || new SurveyConfigModel();
      }

      if(this._response.max_score) {
        this.max_score = this._response.max_score || 0;
      }
      // console.log('render-responses.component:509015', this._response);
    }

    if(changes['survey'] && !changes['survey'].firstChange) {
      this.survey = changes['survey'].currentValue;
      this.config = this.survey.config;
      this.max_score = this.survey.max_score || 0;
      this.createChart();
      // console.log('render-responses.component:557912', this.survey);
    }
  }

  createChart(){
    let score : number = this.response?.score || 0;
    let max : number = this.max_score;
    // console.log('render-responses.component:354048', score, max);

    this.doughnutChartDatasets[0].data = [ score, max-score ];
    this.doughnutChartOptions!.plugins!.title!.text = `${(score / max) * 100}%`;
    
  }

  hasContent() : boolean {
    return this._response.content && this._response.content.length > 0;
  }

  getSurveyTitle() : string {
    return this.response?.survey_name || this.survey.name || '';
  }

  /**
   * This function
   */
  getQuestion(rc : ResponseContentModel) : string {
    let rst = '';

    if(rc.question) { return rc.question; }

    if(!rc.question_id || !this.survey) {
      return rst;
    } 

    let question = this.survey.questions.find(q => q.id === rc.question_id);
    if(question) {
      rst = question.question;
    }

    return rst;
  }

  calculateScore() : number {
    let score = 0;

    if(this.max_score === 0) { return 0; }

    this._response.content.forEach((rc) => {
      score += rc.score || 0;
    });

    return (score / this.max_score) * 100;
  }

  setProgressBarWidth() {
    return `width : ${this.calculateScore()}%`;
  }

  setTooltip() {
    let score = 0;
    this._response.content.forEach((rc) => {
      score += rc.score || 0;
    });

    return `Score : ${score} / ${this.max_score}`;
  }

  isScored() {
    return this._response.is_scored || this.survey.is_scored;
  }

  renderScore(rc : ResponseContentModel) {
    if(!rc) { return '---'; }
    if(rc.score == null) { return '---'; }

    return rc.score || 0;
  }

  canShow(section : string) {
    let config = this.config;
    // console.log('render-responses.component:881464', config);
    if(!config) { return true; }
    if(!config.final_result_detail) { return false; }
    
    let c = config.final_result_detail;
    if(c === SURVEY_FINAL_RESULT_DETAIL.NONE) { return false; }

    let rst = false;

    switch(section) {
      case 'score':
        if(c === SURVEY_FINAL_RESULT_DETAIL.SUMMARY || 
           c == SURVEY_FINAL_RESULT_DETAIL.DETAILED || 
           c == SURVEY_FINAL_RESULT_DETAIL.VERBOSE ) { 
            rst = true; 
        }
        break;
      case 'responses':
        if(c == SURVEY_FINAL_RESULT_DETAIL.DETAILED || 
          c == SURVEY_FINAL_RESULT_DETAIL.VERBOSE ) { 
           rst = true; 
       }
       break;
      case 'tips':
        if(c == SURVEY_FINAL_RESULT_DETAIL.VERBOSE ) { 
           rst = true; 
       }
       break;
    }

    return rst;
  }

  getQuestionTipMessage(rc : ResponseContentModel) {
    let rst = '';
    if(!rc) { return rst; }

    if(rc.incorrect_message) { return rc.incorrect_message; }

    if(!rc.question_id || !rc.answer_id) { return rst; }

    let question = this.survey.questions.find(q => q.id === rc.question_id);
    if(!question) { return rst; }

    let option = question.field_options.find(o => o.id === rc.answer_id);
    if(!option) { return rst; }

    rst = option.incorrect_message || '';

    return rst;
  }
}
