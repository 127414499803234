<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <a class="cursor-pointer" (click)="back()"><h1><fa-icon [icon]="faArrowLeft" class="mr-3"></fa-icon>  {{ cohort.name }}</h1></a>
  </div>
</section>

<!-- Page Content -->
  <!-- Filter Section -->
  <section class="flex items-center mb-10">
    <div class="w-1/2">

    </div>
    <div class="w-1/2 text-right items-start">
      <div class="w-full flex">
        <span class="">
        </span>
        <span class="w-full">
          <mat-form-field appearance=outline class="searchInput">
            <input matInput [(ngModel)]="contactFilter" placeholder="Filter Members" (keyup)="updateCohortFilter()">
            <fa-icon matSuffix class="pr-2" [icon]="faSearch"></fa-icon>
          </mat-form-field>
          <button class="form-button ml-5" matTooltip="Add Cohort Member" (click)="openManageContactModal()"><fa-icon [icon]="faPlus"></fa-icon></button>
        </span>
      </div>
    </div>
  </section>

  <table mat-table [dataSource]="dataSource" class="">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" (click)="openManageContactModal(element)">{{ renderName(element) }}</td>
    </ng-container>
  
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element" (click)="openManageContactModal(element)">{{ element.company_name }}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let element" (click)="openManageContactModal(element)">{{ element.phone }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element" (click)="openManageContactModal(element)">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="surveys">
      <th mat-header-cell *matHeaderCellDef class="!text-center">Responses</th>
      <td mat-cell class="!text-center" *matCellDef="let element" (click)="goToContact(element)">{{ element.survey_response_count }} / {{ element.survey_participant_count }}</td>
    </ng-container>
    <ng-container matColumnDef="avg_score">
      <th mat-header-cell class="!text-center" *matHeaderCellDef>Average Score</th>
      <td mat-cell class="!text-center" *matCellDef="let element" (click)="goToContact(element)">{{ renderScore(element.average_score) }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer"></tr>
    <tr class="mat-row" *matNoDataRow>
      <ng-container *ngIf="isLoading">
        <td [attr.colspan]="displayedColumns.length" class="py-5">
          <mat-progress-spinner color="primary" diameter="24" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
        </td>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="text-center my-10" *ngIf="isFiltered()">
            <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
          </div>
          <div class="text-center my-10" *ngIf="!isFiltered()">
            <h4 class="text-center mt-10 mb-5">No cohort members found</h4>
          </div>
        </td>
      </ng-container>
    </tr>
  </table>

  <!-- Pagination Section -->
  <section class="flex items-center justify-between">
    <div class="w-full pt-5">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </section>