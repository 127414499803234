import { v4 as uuidv4 } from 'uuid';

export class OptionListModel {
  id : string;
  name : string;
  incorrect_message? : string;
  score? : number;

  constructor() {
    this.id = uuidv4();
    this.name = '';
    this.incorrect_message = '';
    this.score = 0;
  } 
}