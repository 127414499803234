import { ErrorHandler, forwardRef, importProvidersFrom, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainTemplateComponent } from './templates/main-template/main-template.component';
import { TouchpointComponent } from './pages/touchpoints/touchpoints.component';
import { QuestionLibraryComponent } from './pages/question-library/question-library.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AuthTemplateComponent } from './templates/auth-template/auth-template.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Page404Component } from './pages/page404/page404.component';
import { Page500Component } from './pages/page500/page500.component';
import { LeftNavPanelComponent } from './templates/main-template/left-nav-panel/left-nav-panel.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RightPanelComponent } from './templates/main-template/right-panel/right-panel.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

// Angular Material
import {MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

// Modals
import { AddQuestionLibraryModalComponent } from './modals/add-question-library-modal/add-question-library-modal.component';
import { AiAssistantModalComponent } from './modals/ai-assistant-modal/ai-assistant-modal.component';
import { CancelSubscriptionConfirmModalComponent } from './modals/cancel-subscription-confirm-modal/cancel-subscription-confirm-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ManageCohortModalComponent } from './modals/manage-cohort-modal/manage-cohort-modal.component';
import { ManageContactModalComponent } from './modals/manage-contact-modal/manage-contact-modal.component';
import { ManageProductModalComponent } from './modals/manage-product-modal/manage-product-modal.component';
import { ManageQuestionConditionsModalComponent } from './modals/manage-question-conditions-modal/manage-question-conditions-modal.component';
import { ManageSurveyModalComponent } from './modals/manage-survey-modal/manage-survey-modal.component';
import { ManageTouchpointModalComponent } from './modals/manage-touchpoint-modal/manage-touchpoint-modal.component';
import { OnboardingModalComponent } from './modals/onboarding-modal/onboarding-modal.component';
import { PlanSignupModalComponent } from './modals/plan-signup-modal/plan-signup-modal.component';
import { PlanUpgradeModalComponent } from './modals/plan-upgrade-modal/plan-upgrade-modal.component';
import { SurveyProUpgradeModalComponent } from './modals/survey-pro-upgrade-modal/survey-pro-upgrade-modal.component';
import { UpdatePaymentInfoModalComponent } from './modals/update-payment-info-modal/update-payment-info-modal.component';
import { UploadTouchpointModalComponent } from './modals/upload-touchpoint-modal/upload-touchpoint-modal.component';

// Firebase
// import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { QuillConfigModule, QuillModule } from 'ngx-quill'
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { QuestionEditorComponent } from './pages/question-editor/question-editor.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TopToolbarComponent } from './components/top-toolbar/top-toolbar.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DragDropDirective } from './directives/drag-drop.directive';
import { GeneralSettingsComponent } from './pages/settings/general-settings/general-settings.component';
import { AccountSettingsComponent } from './pages/settings/account-settings/account-settings.component';
import { PlanCalculatorComponent } from './components/plan-calculator/plan-calculator.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ResponseLibraryComponent } from './pages/response-library/response-library.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { AuthActionComponent } from './pages/auth-action/auth-action.component';
import { ResetPasswordStepTwoComponent } from './pages/auth-action/reset-password-step-two/reset-password-step-two.component';
import { OnboardingBadgeComponent } from './components/onboarding-badge/onboarding-badge.component';
import { DoSurveyComponent } from './pages/do-survey/do-survey.component';
import { SurveyTemplateComponent } from './templates/survey-template/survey-template.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { SurveyQuestionEditorComponent } from './pages/survey-details/survey-question-editor/survey-question-editor.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SurveyDetailsComponent } from './pages/survey-details/survey-details.component';
import { SurveyParticipantsComponent } from './pages/survey-details/survey-participants/survey-participants.component';
import { SurveyPanelComponent } from './components/survey-panel/survey-panel.component';
import { PortletComponent } from './components/portlet/portlet.component';
import { SurveyPointPortletComponent } from './portlets/survey-point-portlet/survey-point-portlet.component';
import { TouchPointPortletComponent } from './portlets/touch-point-portlet/touch-point-portlet.component';
import { ErrorHandlingService } from './services/error-handling.service';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { OptionArrayInputComponent } from './components/option-array-input/option-array-input.component';
import { CohortsComponent } from './pages/cohorts/cohorts.component';
import { CohortDetailsComponent } from './pages/cohorts/cohort-details/cohort-details.component';
import { CohortsPortletComponent } from './portlets/cohorts-portlet/cohorts-portlet.component';
import { ContactMemberDetailsComponent } from './pages/contact-member-details/contact-member-details.component';
import { SubscribeBannerComponent } from './components/subscribe-banner/subscribe-banner.component';
import { RenderResponsesComponent } from './components/render-responses/render-responses.component';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { ResponseViewerComponent } from './pages/response-library/response-viewer/response-viewer.component';
import { ViewResponseExternalComponent } from './pages/view-response-external/view-response-external.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SurveyPreviewComponent } from './pages/survey-preview/survey-preview.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { ToggleIndicatorButtonComponent } from './components/toggle-indicator-button/toggle-indicator-button.component';
import { ScaleRatingComponent } from './components/scale-rating/scale-rating.component';
import { SurveyQuestionDefinitionComponent } from './pages/survey-details/survey-question-definition/survey-question-definition.component';
import { SurveyPaymentSettingsComponent } from './pages/survey-details/survey-payment-settings/survey-payment-settings.component';
import { PaymentsSettingsComponent } from './pages/settings/payments-settings/payments-settings.component';
import { ExternalSurveysComponent } from './pages/external-surveys/external-surveys.component';
import { MyResponsesComponent } from './pages/my-responses/my-responses.component';

@NgModule({
  declarations: [
    AppComponent,

    AccountSettingsComponent,
    AiAssistantModalComponent,
    AlertBoxComponent,
    AuthActionComponent,
    AuthTemplateComponent,
    CohortDetailsComponent,
    CohortsComponent,
    CohortsPortletComponent,
    ContactMemberDetailsComponent,
    CreditCardComponent,
    DashboardComponent,
    DoSurveyComponent,
    DragDropDirective,
    ExternalSurveysComponent,
    ForgotPasswordComponent,
    GeneralSettingsComponent,
    LeftNavPanelComponent,
    LoadingComponent,
    MainTemplateComponent,
    MyResponsesComponent,
    OnboardingBadgeComponent,
    OptionArrayInputComponent,
    Page404Component,
    Page500Component,
    PaymentsSettingsComponent,
    PlanCalculatorComponent,
    PortletComponent,
    QuestionEditorComponent,
    QuestionLibraryComponent,
    RenderResponsesComponent,
    ResetPasswordStepTwoComponent,
    ResponseLibraryComponent,
    ResponseViewerComponent,
    RightPanelComponent,
    ScaleRatingComponent,
    SettingsComponent,
    SigninComponent,
    SignupComponent,
    StarRatingComponent,
    SubscribeBannerComponent,
    SurveyDetailsComponent,
    SurveyPanelComponent,
    SurveyParticipantsComponent,
    SurveyPaymentSettingsComponent,
    SurveyPointPortletComponent,
    SurveyPreviewComponent,
    SurveyQuestionDefinitionComponent,
    SurveyQuestionEditorComponent,
    SurveysComponent,
    SurveyTemplateComponent,
    ToggleIndicatorButtonComponent,
    TopToolbarComponent,
    TouchpointComponent,
    TouchPointPortletComponent,
    ViewResponseExternalComponent,

    // Modals
    ManageProductModalComponent,
    AddQuestionLibraryModalComponent,
    CancelSubscriptionConfirmModalComponent,
    ConfirmModalComponent,
    ManageCohortModalComponent,
    ManageContactModalComponent,
    ManageQuestionConditionsModalComponent,
    ManageSurveyModalComponent,
    ManageTouchpointModalComponent,
    OnboardingModalComponent,
    PlanSignupModalComponent,
    PlanUpgradeModalComponent,
    SurveyProUpgradeModalComponent,
    UpdatePaymentInfoModalComponent,
    UploadTouchpointModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BaseChartDirective,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSelectModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    NgxStripeModule.forRoot(environment.stripe.publishableKey),
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['link'],
                // ['link', 'image'],
                ['clean']
            ]
        }
    }),
    SafeHtmlPipe,
    StripHtmlPipe    
],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    // provideAnalytics(() => getAnalytics()),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideCharts(withDefaultRegisterables()),
    provideAnimationsAsync(),
    { provide : TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    // MatDatepickerModule,
    importProvidersFrom(MatNativeDateModule),
    ScreenTrackingService,
    UserTrackingService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
      showDelay: 750,
      hideDelay: 0,
      touchendHideDelay: 0
    }},
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }},{ 
      provide : HTTP_INTERCEPTORS,
      useClass : AuthInterceptor,
      multi : true
    },{
      provide : ErrorHandler,
      useClass : ErrorHandlingService
    }, provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
