<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <h1>Response Library</h1>
  </div>
</section>

<!-- Page Content -->
  <!-- Filter Section -->
  <section class="flex items-center mb-10 sm:flex-wrap md:flex-wrap">
    <!-- <div class="sm:!w-full md:!w-full lg:flex-grow xl:flex-grow flex"> -->
    <div class="flex flex-grow">
      <mat-form-field appearance=outline class="w-full">
        <mat-select [(ngModel)]="selectedSurvey" placeholder="Filter by question set" (selectionChange)="handleSelectedSurveyChange()">
          <mat-option *ngFor="let survey of surveys" [value]="survey">{{ survey.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="sm:!w-full md:!w-full lg:!w-1/2 xl:!w-1/2 flex lg:ml-5 xl:ml-5">
      <mat-form-field appearance=outline class="searchInput flex flex-grow">
        <input matInput [(ngModel)]="filterText" (keyup)="handleFilter()" placeholder="Filter Responses">
        <fa-icon matSuffix class="pr-5" [icon]="faSearch"></fa-icon>
      </mat-form-field>
      <button class="flex !pt-5 pb-4 px-6 mb-6 ml-5" matTooltip="Export to CSV" (click)="exportResponses()"><fa-icon [icon]="faFileExport"></fa-icon></button>
    </div>
  </section>

<!-- Response Table -->
<table mat-table [dataSource]="dataSource" class="">
  <!-- Contact Name Column -->
  <ng-container matColumnDef="contact_name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{element.contact_name}}</td>
  </ng-container>

  <!-- Cohort Name -->
  <ng-container matColumnDef="cohort_name">
    <th mat-header-cell *matHeaderCellDef>Cohort</th>
    <td mat-cell *matCellDef="let element">{{element.cohort_name}}</td>
  </ng-container>

  <!-- Company Name Column -->
  <ng-container matColumnDef="contact_company_name">
    <th mat-header-cell *matHeaderCellDef>Company</th>
    <td mat-cell *matCellDef="let element">{{element.contact_company_name}}</td>
  </ng-container>

  <!-- Contact Date Column -->
  <ng-container matColumnDef="recorded_date">
    <th mat-header-cell *matHeaderCellDef>Contact Date</th>
    <td mat-cell *matCellDef="let element">{{element.recorded_date | date: 'yyyy-MM-dd' }}</td>
  </ng-container>

  <!-- Question Library Column -->
  <ng-container matColumnDef="ql">
    <th mat-header-cell *matHeaderCellDef>Question Set</th>
    <td mat-cell *matCellDef="let element">{{renderQuestionAsString(element.ql)}}</td>
  </ng-container>

  <!-- Responses Column -->
  <ng-container matColumnDef="responses">
    <th mat-header-cell *matHeaderCellDef>Responses</th>
    <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="viewResponse(element)">
      <span class="font-bold">View Responses</span>
      <span class="float-right">
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </span>
      <!-- <div class="mb-1" *ngFor="let response of element.responses">
        <div class="font-bold">{{response.question | stripHtml }}</div>
        <div>{{response.answer}}</div>
        <div class="italic">{{response.extra}}</div>
      </div> -->
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row align-middle"></tr>
  <tr class="mat-row" *matNoDataRow>
    <ng-container *ngIf="isLoading">
      <td [attr.colspan]="displayedColumns.length" class="py-5">
        <mat-progress-spinner color="primary" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
      </td>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <td [attr.colspan]="displayedColumns.length">
        <div class="text-center my-10" *ngIf="isFiltered()">
            <h4 *ngIf="dataSource.filter">No responses found matching the filter "{{dataSource.filter}}"</h4>
            <h4 *ngIf="!dataSource.filter">
              No responses yet!<br/>
              You'll find their responses here when we finish contacting them
            </h4>
        </div>
        <div class="text-center my-10" *ngIf="!isFiltered()">
          <h4>
            Select a question set using the dropdown above to view responses
          </h4>
        </div>
      </td>
    </ng-container>
  </tr>
</table>

<!-- Pagination Section -->
<section class="flex items-center justify-between pt-10">
  <div class="w-full">
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</section>