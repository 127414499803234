import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {
  public frmSignin : FormGroup = new FormGroup({});
  public showForgotPassword : boolean = false;

  constructor(
    private auth : AuthService,
    private dss : DataSharingService,
    private router : Router,
    private snackbar : MatSnackBar
  ) { 
    this.frmSignin = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  
  signin() {
    try {
      this.showForgotPassword = false;

      if(this.frmSignin.invalid) {
        this.snackbar.open('Please provide a valid email and password.', 'Dismiss', { duration: 3000 });
        return;
      } else {
        let email = this.frmSignin.get('email')?.value;
        let password = this.frmSignin.get('password')?.value;
        
        this.auth.signin(email, password).subscribe({
          next: (res) => {
            // console.log('signin.component:026', res);
            this.auth.saveAuth(res.payload);
            this.router.navigate(['/dashboard']);
          },
          error: (err) => {
            if(err.code) {
              switch(err.code) {
                case 'auth/user-not-found':
                case 'auth/wrong-password':
                case 'auth/invalid-credential':
                  this.showForgotPassword = true;
                  this.snackbar.open("The credentials your provided were incorrect.  Please try again.", 'Dismiss', { duration: 3000 });
                  break;
                case 'auth/too-many-requests':
                  this.snackbar.open('Well, this is starting to look suspicious...  Did you forget your password?', 'Dismiss', { duration: 3000 });
                  this.showForgotPassword = true;
                  // TODO : Log this event
                  break;
                default:
                  this.snackbar.open('An error occurred. Please try again.', 'Dismiss', { duration: 3000 });
                  console.error('signin.component:785437', err);
                  break;
              }
            } else {
              console.error('signin.component:101064', err);
              this.snackbar.open('An error occurred. Please try again.', 'Dismiss', { duration: 3000 });
            }
          }
        });
      }
    } catch (error) {
      console.error('signin.component:692755', error);
      this.snackbar.open('An error occurred. Please try again.', 'Dismiss', { duration: 3000 });
    }
  }

}
