<!-- Left Nav Panel -->
<ng-container>
  <div class="navbar-menu relative z-50 xl:flex xl:flex-col">
    <div class="navbar-backdrop fixed xl:hidden inset-0 bg-gray-800 opacity-10" (click)="toggleMenu()"></div>
    <div class="fixed inset-0 max-w-xss bg-white">
      <div class="flex-1 py-6 overflow-x-hidden overflow-y-auto">
        <div class="flex flex-col flex-wrap px-7 -m-2.5">
          <div class="pt-5 pb-6">
            <a class="inline-flex items-center h-7" href="#"><img src="../assets/SurveyPoint-logo.png" alt=""></a>
          </div>
  
          <!-- Menu items -->
          <div class="menu">
            <a class="menu-item" (click)="navTo('/dashboard')">
              <fa-icon class="menu-item-icon" [icon]="faHouse"></fa-icon>
              <span class="menu-item-text ">Dashboard</span>
            </a>
            <!-- <mat-divider></mat-divider> -->
            <!-- Surveys -->
            <div class="border-t w-full" *ngIf="enableSurveys">
              <a class="menu-item" (click)="navTo('/surveys')">
                <onboarding-badge class="w-full flex items-center" [step]="[7]" tip="You can view all of the surveys you've created here">
                  <fa-icon class="menu-item-icon" [icon]="faSquarePollHorizontal"></fa-icon>
                  <span class="menu-item-text ">Surveys</span>
                </onboarding-badge>
              </a>
              <a class="menu-item" (click)="navTo('/rl')">
                <onboarding-badge class="w-full flex items-center" [step]="[8]" tip="You can view all of the responses that come in here">
                  <fa-icon class="menu-item-icon" [icon]="faClipboard"></fa-icon>
                  <span class="menu-item-text ">Responses</span>
                </onboarding-badge>
              </a>
              <a class="menu-item" (click)="navTo('/cohorts')">
                <onboarding-badge class="w-full flex items-center" [step]="[9]" tip="If you work with specific groups of people, you can manage them as cohorts here">
                  <fa-icon class="menu-item-icon" [icon]="faPeople"></fa-icon>
                  <span class="menu-item-text ">Cohorts</span>
                </onboarding-badge>
              </a>
            </div>

            <!-- Touchpoints -->
            <div class="border-t w-full" *ngIf="enableTouchpoints">
              <mat-divider></mat-divider>
              <a class="menu-item" (click)="navTo('/touchpoints')">
                <fa-icon class="menu-item-icon" [icon]="faDiagramProject"></fa-icon>
                <span class="menu-item-text ">Touchpoint Manager</span>
              </a>
              <a class="menu-item" (click)="navTo('/qlt')">
                <fa-icon class="menu-item-icon" [icon]="faBook"></fa-icon>
                <span class="menu-item-text ">Question Bank</span>
              </a>
              <a class="menu-item" (click)="navTo('/rl')">
                <fa-icon class="menu-item-icon" [icon]="faClipboard"></fa-icon>
                <span class="menu-item-text">Responses</span>
              </a>
            </div>
  
            <!-- External surveys and completed responses -->
            <div class="border-t w-full">
              <a class="menu-item" (click)="navTo('/my/responses')">
                <fa-icon class="menu-item-icon" [icon]="faClipboardCheck"></fa-icon>
                <span class="menu-item-text ">My responses</span>
              </a>
              <a class="menu-item" (click)="navTo('/my/ext-surveys')">
                <fa-icon class="menu-item-icon" [icon]="faCheckToSlot"></fa-icon>
                <span class="menu-item-text ">External surveys</span>
              </a>
            </div>
            <div class="border-t w-full">
              <a class="menu-item" (click)="navTo('/settings')">
                <fa-icon class="menu-item-icon" [icon]="faGear"></fa-icon>
                <span class="menu-item-text ">Settings</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>