import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService extends ErrorHandler {
  private count = 0;

  override handleError(error: any) {
    this.count++;
    console.error('Error : (', this.count, ')', error);
  }

  // constructor() { }
}
