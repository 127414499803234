import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { faArrowsRotate, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { SurveyModel } from 'src/app/models/survey.model';
import { MerchantProductModel } from 'src/app/models/tpp.model';
import { SurveysService } from 'src/app/services/surveys.service';
import { TPPService } from 'src/app/services/tpp.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ApiResponseModel } from 'src/app/services/api.service';

@Component({
  selector: 'app-manage-product-modal',
  templateUrl: './manage-product-modal.component.html',
  styleUrl: './manage-product-modal.component.scss'
})
export class ManageProductModalComponent {
  cdr = inject(ChangeDetectorRef);
  data = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  modal = inject(MatDialog);
  tpp = inject(TPPService);
  surveysService = inject(SurveysService);  

  public isSyncing : boolean = false;

  // Strings
  public title : string = 'Manage Product';
  public description : string = `Products are packages of surveys that can be assigned prices. To begin, define your pricing structure in Stripe and sync them with SurveyPoint. Next, assign the pricing and surveys to your product. If you assign multiple surveys, when a user purchases this product, they will receive access to all of the surveys in the product.`;
  public product_name : string = '';

  // Icons
  faArrowsRotate = faArrowsRotate;
  faCircleXmark = faCircleXmark;
  faPlus = faPlus;
  faUpRightFromSquare = faUpRightFromSquare;
  faTrash = faTrash;

  // Models
  public product : MerchantProductModel = new MerchantProductModel();
  public products : MerchantProductModel[] = [];
  public surveys : SurveyModel[] = [];
  public selectedSurvey : SurveyModel = new SurveyModel();
  public selectedSurveys : SurveyModel[] = [];
  public stripeProducts : any[] = [];
  public selectedStripeProduct : any = {};

  public tooltips = {
    acctSetupComplete : 'This account is set up and ready to receive payments. Update this field if you need to change the account.',
    enablePayments : 'Allow clients to pay for surveys online. This will require you to set up a Stripe account.',
    name : 'The name of the product. This will be displayed to the client.',
    surveys : 'Select a survey then click the "+" button to add it to the product.',
    stripeAccount : 'This is the account that will receive payments from clients. This account must be set up with Stripe and it starts with "acct_".',
    stripePriceId : 'The price of the product (defined in Stripe). This will be used to charge the client.',
    frequency : 'How often should we charge the participant?',
    payment_timing: 'When should we ask for payment?',
    currency : 'Currency to to use',
    amount: 'Amount to charge the participant',
  }

  constructor() { }

  ngOnInit() {
    if(this.data && this.data.product) {
      this.product = this.data.product;
      this.product_name = this.product.name;
      console.log('manage-product-modal.component:238123', this.product);
    }

    if(this.data && this.data.products) {
      this.products = this.data.products;
      console.log('manage-product-modal.component:832045', this.products);      
    }

    this.syncPrices();
    this.loadSurveys();
  }

  ngOnDestroy() {
    console.log('manage-product-modal.component:934157', 'destroyed');
  }

  close() {
    this.dialogRef.close();
  }

  handleSave() {
    this.tpp.updateMerchantProduct(this.product.id, this.product).subscribe({
      next : (response) => {
        if(response && response.payload) {
          this.dialogRef.close({
            action : 'save',
            product : this.product
          });
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  public syncPrices() {
    this.isSyncing = true;
    this.tpp.getStripeProducts().subscribe({
      next : (response) => {
        if(response && response.payload) {
          this.stripeProducts = response.payload;
          console.log('manage-product-modal.component:252382', this.stripeProducts);
        }
        this.isSyncing = false;
      },
      error : (error) => {
        console.log(error);
        this.isSyncing = false;
      }
    });
  }

  public addPrice() {
    if(!this.selectedStripeProduct) { return; }
    console.log('manage-product-modal.component:822185', this.selectedStripeProduct);
    let exists = this.product.stripe_price_ids.find((p) => p === this.selectedStripeProduct);
    if(exists) { return; }

    this.product.stripe_price_ids.push(this.selectedStripeProduct);
    this.selectedStripeProduct = null;
    console.log('manage-product-modal.component:322787', this.product.stripe_price_ids, this.selectedStripeProduct);
  }

  public removeProduct(product : any) {
    let index = this.product.stripe_price_ids.findIndex((p) => p === product);
    if(index > -1) {
      this.product.stripe_price_ids.splice(index, 1);
    }

    console.log('manage-product-modal.component:918477', product, this.product.stripe_price_ids, index);
  }

  public addSurvey(survey? : SurveyModel) {
    if(!survey) {
      survey = this.selectedSurvey;
    }

    if(!survey.id) { return; }

    // Check if the survey is already added
    let exists = this.selectedSurveys.find((s) => s.id === survey.id);
    if(exists) {
      return;
    }

    // If the survey has already been assigned
    console.log('manage-product-modal.component:023753',this.product, survey);
    this.tpp.assignSurveyProduct(this.product, survey).subscribe({
      next : (response) => {
        if(response && response.payload) {
          this.selectedSurveys.push(survey);

          // Find the survey in the surveys list
          let s = this.surveys.find((s) => s.id === survey.id);
          console.log('manage-product-modal.component:790406', s, survey.id);
          if(s) {
            s.merchant_product_id = this.product.id;
          }

          if(!this.product.surveys) {
            this.product.surveys = [];
          }
          this.product.surveys.push(survey);

          console.log('manage-product-modal.component:310391', this.selectedSurveys, '\nProduct\n', this.product, '\nSurvey\n', survey);
          this.selectedSurvey = new SurveyModel();
        }
      },
      error : (error) => {
        console.log('manage-product-modal.component:013187', error);
      }
    });
  }

  public isSurveyAssignedtoAnotherProduct(survey : SurveyModel) {
    return survey.merchant_product_id;
    // return survey.merchant_product_id && survey.merchant_product_id !== this.product.id;
  }

  public removeSurvey(survey : SurveyModel) {
    this.tpp.unassignSurveyProduct(this.product, survey).subscribe({
      next : (response) => {
        if(response && response.payload) {
          // Remove from selectedSurveys
          let index = this.selectedSurveys.findIndex((s) => s.id === survey.id);
          if(index > -1) {
            this.selectedSurveys.splice(index, 1);
          }

          // Remove from product.surveys
          let pIndex = this.product.surveys?.findIndex((s) => s.id === survey.id);
          if(pIndex && pIndex > -1) {
            this.product.surveys?.splice(pIndex, 1);
          }

          // Remove from surveys
          let sIndex = this.surveys.findIndex((s) => s.id === survey.id);
          if(sIndex && sIndex > -1) {
            this.surveys[sIndex].merchant_product_id = null;
          }
        }
      },
      error : (error) => {
        console.log('manage-product-modal.component:013187', error);
      }
    });
  }

  public loadSurveys() {
    this.surveysService.getSurveyList().subscribe({
      next : (response : ApiResponseModel) => {
        if(response && response.payload) {
          this.surveys = response.payload;
          this.selectedSurveys = this.product.surveys || [];
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  stripeIdAssigned(stripe_price_id : string) {
    // search for the stripe_price_id in the products
    let product = this.products.find((p) => p.stripe_price_ids.includes(stripe_price_id));
    return !!product;
  }

  stripeIdAssignedTo(stripe_price_id : string) {
    // search for the stripe_price_id in the products
    let product = this.products.find((p) => p.stripe_price_ids.includes(stripe_price_id));
    return product?.name;
  }

  getStripePrice(price_id : string) {
    return this.stripeProducts.find((p) => p.id === price_id);
  }

  deleteProduct() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please Confirm',
      message: `<p>Your are about to delete this product. Please note that this will prevent users from accessing the surveys that they have purchased unless you reassign the Stripe Product Pricing to another product. This will also NOT cancel your users' subscriptions. If you would also like to cancel their subscription, please complete the process in your Stripe control panel.</p>  <br/><br/><b>Are you sure you want to delete this product?</b>`,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.tpp.deleteMerchantProduct(this.product.id).subscribe({
          next : (response) => {
            if(response && response.payload) {
              this.dialogRef.close({
                action : 'delete',
                product : this.product
              });
            }
          },
          error : (error) => {
            console.log(error);
          }
        });
      }
    });
  }

  renderStripeField(price_id : string, field : string) {
    let price = this.getStripePrice(price_id);
    if(price) {
      return price[field];
    }
    return '';
  }

  shortenPriceId(price_id : string) {
    return price_id.slice(0, 10) + '...' + price_id.slice(price_id.length - 10, price_id.length);
  }

  navToStripeProducts() {
    window.open('https://dashboard.stripe.com/products', '_blank');
  }
}
