import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'scale-rating',
  templateUrl: './scale-rating.component.html',
  styleUrl: './scale-rating.component.scss'
})
export class ScaleRatingComponent {
  @Input() rating : any = 0;
  @Output() ratingChange = new EventEmitter<any>();
  @Input() maxRating : number = 10;
  @Input() lowLabel : string = '';
  @Input() highLabel : string = '';
  @Input() structure : { name : string, value : string }[] = [];

  public _rating : number = 0;

  ngOnChanges(changes : SimpleChanges) {
    if(changes['rating']) {
      let r = changes['rating'].currentValue;
      this._rating = parseInt(r);
    }
  }

  setRating(event : any) {
    this._rating = event.value;
    this.ratingChange.emit(event.value);
  }

}
