import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'alert-box',
  templateUrl: './alert-box.component.html',
  styleUrl: './alert-box.component.scss'
})
export class AlertBoxComponent {
  @Input() type : string = 'info';
  @Input() clickable : boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes) { return; }

    if(changes['type']) {
      this.type = changes['type'].currentValue;
    }

    if(changes['clickable']) {
      this.clickable = changes['clickable'].currentValue;
    }
  }

  getClasses() {
    let classes = ['alert'];
    if(this.type) {
      classes.push(`${this.type}`);
    }
    if(this.clickable) {
      classes.push('cursor-pointer');
    }
    return classes.join(' ');
  }
}
