import { SurveyModel, SurveySummaryModel } from "./survey.model";
import { v4 as uuidv4 } from 'uuid';

export class Merchants {
  id? : string;
  uid? : string;
  company_id? : string;
  stripe_user_id? : string;
  charges_enabled? : boolean;
  payouts_enabled? : boolean;
  requirements? : any;

  constructor() {
    this.id = uuidv4();
    this.uid = '';
    this.company_id = '';
    this.stripe_user_id = '';
    this.charges_enabled = true;
    this.payouts_enabled = true;
    this.requirements = {};
  }
}

export class MerchantProductModel {
  id : string;
  company_id : string;
  merchant_id? : string;
  stripe_price_ids : string[];
  name : string;
  surveys? : SurveyModel[];

  constructor() {
    this.id = uuidv4();
    this.company_id = '';
    this.stripe_price_ids = [];
    this.name = '';
    this.surveys = [];
  }
}

// export class TPPProductSurveyModel {
//   id? : number;
//   tpp_product_id : string;
//   survey_id : string;
//   survey_summary? : SurveySummaryModel;
//   survey? : SurveyModel;
//   tpp_product? : MerchantProductModel;

//   constructor() {
//     this.tpp_product_id = '';
//     this.survey_id = '';
//   }
// }