<!-- Question Section -->
<ng-container *ngIf="!question">
   <h1 class="text-3xl font-bold text-gray-500 text-center">Loading question</h1>
</ng-container>
 <ng-container *ngIf="question">
   <div class="question-title text-center mt-10 mb-20" [innerHTML]="question.question | safeHtml"></div>
   <form [formGroup]="frmQuestion">
     <div class="mt-10 mb-20">
      <!-- Text -->
       <ng-container *ngIf="question.field_type === 'text'">
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput class="input" type="text" formControlName="question" placeholder="Type your answer here" (keyup.enter)="submit()">
          </mat-form-field>
        </div>
       </ng-container>
  
      <!-- Email -->
       <ng-container *ngIf="question.field_type === 'email'">
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput class="input" type="email" formControlName="question" placeholder="Type your answer here" (keyup.enter)="submit()" (change)="updateResponse()">
          </mat-form-field>
        </div>
       </ng-container>
  
       <!-- Number -->
       <ng-container *ngIf="question.field_type === 'number'">
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput class="input" type="number" formControlName="question" placeholder="Type your answer here" (keyup.enter)="submit()" (change)="updateResponse()">
          </mat-form-field>
        </div>
       </ng-container>
  
       <!-- Textarea -->
       <ng-container *ngIf="question.field_type === 'textarea'">
         <mat-form-field appearance=outline class="w-full">
           <textarea matInput class="input" formControlName="question" placeholder="Type your answer here" (change)="updateResponse()"></textarea>
         </mat-form-field>
       </ng-container>
  
       <!-- Radio -->
       <ng-container *ngIf="question.field_type === 'radio'">
         <div class="w-full">
           <mat-radio-group formControlName="question" class="w-full">
             <div *ngFor="let option of question.field_options; let i = index" class="w-full text-left">
               <mat-radio-button class="w-full" [value]="option.name" (change)="updateOptionResponse(option)">{{ option.name }}</mat-radio-button>
             </div>
           </mat-radio-group>
         </div>
       </ng-container>
       
       <!-- Checkbox -->
       <ng-container *ngIf="question.field_type === 'checkbox'">
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <div *ngFor="let option of question.field_options; let i = index">
              <label class="checkbox">
                <input matInput type="checkbox" formControlName="question" [value]="option.name" (change)="updateOptionResponse(option)">{{ option }}
              </label>
            </div>
          </mat-form-field>
        </div>
       </ng-container>
  
       <!-- Star Rating -->
       <ng-container *ngIf="question.field_type === 'star_rating'">
        <div class="w-full text-center mx-auto">
          <star-rating [(rating)]="response.answer" class="inline-block" (change)="updateResponse()"></star-rating>
        </div>
       </ng-container>

       <!-- Scale Rating -->
       <ng-container *ngIf="question.field_type === 'scale_rating'">
        <div class="w-full text-center mx-auto">
          <scale-rating [(rating)]="response.answer" [maxRating]="getQuestionConfig('scale_max')" [lowLabel]="getQuestionConfig('min_label')" [highLabel]="getQuestionConfig('max_label')" class="inline-block" (change)="updateResponse()"></scale-rating>
        </div>
       </ng-container>
     </div>
   </form>
 </ng-container>