import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isLoading : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public message : BehaviorSubject<string> = new BehaviorSubject<string>('Loading...');
  private loaderqueue : number = 0;

  constructor() { }

  start(loadingMessage : string = 'Loading...') {
    this.loaderqueue++;
    this.message.next(loadingMessage);
    this.isLoading.next(true);
  }

  updateMessage(message : string) {
    this.message.next(message);
  }

  stop() {
    this.loaderqueue--;

    if (this.loaderqueue <= 0) {
      this.message.next('Loading...');
      this.isLoading.next(false);
      this.loaderqueue = 0;
    }
  }

}
