import { MerchantProductModel } from "./tpp.model";

export class CompaniesModel {
  id : string;
  name : string | null;
  email : string | null;
  website : string | null;
  address : string | null;
  city : string | null;
  province : string | null;
  postal_code : string | null;
  country : string | null;
  preferences? : CompanyPreferences | null;
  tpp? : ITPPModel;

  constructor() {
    this.id = '';
    this.name = null;
    this.email = null;
    this.website = null;
    this.address = null;
    this.city = null;
    this.province = null;
    this.postal_code = null;
    this.country = null;
  } 
}

export class CompanyPreferences {
  timezone? : string | null;
  start_time? : string | null;
  end_time? : string | null;

  constructor() {}
}

export interface ITPPModel {
  enableTPP? : boolean;
  stripe_acct? : string;
  products? : MerchantProductModel[];
}
