<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">Setup Payment Information</h3>
  <div class="my-5 pr-5">
    <!-- <mat-stepper linear #stepper>
      <mat-step [stepControl]="frmPlanSelect">
        <ng-template matStepLabel>Select a plan</ng-template>
        <form [formGroup]="frmPlanSelect">
          <plan-calculator (onPlanSelected)="planSelected($event)" [plan]="selectedPlan"></plan-calculator>
          <div class="text-center" *ngIf="frmPlanSelect.valid">
            <button class="btn !text-white !py-8 !px-8" [disabled]="(currentPlan?.id == selectedPlan?.id)" mat-button matStepperNext>Sounds Good!</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="frmStripe">
        <ng-template matStepLabel>Complete payment details</ng-template> -->
        <form class="my-5 grid grid-cols-12 gap-4" [formGroup]="frmStripe">
          <div class="col-span-12">
            <mat-label class="font-bold w-full">Cardholder Name</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput formControlName="cardholder" required>
            </mat-form-field>
          </div>
          <div class="col-span-12">
            <mat-label class="font-bold w-full">Address</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput formControlName="addrl1" required>
            </mat-form-field>
          </div>
          <div class="col-span-6">
            <mat-label class="font-bold w-full">City</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput formControlName="city" required>
            </mat-form-field>
          </div>
          <div class="col-span-6">
            <mat-label class="font-bold w-full">State/Province</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput formControlName="state" required>
            </mat-form-field>
          </div>
          <div class="col-span-6">
            <mat-label class="font-bold w-full">Zip/Postal Code</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput formControlName="postal" required>
            </mat-form-field>
          </div>
          <div class="col-span-6">
            <mat-label class="font-bold w-full">Country</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <mat-select formControlName="country">
                @for (country of countries; track country) {
                  <mat-option [value]="country.id">{{ country.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-span-12">
            <div class="stripeInput">
              <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
                <ngx-stripe-card (change)="onElementsChange($event)" [options]="cardOptions" />
              </ngx-stripe-elements>
            </div>
          </div>
          <div class="col-span-12">
            <div class="text-center">
              <!-- <button class="!btn-secondary-outline !py-8 !px-8 mr-3" mat-stroked-button matStepperPrevious>Back</button> -->
              <!-- <button class="btn !text-white !py-8 !px-8" [disabled]="!step2Valid() || paymentInProgress" (click)="completePayment()" mat-button matStepperNext> -->
              <button class="btn !text-white !py-8 !px-8" [disabled]="!step2Valid() || paymentInProgress" (click)="completePayment()" mat-button>
                {{ paymentInProgress ? 'Processing...' : 'Complete Payment Setup' }}
              </button>
            </div>
          </div>
        </form>
      <!-- </mat-step>
    </mat-stepper> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-left w-1/5 pl-5 mb-5">
  </div>
  <div class="w-3/5 pl-5">
  </div>
  <div class="float-right w-1/5 text-right pr-5">
    <button class="btn-secondary-outline mr-3" data-dismiss="modal" type="button" (click)="closeModal()">Close</button>
  </div>
</mat-dialog-actions>