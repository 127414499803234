import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainTemplateComponent } from './templates/main-template/main-template.component';
import { TouchpointComponent } from './pages/touchpoints/touchpoints.component';
import { QuestionLibraryComponent } from './pages/question-library/question-library.component';
import { AuthTemplateComponent } from './templates/auth-template/auth-template.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Page404Component } from './pages/page404/page404.component';
import { Page500Component } from './pages/page500/page500.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { QuestionEditorComponent } from './pages/question-editor/question-editor.component';
import { ResponseLibraryComponent } from './pages/response-library/response-library.component';
import { AuthGuard } from './guards/auth-guards.guard';
import { AuthActionComponent } from './pages/auth-action/auth-action.component';
import { SurveyTemplateComponent } from './templates/survey-template/survey-template.component';
import { DoSurveyComponent } from './pages/do-survey/do-survey.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { SurveyDetailsComponent } from './pages/survey-details/survey-details.component';
import { CohortsComponent } from './pages/cohorts/cohorts.component';
import { CohortDetailsComponent } from './pages/cohorts/cohort-details/cohort-details.component';
import { ContactMemberDetailsComponent } from './pages/contact-member-details/contact-member-details.component';
import { ResponseViewerComponent } from './pages/response-library/response-viewer/response-viewer.component';
import { ViewResponseExternalComponent } from './pages/view-response-external/view-response-external.component';
import { ExternalSurveysComponent } from './pages/external-surveys/external-surveys.component';
import { MyResponsesComponent } from './pages/my-responses/my-responses.component';

const routes: Routes = [
  {
    path: '',
    component: MainTemplateComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'touchpoints', component: TouchpointComponent },
      { path: 'qlt', component: QuestionLibraryComponent },
      { path: 'qlt/details/:id', component: QuestionEditorComponent },
      { path: 'rl', component: ResponseLibraryComponent },
      { path: 'rl/:id', component: ResponseLibraryComponent },
      { path: 'rl/:id/response/:rid', component: ResponseViewerComponent },
      { path: 'cohorts', component: CohortsComponent },
      { path: 'cohorts/:id', component: CohortDetailsComponent },
      { path: 'cohorts/:cohort_id/contacts/:id', component: ContactMemberDetailsComponent },

      { path: 'my/responses', component: MyResponsesComponent },
      { path: 'my/responses/:id', component: ResponseViewerComponent },
      { path: 'my/ext-surveys', component: ExternalSurveysComponent },

      { path: 'surveys', component: SurveysComponent },
      { path: 'surveys/:id', component: SurveyDetailsComponent },

      { path: 'settings', component: SettingsComponent },
    ]
  },{
    path: '',
    component: AuthTemplateComponent,
    children: [
      { path: 'signin', component: SigninComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'reset-password', component: ForgotPasswordComponent },
      { path: 'auth-action', component: AuthActionComponent }
    ]
  },{
    path: '',
    component: SurveyTemplateComponent,
    children: [
      { path: 's', component: DoSurveyComponent },
      { path: 's/:ref', component: DoSurveyComponent },
      { path: 'i', component: DoSurveyComponent },
      { path: 'i/:ref', component: DoSurveyComponent },
      { path: 'r/:id', component: ViewResponseExternalComponent },
    ]
  },
  { path: 'error/404',     component: Page404Component },
  { path: 'error/500',     component: Page500Component },
  { path: '**', redirectTo: '/error/404' },
  { path: '', redirectTo: '/signup', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
