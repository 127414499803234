<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">{{ isNew ? 'Add Cohort':'Edit Cohort' }}</div>
  <div class="my-10">
    <form class="grid grid-cols-12 gap-4" [formGroup]="frmAddCohort">
      <div class="col-span-12">
        <mat-label class="font-bold">Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput placeholder="Enter a name" formControlName="cohort_name">
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-left w-1/2">
    <button *ngIf="!isNew" class="btn-warn-outline bg-white" type="button" (click)="delete()">Delete</button>
  </div>
  <div class="float-right w-1/2 text-right">
    <button class="btn-secondary-outline mr-3" type="button" (click)="close()">Close</button>
    <button class="bg-primary mr-3" type="button" (click)="save()">Save</button>
  </div>
</mat-dialog-actions>