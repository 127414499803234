import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
  standalone: true
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string | null): any {
    if (!value) {
      return '';
    }
    return value.replace(/<.*?>/g, ''); // replace tags
  }

}
