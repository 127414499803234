<div class="auth-content-box">
  <!-- Left Panel -->
  <div class="xs:w-full sm:w-full md:w-full lg:w-1/2">
    <div class="lg:max-w-md center">
      <div class="max-w-md mx-auto text-center py-10">
        <form [formGroup]="frmSignup">
          <img class="center mb-10" src="../assets/SurveyPoint-logo.png" alt="">
          <h3 class="mb-2 text-3xl font-semibold font-heading">Create new account</h3>
          <alert-box *ngIf="promo_code_description">{{ promo_code_description }}</alert-box>
          <div class="flex flex-wrap -mx-2">
            <div class="relative w-full lg:w-1/2 px-2 flex flex-wrap mb-6">
              <mat-label class="font-bold">First Name</mat-label>
              <mat-form-field appearance=outline class="w-full">
                <input matInput placeholder="John" formControlName="first_name">
              </mat-form-field>
            </div>
            <div class="relative w-full lg:w-1/2 px-2 flex flex-wrap mb-6">
              <mat-label class="font-bold">Last Name</mat-label>
              <mat-form-field appearance=outline class="w-full">
                <input matInput placeholder="Doe" formControlName="last_name">
              </mat-form-field>
            </div>
          </div>
          <div class="relative flex flex-wrap mb-6">
            <mat-label class="font-bold">Company Name</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput placeholder="My Company" formControlName="company_name">
            </mat-form-field>
          </div>
          <div class="relative flex flex-wrap mb-6">
            <mat-label class="font-bold">Email Address</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput placeholder="email@domain.com" formControlName="email">
            </mat-form-field>
          </div>
          <div class="relative flex flex-wrap mb-6">
            <mat-label class="font-bold">Password</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput type="password" formControlName="password">
            </mat-form-field>
          </div>
          <div class="relative flex flex-wrap mb-6">
            <mat-checkbox class="" formControlName="confirmTerms">I agree to the Terms of Service</mat-checkbox>
          </div>
          <div class="error-block">
            <!-- <alert-box type="error">
              <div>{{ error }}</div>
            </alert-box> -->
            <alert-box type="warn" *ngIf="submitted && (first_name?.hasError('required') || last_name?.hasError('required') || email?.hasError('required') || password?.hasError('required') )">
              <div>Please check that all fields are completed</div>
            </alert-box>
            <alert-box type="warn" *ngIf="submitted && email?.hasError('email')">
              <div>You must provide a valid email address</div>
            </alert-box>
            <alert-box type="warn" *ngIf="submitted && password?.hasError('pattern')">
              <div>Your password should be at least 8 characters and have at least one uppercase, lowercase, and number</div>
            </alert-box>
            <alert-box type="warn" *ngIf="submitted && confirmTerms?.hasError('required')">
              <div>You must agree to the terms and conditions</div>
            </alert-box>
          </div>
          <button class="w-full mb-4" (click)="submit()">Get Started</button>
          <p class="text-sm text-gray-500 font-bold mb-3">Already have an account?</p>
          <button [routerLink]="'/signin'" class="w-full btn-secondary-outline text-white">Sign In</button>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Panel -->
  <div class="xs:hidden sm:hidden md:hidden lg:block lg:w-1/2 login-splash-image">
  </div>
</div>