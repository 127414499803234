export const environment = {
  api : {
    url : 'https://sptestapi.surveypoint.io'
  },
  firebase: {
    apiKey: "AIzaSyD_ZzSk225hsMfq0_Pkw2rZcsQRq3vK4O0",
    authDomain: "thirdpartytouch-test.firebaseapp.com",
    databaseURL: "https://thirdpartytouch-test-default-rtdb.firebaseio.com",
    projectId: "thirdpartytouch-test",
    storageBucket: "thirdpartytouch-test.appspot.com",
    messagingSenderId: "577483823284",
    appId: "1:577483823284:web:39e853b5d0deebbf949fe1",
    measurementId: "G-GRJV043LFC"
  },
  stripe: {
    publishableKey: 'pk_test_51I3qFmFmF2GjHESsAwfQDK7FbQUMYqYR5oC9C4llsJCXmXrycIfkEOtkzOTFal9QBFc2d1fdxO7ooxxChooYLFCz00xkKxD9Qc',
    clientId: 'ca_JGkPtpbPcDXzANF7gGt3ZJhbeakpYBTH',
    connectAuthorizeUrl: 'https://connect.stripe.com/oauth/authorize',
    redirectUri: 'http://localhost:4200/oauth/stripe'
  },
  production: false
};
