import { Component, inject } from '@angular/core';
import { QuestionsModel } from 'src/app/models/question_library.model';
import { SurveyModel } from 'src/app/models/survey.model';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-survey-preview',
  templateUrl: './survey-preview.component.html',
  styleUrl: './survey-preview.component.scss'
})
export class SurveyPreviewComponent {
  dss = inject(DataSharingService);

  faGreaterThan = faGreaterThan;
  faLessThan = faLessThan;

  public survey : SurveyModel | null = null;
  public  position = 0;
  public currentQuestion : QuestionsModel = new QuestionsModel();

  ngOnInit() {
    this.dss.currentSurvey.subscribe(survey => {
      this.survey = survey;
      if(this.survey) {
        this.position = 0;
        this.currentQuestion = this.survey.questions[this.position];
      }
    });
  }

  nextQuestion() {
    // console.log('survey-preview.component:381271', this.survey, this.position, 'sql', this.survey!.questions.length);
    if(!this.survey) { return; }

    // Don't go forward if we're at the last question
    if(this.position < this.survey.questions.length - 1) {
      this.position++;
      this.currentQuestion = this.survey.questions[this.position];
      return;
    }
  }

  previousQuestion() {
    // console.log('survey-preview.component:787353', this.survey, this.position);
    if(!this.survey) { return; }

    // Don't go back if we're at the first question
    if(this.position > 0) {
      this.position--;
      this.currentQuestion = this.survey.questions[this.position];
      return;
    }
  }
}
