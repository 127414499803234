<tpt-portlet>
  <div portlet-header>
    <div class="grid grid-cols-12">
      <div class="col-span-6">
        <h2>Recent TouchPoints</h2>
      </div>
      <div class="col-span-6 text-right">
        <button class="form-button bg-white text-gray-600" matTooltip="Refresh TouchPoint List" (click)="reloadPortlet()">
          <div [ngClass]="{ 'animate-spin' : portletLoading }">
            <fa-icon [icon]="faRefresh"></fa-icon>
          </div>
        </button>
        <button class="form-button ml-3 bg-white text-gray-600" matTooltip="Create Touchpoint" (click)="openTouchpointModal()">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div portlet-body>
    <div class="grid grid-cols-12 gap-4">
      <div class="font-bold col-span-8 px-4 py-2">Name</div>
      <div class="font-bold col-span-4 px-4 py-2">Status</div>
      <div class="col-span-12">
        <ng-container *ngIf="touchpoints.length === 0">
          <div class="text-center">No touchpoints found</div>
        </ng-container>
        <ng-container *ngIf="touchpoints.length > 0">
          <div class="portlet-table-row grid grid-cols-12 gap-4" (click)="handleStatusClick(touchpoint)" [matTooltip]="getStatusTooltip(touchpoint.status)" *ngFor="let touchpoint of touchpoints">
            <div class="col-span-8 px-4 py-2 cursor-pointer">{{ touchpoint.contact_name }}</div>
            <div class="col-span-4 px-4 py-2 cursor-pointer">{{ getStatusTooltip(touchpoint.status) }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</tpt-portlet>