<div class="auth-content-box">
  <!-- Left Panel -->
  <div class="xs:px-10 sm:w-full md:w-full lg:w-1/2">
    <div class="lg:max-w-md center">
      <div class="max-w-md mx-auto text-center py-10">
        <img class="center mb-10" src="../assets/SurveyPoint-logo.png" alt="">

        <ng-container [ngSwitch]="mode">
          <ng-container *ngSwitchCase="'resetPassword'">
            <reset-password-step-two [oobCode]="oobCode"></reset-password-step-two>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <h1 class="text-2xl font-bold text-gray-800 mb-3">You seem to be lost...</h1>
            <p class="my-6">Were you trying to do one of these actions?</p>
            <p class="text-sm text-gray-500 font-bold mb-3">No account yet?</p>
            <button [routerLink]="'/signup'" class="w-full btn-secondary-outline text-white">Get Started</button>
            <p class="text-sm text-gray-500 font-bold my-3">Did you remember what it was?</p>
            <button [routerLink]="'/signin'" class="w-full btn-secondary-outline text-white">Sign In</button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Right Panel -->
  <div class="hidden lg:block lg:w-1/2 login-splash-image">
    
  </div>
</div>