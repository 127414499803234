<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">
    <span class="mt-5 float-left">{{ isNew ? 'Add' : 'Edit' }} Touchpoint </span>
    <span class="mt-5 float-right">
      <!-- <mat-chip class="mat-success py-2 px-5">{{ touchpoint.status }}</mat-chip> -->
      <div class="touchpoint-status {{ squishText(getTouchpointStatus(touchpoint.status).toLowerCase()) }} py-2 px-5">{{ getTouchpointStatus(touchpoint.status) }}</div>
    </span>
  </div>
  <alert-box type="info" *ngIf="touchpoint.status =='In queue'">
    This Touchpoint is queued for calling and cannot be edited. To remove it from the queue, <span class="cursor-pointer font-bold" (click)="pauseTouchpoint()">click here</span>.
  </alert-box>
  <alert-box type="info" *ngIf="touchpoint.status =='Paused'">
    This Touchpoint is paused. No calls will be made until you resume it. To resume it, <span class="cursor-pointer font-bold" (click)="resumeTouchpoint()">click here</span>.
  </alert-box>
  <alert-box type="warn" *ngIf="frmTouchpoint.invalid && frmTouchpoint.touched && time_slot_start?.hasError('startEndError')">
    The calling time window should end after it starts.
  </alert-box>
  <alert-box type="warn" *ngIf="frmTouchpoint.invalid && frmTouchpoint.touched && time_slot_start?.hasError('requiredGapError')">
    To ensure that we have higher chances of reaching the contact, please make sure that the time slot is at least {{ GAP/100 }} hours apart.
  </alert-box>
  <div class="mt-10 mb-5" *ngIf="getTouchpointStatus(touchpoint.status) == 'Error'">
    <alert-box type="warn" *ngFor="let error of touchpointErrors">
      {{ error }}
    </alert-box>
  </div>

  <div class="my-10">
    <form class="grid grid-cols-12 gap-4" [formGroup]="frmTouchpoint">
      <div class="col-span-6">
        <mat-label class="font-bold">Contact Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="contact_name">
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold">Phone Number</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="contact_phone_number">
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold">Contact's Company Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="contact_company_name">
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold" matTooltip="Provide the name of the person the contact would recognize">Representative Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="representative">
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold" matTooltip="Is there a specific event you're asking about?">Reference Date</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="event_date">
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold" matTooltip="What product/service were you dealing with the contact with?">Reference Product/Service</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="product_service">
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold" matTooltip="Provide any additional information to remind the contact about what we're asking about">Context</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="context">
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold">Question Set to Ask</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <mat-select [value]="touchpoint.question_library_id" formControlName="question_library">
            @for (q of ql; track q) {
              <mat-option [value]="q.id">{{ q.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold">Best Time to Call</mat-label>
        <span class="flex items-center">
          <mat-form-field appearance=outline class="">
            <mat-select [value]="touchpoint.time_slot_start" formControlName="time_slot_start">
              @for (hour of hours; track hour) {
            <mat-option [value]="hour.value">{{hour.desc}}</mat-option>
          }
            </mat-select>
          </mat-form-field>
          <div class="px-5">to</div>
          <mat-form-field appearance=outline class="">
            <mat-select [value]="touchpoint.time_slot_end" formControlName="time_slot_end">
              @for (hour of hours; track hour) {
            <mat-option [value]="hour.value">{{hour.desc}}</mat-option>
          }
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold" matTooltip="You can change this setting in Settings|Preferences">Time zone</mat-label>
        <div class="w-full">
          <mat-form-field appearance=outline class="w-full">
            <input matInput formControlName="contact_timezone">
          </mat-form-field>
        </div>
      </div>
      <div class="col-span-12">
        <mat-checkbox formControlName="contact_asap" class="">Call ASAP (this will count as 2 touchpoints)</mat-checkbox>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-left w-1/2">
    <button *ngIf="!isNew && !isReadOnly" class="btn-warn-outline" type="button" (click)="deleteTouchpoint()">Delete</button>
  </div>
  <div class="float-right w-1/2 text-right">
    <button class="btn-secondary-outline mr-3" type="button" (click)="close()">Close</button>
    <button class="bg-primary mr-3" *ngIf="!isReadOnly" data-dismiss="modal" type="button" (click)="save()">Save</button>
  </div>
</mat-dialog-actions>