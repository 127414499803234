import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
  standalone: true
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    private sanitizer : DomSanitizer,
  ) {}

  transform(html : string | null) {
    if (!html) return '';
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
