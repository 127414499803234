<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">AI Assistant</div>
  <div class="my-10">
    <form class="grid grid-cols-12 gap-4" [formGroup]="frmPrompt">
      <div class="col-span-12">
        <mat-label class="font-bold">What kind of survey would you like to create?</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <textarea matInput class="input !h-40" formControlName="prompt" placeholder="Enter prompt here"></textarea>
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold">How many questions should it create?</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput type="number" formControlName="number_of_questions" min="1" max="10" (keyup)="limitNumber()">
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex xs:flex-wrap lg:flex-nowrap">
    <button class="xs:w-full xs:mr-0 lg:mr-4 lg:w-1/2 btn-secondary-outline" type="button" (click)="close()">Close</button>
    <button class="xs:w-full xs:mt-4 lg:mt-0 lg:w-1/2 bg-primary" type="button" [disabled]="frmPrompt.invalid && !isSubscribed" (click)="submit()">Save</button>
  </div>
</mat-dialog-actions>