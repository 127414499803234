import { Component, inject, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { SurveyResponsesModel } from 'src/app/models/responses.model';
import { SurveyModel } from 'src/app/models/survey.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { MyService } from 'src/app/services/my.service';

@Component({
  selector: 'app-my-responses',
  templateUrl: './my-responses.component.html',
  styleUrl: './my-responses.component.scss'
})
export class MyResponsesComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  my = inject(MyService);
  dss = inject(DataSharingService);
  router = inject(Router);


  public isLoading : boolean = false
  private surveyIds : string[] = [];
  public surveys : SurveyModel[] = [];
  public responses : SurveyResponsesModel[] = [];
  public responseFilter : string | null = null;

  faSearch = faSearch;
  faPlus = faPlus;

  public dataSource : MatTableDataSource<SurveyResponsesModel> = new MatTableDataSource<SurveyResponsesModel>();
  public displayedColumns: string[] = [
    'recorded_date', 
    'survey_name',
    'score', 
  ];

  constructor() { }

  ngOnInit(): void {
    this.loadResponses();
  }

  loadResponses() {
    this.isLoading = true;
    this.my.getMyResponses().subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.responses = response.payload;
          this.dataSource = new MatTableDataSource(response.payload);
          this.dataSource.paginator = this.paginator;
        }
        this.isLoading = false;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  loadSurveys() {
    this.isLoading = true;
    this.my.getMySurveys(this.surveyIds).subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.surveys = response.payload;
        }
        this.isLoading = false;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  updateFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue === '' || filterValue === null || filterValue === undefined) {
      this.dataSource.filter = '';
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  renderSurveyName(response : SurveyResponsesModel) {
    if(response.survey_id != null) {
      let survey = this.surveys.find(survey => survey.id === response.survey_id);
      if(survey) {
        return survey.name;
      }
    }

    return '';

  }

  isFiltered() {
    return this.dataSource.filter !== null && this.dataSource.filter !== undefined && this.dataSource.filter !== '';
  }

  viewResponse(response : SurveyResponsesModel) {
    this.dss.currentSurveyResponse.next(response);
    this.router.navigate(['/my/responses', response.id]);
  }
}