import { ChangeDetectorRef, Component, inject, ViewChild, viewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { faGear, faArrowLeft, faFloppyDisk, faTrash, faRocket, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { QuestionsModel } from 'src/app/models/question_library.model';
import { SURVEY_FINAL_RESULT_DETAIL, SURVEY_FINAL_RESULT_TIMING, SurveyModel, SurveyParticipantsModel } from 'src/app/models/survey.model';
import { ApiResponseModel, ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SurveyQuestionEditorComponent } from './survey-question-editor/survey-question-editor.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import * as dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { PlanUpgradeModalComponent } from 'src/app/modals/plan-upgrade-modal/plan-upgrade-modal.component';
import { LoadingService } from 'src/app/services/loading.service';
import { CompaniesModel } from 'src/app/models/companies.model';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrl: './survey-details.component.scss'
})
export class SurveyDetailsComponent {
  api = inject(ApiService);
  companyService = inject(CompanyService);
  cd = inject(ChangeDetectorRef);
  dss = inject(DataSharingService);
  loading = inject(LoadingService);
  modal = inject(MatDialog);
  router = inject(Router);
  route = inject(ActivatedRoute);
  snackbar = inject(MatSnackBar);
  subscriptionService = inject(SubscriptionService);
  surveyService = inject(SurveysService);

  @ViewChild(SurveyQuestionEditorComponent) surveyQuestionEditor! : SurveyQuestionEditorComponent;
  accordion = viewChild.required(MatAccordion);

  faGear = faGear;
  faArrowLeft = faArrowLeft;
  faFloppyDisk = faFloppyDisk;
  faTrash = faTrash;
  faRocket = faRocket;
  faShareNodes = faShareNodes;
  faCircleQuestion = faCircleQuestion;

  SURVEY_FINAL_RESULT_TIMING = SURVEY_FINAL_RESULT_TIMING;
  SURVEY_FINAL_RESULT_DETAIL = SURVEY_FINAL_RESULT_DETAIL;

  editorConfig = {
    // license_key: 'wppw67dx4m3kcswajia9vsa7xqxdryeovbbch7t4s0o33pl5',
    api_key: 'gpl',
    base_url: '/tinymce',
    suffix: '.min',
    height: 250,
    branding: false,
    menubar: false, 
    plugins: [
      // 'advlist lists link image print preview anchor table',
      'advlist', 'lists', 'image', 'link'
      // 'advlist autolink lists link image charmap print preview anchor',
      // 'searchreplace visualblocks code fullscreen',
      // 'insertdatetime media table paste code'
    ],
    toolbar:
      `formatselect | bold italic forecolor fontsize | table image link |
      alignleft aligncenter alignright alignjustify | 
      bullist numlist outdent indent | removeformat | undo redo`,
  };

  public survey : SurveyModel = new SurveyModel();
  public participants : SurveyParticipantsModel[] = [];
  public questions : QuestionsModel[] = [];
  public frmSurveyDetails : FormGroup = new FormGroup({});
  public questionsChanged : boolean = false;
  public questionsInvalid : boolean = false;
  public today : Date = new Date(); 
  public enablePro : boolean = false;
  public tabIndex : number = 0;
  public company : CompaniesModel = new CompaniesModel();

  public tooltips = {
    allow_back : 'Allow participants to go back to previous questions',
    availability : 'The start and end date of when the survey will be available to participants. Leave blank for no end date.',
    delete : 'Delete the survey',
    final_result_detail : 'How much detail to show in the final results',
    final_result_timing : 'When to show the final results to participants',
    participants : 'For invite-only surveys, only those who have received invites can respond. Anyone can respond to surveys marked Public.',
    publish : 'Publish the survey to participants',
    registration_required : 'Determine how participants must register to respond to the survey. If "Yes", participants will be required to register before responding. If "No", participants can respond without registering. If "Optional", participants can choose to skip registration. Note that skipping registration, we cannot track who responded.',
    show_branding : 'Show the Third Party Touch branding on the survey',
    shuffle_questions : 'Randomizes the order of the questions for each participant. Please note that any conditional logic will be disabled.',
    save : 'Save changes to the survey',
    share : 'Copy the survey link to the clipboard for sharing',
    cantshare : "Can't share this survey because it's invitation only",
    is_anonymous : 'For anonymous surveys, you will receive the responses, but the responses will not be linked to the participant.',
    reusable_invitations : 'Can invitations be reused?',
    allow_multiple_entries : 'Allow participants to respond to the survey multiple times',
    allow_incorrect_advance : 'Allow participants to advance to the next question even if they get the current question wrong',
    quiz_mode : 'Quiz mode allows you to assign scores to questions',
    editor_welcome_tooltip : `
      Try these to customize your message to your participants: \n
      {{name}} - shows the users name\n
      {{email}} - shows the user's email address\n
      {{company}} - shows the user's company\n
      {{start}} - shows the survey's start date\n
      {{end}} - shows the survey's end date`,
    editor_thankyou_tooltip : `
      Try these to customize your message to your participants: \n
      {{name}} - shows the users name\n
      {{email}} - shows the user's email address\n
      {{company}} - shows the user's company\n
      {{start}} - shows the survey's start date\n
      {{end}} - shows the survey's end date`,

    pro_disabled : 'Unlock these features by upgrading to Pro',
    pro_is_public : 'Pro users can create invite-only surveys. Only those who have received invites can respond. The invitations are tied to the participants\' responses.',
    pro_is_anonymous : 'Pro users can create anonymous surveys. Anonymous surveys do not require participants to identify themselves to respond. The responses will not be linked to the participant.',
  }

  constructor() { 
    if(this.dss.currentSurvey.value) {
      this.survey = this.dss.currentSurvey.value;
    } else {
      this.survey = new SurveyModel();      
    }

    if(this.companyService.company) {
      this.company = this.companyService.company.value || new CompaniesModel();
      // console.log('survey-details.component:313174', this.company);
    }


    this.frmSurveyDetails = new FormGroup({
      name : new FormControl('', [Validators.required]),
      welcome : new FormControl(''),
      thank_you : new FormControl(''),
      is_anonymous : new FormControl(false),
      is_scored : new FormControl(false),
      invite_only : new FormControl(false),
      start : new FormControl(''),
      end : new FormControl(''),
      require_registration : new FormControl('Y'),
      show_branding : new FormControl(false),
      shuffle_questions : new FormControl(false),
      allow_back : new FormControl(false),
      quiz_mode : new FormControl(false),
      allow_incorrect_advance : new FormControl(true),
      allow_multiple_entries : new FormControl(false),
      reusable_invitations : new FormControl(false),
      final_result_timing : new FormControl(SURVEY_FINAL_RESULT_TIMING.NEVER),
      final_result_detail : new FormControl(SURVEY_FINAL_RESULT_DETAIL.NONE),
    });
  }

  get start() { return this.frmSurveyDetails.get('start'); }
  get end() { return this.frmSurveyDetails.get('end'); }


  ngOnInit(): void {
    // Grab the params and if the survey id 
    // If different from the current survey id, load the new survey
    this.route.params.subscribe((params) => {
      if(this.survey) {
        if(this.survey.id == params['id']) {
          if(!this.survey.questions) { 
            this.survey.questions = [];
          }

          // console.log('survey-details.component:042476', this.survey.questions.length, this.survey.questions);
          this.questions = this.survey.questions;
          this.populateForm(this.survey);
        } else {
          this.loadSurvey(params['id']);
        }
      } else {
        // Nothing loaded yet -- load it on
        this.loadSurvey(params['id']);
      }
    });

    this.subscriptionService.stripeSubscription.subscribe(
      (subscription : any) => {
        if(subscription) {
          // Found subscriptions, now check if we have the subscription for this service
          if(this.subscriptionService.hasPlanforService('survey')) {
            this.enablePro = true;
          } else {
            this.enablePro = false;
          }
        } else {
          this.enablePro = false;
        }

        if(this.survey?.is_published) {
          this.setPublishedFormFeatures();
        } else {
          this.setUnPublishedFormFeatures();
        }
      }
    )
  }

  loadSurvey(id : string) {
    this.loading.start('Loading survey details...');
    this.surveyService.getSurvey(id).subscribe({
      next : (response) => {
        // console.log('survey-details.component:449963', response);
        if(response.payload) {
          this.survey = response.payload;
          this.questions = this.survey!.questions? this.survey!.questions : [];
          // console.log('survey-details.component:966694', this.questions.length);
          this.populateForm(this.survey);
          this.dss.currentSurvey.next(this.survey);
        } else {
          // Survey not found
          this.survey = new SurveyModel();
          this.dss.currentSurvey.next(this.survey);
        }

        this.loading.stop();

        // console.log('survey-details.component:019274', this.questions.length, this.questions);
      },
      error : (error) => {
        console.log(error);
        this.loading.stop();
      }
    });
  }

  populateForm(survey : SurveyModel | null) {
    if(!survey) return;

    if(!survey.config) {
      survey.config = {
        final_result_detail: SURVEY_FINAL_RESULT_DETAIL.NONE,
        final_result_timing: SURVEY_FINAL_RESULT_TIMING.NEVER
      };
    }

    this.frmSurveyDetails.patchValue({
      name : survey.name,
      welcome : survey.welcome,
      thank_you : survey.thank_you,
      is_anonymous : survey.is_anonymous,
      invite_only : !survey.is_public,
      start : survey.start,
      end : survey.end,
      require_registration : survey.require_registration,
      show_branding : survey.is_branded,
      shuffle_questions : survey.shuffle_questions,
      allow_back : survey.allow_back,
      quiz_mode : survey.is_scored,
      allow_incorrect_advance : !survey.allow_incorrect_advance,
      allow_multiple_entries : survey.allow_multiple_entries,
      reusable_invitations : survey.config.reusable_invitations,
      final_result_detail : survey.config.final_result_detail,
      final_result_timing : survey.config.final_result_timing
    });

    if(this.survey?.is_published) {
      this.setPublishedFormFeatures();
    } else {
      this.setUnPublishedFormFeatures();
    }
  }

  back() {
    window.history.back();
    // this.router.navigate(['/surveys']);
  }

  enableSave() {
    return (this.frmSurveyDetails.valid && this.frmSurveyDetails.dirty) || (this.questionsChanged && !this.questionsInvalid);
  }

  setPublishedFormFeatures() {
    if(this.enablePro) {
      this.frmSurveyDetails.get('is_anonymous')?.disable();
      this.frmSurveyDetails.get('shuffle_questions')?.disable();
      this.frmSurveyDetails.get('quiz_mode')?.disable();
      this.frmSurveyDetails.get('allow_incorrect_advance')?.disable();  
      this.frmSurveyDetails.get('invite_only')?.disable();
      this.frmSurveyDetails.get('allow_multiple_entries')?.disable();
      this.frmSurveyDetails.get('reusable_invitations')?.disable();
      this.frmSurveyDetails.get('require_registration')?.disable();
      this.frmSurveyDetails.get('final_result_detail')?.enable();
      this.frmSurveyDetails.get('final_result_timing')?.enable();
      this.frmSurveyDetails.get('show_branding')?.enable();
    } else {
      this.frmSurveyDetails.get('is_anonymous')?.disable();
      this.frmSurveyDetails.get('shuffle_questions')?.disable();
      this.frmSurveyDetails.get('quiz_mode')?.disable();
      this.frmSurveyDetails.get('allow_incorrect_advance')?.disable();  
      this.frmSurveyDetails.get('invite_only')?.disable();
      this.frmSurveyDetails.get('allow_multiple_entries')?.disable();
      this.frmSurveyDetails.get('reusable_invitations')?.disable();
      this.frmSurveyDetails.get('require_registration')?.disable();
      this.frmSurveyDetails.get('final_result_detail')?.disable();
      this.frmSurveyDetails.get('final_result_timing')?.disable();
      this.frmSurveyDetails.get('show_branding')?.disable();
    }
  }

  setUnPublishedFormFeatures() {
    if(this.enablePro) {
      this.frmSurveyDetails.get('is_anonymous')?.enable();
      this.frmSurveyDetails.get('shuffle_questions')?.enable();
      this.frmSurveyDetails.get('quiz_mode')?.enable();
      this.frmSurveyDetails.get('invite_only')?.enable();
      this.frmSurveyDetails.get('allow_incorrect_advance')?.enable();  
      this.frmSurveyDetails.get('reusable_invitations')?.enable();
      this.frmSurveyDetails.get('allow_multiple_entries')?.enable();
      this.frmSurveyDetails.get('require_registration')?.enable();
      this.frmSurveyDetails.get('final_result_detail')?.enable();
      this.frmSurveyDetails.get('final_result_timing')?.enable();
      this.frmSurveyDetails.get('show_branding')?.enable();
    } else {
      this.frmSurveyDetails.get('is_anonymous')?.disable();
      this.frmSurveyDetails.get('shuffle_questions')?.disable();
      this.frmSurveyDetails.get('quiz_mode')?.disable();
      this.frmSurveyDetails.get('allow_incorrect_advance')?.disable();  
      this.frmSurveyDetails.get('invite_only')?.disable();
      this.frmSurveyDetails.get('allow_multiple_entries')?.disable();
      this.frmSurveyDetails.get('reusable_invitations')?.disable();
      this.frmSurveyDetails.get('require_registration')?.disable();
      this.frmSurveyDetails.get('final_result_detail')?.disable();
      this.frmSurveyDetails.get('final_result_timing')?.disable();
      this.frmSurveyDetails.get('show_branding')?.disable();
    }
  }

  saveSurvey() {
    if(!this.survey) return;
    
    this.survey!.questions = this.questions;

    let survey = this.survey;
    survey.name = this.frmSurveyDetails.get('name')?.value;
    survey.welcome = this.frmSurveyDetails.get('welcome')?.value;
    survey.thank_you = this.frmSurveyDetails.get('thank_you')?.value;
    survey.is_anonymous = this.frmSurveyDetails.get('is_anonymous')?.value;
    survey.is_public = !this.frmSurveyDetails.get('invite_only')?.value;
    survey.start = this.frmSurveyDetails.get('start')?.value;
    survey.end = this.frmSurveyDetails.get('end')?.value;
    survey.is_branded = this.frmSurveyDetails.get('show_branding')?.value;
    survey.is_scored = this.frmSurveyDetails.get('quiz_mode')?.value;
    survey.allow_incorrect_advance = !this.frmSurveyDetails.get('allow_incorrect_advance')?.value;
    survey.shuffle_questions = this.frmSurveyDetails.get('shuffle_questions')?.value;
    survey.require_registration = this.frmSurveyDetails.get('require_registration')?.value;
    survey.allow_back = this.frmSurveyDetails.get('allow_back')?.value;
    survey.allow_multiple_entries = this.frmSurveyDetails.get('allow_multiple_entries')?.value;
    survey.config.reusable_invitations = this.frmSurveyDetails.get('reusable_invitations')?.value;
    survey.config.final_result_detail = this.frmSurveyDetails.get('final_result_detail')?.value;
    survey.config.final_result_timing = this.frmSurveyDetails.get('final_result_timing')?.value;

    // console.log('survey-details.component:559331', this.survey, this.participants);

    this.api.put((this.api.endpoints.surveys + '/' + this.survey!.id), survey).subscribe({
      next : (response : ApiResponseModel) => {
        this.frmSurveyDetails.markAsPristine();
        this.surveyQuestionEditor.frmQuestionSetEditor.markAsPristine();
        this.questionsChanged = false;
        this.questionsInvalid = false;
        // console.log('survey-details.component:914718', this.frmSurveyDetails.valid, ' || ',this.frmSurveyDetails.dirty,' || ', this.questionsChanged, !this.questionsInvalid);
      },
      error : (error) => {
        console.log(error);
      }
    });
  }
  
  isPublishable() {
    let isPublished = this.survey?.is_published || false;
    let invitationButNoParticipants = !this.survey?.is_public && this.participants.length === 0;

    let rst = !isPublished && !invitationButNoParticipants && !this.questionsInvalid && this.frmSurveyDetails.valid;
    return rst;
  }

  inviteButNoParticipants() {
    return !this.survey?.is_public && this.participants.length === 0;
  }

  onCloseButNoEndDate() {
    if(!this.survey) return false;
    if(!this.survey.config) return false;

    let se = this.survey.end ? true : false;

    return this.survey.config.final_result_timing == SURVEY_FINAL_RESULT_TIMING.ON_CLOSE && !se;
  }

  handlePublishSurvey() {
    let additionalMessages = '';

    this.survey!.is_public = !this.frmSurveyDetails.get('invite_only')?.value;
    this.survey!.is_anonymous = this.frmSurveyDetails.get('is_anonymous')?.value;

    console.log('survey-details.component:661425', this.survey?.is_public, this.survey?.is_anonymous);

    if(!this.survey) return;

    if(this.survey?.is_public) {
      additionalMessages += '<li>Anyone with the link or has been sent an invitation can respond.</li>';

      if(this.survey?.is_anonymous) {
        additionalMessages += `<li>You won't receive any information about the participant other than their responses</li>`;
      }
    } else {
      additionalMessages += `<li><span class="font-bold">Invite only</span> - Only those who have received invites can respond. We'll send the invites as soon as the survey is published</li>`;

      if(this.survey?.is_anonymous) {
        additionalMessages += `<li><span class="font-bold">The invitations are not tied to the participants' responses.</li>`;
      }
    }

    if(this.survey?.is_scored) {
      additionalMessages += '<li>The survey is in <span class="font-bold">Quiz Mode</span>.  We will track the scores for their responses</li>';
    }

    if(this.survey?.end) {
      if(this.survey?.start) {
        additionalMessages += `<li>The survey will be available to participants between ${this.renderDate(this.survey.start)} and ${this.renderDate(this.survey.end)}</li>`;
      } else {
        additionalMessages += `<li>The survey will be available to participants until ${this.renderDate(this.survey.end)}</li>`;
      }
    } else {
      if(this.survey?.start) {
        additionalMessages += `<li>The survey will be available to participants starting ${this.renderDate(this.survey.start)}</li>`;
      } else {
        additionalMessages += '<li>The survey will be available to participants immediately</li>';
      }
    }

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please Confirm',
      message: `<p>This survey will be available to participants immediately.  Here's how you set up the survey:</p><ul class="my-5 ml-4 list-disc">${additionalMessages}</ul><p>Once your survey is published, you won't be able to change these settings.  <br/><br/><b>Are you sure you want to publish this survey?</b></p>`,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.survey!.is_published = true;
        this.saveSurvey();
        this.publishSurvey();
      }
    });
  }

  publishSurvey() {
    if(!this.survey) return;

    this.survey.is_published = true;
    this.surveyService.publishSurvey(this.survey).subscribe({
      next : (response : ApiResponseModel) => {
        // console.log(response);
        this.snackbar.open('The survey has been published', 'Dismiss', { duration: 3000 });
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  updateSurveyModel(name : string, event : any) {
    if(!this.survey) return;
    if(!this.survey.config) {
      this.survey.config = {
        final_result_detail: SURVEY_FINAL_RESULT_DETAIL.NONE,
        final_result_timing: SURVEY_FINAL_RESULT_TIMING.NEVER
      };
    }

    switch(name) {
      case 'name':
        this.survey.name = event.value;
        break;
      case 'welcome':
        this.survey.welcome = event.value;
        break;
      case 'thank_you':
        this.survey.thank_you = event.value;
        break;
      case 'is_anonymous':
        this.survey.is_anonymous = event.checked;
        break;
      case 'invite_only':
        this.survey.is_public = !event.checked;
        if(event.checked) {
          this.frmSurveyDetails.get('reusable_invitations')?.enable();
        } else {
          this.frmSurveyDetails.get('reusable_invitations')?.disable();
        }
        break;
      case 'require_registration':
        this.survey.require_registration = event.value;
        break;
      case 'show_branding':
        this.survey.is_branded = event.checked;
        break;
      case 'shuffle_questions':
        this.survey.shuffle_questions = event.checked;
        break;
      case 'allow_back':
        this.survey.allow_back = event.checked;
        break;
      case 'allow_incorrect_advance':
        this.survey.allow_back = event.checked;
        break;
      case 'start':
        this.survey.start = event.value;
        break;
      case 'end':
        this.survey.end = event.value;
        break;
      case 'reusable_invitations':
        this.survey.config.reusable_invitations = event.value;
        break;
      case 'allow_multiple':
        this.survey.allow_multiple_entries = event.checked;
        break;
      case 'final_result_detail':
        this.survey.config.final_result_detail = event.value;
        break;
      case 'final_result_timing':
        this.survey.config.final_result_timing = event.value;
        break;
    }
  }

  renderDate(date : string) {
    if(!date) return '';

    return dayjs(date).format('MMMM DD, YYYY');
  }

  deleteSurvey() {
    console.log('survey-details.component:559332');
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
    title: 'Please confirm',
    message: 'Are you sure you want to delete this survey? This action cannot be undone.',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.api.delete((this.api.endpoints.surveys + '/' + this.survey!.id), this.survey).subscribe({
          next : (response : ApiResponseModel) => {
            console.log(response);
            this.router.navigate(['/surveys']);
          },
          error : (error) => {
            console.log(error);
            this.snackbar.open('An error occurred while deleting the survey', 'Dismiss', { duration: 3000 });
          }
        });
      }
    });
  }
  
  renderSurveyName() {
    return this.survey ? this.survey.name : 'Unnamed Survey';
  }

  renderQuestions() {
    return JSON.stringify(this.questions);
  }

  renderSurveyStatus() {
    return this.survey?.is_published ? 'Published' : 'Draft';
  }

  markQuestionsChanged(event : any) {
    // console.log('survey-details.component:182996', event);
    this.questionsChanged = event;
    this.cd.detectChanges();
  }

  markQuestionsInvalid(event : any) {
    // console.log('survey-details.component:465225', event);
    this.questionsInvalid = event;
    this.cd.detectChanges();
  }

  canShare() {
    let rst = false;

    if(this.survey) {
      let isPublished = this.survey?.is_published || false;
      let isPublic = this.survey?.is_public || false;

      rst = isPublished && isPublic;
    }
    return rst;
  }

  shareSurvey() {
    if(this.survey) {
      let url = this.survey?.ref || '';
      url = window.location.origin + '/s/' + url;
      navigator.clipboard.writeText(url).then(() => {
        this.snackbar.open('The link to the survey has been copied to clipboard', 'Dismiss', { duration: 3000 });
      });
    } else {
      this.snackbar.open('No survey to share', 'Dismiss', { duration: 3000 });
    }
  }

  isPublished() {
    return this.survey?.is_published || false;
  }

  getShareTooltip() {
    return this.canShare() ? this.tooltips.share : this.tooltips.cantshare;
  }

  openUpgradeModal() {
    // If the user is already a pro user, don't show the modal
    if(this.enablePro) return;

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'lg:w-3/4' ];
    config.data = { 
      title : 'Upgrade to Pro to use this feature',
      feature : 'survey_pro_features',
    };

    this.modal.open(PlanUpgradeModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        console.log('subscribe-banner.component:159367', result);
        switch(result.action) {
          case 'subscribe':
            this.enablePro = this.subscriptionService.hasPlanforService('survey');
            break;
          default:
            break;
        }
      }
    });
  }

  getUpgradeTooltip() {
    return this.enablePro ? '' : this.tooltips.pro_disabled;
  }

  showPaymentsTab() {
    if(!this.company) return false;
    if(this.company.tpp && !this.company.tpp.enableTPP) return false;
    return true;
  }

  paymentAccountSet() {
    if(!this.company) return false;
    if(this.company.tpp && this.company.tpp.stripe_acct) return true;
    return false;
  }

  navToSettings() {
    this.router.navigate(['/settings']);
  }
}
