import { Injectable } from '@angular/core';
import { SurveyParticipantsModel } from '../models/survey.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyParticipantService {

  constructor(
    private api : ApiService
  ) { }

  public selfRegisterParticipant(survey_participant : SurveyParticipantsModel) {
    return this.api.post(this.api.endpoints.public_surveys + '/s/' + survey_participant.ref + '/participant', survey_participant);
  }

  public sendDirectInvite(survey_id : string, payload : any) {
    return this.api.post(this.api.endpoints.survey_participants.replace(':survey_id', survey_id) + '/direct', payload);
  }

  public sendCohortInvite(survey_id : string, payload : any) {
    return this.api.post(this.api.endpoints.survey_participants.replace(':survey_id', survey_id) + '/cohort', payload);
  }
}
