<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">{{ _title }}</h3>
  <div class="mb-5 mt-10 pr-5" [innerHTML]="_message">
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="mx-3 mt-10 w-full flex flex-nowrap mb-3">
    <button class="w-1/2 flex btn-secondary-outline mr-3" data-dismiss="modal" type="button" (click)="cancel()">
      <span class="text-center mx-auto">{{ _cancelButtonText }}</span>
    </button>
    <button class="w-1/2 flex btn bg-primary" type="button" (click)="confirm()">
      <span class="text-center mx-auto">{{ _confirmButtonText }}</span>
      </button>
  </div>
</mat-dialog-actions>