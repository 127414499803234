import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataSharingService } from './data-sharing.service';
import { CompaniesModel } from '../models/companies.model';
import { CompanyService } from './company.service';
let url = environment.api.url;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  dss = inject(DataSharingService);
  http = inject(HttpClient);

  public endpoints = {
    cohorts : url + '/v1/companies/:company_id/cohorts',
    companies : url + '/v1/companies',
    contacts : url + '/v1/companies/:company_id/cohorts/:cohort_id/contacts',
    my : url + '/v1/my',
    plans : url + '/v1/plans',
    promo_codes : url + '/v1/promo_codes/:code',
    public_surveys : url + '/v1/surveys',
    public_responses : url + '/v1/responses',
    question_library : url + '/v1/companies/:company_id/ql',
    question_structure : url + '/v1/ql-structure',
    question_templates : url + '/v1/ql-templates',
    refresh : url + '/v1/refresh',
    responses : url + '/v1/companies/:company_id/responses',
    signin : url + '/v1/signin',
    signout : url + '/v1/signout',
    signup : url + '/v1/signup',
    subscriptions : url + '/v1/companies/:company_id/subscriptions',
    surveys : url + '/v1/companies/:company_id/surveys',
    survey_ai : url + '/v1/companies/:company_id/surveys/assistant',
    survey_sessions : url + '/v1/surveys/sessions',
    survey_participants : url + '/v1/companies/:company_id/surveys/:survey_id/participants',
    touchpoints : url + '/v1/companies/:company_id/touchpoints',
    tpp : url + '/v1/companies/:company_id/tpp',
    users : url + '/v1/users',
  }

  private company : CompaniesModel | null = new CompaniesModel();

  constructor() { 
    this.dss.company.subscribe({
      next : (company) => {
        this.company = company ? company : new CompaniesModel();
      },
      error : (error) => {
        console.error('api.service:081', error);
      }
    });
  }

  public get(url: string, httpOptions?: any) : Observable<any> {
    url = url.replace(':company_id', this.company!.id);

    if(httpOptions)
      return this.http.get(url, httpOptions);
    else
      return this.http.get(url);
  }

  public getFile(url: string, httpOptions?: any) : Observable<any> {
    url = url.replace(':company_id', this.company!.id);

    if(httpOptions) {
      httpOptions = { ...httpOptions, responseType: 'blob' };
      return this.http.get(url, httpOptions);
    } else {
      return this.http.get(url, { responseType: 'blob' });
    }
  }

  public post(url: string, data: any, httpOptions? : any) : Observable<any> {
    url = url.replace(':company_id', this.company!.id);

    if(httpOptions) 
      return this.http.post(url, data, httpOptions);
    else
      return this.http.post(url, data);
  }

  public postFile(url: string, data: any, httpOptions? : any) : Observable<any> {
    url = url.replace(':company_id', this.company!.id);

    if(httpOptions) {
      httpOptions = { ...httpOptions, responseType: 'blob' };
      return this.http.post(url, data, httpOptions);
    } else {
      return this.http.post(url, data, { responseType: 'blob' });
    }
  }

  public put(url: string, data: any, httpOptions? : any) : Observable<any> {
    url = url.replace(':company_id', this.company!.id);

    if(httpOptions)
      return this.http.put(url, data, httpOptions);
    else
      return this.http.put(url, data);
  }

  public delete(url: string, httpOptions?: any) : Observable<any> {
    url = url.replace(':company_id', this.company!.id);

    if(httpOptions)
      return this.http.delete(url, httpOptions);
    else
      return this.http.delete(url);
  }
}

export interface ApiResponseModel {
  version? : string;
  status? : number;
  track? : string;
  message? : string;
  payload? : any;
}