<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <h1>Dashboard</h1>
  </div>
</section>

<!-- Banner -->
<tpt-subscribe-banner (onSubscribe)="updateSubscription()" *ngIf="!loadingSubscription && !isPro"></tpt-subscribe-banner>

<!-- Page Content -->
<div class="w-full mb-4 break-inside-avoid-column">
  <tpt-survey-point-portlet></tpt-survey-point-portlet>
</div>
  <section class="columns-2 gap-8 space-y-4">
    <!-- <div class="w-1/2">
      <tpt-touch-point-portlet></tpt-touch-point-portlet>
    </div> -->
    <ng-container *ngIf="cohorts">
      <div class="w-full break-inside-avoid-column" *ngFor="let cohort of cohorts">
        <tpt-cohorts-portlet [cohort]="cohort"></tpt-cohorts-portlet>
      </div>
    </ng-container>
  </section>