import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { TouchpointModel } from '../models/touchpoint.model';
import { ApiResponseModel, ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TouchpointService {
  public touchpoints : BehaviorSubject<TouchpointModel[]> = new BehaviorSubject<TouchpointModel[]>([]);

  constructor(
    private api : ApiService
  ) { }

  getTouchpoints(days? : number) {
    let query = '';

    if(days) {
      query = `?numberOfDays=${days}`;
    }

    this.api.get(this.api.endpoints.touchpoints + query).subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.touchpoints.next(response.payload);
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  add(touchpoint : TouchpointModel) {
    return this.api.post(this.api.endpoints.touchpoints, touchpoint).pipe(
      tap((response : ApiResponseModel) => {
        // Put the new touchpoint at the beginning of the list
        this.touchpoints.next([response.payload[0], ...this.touchpoints.value]);
      })
    )
  }

  update(touchpoint : TouchpointModel) {
    console.log('touchpoint.service:682277', touchpoint);
    return this.api.put(this.api.endpoints.touchpoints + '/' + touchpoint.id, touchpoint).pipe(
      tap((response : ApiResponseModel) => {
        let touchpoints = this.touchpoints.value;
        let index = touchpoints.findIndex((t) => t.id === touchpoint.id);
        touchpoints[index] = touchpoint;
        this.touchpoints.next(touchpoints);
      })
    )
  }

  delete(touchpoint : TouchpointModel) {
    return this.api.delete(this.api.endpoints.touchpoints + '/' + touchpoint.id).pipe(
      tap((response : ApiResponseModel) => {
        let touchpoints = this.touchpoints.value;
        let index = touchpoints.findIndex((t) => t.id === touchpoint.id);
        touchpoints.splice(index, 1);
        this.touchpoints.next(touchpoints);
      })
    )
  }
}
