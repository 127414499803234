import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty, faStarHalfStroke } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrl: './star-rating.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true
    }
  ]
})
export class StarRatingComponent {
  @Input() maxStars: number = 5; // Default to 5 stars
  @Input() rating: number = 0; // Default to no rating
  @Output() ratingChange = new EventEmitter<number>();

  stars: number[] = [];
  hoverRating: number = 0;

  faStarSolid = faStarSolid;
  faStarHalf = faStarHalfStroke;
  faStarEmpty = faStarEmpty;

  // For ControlValueAccessor
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {
    this.stars = Array(this.maxStars).fill(0);
  }

  getFillPercentage(starIndex: number): number {
    const ratingToCheck = this.hoverRating || this.rating;

    if (ratingToCheck >= starIndex) {
      return 100; // Full star
    } else if (ratingToCheck > starIndex - 1 && ratingToCheck < starIndex) {
      return 50; // Half star
    } else {
      return 0; // Empty star
    }
  }

  getIconForStar(starIndex: number): any {
    const fillPercentage = this.getFillPercentage(starIndex);

    if (fillPercentage === 100) {
      return this.faStarSolid; // Full star
    } else if (fillPercentage === 50) {
      return this.faStarHalf; // Half star
    } else {
      return this.faStarEmpty; // Empty star
    }
  }

  onMouseMove(event: MouseEvent, starIndex: number): void {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    const width = rect.width;

    const percentage = x / width;
    this.hoverRating = starIndex - 1 + (percentage > 0.5 ? 1 : 0.5);
  }

  onMouseLeave(): void {
    this.hoverRating = 0; // Reset to current rating on mouse leave
  }

  onClick(starIndex: number): void {
    this.rating = this.hoverRating || starIndex;
    this.ratingChange.emit(this.rating);
    this.onChange(this.rating); // Notify Angular form about the change
    this.onTouched();
  }

  // ControlValueAccessor interface methods
  writeValue(rating: number): void {
    this.rating = rating;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Optional: implement if you want to disable the component
  }
}