import { CompaniesModel } from "./companies.model";
import { QuestionsModel } from "./question_library.model";
import { SurveyResponsesModel } from "./responses.model";

export const SURVEY_FINAL_RESULT_DETAIL = {
  NONE : 'none',
  SUMMARY : 'summary',
  DETAILED : 'detailed',
  VERBOSE : 'verbose',
}

export const SURVEY_FINAL_RESULT_TIMING = {
  NEVER : 'never',
  ON_COMPLETE : 'on_complete',
  ON_CLOSE : 'on_close',
}

export class SurveyModel {
  active : boolean;
  allow_back : boolean;
  allow_multiple_entries : boolean;
  company_id : string | null;
  company? : CompaniesModel;
  merchant_product_id? : string | null;
  end : string | null;
  id : string | null;
  config : SurveyConfigModel;
  is_anonymous : boolean;
  is_branded : boolean;
  is_public : boolean;
  is_published : boolean;
  is_scored : boolean;
  allow_incorrect_advance : boolean;
  require_registration : string | null;
  shuffle_questions : boolean;
  max_score : number;
  name : string | null;
  questions : QuestionsModel[];
  ref : string | null;
  start : string | null;
  thank_you : string | null;
  welcome : string | null;
  survey_participant_id? : string;
  survey_participant? : SurveyParticipantsModel;
  selected? : boolean;  // not in the DB -- for UI purposes only

  constructor() {
    this.active = true;
    this.allow_back = true;
    this.allow_multiple_entries = false;
    this.company_id = null;
    this.config = new SurveyConfigModel();
    this.end = null;
    this.id = null;
    this.is_anonymous = false;
    this.is_branded = true;
    this.is_public = true;
    this.is_published = false;
    this.is_scored = false;
    this.allow_incorrect_advance = false;
    this.require_registration = 'Y';
    this.max_score = 0;
    this.name = null;
    this.questions = [];
    this.ref = null;
    this.shuffle_questions = false;
    this.start = null;
    this.thank_you = null;
    this.welcome = null;
  }
}  

export class SurveyConfigModel {
  payment_survey_access? : string;
  payment_survey_access_duration? : number;
  payment_survey_access_duration_unit? : string;
  payment_survey_access_end_date ? : string;
  payment_timing? : string;
  payment_fee? : number;
  payments_enable? : boolean;
  payment_message? : string;
  payment_currency? : string;
  payment_message_successful? : string;
  payment_message_unsuccessful? : string;
  reusable_invitations? : boolean;
  final_result_timing? : string;
  final_result_detail? : string;

  // TODO: Refactor these from `SurveyModel` to here
  // allow_back? : boolean;
  // allow_multiple_entries : boolean;
  // is_anonymous? : boolean;
  // is_branded? : boolean;
  // is_public? : boolean;
  // is_scored? : boolean;
  // allow_incorrect_advance? : boolean;
  // require_registration? : string | null;
  // shuffle_questions? : boolean;

  constructor() {
    this.final_result_timing = SURVEY_FINAL_RESULT_TIMING.NEVER;
    this.final_result_detail = SURVEY_FINAL_RESULT_DETAIL.NONE;

    // this.allow_back = true;
    // this.allow_multiple_entries = false;
    // this.is_anonymous = false;
    // this.is_branded = true;
    // this.is_public = true;
    // this.is_scored = false;
    // this.allow_incorrect_advance = false;
    // this.require_registration = 'Y';
    // this.shuffle_questions = false;
  }
}

export class SurveySummaryModel {
  id : string;
  name : string;
  is_public : boolean;
  is_anonymous : boolean;
  is_published : boolean;
  start : string;
  end : string;
  opens : number;
  start_count : number;
  response_count : number;

  constructor() {
    this.id = '';
    this.name = '';
    this.is_public = false;
    this.is_anonymous = false;
    this.is_published = false;
    this.start = '';
    this.end = '';
    this.opens = 0;
    this.start_count = 0;
    this.response_count = 0;
  }
}

export class SurveyParticipantsModel {
  id : string | null;
  ref : string | null;
  survey_id : string | null;
  name : string | null;
  company? : string;
  email : string | null;
  sent : Date | null;
  completed : Date | null;
  source : string | null;
  opens : number | null;
  start_count : number | null;
  active : boolean;
  survey? : SurveyModel;
  survey_responses? : SurveyResponsesModel[];

  constructor() {
    this.id = null;
    this.ref = null;
    this.survey_id = null;
    this.name = null;
    this.email = null;
    this.sent = null;
    this.completed = null;
    this.source = null;
    this.opens = 0;
    this.start_count = 0;
    this.active = true;
  }  
}