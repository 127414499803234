import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faDiagramProject, faBook } from '@fortawesome/free-solid-svg-icons';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrl: './onboarding-modal.component.scss'
})
export class OnboardingModalComponent {
  faDiagramProject = faDiagramProject;
  faBook = faBook;

  public credits : number = 0;
  private credits$ : any;

  constructor(
    private subscriptionService : SubscriptionService,
    private dialogRef: MatDialogRef<OnboardingModalComponent>,
  ) { }

  ngOnInit() {
    this.credits$ = this.subscriptionService.credits.subscribe({
      next : (credits) => {
        this.credits = credits;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  ngOnDestroy() {
    // this.credits$.unsubscribe();
  }

  close() {
    this.dialogRef.close();
  }
  
}
