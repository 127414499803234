<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <h1>Settings</h1>
  </div>
</section>

<mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectTab($event)">
  <mat-tab class="font-bold" label="General">
    <section class="py-10">
      <app-general-settings></app-general-settings>
    </section>
  </mat-tab>
  <mat-tab label="Account"> 
    <section class="py-10">
      <app-account-settings></app-account-settings> 
    </section>
  </mat-tab>
  <mat-tab label="Payments"> 
    <section class="py-10">
      <app-payments-settings></app-payments-settings>
    </section>
  </mat-tab>
</mat-tab-group>