import { Component } from '@angular/core';
import { faUserSecret, faClipboardUser, faPencil, faPlus, faRocket, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { SurveySummaryModel } from 'src/app/models/survey.model';
import { SurveysService } from 'src/app/services/surveys.service';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime'; // Import the relativeTime plugin
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManageSurveyModalComponent } from 'src/app/modals/manage-survey-modal/manage-survey-modal.component';
import { ApiResponseModel } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
dayjs.extend(relativeTime); // Extend dayjs with the relativeTime plugin

@Component({
  selector: 'tpt-survey-point-portlet',
  templateUrl: './survey-point-portlet.component.html',
  styleUrl: './survey-point-portlet.component.scss'
})
export class SurveyPointPortletComponent {
  faPlus = faPlus;
  faRefresh = faRefresh;

  public portletLoading = false;
  public surveys : SurveySummaryModel[] = [];

  constructor(
    private modal : MatDialog,
    private router : Router,
    private snackbar : MatSnackBar,
    private surveysService : SurveysService,
  ) { 
    this.loadSummaryData();
  }

  ngOnInit() {
    this.surveysService.surveySummaries.subscribe({
      next : (surveys) => {
        this.surveys = surveys;
        this.portletLoading = false;
      }
    });
  }

  reloadPortlet() {
    this.portletLoading = true;
    this.loadSummaryData();
  }

  loadSummaryData() {
    this.surveysService.loadSurveySummaries();
  }

  getRelativeTime(date : string) {
    if(!date) { return ''; }
    return dayjs(date).fromNow();
  }

  navToSurveyDetail(id : string) {
    if(!id) { return; }
    this.router.navigate(['/surveys/' + id]);
  }

  navToResponses(id : string) {
    if(!id) { return; }
    this.router.navigate(['/rl/' + id]);
  }

  openAddEditSurveyModal() {
    try {
      console.log('surveys.component:716567');
      let config = new MatDialogConfig();
      config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-3/4' ];
      config.data = { 
      };
  
      // Open modal
      this.modal.open(ManageSurveyModalComponent, config).afterClosed().subscribe(
        (result) => {
          console.log('surveys.component:874265', result);
          if(result) {
            if(result.action) {
              switch(result.action) {
                case 'add':
                  console.log('surveys.component:019565 - add', result.survey);
                  this.surveysService.createSurvey(result.survey).subscribe({
                    next : (response : ApiResponseModel) => {
                      console.log('surveys.component:152373', response);
                      // this.dss.currentSurvey.next(response.payload);
                      this.router.navigate([ '/surveys/' + response.payload.id ]);
                    },
                    error : (error) => {
                      console.log(error);
                      this.snackbar.open('Failed to add survey', 'Dismiss', { duration : 3000 });
                    }
                  });
                  break;
                case 'edit':
                  // this.api.put((this.api.endpoints.surveys + '/' + result.survey.id), result.survey).subscribe({
                  //   next : (response : ApiResponseModel) => {
                  //   },
                  //   error : (error) => {
                  //     console.log(error);
                  //   }
                  // });
                  break;
                case 'delete':
                  // this.api.delete((this.api.endpoints.surveys + '/' + result.survey.id), result.survey).subscribe({
                  //   next : (response : ApiResponseModel) => {
                  //     console.log(response);
                  //   },
                  //   error : (error) => {
                  //     console.log(error);
                  //   }
                  // });
                  break;
                default:
                  break;
              }
            }
          }
        }
      );
    } catch(error) {
      console.log('surveys.component:799094', error);
    }
  }
}
