import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faCopy } from '@fortawesome/free-solid-svg-icons';
import { AddQuestionLibraryModalComponent } from 'src/app/modals/add-question-library-modal/add-question-library-modal.component';
import { QuestionCategoriesModel, QuestionLibraryModel, QuestionsModel } from 'src/app/models/question_library.model';
import { ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { QuestionLibraryService } from 'src/app/services/question-library.service';

// const LIBRARY_TYPES = {
//   TOUCHPOINTS : 'T',
//   SURVEYS : 'S'
// };

@Component({
  selector: 'app-question-library',
  templateUrl: './question-library.component.html',
  styleUrls: ['./question-library.component.scss']
})
export class QuestionLibraryComponent implements OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  faPlus = faPlus;
  faCopy = faCopy;

  private libraryType : string = 'T';

  public isLoading : boolean = true;
  public ql : QuestionLibraryModel[] = [];
  public categories : QuestionCategoriesModel[] = [];
  private qlstructure$ : any;
  private ql$ : any;
  public dataSource : MatTableDataSource<QuestionLibraryModel> = new MatTableDataSource<QuestionLibraryModel>();
  public displayedColumns: string[] = [
    'name',
    'questions',
    'short_id',
  ];
  public selectedPageSize : number = 10;

  constructor(
    private api : ApiService,
    private qls : QuestionLibraryService,
    private modal : MatDialog,
    private route : ActivatedRoute,
    private router : Router,
    private snackbar : MatSnackBar
  ) { 
    if(!this.qls.questionStructure.value) {
      this.qls.getQuestionStructure();
    }

    this.qlstructure$ = this.qls.questionStructure.subscribe((data: any) => {
      console.log('question-library.component:190295');
      if(data && data.categories) {
        this.categories = data.categories;
      }
    });

    // Determine the current route and set `libraryType` accordingly
    this.route.url.subscribe({
      next : (url) => {
        if(url[0].path === 'qlt') {
          this.libraryType = qls.LIBRARY_TYPES.TOUCHPOINTS;
        } else if(url[0].path === 'qls') {
          this.libraryType = qls.LIBRARY_TYPES.SURVEYS;
        }
      }
    });

    if(this.qls.questionLibrary.value.length == 0)  {
      this.qls.getQuestionLibrary(this.libraryType);
    }

    this.ql$ = this.qls.questionLibrary.subscribe({
      next : (data: any) => {
        console.log('question-library.component:297923');
        this.isLoading = false;
        this.ql = data;
        this.dataSource = new MatTableDataSource<QuestionLibraryModel>(this.ql);
        this.dataSource.paginator = this.paginator;
      },
      error : (error) => {
        console.error('020662', error);
      },
      complete : () => {
        this.isLoading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    // if (this.ql$) this.ql$.unsubscribe();
    // if (this.qlstructure$) this.qlstructure$.unsubscribe();
  }

  openAddQuestionLibraryModal() {
    let ql = new QuestionLibraryModel();
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      questionLibrary: ql,
      is_new: true 
    };

    this.modal.open(AddQuestionLibraryModalComponent, config).afterClosed().subscribe(
      (result) => {
        console.log('question-library.component:465442', result, config, config.data);
        if(result && result.action == 'save') {
          // If it's new, add it to the list
          if(config.data.is_new) {
            console.log('question-library.component:417756', result.ql);
            this.ql.push(result.ql);
            this.qls.questionLibrary.next(this.ql);
            this.router.navigate([ '/qlt/details', result.ql.id ]);
          } else {
            // If it's not new, update the list
            let index = this.ql.findIndex((ql) => ql.id === result.id);
            this.ql[index] = result;
            this.qls.questionLibrary.next(this.ql);
          }
        }
      }
    );
  }

  editQuestion(row : any) {
    console.log('question-library.component:304152', row.id);
    this.router.navigate([ '/qlt/details', row.id ]);
  }

  lookupCategory(id : string) {
    if(!this.categories) return '';

    let category = this.categories.find((category) => category.id === id);
    return category ? category.name : '';
  }

  returnShortID(currentQuestionSet: QuestionLibraryModel | undefined) {
    return currentQuestionSet?.short_id || '';
  }

  /**
   * This function copies the question set ID to the clipboard
   */
  copyQuestionShortID(currentQuestionSet: QuestionLibraryModel | undefined, event : any) {
    event.stopPropagation();
    navigator.clipboard.writeText(this.returnShortID(currentQuestionSet)).then(() => {
      this.snackbar.open('Question Set ID copied to clipboard', 'Dismiss', { duration: 3000 });
    });
  }

  hasQuestions(ql : QuestionLibraryModel) {
    if(!ql.questions) return false;
    return ql.questions.length > 0;
  }

  isQuestionDefined(question : QuestionsModel) {
    if(!question) return false;
    return question.question ? true : false;
  }
}
