import { SurveyParticipantsModel } from "./survey.model";

export class ContactsModel {
  id : string;
  cohort_id : string;
  first_name : string;
  last_name : string;
  company_name : string;
  phone : string;
  email : string;
  survey_participants? : SurveyParticipantsModel[];
  survey_participant_count? : number;
  survey_response_count? : number;
  average_score? : number;

  constructor() {
    this.id = '';
    this.cohort_id = '';
    this.first_name = '';
    this.last_name = '';
    this.company_name = '';
    this.phone = '';
    this.email = '';
  } 
}

export class CohortsModel {
  id : string;
  company_id : string;
  contacts? : ContactsModel[];
  name : string;
  contact_count? : number;
  pinned? : boolean;

  constructor() {
    this.id = '';
    this.company_id = '';
    this.name = '';
  }
}