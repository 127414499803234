import { Component, inject, Input } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'onboarding-badge',
  templateUrl: './onboarding-badge.component.html',
  styleUrl: './onboarding-badge.component.scss'
})
export class OnboardingBadgeComponent {
  @Input() step : number[] | null = null;
  @Input() isLast : boolean = false;
  @Input() tip : string = '';
  @Input() class : string = '';

  api = inject(ApiService);
  auth = inject(AuthService);
  dss = inject(DataSharingService);

  faCircle = faCircle;

  public onboardingProgress : number = 0;
  private onboardingProgress$ : any = null;
  public onboardingComplete : boolean = true;
  private onboardingComplete$ : any = null;

  constructor(
  ) { }

  ngOnInit() {
    this.onboardingComplete$ = this.dss.onboardingComplete.subscribe((complete) => {
      this.onboardingComplete = complete;
    });
    this.onboardingProgress$ = this.dss.onboardingProgress.subscribe((progress) => {
      this.onboardingProgress = progress;
    });
  }

  ngOnDestroy() {
    // this.onboardingProgress$.unsubscribe();
    // this.onboardingComplete$.unsubscribe();
  }

  isCurrentStep() : boolean {
    if(this.step) {
      return this.step.includes(this.onboardingProgress);
    } else {
      return false;
    }
  }

  next() {
    if(!this.step) return;
    if(this.step.length === 0) return;

    // Only increment progress if not complete and is current step
    if(!this.onboardingComplete && this.isCurrentStep()) {
      this.dss.onboardingProgress.next(this.onboardingProgress + 1);
      if(this.isLast) {
        this.dss.onboardingComplete.next(true);
      }
      this.updateProgress();
    }
  }

  previous() {
    // Prevent negative progress and only decrement if not complete and is current step
    if(this.onboardingProgress > 0 && !this.onboardingComplete && this.isCurrentStep()) {
      this.dss.onboardingProgress.next(this.onboardingProgress - 1);
      this.updateProgress();
    }
  }

  reset() {
    this.dss.onboardingProgress.next(0);
  }

  updateProgress() {
    this.dss.saveOnboardingProgress(this.onboardingComplete, this.onboardingProgress);

    this.api.put(this.api.endpoints.users + '/me/onboard', { onboarding_complete: this.onboardingComplete, onboarding_progress : this.onboardingProgress  }).subscribe({
      next : (response) => {
        // console.log('onboarding-badge.component:865436', response);
      },
      error : (error) => {
        console.error('onboarding-badge.component:135316', error);
      }
    });
  }
}
