<div class="card">
    <div class="card-inner">
        <div class="front">
            <img src="https://i.ibb.co/PYss3yv/map.png" class="map-img">
            <div class="row">
                <img src="../assets/chip.png" width="60px">
                <img *ngIf="brand.toLowerCase() == 'visa'" src="../assets/visa.png" width="60px">
                <img *ngIf="brand.toLowerCase() == 'mastercard'" src="../assets/mc.png" width="60px">
            </div>
            <div class="row card-no">
                <div>XXXX</div>
                <div>XXXX</div>
                <div>XXXX</div>
                <div>{{ last4 }}</div>
            </div>
            <div class="row card-holder">
                <p></p>
                <p>VALID THRU</p>
            </div>
            <div class="row name">
                <p>{{ cardholder }}</p>
                <p>{{ exp_month }} / {{ exp_year }}</p>
            </div>
        </div>
        <div class="back">
            <img src="https://i.ibb.co/PYss3yv/map.png" class="map-img">
            <div class="bar"></div>
            <div class="row card-cvv">
                <div>
                    <img src="https://i.ibb.co/S6JG8px/pattern.png">
                </div>
                <p>XXX</p>
            </div>
            <div class="row card-text">
                <p></p>
            </div>
            <div class="row signature">
                <p></p>
                <img src="https://i.ibb.co/WHZ3nRJ/visa.png" width="80px">
            </div>
        </div>
    </div>
</div>