import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-indicator-button.component.html',
  styleUrl: './toggle-indicator-button.component.scss'
})
export class ToggleIndicatorButtonComponent {
  @Input() state : boolean = false;
  @Output() click = new EventEmitter<boolean>();

  faCircle = faCircle;
  faCircleCheck = faCircleCheck;


  toggle(event : any) {
    event.stopPropagation();
    this.state = !this.state;
    this.click.emit(this.state);
  }

}
