<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">{{ title }}</h3>
  <div class="text-center">
    <mat-progress-bar class="mt-5 w-full" mode="determinate" [value]="step/finalStep * 100"></mat-progress-bar>
  </div>
  <div class="my-5" *ngIf="step==1">
    <div class="mt-5 w-full px-5 py-3">
      <h5 class="font-bold mb-3">SurveyPoint</h5>
      <div class="w-full flex flex-wrap">
        <div class="flex flex-wrap xs:w-full lg:w-1/2 pr-5">
          <div class="block w-full plan-button mb-3" *ngFor="let plan of surveypointPlans" [ngClass]="{ selected : isSelectedSurveypointPlan(plan) }" (click)="selectSurveypointPlan(plan)">
            <span class="w-full font-bold">{{ plan.name }}</span>
            <span class="w-full ml-5 save-tag" *ngIf="getPlanTag(plan)">{{ getPlanTag(plan) }}</span>
          </div>
          <div class="w-full plan-button mb-3" (click)="selectSurveypointPlan()" [ngClass]="{ selected : !selectedSurveypointPlan }">
            <span class="font-bold">Free ($0.00/mo)</span>
          </div>
        </div>
        <div class="w-1/2 flex xs:w-full lg:w-1/2">
          <div *ngIf="selectedSurveypointPlan" class="block w-full">
            <span class="pro-tag">PRO</span>
            expands the capabilities of SurveyPoint with advanced features and customization options including:
            <ul class="mt-3">
              <li>Unlimited number of surveys</li>
              <li>Unlimited questions per survey</li>
              <li>Unlimited responses</li>
              <li>Anonymous surveys</li>
              <li>Invite-only surveys</li>
            </ul>
          </div>
          <div *ngIf="!selectedSurveypointPlan" class="block w-full">
            SurveyPoint is a tool that helps you ask the right questions to the right people at the right time. 
            It's a great way to get feedback from your customers, employees, or anyone else you need to hear from. The free plan includes:
            <ul class="mt-3">
              <li>20 number of surveys</li>
              <li>20 questions per survey</li>
              <li>20 responses</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mt-5 w-full px-5 py-3">
      <h5 class="font-bold mb-3">TouchPoint</h5>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-6">
          <div class="w-full plan-button mb-3" *ngFor="let plan of touchpointPlans" [ngClass]="{ selected : isSelectedTouchpointPlan(plan) }" (click)="selectTouchpointPlan(plan)">
            <span class="font-bold">{{ plan.name }}</span>
            <span class="ml-5 save-tag" *ngIf="getPlanTag(plan)">{{ getPlanTag(plan) }}</span>
          </div>
          <div class="w-full plan-button mb-3" (click)="selectTouchpointPlan()" [ngClass]="{ selected : !selectedTouchpointPlan }">
            <span class="font-bold">No touchpoints ($0.00/mo)</span>
          </div>
        </div>
        <div class="col-span-6">
          <div *ngIf="selectedTouchpointPlan">
            <div class="mb-3">We'll reach out to your contacts on your behalf to ask them the questions you want to ask so that you can hear the answers you need to hear.</div>
            <div class="font-bold">Price per call</div>
            <ul>
              <li>First 25 : $8.49</li>
              <li>25 to 50 : $7.99</li>
              <li>51 to 100: $7.49</li>
              <li>Over 100 : $6.49</li>
            </ul>
          </div>
          <div *ngIf="!selectedTouchpointPlan">
            TouchPoint service disabled.  Select <b>Pay per use</b> to enable TouchPoints. There are no monthly charges and you will only be charged for the TouchPoints you use.
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="my-5" *ngIf="step==2">
    <form class="my-5 grid grid-cols-12 gap-4" [formGroup]="frmStripe">
      <div class="col-span-12">
        <mat-label class="font-bold w-full">Cardholder Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="cardholder" required>
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold w-full">Address</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="addrl1" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">City</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="city" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">State/Province</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="state" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">Zip/Postal Code</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="postal" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">Country</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <mat-select formControlName="country">
            @for (country of countries; track country) {
              <mat-option [value]="country.id">{{ country.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <div class="stripeInput">
          <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
            <ngx-stripe-card (change)="onElementsChange($event)" [options]="cardOptions" />
          </ngx-stripe-elements>
        </div>
      </div>
    </form>
  </div>
  <div class="my-5" *ngIf="step==3">
    <h3>Here's a summary of your changes</h3>
    <div class="mt-3">These changes will take effect on your next billing period.</div>
    <div class="mt-5">
      <ng-container *ngIf="planChanged('s')">
        <h5 class="font-bold mb-3">SurveyPoint</h5>
        <div class="mb-3" *ngIf="!selectedSurveypointPlan">You are changing your SurveyPoint plan from <b>{{ getSurveypointPlanName(surveypointPlan!) }}</b> to <b>SurveyPoint Free</b></div>
        <div class="mb-3" *ngIf="selectedSurveypointPlan">You are changing your SurveyPoint plan from <b>{{ getSurveypointPlanName(surveypointPlan!) }}</b> to <b>{{ selectedSurveypointPlan?.name }}</b></div>
      </ng-container>
      <ng-container *ngIf="planChanged('t')">
        <h5 class="mt-5 mb-3 font-bold">TouchPoint</h5>
        <div class="mb-3" *ngIf="!selectedTouchpointPlan">You are disabling TouchPoint services</div>
        <div class="mb-3" *ngIf="selectedTouchpointPlan">You are changing your TouchPoint plan from <b>{{ getTouchPointPlanName(touchpointPlan!) }}</b> to <b>{{ selectedTouchpointPlan?.name }}</b></div>
      </ng-container>
    </div>
    <div class="mt-10 mb-5" *ngIf="selectedSurveypointPlan || selectedTouchpointPlan">
      <h5 class="font-bold mb-3">Payment Information</h5>
      <ng-container *ngIf="paymentRequired">
        <div><b>{{ frmStripe.value.cardholder }}</b></div>
        <div>{{ frmStripe.value.addrl1 }}, {{ frmStripe.value.city }}, {{ frmStripe.value.state }}, {{ frmStripe.value.postal }}, {{ getSelectedCountry() }}</div>
        <div class="mb-3"><b>{{ getCardType() | titlecase }}</b> ending in <b>{{ getCardLast4() }}</b></div>
      </ng-container>
      <ng-container *ngIf="!paymentRequired">
        <div>No updates</div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex xs:flex-wrap float-right lg:flex-nowrap w-full">
    <button class="xs:w-full lg:w-1/2 mt-3 btn btn-secondary-outline mr-3" data-dismiss="modal" type="button" (click)="close()">Close</button>
    <button class="xs:w-full lg:w-1/2 mt-3 btn btn-secondary-outline mr-3" data-dismiss="modal" type="button" [disabled]="!canReverse()" (click)="prev()">Previous</button>
    <button class="xs:w-full lg:w-1/2 mt-3 btn btn-primary-outline bg-primary mr-3" data-dismiss="modal" type="button" [disabled]="!canAdvance()" (click)="next()">
      <span>{{ getStepButtonLabel() }}</span>
      <span class="ml-5 inline-block align-middle" *ngIf="paymentInProgress">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="18" class="my-0"></mat-progress-spinner>
      </span>
    </button>
  </div>
</mat-dialog-actions>