import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ManageSurveyModalComponent } from 'src/app/modals/manage-survey-modal/manage-survey-modal.component';
import { SurveyModel } from 'src/app/models/survey.model';
import { ApiResponseModel, ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrl: './surveys.component.scss'
})
export class SurveysComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  faPlus = faPlus;
  faSearch = faSearch;

  public dataSource : MatTableDataSource<SurveyModel> = new MatTableDataSource<SurveyModel>();
  public displayedColumns: string[] = [
    'name', 
    'is_anonymous', 
    'is_public', 
    'timing',
    'status', 
  ];
  public surveys : SurveyModel[] = [];

  public isLoading : boolean = true;

  constructor(
    private api : ApiService,
    private dss : DataSharingService,
    private modal : MatDialog,
    private router : Router,
    private snackbar : MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loadSurveys();
  }

  loadSurveys() {
    this.api.get(this.api.endpoints.surveys).subscribe({
      next : (response : ApiResponseModel) => {
        this.surveys = response.payload;
        this.dataSource = new MatTableDataSource(this.surveys);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error : (error) => {
        console.log(error);
        this.snackbar.open('Failed to load surveys', 'Dismiss', { duration : 3000 });
      },
      complete : () => {
        this.isLoading = false;
      }
    });    
  }

  updateFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue === '' || filterValue === null || filterValue === undefined) {
      this.dataSource.filter = '';
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  openAddEditSurveyModal(survey? : SurveyModel) {
    try {
      console.log('surveys.component:716567');
      let config = new MatDialogConfig();
      config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
      config.data = { 
      };
  
      // Open modal
      this.modal.open(ManageSurveyModalComponent, config).afterClosed().subscribe(
        (result) => {
          console.log('surveys.component:874265', result);
          if(result && result.action == 'add') {
            console.log('surveys.component:019565 - add', result.survey);
            this.api.post(this.api.endpoints.surveys, result.survey).subscribe({
              next : (response : ApiResponseModel) => {
                console.log('surveys.component:152373', response);
                this.surveys.push(response.payload);
                this.dss.currentSurvey.next(response.payload);
                this.router.navigate([ '/surveys/' + response.payload.id ]);
              },
              error : (error) => {
                console.log(error);
                this.snackbar.open('Failed to add survey', 'Dismiss', { duration : 3000 });
              }
            });
          }
        }
      );
    } catch(error) {
      console.log('surveys.component:799094', error);
    }
  }

  isFiltered() : boolean {
    return this.dataSource.filter !== '';
  }

  /**
   * This function converts `survey.start` and `survey.end` from strings to dates
   * then displays the `start` and `end` date of the survey like this:
   * 2024-01-01 - 2024-01-31 or 
   * Now - 2024-01-31 or
   * 2024-01-01 - Onward
   * "" if no dates are set
   * @param survey 
   */
  renderTiming(survey : SurveyModel) : string {
    if(survey.start && survey.end) {
      let start = dayjs(survey.start);
      let end = dayjs(survey.end);

      if(start.isBefore(dayjs()) && end.isAfter(dayjs())) {
        return 'Now - ' + end.format('YYYY-MM-DD');
      } else if(start.isAfter(dayjs()) && end.isAfter(dayjs())) {
        return start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD');
      } else if(start.isBefore(dayjs()) && end.isBefore(dayjs())) {
        return start.format('YYYY-MM-DD') + ' - Onward';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  
  openSurveyDetails(survey : SurveyModel) {
    this.dss.currentSurvey.next(survey);
    this.router.navigate([ '/surveys/' + survey.id ]);
  }

}
