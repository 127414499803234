  <tpt-portlet>
    <div portlet-header>
      <div class="grid grid-cols-12">
        <div class="col-span-6">
          <h2>Surveys</h2>
        </div>
        <div class="col-span-6 text-right">
          <button class="form-button bg-white text-gray-600" matTooltip="Refresh Survey List" (click)="reloadPortlet()">
            <div [ngClass]="{ 'animate-spin' : portletLoading }">
              <fa-icon [icon]="faRefresh"></fa-icon>
            </div>
          </button>
          <onboarding-badge [step]="[0]" tip="Click the plus to create a new survey">
            <button class="form-button ml-3 bg-white text-gray-600" matTooltip="Create Survey" (click)="openAddEditSurveyModal()">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </onboarding-badge>
        </div>
      </div>
    </div>
    <div portlet-body>
      <div class="grid grid-cols-12 gap-4">
        <div class="font-bold col-span-6 px-4 py-2">Name</div>
        <div class="font-bold col-span-2 px-4 py-2 text-center">Opens</div>
        <div class="font-bold col-span-2 px-4 py-2 text-center">Responses</div>
        <div class="font-bold col-span-2 px-4 py-2">Close Date</div>
        <div class="col-span-12">
          <ng-container *ngIf="surveys.length === 0">
            <div class="text-center">No surveys found.</div>
          </ng-container>

          <ng-container *ngIf="surveys.length > 0">
            <div class="portlet-table-row grid grid-cols-12 gap-4" *ngFor="let survey of surveys">
              <div class="col-span-6 px-4 py-2 cursor-pointer" matTooltip="View survey details" (click)="navToSurveyDetail(survey.id)">
                <span class="mr-3">{{ survey.name }}</span>
                <span *ngIf="!survey.is_published" class="warn-tag">Draft</span>
              </div>
              <div class="col-span-2 px-4 py-2 text-center cursor-pointer" matTooltip="View responses" (click)="navToResponses(survey.id)">{{ survey.opens }}</div>
              <div class="col-span-2 px-4 py-2 text-center cursor-pointer" matTooltip="View responses" (click)="navToResponses(survey.id)">{{ survey.response_count }}</div>
              <div class="col-span-2 px-4 py-2">{{ getRelativeTime(survey.end) }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </tpt-portlet>