import { Injectable } from '@angular/core';
import { ApiResponseModel, ApiService } from './api.service';
import { CohortsModel, ContactsModel } from '../models/contacts.model';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  public cohorts : BehaviorSubject<CohortsModel[]> = new BehaviorSubject<CohortsModel[]>([]);
  public contacts : BehaviorSubject<ContactsModel[]> = new BehaviorSubject<ContactsModel[]>([]);

  constructor(
    private api : ApiService
  ) { }

  // Cohorts
  public getCohorts() {
    return this.api.get(this.api.endpoints.cohorts).pipe(
      map((response : ApiResponseModel) => {
        this.cohorts.next(response.payload);
        return response;
      })
    );
  }

  public getCohort(contact_id : string) {
    return this.api.get(this.api.endpoints.cohorts + '/' + contact_id);
  }

  public addCohort(cohort : any) {
    return this.api.post(this.api.endpoints.cohorts, cohort);
  }

  public updateCohort(cohort : CohortsModel) {
    return this.api.put(this.api.endpoints.cohorts + '/' + cohort.id, cohort);
  }
  public deleteCohort(cohort_id : any) {
    return this.api.delete(this.api.endpoints.cohorts + '/' + cohort_id);
  }

  // Contacts
  public getContacts(cohort_id : string) {
    return this.api.get(this.api.endpoints.contacts.replace(':cohort_id', cohort_id));
  }

  public getContactSurveys(cohort_id : string, contact_id : string) : Observable<ApiResponseModel> {
    console.log('contacts.service:287409', cohort_id, contact_id);
    return this.api.get(`${this.api.endpoints.contacts.replace(':cohort_id', cohort_id)}/${contact_id}/surveys`);
  }

  public getContact(cohort_id : string, contact_id : string) {
    console.log('contacts.service:876992', cohort_id, contact_id);
    return this.api.get(`${this.api.endpoints.contacts.replace(':cohort_id', cohort_id)}/${contact_id}`).pipe(
      tap((response : ApiResponseModel) => {
        this.contacts.next([response.payload]);
      })
    );
  }

  public addContact(contact : ContactsModel) {
    return this.api.post(this.api.endpoints.contacts.replace(':cohort_id', contact.cohort_id), contact);
  }

  public updateContact(contact : ContactsModel) {
    return this.api.put(this.api.endpoints.contacts.replace(':cohort_id', contact.cohort_id) + '/' + contact.id, contact);
  }

  public deleteContact(cohort_id : string, contact_id : string) {
    return this.api.delete(this.api.endpoints.contacts.replace(':cohort_id', cohort_id) + '/' + contact_id);
  }
}
