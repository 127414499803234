import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { SurveyModel } from 'src/app/models/survey.model';

@Component({
  selector: 'app-survey-payment-settings',
  templateUrl: './survey-payment-settings.component.html',
  styleUrl: './survey-payment-settings.component.scss'
})
export class SurveyPaymentSettingsComponent {
  cdr = inject(ChangeDetectorRef);

  @Input() survey : SurveyModel =  new SurveyModel();
  @Input() readOnly : boolean = false;
  @Output() isDirty : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isInvalid : EventEmitter<boolean> = new EventEmitter<boolean>();

  faCircleQuestion = faCircleQuestion;

  public frmPaymentSettings : any = new FormGroup({
    payments_enable: new FormControl(false),
    payment_timing: new FormControl('', [Validators.required]),
    product_options: new FormArray([]),
    payment_fee: new FormControl(0, [Validators.required, Validators.min(0)]),
    payment_survey_access : new FormControl('once', [Validators.required]),
    payment_survey_access_duration : new FormControl(null, [Validators.required, Validators.min(0)]),
    payment_survey_access_duration_unit : new FormControl('days', [Validators.required]),
    payment_survey_access_end_date : new FormControl(null),
    payment_currency: new FormControl('', [Validators.required]),
    payment_message: new FormControl(''),
    payment_message_successful: new FormControl(''),
    payment_message_unsuccessful: new FormControl('')
  });

  get product_options() { return this.frmPaymentSettings.get('product_options') as FormArray; }

  // public frmProductOptions : any = new FormGroup({
  //   price: new FormControl(0, [Validators.required, Validators.min(0)]),
  //   currency: new FormControl('', [Validators.required]),
  //   duration: new FormControl('monthly', [Validators.required]),
  //   duration_units: new FormControl('days', [Validators.required]),
  // });

  public tooltips = {
    payments_enable : 'Require payment to take this survey',
    paymentSettings: 'Do you want to charge participants to take this survey?',
    payment_timing: 'When should we ask for payment?',
    frequency : 'How often should we charge the participant?',
    payment_fee: 'Amount to charge for this survey',
    payment_currency : 'payment_currency to charge for this survey',

    payment_message: 'Message to show to the participant before they pay',
    payment_message_successful: 'Message to show to the participant after they pay',
    payment_message_unsuccessful: 'Message to show to the participant if the payment fails',
    payment_survey_access: 'How long do you want to give participants to take this survey? (They will always be able to access their responses)',
  }

  constructor() {}

  ngOnInit() {
    this.frmPaymentSettings.valueChanges.subscribe((value : any) => {
      // console.log('survey-payment-settings.component:659124', value);
      this.isDirty.emit(this.frmPaymentSettings.dirty);
      this.isInvalid.emit(this.frmPaymentSettings.invalid);
    });

    this.product_options.push(new FormGroup({
      price_id: new FormControl('', [Validators.required]),
      payment_frequency: new FormControl('month', [Validators.required]),
      payment_fee: new FormControl(0, [Validators.required, Validators.min(0)]),
      payment_currency: new FormControl('usd', [Validators.required]),
    }));
  }

  ngOnChanges(changes : SimpleChanges) {    
    if(changes['survey']) {
      this.survey = changes['survey'].currentValue;
      console.log('survey-payment-settings.component:495476', this.survey);
      this.frmPaymentSettings.patchValue({
        payments_enable: (this.survey && this.survey.config && this.survey.config.payments_enable) ? this.survey.config.payments_enable : false,
        payment_timing : (this.survey && this.survey.config && this.survey.config.payment_timing) ? this.survey.config.payment_timing : '',
        payment_survey_access : (this.survey && this.survey.config && this.survey.config.payment_survey_access) ? this.survey.config.payment_survey_access : 'once',
        payment_survey_access_duration : (this.survey && this.survey.config && this.survey.config.payment_survey_access_duration) ? this.survey.config.payment_survey_access_duration : null,
        payment_survey_access_duration_unit : (this.survey && this.survey.config && this.survey.config.payment_survey_access_duration_unit) ? this.survey.config.payment_survey_access_duration_unit : 'days',
        payment_survey_access_end_date : (this.survey && this.survey.config && this.survey.config.payment_survey_access_end_date) ? this.survey.config.payment_survey_access_end_date : null,
        payment_currency : (this.survey && this.survey.config && this.survey.config.payment_currency) ? this.survey.config.payment_currency : '',
        payment_fee: this.survey.config.payment_fee,
        payment_message: this.survey.config.payment_message,
        payment_message_successful: this.survey.config.payment_message_successful,
        payment_message_unsuccessful: this.survey.config.payment_message_unsuccessful
      });

      console.log('survey-payment-settings.component:819206',this.survey.config.payments_enable);
      this.setPaymentFieldStates();

      this.cdr.detectChanges();
    }

    if(changes['readOnly']) {
      this.readOnly = changes['readOnly'].currentValue;
    }
  }

  updateSurveyModel(name : string, event : any) {
    // console.log('survey-payment-settings.component:796036', name, event);

    if(!this.survey) return;
    switch(name) {
      case 'payments_enable':
        this.survey.config.payments_enable = event.checked;
        this.setPaymentFieldStates();
        break;
      case 'payment_timing':
        this.survey.config.payment_timing = event.value;
        break;
      case 'payment_survey_access':
        this.survey.config.payment_survey_access = event.value;
        switch(event.value) {
          case 'duration':
            this.frmPaymentSettings.get('payment_survey_access_duration').setValidators([Validators.required, Validators.min(0)]);
            this.frmPaymentSettings.get('payment_survey_access_duration').updateValueAndValidity();
            this.frmPaymentSettings.get('payment_survey_access_duration_unit').setValidators([Validators.required]);
            this.frmPaymentSettings.get('payment_survey_access_duration_unit').updateValueAndValidity();
            this.frmPaymentSettings.patchValue({
              payment_survey_access_duration : (this.survey && this.survey.config && this.survey.config.payment_survey_access_duration) ? this.survey.config.payment_survey_access_duration : null,
              payment_survey_access_duration_unit : (this.survey && this.survey.config && this.survey.config.payment_survey_access_duration_unit) ? this.survey.config.payment_survey_access_duration_unit : 'days',
            });
            break;
          case 'date':
            break;
          default : 
            this.frmPaymentSettings.get('payment_survey_access_duration').clearValidators();
            this.frmPaymentSettings.get('payment_survey_access_duration').updateValueAndValidity();
            this.frmPaymentSettings.get('payment_survey_access_duration_unit').clearValidators();
            this.frmPaymentSettings.get('payment_survey_access_duration_unit').updateValueAndValidity();
        }
        break;
      case 'payment_survey_access_duration':
        this.survey.config.payment_survey_access_duration = event.value;
        break;
      case 'payment_survey_access_duration_unit':
        this.survey.config.payment_survey_access_duration_unit = event.value;
        break;
      case 'payment_fee':
        this.survey.config.payment_fee = event.target.value;
        break;
      case 'payment_currency':
        this.survey.config.payment_currency = event.value;
        break;
      case 'payment_message':
        this.survey.config.payment_message = event.html;
        break;
      case 'payment_message_successful':
        this.survey.config.payment_message_successful = event.html;
        break;
      case 'payment_message_unsuccessful':
        this.survey.config.payment_message_unsuccessful = event.html;
        break;
    }
  }

  setPaymentFieldStates() {
    if(this.survey && this.survey.config && this.survey.config.payments_enable) {
      this.frmPaymentSettings.get('payment_fee').enable();
      this.frmPaymentSettings.get('payment_timing').enable();
      this.frmPaymentSettings.get('payment_survey_access').enable();
      this.frmPaymentSettings.get('payment_survey_access_duration').enable();
      this.frmPaymentSettings.get('payment_survey_access_duration_unit').enable();
      this.frmPaymentSettings.get('payment_currency').enable();
      this.frmPaymentSettings.get('payment_message').enable();
      this.frmPaymentSettings.get('payment_message_successful').enable();
      this.frmPaymentSettings.get('payment_message_unsuccessful').enable();        
    } else {
      this.frmPaymentSettings.get('payment_fee').disable();
      this.frmPaymentSettings.get('payment_timing').disable();
      this.frmPaymentSettings.get('payment_survey_access').disable();
      this.frmPaymentSettings.get('payment_survey_access_duration').disable();
      this.frmPaymentSettings.get('payment_survey_access_duration_unit').disable();
      this.frmPaymentSettings.get('payment_currency').disable();
      this.frmPaymentSettings.get('payment_message').disable();
      this.frmPaymentSettings.get('payment_message_successful').disable();
      this.frmPaymentSettings.get('payment_message_unsuccessful').disable();        
    }
  }

  showDurationOptions() {
    return this.frmPaymentSettings.get('payment_survey_access').value == 'duration';
  }
}
