<!-- Page Title -->
<div class="w-full mb-5">
  <h1>My Responses</h1>
</div>

<!-- Page Content -->
  <!-- Filter Section -->
  <section class="flex items-center mb-10">
    <div class="w-full text-right flex">
      <mat-form-field appearance=outline class="searchInput sm:!w-full md:!w-full lg:flex-grow xl:flex-grow flex">
        <input matInput (keyup)="updateFilter($event)" placeholder="Filter Surveys">
        <fa-icon matSuffix class="pr-5" [icon]="faSearch"></fa-icon>
      </mat-form-field>
    </div>
  </section>

  <!-- Table Section // Plan users only -->
  <ng-container >
    <table mat-table [dataSource]="dataSource" class="">
      <!-- recorded_date Column -->
      <ng-container matColumnDef="recorded_date">
        <th mat-header-cell *matHeaderCellDef>Recorded Date</th>
        <td mat-cell *matCellDef="let element">{{element.recorded_date | date: 'yyyy-MM-dd'}}</td>
      </ng-container>
    
      <!-- survey_name Column -->
      <ng-container matColumnDef="survey_name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.survey_name  }}</td>
      </ng-container>
    
      <!-- score Column -->
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef>Score</th>
        <td mat-cell *matCellDef="let element">{{ element.score }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
      <tr mat-row 
        *matRowDef="let row; columns: displayedColumns;" 
        class="portlet-table-row select-none cursor-pointer"
        (click)="viewResponse(row)"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <ng-container *ngIf="isLoading">
          <td [attr.colspan]="displayedColumns.length" class="py-5">
            <mat-progress-spinner color="primary" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
          </td>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="text-center my-10" *ngIf="isFiltered()">
              <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
            </div>
            <div class="text-center my-10" *ngIf="!isFiltered()">
              <h4 class="text-center mt-10 mb-5">No surveys found</h4>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  
    <!-- Pagination Section -->
    <section class="flex items-center justify-between mt-10">
      <div class="w-full">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </section>
  </ng-container>