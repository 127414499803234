import { Component, EventEmitter, inject, Input, Output, SimpleChanges } from '@angular/core';
import { QuestionsModel } from 'src/app/models/question_library.model';
import { ResponseContentModel } from 'src/app/models/responses.model';
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { OptionListModel } from 'src/app/models/option-list.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-survey-panel',
  templateUrl: './survey-panel.component.html',
  styleUrl: './survey-panel.component.scss'
})
export class SurveyPanelComponent {
  fb = inject(FormBuilder);

  @Input() question : QuestionsModel = new QuestionsModel();
  @Input() response : ResponseContentModel = new ResponseContentModel();
  @Output() responseChange : EventEmitter<ResponseContentModel> = new EventEmitter<ResponseContentModel>();
  @Output() onNext : EventEmitter<void> = new EventEmitter<void>();
  @Input() index : number = 0;
  // @Output() onNext : EventEmitter<ResponseContentModel> = new EventEmitter<ResponseContentModel>();
  // @Output() onPrevious : EventEmitter<ResponseContentModel> = new EventEmitter<ResponseContentModel>();

  faGreaterThan = faGreaterThan;
  faLessThan = faLessThan;

  public frmQuestion : FormGroup = new FormGroup({});

  // public response : ResponseContentModel = new ResponseContentModel();

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(changes : SimpleChanges) {
    if(changes['question']) {
      // console.log('survey-panel.component:552723 - Question updated', changes['question']);
      this.question = changes['question'].currentValue;
      // this.response.question = this.question.question;
      this.response = new ResponseContentModel();
      this.initializeQuestionForm();
    }
    if(changes['index']) {
      this.index = changes['index'].currentValue;
    }
    if(changes['response']) {
      this.response = changes['response'].currentValue;
    }
  }

  initializeQuestionForm() {
    if(!this.question) { return }

    switch(this.question.field_type) {
      case 'text':
      case 'textarea':
      case 'number':
      case 'radio':
        this.frmQuestion = this.fb.group({
          question: [this.response.answer, this.question.required ? Validators.required: null] 
        });
        break;
      case 'email':
        this.frmQuestion = this.fb.group({
          question: [this.response.answer, [this.question.required ? Validators.required: null, Validators.email]] 
        });
        break;
    }

  }

  updateResponse() {
    this.response.question_id = this.question.id;
    // console.log('survey-panel.component:677844', this.response);
    this.responseChange.emit(this.response);
  }

  updateOptionResponse(option : OptionListModel) {
    this.response.question_id = this.question.id;
    this.response.answer_id = option.id;
    this.response.answer = option.name;

    // console.log('survey-panel.component:209153', this.response, option);
    this.responseChange.emit(this.response);
  }

  submit() {
    this.responseChange.emit(this.response);
    this.onNext.emit();
  }

  getQuestionConfig(key : string) {
    if(!this.question.field_config) {
      return null;
    }

    switch(key) {
      case 'min_label':
        return this.question.field_config.min_label;
      case 'max_label':
        return this.question.field_config.max_label;
      case 'scale_max':
        return parseInt(this.question.field_config.scale_max);
    }
    return this.question.field_config[key];
    return null;
  }

  // parseFieldOptions(options : string) {
  //   // Field options is a semicolon separated list of options
  //   if(options) {
  //     return options.split(';');
  //   }
  //   return [];
  // }
}
