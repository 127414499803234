<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <a class="cursor-pointer" (click)="back()">
      <h1><fa-icon [icon]="faArrowLeft" class="mr-3"></fa-icon> Survey Details</h1>
    </a>
  </div>
</section>

<div class="grid grid-cols-12 gap-4 mb-10">
  <div class="col-span-6">
    <h1>{{ renderSurveyName() }}</h1>
    <div class="font-bold text-gray-500">{{ renderSurveyStatus() }}</div>
  </div>
  <div class="col-span-6 text-right">
    <button class="ml-5 form-button outline warning" (click)="deleteSurvey()" [matTooltip]="tooltips.delete"><fa-icon
        [icon]="faTrash" class="text-red-300"></fa-icon></button>
    
    <button color="primary" [disabled]="!enableSave()" class="ml-5 form-button" (click)="saveSurvey()" [matTooltip]="tooltips.save">
      <onboarding-badge [step]="[5]" tip="Save your survey. You can do this at any time to make sure you don't lose your work.">
        <fa-icon [icon]="faFloppyDisk"></fa-icon>
      </onboarding-badge>
    </button>
    <button *ngIf="!survey?.is_published" color="primary" [disabled]="!isPublishable()" class="ml-5 form-button" (click)="handlePublishSurvey()" [matTooltip]="tooltips.publish">
      <onboarding-badge [step]="[6]" tip="Once you're satisfied with how everything looks, publish the survey to make it accessible to participants.  Please be aware that once your survey is published, most of the settings can no longer be changed. If you've configured a start date, the survey won't be available until that then.">
        <fa-icon [icon]="faRocket"></fa-icon>
      </onboarding-badge>
    </button>
    <button *ngIf="survey?.is_published" [disabled]="!canShare()" color="primary" class="ml-5 form-button"
      (click)="shareSurvey()" [matTooltip]="getShareTooltip()"><fa-icon [icon]="faShareNodes"></fa-icon></button>
  </div>
</div>

<alert-box class="px-10" *ngIf="inviteButNoParticipants()" type="warning">You have set this survey to be invitation only but you haven't invited anyone. You won't be able to publish until you add participants</alert-box>
<alert-box class="px-10" *ngIf="onCloseButNoEndDate()" type="info">You have set this survey to show final results at the close of the survey but you haven't set an end date. Results will be shown as soon as participants complete the survey.</alert-box>

<mat-tab-group>
  <mat-tab label="Questions">
    <div class="px-10 pt-8 pb-10">
      <app-survey-question-editor class="pt-5" 
        [(questions)]="questions" 
        [survey]="survey"
        [readOnly]="isPublished()" 
        [tabIndex]="tabIndex"
        [isScored]="survey?.is_scored || false" 
        (isDirty)="markQuestionsChanged($event)"
        (isInvalid)="markQuestionsInvalid($event)">
      </app-survey-question-editor>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>Preview</span>
    </ng-template>
    <div class="xs:px-5 lg:px-10 pt-5 pb-10">
      <app-survey-preview></app-survey-preview>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <onboarding-badge [step]="[3]" tip="If you want to invite specific people to do the survey, add them here. We'll send them the invitation via email">
        <span>Participants</span>
      </onboarding-badge>
    </ng-template>
    <div class="px-10 pt-8 pb-10">
      <app-survey-participants [survey]="survey" [(participants)]="participants"></app-survey-participants>
    </div>
  </mat-tab>
  <!-- <mat-tab *ngIf="showPaymentsTab()">
    <ng-template mat-tab-label>
      <span>Payments <span class="ml-3 pro-tag">PRO</span></span>
    </ng-template>
    <div *ngIf="!paymentAccountSet()" class="py-28">
      <h3 class="text-center">Please set up your payment account in <a class="buttonlink" href="/settings">Settings</a> before you can collect payments</h3>      
    </div>
    <div *ngIf="paymentAccountSet()" class="xs:px-5 lg:px-10 pt-5 pb-10">
      <app-survey-payment-settings 
        (isDirty)="markQuestionsChanged($event)"
        (isInvalid)="markQuestionsInvalid($event)"
        [survey]="survey" 
        [readOnly]="isPublished()">
      </app-survey-payment-settings>
    </div>
  </mat-tab> -->
  <mat-tab>
    <ng-template mat-tab-label>
      <onboarding-badge [step]="[4]" tip="Here, you can set up additional features of your survey">
        <span>Settings</span>
      </onboarding-badge>
    </ng-template>
    <div class="px-10 pt-8 pb-10">
      <form class="flex flex-wrap" [formGroup]="frmSurveyDetails">
        <div class="w-full">
          <mat-label class="font-bold required">Name</mat-label>
          <mat-form-field appearance=outline class="w-full">
            <input matInput required placeholder="Enter a name" formControlName="name">
          </mat-form-field>
        </div>
        <div class="w-full">
          <mat-label class="font-bold">
            Welcome instructions
            <fa-icon [matTooltip]="tooltips.editor_welcome_tooltip" [icon]="faCircleQuestion"
              class="mr-3 text-blue-500"></fa-icon>
          </mat-label>
          <!-- <editor [init]="editorConfig" id="editor-welcome" formControlName="welcome"></editor> -->
          <quill-editor class="w-full" formControlName="welcome"></quill-editor>
        </div>
        <div class="w-full mt-5 mb-5">
          <mat-label class="font-bold">
            Thank you message
            <fa-icon [matTooltip]="tooltips.editor_thankyou_tooltip" [icon]="faCircleQuestion"
              class="mr-3 text-blue-500"></fa-icon>
          </mat-label>
          <!-- <editor [init]="editorConfig" id="editor-thank_you" formControlName="thank_you"></editor> -->
          <quill-editor class="w-full" formControlName="thank_you"></quill-editor>
        </div>
        <div class="flex flex-wrap w-1/2 mb-5 pr-5 xl:w-1/3">
          <div class="w-full flex"><mat-label class="w-full font-bold" [matTooltip]="tooltips.allow_back">Allow return to previous question?</mat-label></div>
          <div class="w-full flex"><mat-slide-toggle  formControlName="allow_back" (change)="updateSurveyModel('allow_back', $event)" color="text-primary"></mat-slide-toggle></div>
        </div>
        <div class="flex flex-wrap xs:w-full lg:xs:w-full lg:w-1/2 mb-5 lg:pr-5 xl:w-1/3">
          <mat-label class="w-full font-bold" [matTooltip]="tooltips.availability">Start date</mat-label>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput [matDatepicker]="picker" formControlName="start" placeholder="Start date"
              (dateChange)="updateSurveyModel('start', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="start!.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-wrap xs:w-full lg:xs:w-full lg:w-1/2 mb-5 lg:pl-5 xl:w-1/3">
          <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.availability">End date</mat-label></div>
          <div class="w-full"><mat-form-field class="w-full" appearance="outline">
            <input matInput [matDatepicker]="endPicker" formControlName="end" placeholder="End date"
              (dateChange)="updateSurveyModel('end', $event)">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="end!.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field></div>
        </div>

        <!-- Pro features -->
        <div class="w-full border rounded px-5 py-8 bg-gray-100">
          <div class="w-full mb-8" (click)="openUpgradeModal()" [ngClass]="{ 'cursor-pointer' : !enablePro }" [matTooltip]="getUpgradeTooltip()">
            <span class="pro-tag">PRO</span> <span class="ml-3 font-bold">Features</span>
            <span *ngIf="!enablePro" class="float-end text-gray-500">Unlock these features by upgrading to a paid plan</span>
          </div>
          <div class="feature-box flex-wrap">
            <div class="xs:w-full md:w-1/2 lg:w-1/3 md:pr-5">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.is_anonymous">Anonymous survey? </mat-label></div>
              <div class="w-full"><mat-slide-toggle formControlName="is_anonymous" (change)="updateSurveyModel('is_anonymous', $event)"
                color="text-primary"></mat-slide-toggle></div>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pt-0 lg:w-1/3 lg:pr-5">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.shuffle_questions">Shuffle questions?</mat-label></div>
              <div class="w-full"><mat-slide-toggle formControlName="shuffle_questions" (change)="updateSurveyModel('shuffle_questions', $event)" 
                color="text-primary"></mat-slide-toggle></div>
            </div>
          </div>
          <div class="feature-box flex-wrap">
            <div class="xs:w-full md:w-1/2 lg:w-1/3 md:pr-5">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.quiz_mode">Quiz mode </mat-label></div>
              <mat-slide-toggle formControlName="quiz_mode" (change)="updateSurveyModel('quiz_mode', $event)"
                color="text-primary"></mat-slide-toggle>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pt-0 lg:w-1/3 pr-5">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.allow_incorrect_advance">Advance only on correct answer</mat-label></div>
              <mat-slide-toggle formControlName="allow_incorrect_advance" (change)="updateSurveyModel('allow_incorrect_advance', $event)"
                color="text-primary"></mat-slide-toggle>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pr-4 md:pt-5 lg:pt-0 lg:w-1/3">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.final_result_timing">Show final results when</mat-label></div>
              <div class="w-full">
                <mat-form-field appearance=outline class="w-full">
                  <mat-select name="final_result_timing" formControlName="final_result_timing"
                    aria-label="When should we show final results?" hideSingleSelectionIndicator="false"
                    (selectionChange)="updateSurveyModel('final_result_timing', $event)">
                    <mat-option value="">Please select</mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_TIMING.NEVER">Don't show final results</mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_TIMING.ON_COMPLETE">The survey has been completed </mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_TIMING.ON_CLOSE">The survey has ended</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pt-5 lg:pt-0 lg:w-1/3">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.final_result_detail">Final result detail</mat-label></div>
              <div class="w-full">
                <mat-form-field appearance=outline class="w-full">
                  <mat-select name="final_result_detail" formControlName="final_result_detail"
                    aria-label="Display final results?" hideSingleSelectionIndicator="false"
                    (selectionChange)="updateSurveyModel('final_result_detail', $event)">
                    <mat-option value="">Please select</mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_DETAIL.NONE">Don't show any detail </mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_DETAIL.SUMMARY">Show score only </mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_DETAIL.DETAILED">Show score, questions and responses </mat-option>
                    <mat-option [value]="SURVEY_FINAL_RESULT_DETAIL.VERBOSE">Show score, questions, responses, and feedback </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="feature-box flex-wrap">
            <div class="xs:w-full md:w-1/2 md:pr-5 lg:w-1/3">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.participants">Invitation only? </mat-label></div>
              <mat-slide-toggle formControlName="invite_only" (change)="updateSurveyModel('invite_only', $event)"
                color="text-primary"></mat-slide-toggle>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pt-0 lg:w-1/3">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.allow_multiple_entries">Allow multiple entries?
                </mat-label></div>
              <mat-slide-toggle formControlName="allow_multiple_entries"
                (change)="updateSurveyModel('allow_multiple_entries', $event)" color="text-primary"></mat-slide-toggle>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pr-5 lg:pt-0 lg:w-1/3">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.reusable_invitations">Reusable invites </mat-label></div>
              <mat-slide-toggle formControlName="reusable_invitations" (change)="updateSurveyModel('reusable_invitations', $event)"
                color="text-primary"></mat-slide-toggle>
            </div>
            <div class="xs:w-full xs:pt-5 md:w-1/2 md:pt-5 lg:w-1/3">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.registration_required">Require
                  registration</mat-label></div>
              <div class="w-full">
                <mat-form-field appearance=outline class="w-full">
                  <mat-select name="require_registration" formControlName="require_registration"
                    aria-label="Require registration?" hideSingleSelectionIndicator="false"
                    (selectionChange)="updateSurveyModel('require_registration', $event)">
                    <mat-option value="Y"> Yes </mat-option>
                    <mat-option value="O"> Optional </mat-option>
                    <mat-option value="N"> No </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="feature-box">
            <div class="w-full">
              <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.show_branding">Show branding? </mat-label></div>
              <div class="w-full"><mat-slide-toggle formControlName="show_branding" (change)="updateSurveyModel('show_branding', $event)"
                color="text-primary"></mat-slide-toggle></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-tab>
</mat-tab-group>