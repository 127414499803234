<section class="py-10">
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-4">
      <h1 class="mb-5">Your Services</h1>
      <div class="px-5 py-5">
        <div class="mb-5">
          <h5 class="font-bold">SurveyPoint</h5>
          <alert-box *ngIf="isPlanCancelled(surveypointStripePlan.id || '')" [type]="'warn'" [clickable]="true" (click)="cancelCancellation(surveypointStripePlan.id)">
            Your SurveyPoint plan will revert to the free plan on <b>{{ getPlanCancellationDate(surveypointStripePlan.id || '') | date : 'MMM d, yyyy' }}</b>. Want to keep all the advanced features you have today?.
          </alert-box>
          <div *ngIf="!surveypointStripePlan" class="mb-3">Free ($0.00/mo)</div>
          <div *ngIf="surveypointStripePlan" class="mb-3">{{ renderPlanName() }}</div>
        </div>
        <!-- <div class="mb-5">
          <h5 class="font-bold">TouchPoint</h5>
          <alert-box *ngIf="isPlanCancelled(touchpointPlan?.stripe_price_id || '')" [type]="'warn'" [clickable]="true" (click)="cancelCancellation(touchpointPlan)">
            Your TouchPoint plan will be disabled on <b>{{ getPlanCancellationDate(touchpointPlan?.stripe_price_id || '') | date : 'MMM d, yyyy' }}</b>. You will be billed for any TouchPoints you used up to that day. After that, we will not be able to reach out to your Touchpoints. Want to continue using TouchPoints? There's no monthly fee and you only pay for the TouchPoints you use.
          </alert-box>
          <alert-box *ngIf="!touchpointPlan" type="warn">
            Once you enroll into the pay as you go plan,
            we can reach out to your Touchpoints uninterrupted. 
            You'll only be charged for the Touchpoints you use.
          </alert-box>
          <div>Price per call</div>
          <ul>
            <li>First 25 : $8.49</li>
            <li>25 to 50 : $7.99</li>
            <li>51 to 100: $7.49</li>
            <li>Over 100 : $6.49</li>
          </ul>
        </div> -->
        <button class="mt-5" *ngIf="!isCustomPlan()" (click)="openChangePlanModal()">Change Plan</button>
        <!-- <button class="mt-5 ml-3" (click)="openPaymentInfoUpdateModal()">Update Payment Information</button> -->
      </div>
    </div>
    <div class="col-span-8">
      <h1 class="mb-5">Invoice History</h1>
      <table mat-table [dataSource]="dataSource">
        <!-- Invoice Date -->
        <ng-container matColumnDef="invoice_date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">{{ renderDate(element.created) }}</td>
        </ng-container>
      
        <!-- Invoice Number -->
        <ng-container matColumnDef="invoice_number">
          <th mat-header-cell *matHeaderCellDef>Number</th>
          <td mat-cell *matCellDef="let element">{{element.number}}</td>
        </ng-container>
      
        <!-- Invoice Amount -->
        <ng-container matColumnDef="invoice_amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">{{ renderAmountDue(element.amount_due) }}</td>
        </ng-container>
      
        <!-- Status -->
        <ng-container matColumnDef="invoice_status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{element.status | titlecase }}</td>
        </ng-container>
    
        <!-- Download Link -->
        <ng-container matColumnDef="invoice_dl">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.invoice_pdf" [href]="element.invoice_pdf"><fa-icon matTooltip="Download invoice" mat class="ml-5 text-gray-400" [icon]="faFilePdf"></fa-icon></a>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <h4 class="text-center mt-10 mb-5">No invoices found</h4>
          </td>
        </tr>
      </table>
    </div>
  </div>
</section>