<div class="py-10 px-10 bg-gray-100 border">
  <div class="mb-10 mt-5 center">
    <h3 class="text-center">Invite Participants</h3>
    <div class="text-center">
      <span *ngIf="survey?.is_public">They will receive an email with a link to the survey.</span>
      <span *ngIf="!survey?.is_public">They will receive a personalized link to the survey. This link may only be used once and cannot be shared.</span>
    </div>
  </div>
  <form [formGroup]="frmAddParticipant" #formDirective="ngForm" class="w-full ">
    <div class="w-full">
      <div class="font-bold">Assign Directly</div>
    </div>
    <div class="flex flex-wrap mb-8">
      <div class="xs:w-full lg:w-5/12 lg:pr-5">
        <div class="w-full"><mat-label class="font-bold required">Name</mat-label></div>
        <div class="w-full"><mat-form-field appearance=outline class="w-full">
          <input matInput required placeholder="Enter a name" formControlName="name" (keyup.enter)="inviteParticipant('direct')">
        </mat-form-field></div>
      </div>
      <div class="xs:w-full xs:pt-5 lg:w-5/12 lg:pt-0">
        <div class="w-full"><mat-label class="font-bold required">Email</mat-label></div>
        <div class="w-full"><mat-form-field appearance=outline class="w-full">
          <input matInput required placeholder="Enter a name" formControlName="email" (keyup.enter)="inviteParticipant('direct')">
        </mat-form-field></div>
      </div>
      <div class="xs:w-full lg:w-2/12 lg:pl-5">
        <br class="xs:hidden lg:block" />
        <button type="button" class="w-full form-button bg-primary" (click)="inviteParticipant('direct')">
          <fa-icon [icon]="faPlus"></fa-icon>
          <!-- <span class="text-white ml-3">Invite Participant</span> -->
        </button>
      </div>
    </div>
  </form>
  <form [formGroup]="frmAddCohort" #formDirective="ngForm" class="flex flex-wrap">
    <div class="w-full">
      <p class="font-bold">Assign by Cohort</p>
    </div>
    <div class="xs:w-full xs:pt-5 lg:w-10/12 lg:pt-0">
      <div class="flex flex-wrap">
        <div class="w-full"><mat-label class="font-bold">Cohort</mat-label></div>
        <div class="w-full"><mat-form-field appearance=outline class="w-full">
          <mat-select formControlName="cohort" placeholder="Select a cohort">
            @for (cohort of cohorts; track cohort) {
              <mat-option [value]="cohort.id">{{cohort.name}} ({{ cohort.contact_count }} members)</mat-option>
            }
          </mat-select>
        </mat-form-field></div>
      </div>
    </div>
    <div class="xs:w-full lg:w-2/12 lg:pl-5">
      <br class="xs:hidden lg:block" />
      <button type="button" class="w-full form-button bg-primary" (click)="inviteParticipant('cohort')">
        <fa-icon [icon]="faPlus"></fa-icon>
        <!-- <fa-icon [icon]="faUsers"></fa-icon>
        <span class="text-white ml-3">Assign Cohort</span> -->
      </button>
    </div>
  </form>
</div>

<div class="grid grid-cols-12 gap-4 mt-10">
  <div class="col-span-6">
    <h1>Participants</h1>
  </div>
  <div class="col-span-6 text-right">
    <mat-form-field appearance=outline class="searchInput">
      <input matInput (keyup)="updateFilter($event)" placeholder="Filter Participants">
      <fa-icon matSuffix class="pr-2" [icon]="faSearch"></fa-icon>
    </mat-form-field>
  </div>
</div>
<!-- Participant Table -->
<table mat-table [dataSource]="dataSource" class="my-10">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{element.name}}</td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{element.email}}</td>
  </ng-container>

  <!-- Cohort Column -->
  <ng-container matColumnDef="cohort">
    <th mat-header-cell *matHeaderCellDef>Cohort</th>
    <td mat-cell *matCellDef="let element">{{ getCohortName(element.cohort_id) }}</td>
  </ng-container>

  <!-- Completed Column -->
  <ng-container matColumnDef="completed">
    <th mat-header-cell *matHeaderCellDef>
      <div class="w-full text-center">Completed</div>
    </th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <div *ngIf="element.completed" class="text-center">
        <fa-icon [icon]="faCheck" class="text-green-500"></fa-icon>
      </div>
    </td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="text-right"> 
        <span (click)="shareLink(element)" [ngClass]="{ 'text-gray-300' : element.completed }" class="cursor-pointer" [matTooltip]="tooltips.shareLink" *ngIf="survey?.is_published">
          <fa-icon [icon]="faShareNodes"></fa-icon>
        </span>
        <span (click)="resendInvitation(element)" [ngClass]="{ 'text-gray-300' : element.completed }" class="ml-5 cursor-pointer" [matTooltip]="tooltips.resend" *ngIf="survey?.is_published">
          <fa-icon [icon]="faEnvelope"></fa-icon>
        </span>
        <span (click)="removeInvitation(element)" class="ml-5 cursor-pointer" [matTooltip]="tooltips.remove" *ngIf="!survey?.is_published || (survey?.is_published && !survey?.is_public)">
          <fa-icon [icon]="faTrashCan"></fa-icon>
        </span>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row"></tr>
  <tr class="mat-row" *matNoDataRow>
    <ng-container *ngIf="isLoading">
      <td [attr.colspan]="displayedColumns.length" class="py-5">
        <mat-progress-spinner color="primary" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
      </td>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <td [attr.colspan]="displayedColumns.length">
        <div class="text-center my-10" *ngIf="isFiltered()">
            <h4>No participants found matching the filter "{{dataSource.filter}}"</h4>
        </div>
        <div class="text-center my-10" *ngIf="!isFiltered()">
          <h4>No participants yet! Add one above</h4>
        </div>
      </td>
    </ng-container>
  </tr>
</table>

<!-- Pagination Section -->
<section class="flex items-center justify-between pt-10">
  <div class="w-full">
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</section>