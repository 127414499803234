import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrl: './auth-action.component.scss'
})
export class AuthActionComponent {

  public mode : string = '';
  public oobCode : string = '';
  private activatedRoute$ : any;

  constructor(
    private activatedRoute : ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // Get the mode and oobCode from the URL
    this.activatedRoute$ = this.activatedRoute.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.oobCode = params['oobCode'];
      console.log('auth-action.component:929952', this.mode, this.oobCode);
    });
  }

  ngOnDestroy(): void {
    // this.activatedRoute$.unsubscribe();
  }

}
