import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionCategoriesModel, QuestionLibraryModel } from 'src/app/models/question_library.model';
import { ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
const OPTIONS = {
  SCRATCH : 1,
  TEMPLATE : 0
};

@Component({
  selector: 'app-add-question-library-modal',
  templateUrl: './add-question-library-modal.component.html',
  styleUrl: './add-question-library-modal.component.scss'
})
export class AddQuestionLibraryModalComponent implements OnInit {
  public questionCategories : QuestionCategoriesModel[] = [];
  public selectedCategory : string | null = null;
  public questionLibrary : QuestionLibraryModel = new QuestionLibraryModel();
  public questionLibraryTemplates : QuestionLibraryModel[] = [];
  public selectedTemplate : string | null = null;
  public frmAddQuestionLibrary : FormGroup = new FormGroup({});
  public addOptions = [
    { label: 'I want to start from scratch', value: OPTIONS.SCRATCH, default: true },
    { label: 'I want to start with a template', value: OPTIONS.TEMPLATE }
  ];
  public showTemplatePicker : boolean = false;
  

  constructor(
    private api : ApiService,
    private dss : DataSharingService,
    private dialogRef : MatDialogRef<AddQuestionLibraryModalComponent>,
    private snackbar : MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data : any
  ) { 
    this.loadQuestionCategories();
    this.loadQuestionTemplates();

    if(data && data.questionLibrary) {
      this.questionLibrary = data.questionLibrary;
    }

    this.frmAddQuestionLibrary = new FormGroup({
      libraryName: new FormControl(this.questionLibrary.name, [ Validators.required ]),
      action: new FormControl(OPTIONS.SCRATCH, [ Validators.required ]),
      category: new FormControl(OPTIONS.SCRATCH, [ Validators.required ]),
    });

    if(!data.is_new) {
      this.frmAddQuestionLibrary.setValue({
        // category: this.questionLibrary.category,
        libraryName: this.questionLibrary.name,
      });
    }
  }

  // get category() { return this.frmAddQuestionLibrary.get('category'); }
  get libraryName() { return this.frmAddQuestionLibrary.get('libraryName'); }
  get action() { return this.frmAddQuestionLibrary.get('action'); }

  ngOnInit(): void {}

  loadQuestionCategories() {
    try {
      let qlStructure = this.dss.questionStructure.value;
      console.log('add-question-library-modal.component:142541', qlStructure);
  
      if(qlStructure && qlStructure.categories) {
        this.questionCategories = qlStructure.categories;
      } else {
        this.api.get(this.api.endpoints.question_structure).subscribe({
          next : (data) => {
            console.log('add-question-library-modal.component:268914', data);
            if(data.payload) {
              this.dss.questionStructure.next(data.payload);
              this.questionCategories = data.payload.categories;
            }
          },
          error : (error) => {
            console.error('add-question-library-modal.component:081', error);
            this.snackbar.open('Error loading question categories', 'Dismiss', { duration: 3000 });
          }
        });
      }
    } catch (error) {
      console.error('add-question-library-modal.component:081', error);
      this.snackbar.open('Error loading question categories', 'Dismiss', { duration: 3000 });
    }
  }

  loadQuestionTemplates() {
    try {
      let qlTemplates : QuestionLibraryModel[] = [];

      this.api.get(this.api.endpoints.question_templates).subscribe({
        next : (data) => {
          console.log('add-question-library-modal.component:268914', data);
          if(data.payload) {
            this.questionLibraryTemplates = data.payload;
          }
        },
        error : (error) => {
          console.error('add-question-library-modal.component:081', error);
          this.snackbar.open('Error loading question templates', 'Dismiss', { duration: 3000 });
        }
      });
    } catch (error) {
      console.error('add-question-library-modal.component:081', error);
      this.snackbar.open('Error loading question templates', 'Dismiss', { duration: 3000 });
    }
  }

  changeAction(event : any) {
    switch(event.value) {
      case OPTIONS.SCRATCH:
        this.showTemplatePicker = false;
        this.selectedCategory = null;
        this.selectedTemplate = null;
        break;
      case OPTIONS.TEMPLATE:
        this.showTemplatePicker = true;
        break;
      default:
        this.showTemplatePicker = false;
        break;
    }
  }

  close() {
    this.dialogRef.close();
  }

  canSave() {
    return this.frmAddQuestionLibrary.valid && this.action?.value == OPTIONS.SCRATCH || (this.action?.value == OPTIONS.TEMPLATE && this.selectedTemplate != null);
  }

  save() {
    if(this.frmAddQuestionLibrary.valid) {
      // this.questionLibrary.category = this.category?.value;
      this.questionLibrary.name = this.libraryName?.value;
      if(this.action?.value == OPTIONS.TEMPLATE) {
        this.questionLibrary.questions = this.getSelectedTemplate().questions;
      }

      this.api.post(this.api.endpoints.question_library, this.questionLibrary).subscribe({
        next : (data) => {
          let result = { action: 'save', ql: data.payload ? data.payload[0] : null };
          this.snackbar.open('Question Set saved successfully', 'Dismiss', { duration: 3000 });
          this.dialogRef.close(result);
        },
        error : (error) => {
          console.error('add-question-library-modal.component:081', error);
          this.snackbar.open('Error saving question set', 'Dismiss', { duration: 3000 });
        }
      });
    } else {
      this.snackbar.open('Please fill in all required fields', 'Dismiss', { duration: 3000 });
    }
  }

  changeSelectedCategory(event : any) {
    console.log('add-question-library-modal.component:838887', event.value);
    this.selectedCategory = event.value;
  }

  changeSelectedTemplate(event : any) {
    console.log('add-question-library-modal.component:347564', event.value);
    this.selectedTemplate = event.value;
  }

  getSelectedTemplate() {
    let rst = this.questionLibraryTemplates.find((ql) => ql.id == this.selectedTemplate);
    if(rst) {
      return rst;
    } else {
      return new QuestionLibraryModel();
    }
  }

  filterQuestionLibraryTemplates() {
    return this.questionLibraryTemplates.filter((ql) => ql.category == this.selectedCategory);
  }
}
