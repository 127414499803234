import { Component } from '@angular/core';

@Component({
  selector: 'app-external-surveys',
  templateUrl: './external-surveys.component.html',
  styleUrl: './external-surveys.component.scss'
})
export class ExternalSurveysComponent {

}
