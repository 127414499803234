import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-survey-pro-upgrade-modal',
  templateUrl: './survey-pro-upgrade-modal.component.html',
  styleUrl: './survey-pro-upgrade-modal.component.scss'
})
export class SurveyProUpgradeModalComponent {

  constructor(
    private dialogRef : MatDialogRef<SurveyProUpgradeModalComponent>
  ) { }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

}
