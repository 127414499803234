import { Component, Input } from '@angular/core';

@Component({
  selector: 'credit-card',
  templateUrl: './credit-card.component.html',
  styleUrl: './credit-card.component.scss'
})
export class CreditCardComponent {
  @Input() card : CardDetails | null = null;
  public _card : CardDetails | null = new CardDetails();

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this._card = this.card;
  }

  get last4(): string {
    try {
      let last4 = "XXXX";

      if(this.card && this.card.last4) {
        last4 = this.card.last4;
      }

      return last4;
    } catch (error) {
      console.error(error);
      return "XXXX";
    }
  }
  get brand(): string { return this._card && this._card.brand ? this._card.brand : ""; }
  get cardholder(): string { return this._card && this._card.name ? this._card.name : ""; }
  get exp_month(): string { return this._card && this._card.exp_month ? this._card.exp_month.toString() : "00"; }
  get exp_year(): string { return this._card && this._card.exp_year ? this._card.exp_year.toString() : "00"; }

}

export class CardDetails {
  name : string;
  brand : string;
  last4 : string;
  country : string;
  exp_year : number;
  exp_month : number;

  constructor() {
    this.name = "";
    this.brand = "";
    this.last4 = "XXXX";
    this.country = "";
    this.exp_year = 0;
    this.exp_month = 0;
  }
}