import { Component, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { faArrowsRotate, faCircleCheck, faCircleExclamation, faCircleXmark, faEye, faEyeSlash, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { faStripe, faTwitter  } from '@fortawesome/free-brands-svg-icons';
import { ManageProductModalComponent } from 'src/app/modals/manage-product-modal/manage-product-modal.component';
import { CompaniesModel } from 'src/app/models/companies.model';
import { Merchants, MerchantProductModel } from 'src/app/models/tpp.model';
import { UsersModel } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { environment } from 'src/environments/environment';
import { TPPService } from 'src/app/services/tpp.service';
import { ApiResponseModel } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';

const TXFEE = "1.00";

@Component({
  selector: 'app-payments-settings',
  templateUrl: './payments-settings.component.html',
  styleUrl: './payments-settings.component.scss'
})
export class PaymentsSettingsComponent {
  auth = inject(AuthService);
  companyService = inject(CompanyService);
  dss = inject(DataSharingService);
  modal = inject(MatDialog);
  snackbar = inject(MatSnackBar);
  tpp = inject(TPPService);
  loading = inject(LoadingService);

  TXFEE = TXFEE;

  private connectOAuthUrl = environment.stripe.connectAuthorizeUrl;

  // Font Awesome Icons
  faArrowsRotate = faArrowsRotate;
  faCircleCheck = faCircleCheck;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faPlus = faPlus;
  faSave = faSave;
  faStripe = faStripe;
  faTwitter = faTwitter;
  faCircleXmark = faCircleXmark;
  faCircleExclamation = faCircleExclamation;

  // Models & Observables
  public company : CompaniesModel | null = null;
  private company$ : any;
  public user : UsersModel | null = null;
  private user$ : any; 
  private products : MerchantProductModel[] = [];
  private merchant$ : any;
  public merchant : Merchants = new Merchants();
  public stripeProducts : any[] = [];

  public stripeDetailsLoading : boolean = true;
  public stripeProductsLoading : boolean = false;
  public showPaymentAcct : boolean = false;
  public enable_payments : boolean = false;

  public tooltips = {
    acctSetupComplete : 'This account is set up and ready to receive payments. Update this field if you need to change the account.',
    enablePayments : 'Allow clients to pay for surveys online. This will require you to set up a Stripe account.',
    name : 'The name of the product. This will be displayed to the client.',
    description : 'A brief description of the product. This will be displayed to the client.',
    stripeAccount : 'This is the account that will receive payments from clients. This account must be set up with Stripe and it starts with "acct_".',
    stripePriceId : 'The ID of the product in Stripe. It begins with "price_".',
    frequency : 'How often should we charge the participant?',
    payment_timing: 'When should we ask for payment?',
    currency : 'Currency to to use',
    amount: 'Amount to charge the participant',
    charges_enabled : 'You are set up to take payments.',
    charges_disabled : 'You are not set up to take payments. Click here to connect to Stripe to re-enable.',
    payouts_enabled : 'You are set up to receive the funds from your payments.',
    payouts_disabled : 'You are not set up to receive the funds from your payments. Click here to connect to Stripe to re-enable.',
    hasNoRequirements : 'There are no requirements that need to be met to receive payments.',
    hasUrgentRequirements : 'There are outstanding requirements that must met to be able to receive payments. Click here to resolve these requirements.',
    hasUpcomingRequirements : 'There are requirements that will need to be met to continue receiving payments. Click here to resolve these requirements.',
  }

  public dataSource : MatTableDataSource<MerchantProductModel> = new MatTableDataSource<MerchantProductModel>();
  public displayedColumns: string[] = [
    'name', 
    'pricing', 
    'surveys',
  ];

  constructor() { }

  ngOnInit() {
    this.stripeDetailsLoading = true;
    this.loadstripePrices();

    this.tpp.getMerchant().subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.merchant = response.payload;
        }
        this.stripeDetailsLoading = false;
      },
      error : (err) => {
        console.error('payments-settings.component:182944', err);
        this.stripeDetailsLoading = false;
      }
    });

    this.tpp.getMerchantProducts().subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.products = response.payload;
          this.dataSource.data = this.products;
        }
        this.stripeProductsLoading = false;
      },
      error : (err) => {
        console.error('payments-settings.component:182944', err);
        this.stripeProductsLoading = false;
      }
    });

    this.user$ = this.auth.user.subscribe({
      next : (user : UsersModel | null) => {
        this.user = user;
      },
      error : (err : any) => {
        console.error(err);
      }
    });

    this.company$ = this.companyService.company.subscribe({
      next : (company : CompaniesModel | null) => {
        this.company = company;
        if(!this.company) { return; }
        if(!this.company.tpp) {
          this.company.tpp = {};
        }

        this.enable_payments = this.company.tpp.enableTPP || false;

        if(!this.company.tpp.products) {
          this.company.tpp.products = [];
        } else {
          this.products = this.company.tpp.products;
        }
      },
      error : (err : any) => {
        console.error(err);
      }
    });

    this.merchant$ = this.tpp.merchants.subscribe({
      next : (details : Merchants) => {
        this.merchant = details;
      },
      error : (err : any) => {
        console.error(err);
      }
    });
  }

  ngOnDestroy() {
    // this.user$.unsubscribe();
    // this.company$.unsubscribe();
  }

  loadstripePrices() {
    this.tpp.getStripeProducts().subscribe({
      next : (response) => {
        if(response && response.payload) {
          this.stripeProducts = response.payload;
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  renderStripePrice(id : string) {
    let price = this.stripeProducts.find((product) => product.id === id);

    // $xx.xx CAD / month 
    if(price) {
      if(price.recurring) {
        return `$${price.unit_amount / 100} ${price.currency.toUpperCase()} / ${price.recurring.interval}`;
      } else {
        return `$${price.unit_amount / 100} ${price.currency.toUpperCase()} / lifetime`;
      }
    }

    return '';
  }
  
  stripeHasValue() {
    return this.company?.tpp?.stripe_acct && this.company?.tpp?.stripe_acct.length > 0;
  }

  canSave() {
    return true;
    // return  this.frmPayments.valid && this.frmPayments.dirty;
  }

  togglePaymentAcctVisibility() {
    this.showPaymentAcct = !this.showPaymentAcct;
  }

  saveCompany() {
    try {
      if(!this.company) { return; }

      // Include the updated products
      if(!this.company.tpp) { this.company.tpp = {}; }
      this.company.tpp.products = this.products;

      this.companyService.update(this.company).subscribe({
        next : (response) => {
          if(response.payload) {
            this.companyService.save(this.company!);
          }
        },
        error : (err) => {
          console.error('general-settings.component:045251', err);
        }
      });
    } catch(err) {
      console.error('general-settings.component:173652', err);
      this.snackbar.open('There was an error saving the settings. Please try again', 'Dismiss', { duration : 3000 });
    }
  }

  isFiltered(): boolean {
    return this.dataSource.filter !== '';
  }

  handleAddProduct() {
    let product = new MerchantProductModel();
    product.merchant_id = this.merchant.id;

    this.tpp.createMerchantProduct(product).subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.products.push(response.payload);
          this.dataSource.data = this.products;
          console.log('payments-settings.component:501886', response.payload);
          this.openManageTPPProductModal(response.payload);
        } else {
          this.snackbar.open('There was an error creating the product. Please try again.', 'Dismiss', { duration : 3000 });
        }
      },
      error : (err) => {
        console.error('payments-settings.component:528384', err);
      }
    });
  }

  handleEditProduct(mp : MerchantProductModel) {
    this.openManageTPPProductModal(mp);
  }

  openManageTPPProductModal(mp : MerchantProductModel) {
    // Open the modal
    let config = new MatDialogConfig();

    config.data = { 
      product : mp,
      products : this.products
    };

    console.log('payments-settings.component:900242', config.data);

    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];

    this.modal.open(ManageProductModalComponent, config).afterClosed().subscribe(
      (result : any) => {
        if(result) {
          console.log('payments-settings.component:159367', result);
          let index = -1;

          switch(result.action) {
            case 'save':
              index = this.products.findIndex((product) => product.id === result.product.id);
              if(index > -1) {
                this.products[index] = result.product;
                this.dataSource.data = this.products;
              }
              break;
            case 'delete':
              index = this.products.findIndex((product) => product.id === result.product.id);
              console.log('payments-settings.component:084084', index);
              if(index > -1) {
                this.products.splice(index, 1);
                this.dataSource.data = this.products;
              }
              break;
          }
        }
      }
    );
  }

  handleChargesDisabled() {
    if(this.merchant.charges_enabled) return; 
    this.connectStripe();
  }

  handlePayoutsDisabled() {
    if(this.merchant.payouts_enabled) return;
    this.connectStripe();
  }

  handleRequirements() {
    if(this.hasRequirements() === 'no') return;
    this.connectStripe();    
  }

  connectStripe() {
    try {
      this.loading.start('Preparing to connect to Stripe...');
      this.tpp.initiateStripeConnection().subscribe({
        next : (response : ApiResponseModel) => {
          if(response.payload) {
            if(response.payload.merchants) {
              this.tpp.merchants.next(response.payload.merchants);
            }
  
            if(response.payload.account_link) {
              // Open the Stripe Connect page
              window.open(response.payload.account_link.url, '_blank');            
            }
          }

          this.updateEnablePayments(true);
          this.loading.stop();
        },
        error : (err) => {
          console.error('payments-settings.component:691672', err);
          this.loading.stop();
        }
      });
    } catch(err) {
      console.error('payments-settings.component:588927', err);
      this.loading.stop();
    }
  }

  /**
   * TODO
   */
  refreshProductList() {
    this.stripeProductsLoading = true;
  }

  /**
   * This method checks if the merchant has any requirements that need to be met
   * @returns {string} - yes, no, or upcoming
   */
  hasRequirements() {
    if(!this.merchant.requirements) { return 'no'; }

    if(this.merchant.requirements !== 'no') {

      let keys = Object.keys(this.merchant.requirements);
      if(keys.includes('currently_due')) {
        if(this.merchant.requirements.currently_due.length > 0) return 'yes';
      }
      if(keys.includes('past_due')) {
        if(this.merchant.requirements.past_due.length > 0) return 'yes';
      }
      if(keys.includes('pending_verification')) {
        if(this.merchant.requirements.pending_verification.length > 0) return 'yes';
      }

      if(keys.includes('eventually_due')) {
        if(this.merchant.requirements.eventually_due.length > 0) return 'upcoming';
      }
    }

    return 'no';
  }

  getRequirementsErrors() {
    if(this.merchant && this.merchant.requirements) {
      if(this.merchant.requirements.errors) {
        return this.merchant.requirements.errors;
      }
    }
    return [];
  }

  getRequirementsDeadline() : string {
    let deadline = '';

    if(this.hasRequirements() === 'upcoming') {
      let keys = Object.keys(this.merchant.requirements);
      if(keys.includes('eventually_due')) {
        // convert to a date        
        if(!this.merchant.requirements.current_deadline) {
          deadline = "sometime in the future";
        } else {
          deadline = new Date(this.merchant.requirements.current_deadline).toLocaleDateString();
        }
      }
    }

    return deadline;
  }

  getRequirementsTooltip() {
    switch(this.hasRequirements()) {
      case 'yes':
        return this.tooltips.hasUrgentRequirements;
      case 'upcoming':
        return this.tooltips.hasUpcomingRequirements;
      default:
        return this.tooltips.hasNoRequirements;
    }
  }

  getAccountDisabledReason() {
    return this.merchant.requirements.disabled_reason || '';
  }

  connectInitiated() : boolean {
    return !!this.merchant.stripe_user_id;
  }

  handleEnablePaymentsToggle(event : any) {
    this.updateEnablePayments(event.checked);
  }

  updateEnablePayments(value : boolean) {
    if(!this.company || !this.company.tpp) { return; }
    this.company.tpp.enableTPP = value;
    this.saveCompany();
  }

  manageProductsOnStripe() {
    window.open('https://dashboard.stripe.com/products', '_blank');
  }
}
