<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">{{ isNew ? 'Add Cohort Member':'Edit Cohort Member' }}</div>
  <div class="my-10">
    <form class="flex flex-wrap w-full" [formGroup]="frmManageContact">
      <div class="flex flex-wrap xs:w-full lg:w-1/2 xs:pr-0 lg:pr-4">
        <mat-label class="w-full flex font-bold required">First Name</mat-label>
        <mat-form-field appearance=outline class="w-full flex ">
          <input matInput placeholder="Enter a name" formControlName="first_name">
        </mat-form-field>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 xs:pr-0 lg:pl-4">
        <mat-label class="w-full flex font-bold required">Last Name</mat-label>
        <mat-form-field appearance=outline class="w-full flex">
          <input matInput placeholder="Enter a name" formControlName="last_name">
        </mat-form-field>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 xs:pr-0 lg:pr-4">
        <mat-label class="font-bold">Company Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput placeholder="Enter a name" formControlName="company_name">
        </mat-form-field>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 xs:pr-0 lg:pl-4">
        <mat-label class="font-bold required">Email</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput placeholder="Enter a name" formControlName="email">
        </mat-form-field>
      </div>
      <div class="flex flex-wrap xs:w-full lg:w-1/2 xs:pr-0 lg:pr-4">
        <mat-label class="font-bold">Phone Number</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput placeholder="Enter a name" formControlName="phone">
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex xs:flex-wrap float-right lg:flex-nowrap w-full">
    <ng-container *ngIf="isNew">
      <button class="xs:w-full lg:w-1/2 mt-3 btn-secondary-outline mr-3" type="button" (click)="close()">Close</button>
      <button class="xs:w-full lg:w-1/2 mt-3 bg-primary mr-3" type="button" [disabled]="!canSave()" (click)="handleSave()">Save</button>
    </ng-container>
    <ng-container *ngIf="!isNew">
      <button class="xs:w-full lg:w-1/3 mt-3 btn-warn-outline btn-secondary-outline mr-3" type="button" (click)="delete()">Delete</button>
      <button class="xs:w-full lg:w-1/3 mt-3 btn-secondary-outline mr-3" type="button" (click)="close()">Close</button>
      <button class="xs:w-full lg:w-1/3 mt-3 bg-primary mr-3" type="button" [disabled]="!canSave()" (click)="handleSave()">Save</button>
    </ng-container>
  </div>
</mat-dialog-actions>