<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <a class="cursor-pointer" (click)="back()"><h1><fa-icon [icon]="faArrowLeft" class="mr-3"></fa-icon>  Contact Profile</h1></a>
    <h2 class="mt-5">{{ contact.first_name }} {{ contact.last_name }}</h2>
    <div>{{ contact.email }}</div>
  </div>
</section>

<section class="flex mt-10">
  <div class="w-1/3 pr-5">
    <h2 class="mb-10 text-center">Assigned</h2>
    <div *ngIf="surveys.length == 0" class="mt-10 bg-gray-200 m-3 px-5 py-10 rounded-lg text-center">
      No surveys assigned
    </div>

    <mat-accordion *ngIf="surveys.length > 0">
      <mat-expansion-panel *ngFor="let survey of surveys; let isFirst = first" [expanded]="isFirst" (opened)="panelOpened(survey)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="surveyHeader"> {{ survey.name }} </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="px-3 mb-3">
          {{ renderDateRange(survey) }}
        </div>
        <div class="mb-3" *ngFor="let sp of getParticipantsbySurvey(survey.id || '')">
          <ng-container *ngIf="hasResponses(sp)">
            <div class="spEntry cursor-pointer" [matTooltip]="tooltips.view_response" (click)="setCurrentResponse(spr)" *ngFor="let spr of sp.survey_responses">
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.COMPLETE" class="status mb-3 bg-green-400">Complete</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.INCOMPLETE" class="status mb-3 bg-red-400">Not complete</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.NOT_PUBLISHED" class="status mb-3 bg-blue-400">Unpublished</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.WAITING" class="status mb-3 bg-blue-400">Waiting for response</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.REDACTED" [matTooltip]="tooltips.anonymous" class="status mb-3 cursor bg-blue-400">Responses hidden due to anonymous survey</div>

              <div class="flex">
                <div class="w-1/3 font-bold">Sent</div>
                <div class="w-2/3"> {{ sp.sent | date:'yyy-MM-dd HH:mm' }} </div>

              </div>
              <div class="flex">
                <div class="w-1/3 font-bold">Completed </div>
                <div class="w-2/3"> {{ sp.completed ? (sp.completed | date:'yyy-MM-dd HH:mm') : '---' }} </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!hasResponses(sp)">
            <div class="spEntry cursor-auto">
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.COMPLETE" class="status mb-3 bg-green-400">Complete</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.INCOMPLETE" class="status mb-3 bg-red-400">Not complete</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.NOT_PUBLISHED" class="status mb-3 bg-blue-400">Unpublished</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.WAITING" class="status mb-3 bg-blue-400">Waiting for response</div>
              <div *ngIf="getResponseStatus(sp) == SURVEY_RESPONSE_STATES.REDACTED" [matTooltip]="tooltips.anonymous" class="status mb-3 cursor bg-blue-400">Responses hidden due to anonymous survey</div>

              <div class="flex">
                <div class="w-1/3 font-bold">Sent</div>
                <div class="w-2/3"> {{ sp.sent | date:'yyy-MM-dd HH:mm' }} </div>
              </div>
              <div class="flex">
                <div class="w-1/3 font-bold">Completed </div>
                <div class="w-2/3"> {{ sp.completed ? (sp.completed | date:'yyy-MM-dd HH:mm') : '---' }} </div>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="w-2/3">
    <h1 class="text-center">Results</h1>
    <div class="px-5 py-10">
      <tpt-render-responses [survey]="currentSurvey" [response]="currentResponse"></tpt-render-responses>
    </div>
  </div>
</section>