<!-- Page Title -->
<div class="w-full mb-5">
  <h1>Touchpoint Manager</h1>
</div>

<!-- Page Content -->
  <!-- Filter Section -->
  <section class="flex items-center mb-10">
    <div class="w-full">
      <mat-form-field appearance=outline>
        <mat-select [value]="selectedPeriod" (selectionChange)="handlePeriodChange($event)">
          @for (period of periodFilterOptions; track period) {
        <mat-option [value]="period.value">{{period.name}}</mat-option>
      }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="w-full text-right">
      <mat-form-field appearance=outline class="searchInput">
        <input matInput (keyup)="updateFilter($event)" placeholder="Filter Touchpoint">
        <fa-icon matSuffix class="pr-2" [icon]="faSearch"></fa-icon>
      </mat-form-field>

      <button class="ml-5 form-button" matTooltip="Add Touchpoint" (click)="openAddEditTouchpointModal()">
        <fa-icon [icon]="faPlus"></fa-icon>          
      </button>
      <button class="ml-5 form-button bg-white text-gray-600" matTooltip="Upload Touchpoint" (click)="openUploadTouchpointModal()">
        <fa-icon [icon]="faUpload"></fa-icon>        
      </button>
    </div>
  </section>

  <!-- Table Section // Plan users only -->
  <ng-container *ngIf="!isLoading">
    <table mat-table [dataSource]="dataSource" class="">
      <!-- contact_name Column -->
      <ng-container matColumnDef="contact_name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.contact_name}}</td>
      </ng-container>
    
      <!-- contact_company_name Column -->
      <ng-container matColumnDef="contact_company_name">
        <th mat-header-cell *matHeaderCellDef>Company</th>
        <td mat-cell *matCellDef="let element">{{element.contact_company_name}}</td>
      </ng-container>
    
      <!-- contact_phone_number Column -->
      <ng-container matColumnDef="contact_phone_number">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let element">{{element.contact_phone_number}}</td>
      </ng-container>
    
      <!-- scheduled_day Column -->
      <ng-container matColumnDef="scheduled_day">
        <th mat-header-cell *matHeaderCellDef>Scheduled Day</th>
        <td mat-cell *matCellDef="let element">{{element.scheduled_day}}</td>
      </ng-container>
  
      <!-- time_slot Column -->
      <ng-container matColumnDef="time_slot">
        <th mat-header-cell *matHeaderCellDef>Time Slot</th>
        <td mat-cell *matCellDef="let element">
          {{ renderTimeSlot(element) }}
          <fa-icon *ngIf="element.contact_asap" matTooltip="Call ASAP" mat class="ml-5 text-yellow-600" [icon]="faExclamationTriangle"></fa-icon>
        </td>
      </ng-container>
  
      <!-- ql Column -->
      <ng-container matColumnDef="ql">
        <th mat-header-cell *matHeaderCellDef>Question Set</th>
        <td mat-cell *matCellDef="let element">xxx</td>
      </ng-container>
  
      <!-- contact asap Column -->
      <ng-container matColumnDef="contact_asap">
        <th mat-header-cell *matHeaderCellDef>Contact ASAP?</th>
        <td mat-cell *matCellDef="let element">
          <span class="text-center" *ngIf="element.contact_asap"><fa-icon [icon]="faCheck"></fa-icon></span>
        </td>
      </ng-container>
  
      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          {{element.status}}
          <span *ngIf="element.scheduled_day"> - {{ element.scheduled_day | date: 'yyyy-MM-dd' }}</span>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer" (click)="openAddEditTouchpointModal(row)" [ngClass]="row.status =='Incomplete'?'bg-incomplete':''" ></tr>
      <tr class="mat-row" *matNoDataRow>
        <ng-container *ngIf="isLoading">
          <td [attr.colspan]="displayedColumns.length" class="py-5">
            <mat-progress-spinner color="primary" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
          </td>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="text-center my-10" *ngIf="isFiltered()">
              <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
            </div>
            <div class="text-center my-10" *ngIf="!isFiltered()">
              <h4 class="text-center mt-10 mb-5">No touchpoints found</h4>
              <div class="text-center mb-10">
                <button class="ml-5 btn-secondary-outline mr-3" (click)="openUploadTouchpointModal()"><fa-icon [icon]="faUpload"></fa-icon> Upload Touchpoints</button>
                <button class="ml-5 bg-primary mr-3" (click)="openAddEditTouchpointModal()">
                  <fa-icon [icon]="faPlus"></fa-icon> Add Touchpoint
                </button>
              </div>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  
    <!-- Pagination Section -->
    <section class="flex items-center justify-between">
      <div class="w-full">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </section>
  </ng-container>