import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { QuestionLibraryModel, QuestionLibraryStructureModel, QuestionLibraryViewModel } from '../models/question_library.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionLibraryService {
  public LIBRARY_TYPES = {
    ALL : 'A',
    TOUCHPOINTS : 'T',
    SURVEYS : 'S'
  };
  
  public questionLibrary : BehaviorSubject<QuestionLibraryModel[]> = new BehaviorSubject<QuestionLibraryModel[]>([]);
  public questionLibraryView : BehaviorSubject<QuestionLibraryViewModel[]> = new BehaviorSubject<QuestionLibraryViewModel[]>([]);
  public questionStructure : BehaviorSubject<QuestionLibraryStructureModel | null> = new BehaviorSubject<QuestionLibraryStructureModel | null>(null);

  constructor(
    private api : ApiService
  ) { }

  public getQuestionStructure() {
    return this.api.get(this.api.endpoints.question_structure).subscribe({
      next : (response : any) => {
        this.questionStructure.next(response.payload);
      }
    })
  }

  public getQuestionLibrary(libraryType : string) {
    if(!libraryType) { libraryType = 'all'; }
    
    return this.api.get(this.api.endpoints.question_library + '?type=' + libraryType).subscribe({
      next : (response : any) => {
        this.questionLibrary.next(response.payload);
      }
    })
  }

  public getQuestionLibraryView(libraryType : string) {
    if(!libraryType) { libraryType = 'all'; }
    
    return this.api.get(this.api.endpoints.question_library + '?type=' + libraryType).subscribe({
      next : (response : any) => {
        this.questionLibraryView.next(response.payload);
      }
    })
  }
}
