import { Component, inject, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionConditionsModel, QuestionsModel } from 'src/app/models/question_library.model';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-question-conditions-modal',
  templateUrl: './manage-question-conditions-modal.component.html',
  styleUrl: './manage-question-conditions-modal.component.scss'
})
export class ManageQuestionConditionsModalComponent {
  dialogRef = inject(MatDialogRef<ManageQuestionConditionsModalComponent>);
  data = inject(MAT_DIALOG_DATA);

  faPlus = faPlus;
  faTrash = faTrash;

  public questions : QuestionsModel[] = [];
  public current_question : QuestionsModel = new QuestionsModel();
  public current_question_index : number = -1;
  public conditions : QuestionConditionsModel[] = [];
  public frmAddCondition : FormGroup = new FormGroup({});

  public dataSource : MatTableDataSource<QuestionConditionsModel> = new MatTableDataSource<QuestionConditionsModel>();
  public displayedColumns: string[] = [
    'question', 
    'comparator', 
    'value', 
    'action', 
    'buttons',
  ];

  public comparators : { key: string, value: string }[] = [
    { key : '=', value : 'eq' },
    { key : '<>', value : 'ne' },
    { key : '>', value : 'gt' },
    { key : '<', value : 'lt' },
    { key : '>=', value : 'gte' },
    { key : '<=', value : 'lte' }
  ];
  public actions : { key: string, value: string }[] = [
    // { key : 'show', value : 'show' },
    { key : 'hide', value : 'hide' },
    // { key : 'optional', value : 'optional' },
    // { key : 'disable', value : 'disable' }
  ];

  constructor() { 
    this.frmAddCondition = new FormGroup({
      question_id : new FormControl('', [Validators.required]),
      comparator : new FormControl('', [Validators.required]),
      value : new FormControl('', [Validators.required]),
      action : new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    console.log('manage-question-conditions-modal.component:308076', this.data);
    if(this.data) {
      this.questions = this.data.questions || [];
      this.current_question = this.data.current_question || new QuestionsModel();
      this.conditions = this.current_question.conditions || [];
      this.dataSource.data = this.conditions;

      this.current_question_index = this.questions.findIndex(q => q.id == this.current_question.id);
      console.log('manage-question-conditions-modal.component:572896', this.questions, this.current_question, this.current_question_index);
    }
  }

  /**
   * Add a new condition to the array
   */
  addCondition() {
    let condition = new QuestionConditionsModel();
    condition.question_id = this.frmAddCondition.get('question_id')?.value;
    condition.comparator = this.frmAddCondition.get('comparator')?.value;
    condition.value = this.frmAddCondition.get('value')?.value;
    condition.action = this.frmAddCondition.get('action')?.value;

    this.conditions.push(condition);
    this.dataSource.data = this.conditions
  }

  removeCondition(index : number) {
    this.conditions.splice(index, 1);
    this.dataSource.data = this.conditions;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({ conditions : this.conditions });
  }

  canSave() {
    return true;
  }

  renderQuestion(question_id : string) {
    let question = this.questions.find(q => q.id == question_id);
    return question ? question.question : '';
  }
  renderComparator(comparator : string) {
    let comp = this.comparators.find(c => c.value == comparator);
    return comp ? comp.key : '';
  }
}
