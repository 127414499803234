import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { faEnvelope, faPlus, faSearch, faCheck, faTrashCan, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CohortsModel } from 'src/app/models/contacts.model';
import { SurveyModel, SurveyParticipantsModel } from 'src/app/models/survey.model';
import { ApiResponseModel, ApiService } from 'src/app/services/api.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { SurveyParticipantService } from 'src/app/services/survey-participant.service';

@Component({
  selector: 'app-survey-participants',
  templateUrl: './survey-participants.component.html',
  styleUrl: './survey-participants.component.scss'
})
export class SurveyParticipantsComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('formDirective') formDirective : any;

  @Input() survey : SurveyModel | null = null;
  @Input() participants : SurveyParticipantsModel[] = [];
  @Output() participantsChange = new EventEmitter<SurveyParticipantsModel[]>();

  public cohorts : CohortsModel[] = [];

  faPlus = faPlus;
  faSearch = faSearch;
  faCheck = faCheck;
  faTrashCan = faTrashCan;
  faEnvelope = faEnvelope;
  faUsers = faUsers;
  faShareNodes = faShareNodes;

  public tooltips = {
    addParticipant: 'Add a participant to this survey',
    shareLink: 'Share the survey link with the participant',
    search: 'Search for a participant',
    completed: 'This participant has completed the survey',
    resend: 'Resend the invitation to this participant',
    remove: 'Remove this participant from the survey. If this is an invite-only survey, they will no longer be able to complete it.',
  }

  public frmAddParticipant : FormGroup = new FormGroup({});
  public frmAddCohort : FormGroup = new FormGroup({});
  public dataSource : MatTableDataSource<SurveyParticipantsModel> = new MatTableDataSource<SurveyParticipantsModel>();
  public displayedColumns: string[] = [
    'name', 
    'email', 
    'cohort',
    'completed',
    'actions',
  ];
  public isLoading = false;

  constructor(
    private api : ApiService,
    private cohortsService : ContactsService,
    private spService : SurveyParticipantService,
    private modal : MatDialog,
    private snackbar : MatSnackBar
  ) {
    this.frmAddParticipant = new FormGroup({
      name : new FormControl('', Validators.required),
      email : new FormControl('', Validators.email),
    });

    this.frmAddCohort = new FormGroup({
      cohort : new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.loadCohorts();
  }

  ngOnChanges(changes : SimpleChanges) {
    if(changes['survey']) {
      this.loadParticipants();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  loadCohorts() {
    this.cohortsService.getCohorts().subscribe({
      next : (response : ApiResponseModel) => {
        this.cohorts = response.payload;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }


  loadParticipants() {
    if(!this.survey) return;
    if(!this.survey.id) return;

    let survey_id = this.survey.id || '';

    this.api.get(this.api.endpoints.survey_participants.replace(':survey_id', survey_id)).subscribe({
      next : (response) => {
        this.participants = response.payload;
        this.participantsChange.emit(this.participants);
        // console.log('survey-participants.component:061868', this.participants);
        this.dataSource.data = response.payload;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  isFiltered(): boolean {
    return this.dataSource.filter !== '';
  }

  updateFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue === '' || filterValue === null || filterValue === undefined) {
      this.dataSource.filter = '';
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  inviteParticipant(type : string) {
    let data : any
    let survey_id = this.survey?.id || '';

    switch(type) {
      case 'direct' : 
        if(this.frmAddParticipant.invalid) {
          return;
        }
        data = {
          name : this.frmAddParticipant.value.name,
          email : this.frmAddParticipant.value.email
        }

        this.sendDirectInvite(survey_id, data);
        break;

      case 'cohort' :
        if(this.frmAddCohort.invalid) {
          return;
        }
        data = {
          cohort_id : this.frmAddCohort.value.cohort
        }

        this.sendCohortInvite(survey_id, data);
        break;
    }
    
    console.log('survey-participants.component:368214', data);
  }

  sendDirectInvite(survey_id : string, payload : any) {
    this.spService.sendDirectInvite(survey_id, payload).subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload && Array.isArray(response.payload)) {
          // Payload is an array that contains:
          // [0] - the participant
          // [1] - true/false if the user was already in the survey
          if(!response.payload[1]) {
            this.snackbar.open('This participant is already in the survey', 'Close', { duration: 3000 });
            return;
          }

          console.log('survey-participants.component:960369', JSON.stringify(this.participants));
          this.participants.push(response.payload[0]);
          this.participantsChange.emit(this.participants);
          console.log('survey-participants.component:702487', this.participants);
          this.dataSource.data = this.participants;
          this.dataSource.paginator = this.paginator;
          this.frmAddParticipant.reset();
          this.formDirective.resetForm();
          // this.frmAddParticipant.markAsPristine();
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  sendCohortInvite(survey_id : string, payload : any) {
    console.log('survey-participants.component:864915', payload);
    this.spService.sendCohortInvite(survey_id, payload).subscribe({
      next : (response : ApiResponseModel) => {
        // `response` is structured like this : { participants : SurveyParticipantsModel[], added : number, skipped : number }
        this.participants = [ ...this.participants, ...response.payload.participants ];
        this.participantsChange.emit(this.participants);
        this.dataSource.data = this.participants;
        this.dataSource.paginator = this.paginator;
        this.frmAddParticipant.reset();
        this.formDirective.resetForm();
        // this.frmAddParticipant.markAsPristine();

        let msg = `Added ${response.payload.added} participants. `;
        if(response.payload.skipped > 0) {
          msg += `We didn't add ${response.payload.skipped} participants because they were already in the survey.`;
        }
        this.snackbar.open(msg, 'Close', { duration: 3000 });
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  removeInvitation(participant : SurveyParticipantsModel) {
    let invitationWarnings = '';


    if(!this.survey?.is_public) {
      invitationWarnings += 'They will no longer be able to complete the survey. ';
    } else {
      invitationWarnings += 'Because this is a public survey, they will still be able to complete the survey if they have the link. ';
    }

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: `Are you sure you want to remove ${participant.name} from this survey? ` + invitationWarnings,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.deleteSurveyParticipant(participant);
      }
    });
  }

  deleteSurveyParticipant(participant : SurveyParticipantsModel) {
    let survey_id = this.survey?.id || '';
    let participant_id = participant.id || '';
    // // If the survey is not published, no need to confirm the deletion
    // if(!this.survey?.is_published) {
    //   this.deleteSurveyParticipant(participant);
    //   return;
    // }

    this.api.delete(this.api.endpoints.survey_participants.replace(':survey_id', survey_id) + '/' + participant_id).subscribe({
      next : (response) => {
        this.participants = this.participants.filter((p) => p.id !== participant_id);
        this.participantsChange.emit(this.participants);
        this.dataSource.data = this.participants;
        this.dataSource.paginator = this.paginator;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  isPublished() {
    return this.survey?.is_published || false;
  }

  resendInvitation(participant : SurveyParticipantsModel) {
    if(participant.completed) return;

    let survey_id = this.survey?.id || '';
    let participant_id = participant.id || '';

    this.api.post(this.api.endpoints.survey_participants.replace(':survey_id', survey_id) + '/' + participant_id + '/resend', {}).subscribe({
      next : (response) => {
        this.snackbar.open('The invitation has been resent', 'Dismiss', { duration: 3000 });
        console.log(response);
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  /**
   * This function copies the survey url to the clipboard
   * 
   * @param participant 
   */
  shareLink(participant : SurveyParticipantsModel) {
    if(participant.completed) return;

    if(this.survey) {
      let url = participant.ref || '';
      url = window.location.origin + '/i/' + url;
      navigator.clipboard.writeText(url).then(() => {
        this.snackbar.open('The link to the survey has been copied to clipboard', 'Dismiss', { duration: 3000 });
      });
    } else {
      this.snackbar.open('No survey to share', 'Dismiss', { duration: 3000 });
    }
  }


  getCohortName(cohort_id : string) {
    let cohort = this.cohorts.find((c) => c.id === cohort_id);
    return cohort ? cohort.name : '';
  }
}
