<section>
  <div class="my-5 w-full flex flex-nowrap">
    <div class="flex">
      <button class="form-button outline" (click)="handleOpenAiAssistant()" [matTooltip]="tooltips.ai_assistant" [disabled]="readOnly">
        <fa-icon [icon]="faSparkles" class="text-blue-400"></fa-icon>
        <span class="text-gray-700 ml-5">AI Assistant</span>
        <span class="ml-3 pro-tag">PRO</span>
      </button>
    </div>
    <div class="flex flex-grow">
      <button class="ml-auto mr-3 form-button outline" (click)="accordion().openAll()" [matTooltip]="tooltips.expand" [disabled]="!hasQuestions()">
        <fa-icon class="text-gray-700" [icon]="faArrowsFromLine"></fa-icon>
        <span class="text-gray-700 ml-5">Expand</span>
      </button>
      <button class="form-button outline" (click)="accordion().closeAll()" [matTooltip]="tooltips.collapse" [disabled]="!hasQuestions()">
        <fa-icon class="text-gray-700" [icon]="faArrowsToLine"></fa-icon>
        <span class="text-gray-700 ml-5">Collapse</span>
      </button>
    </div>
  </div>

  <div *ngIf="!hasQuestions()" class="text-center mt-10 mb-5">
    <h4>No questions yet! Add your first one, or start with our AI Assistant.</h4>
  </div>
  <form [formGroup]="frmQuestionSetEditor" class="drag-list" *ngIf="hasQuestions()">
    <!-- Question Block -->
    <mat-accordion formArrayName="q" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-expansion-panel 
        class="drag-box" 
        cdkDrag 
        *ngFor="let question of q.controls; let i = index" 
        [cdkDragDisabled]="readOnly" 
        [disabled]="panelDisabled"
        [expanded]="panelStates[i]"
        (opened)="loadSurveyDefinitionComponent(i)" 
        (closed)="unloadSurveyDefinitionComponent(i)"
      >
         <mat-expansion-panel-header class="my-1">
           <mat-panel-title class="ellipsis">
             <h6 class="font-bold ellipsis">#{{i+1}} - {{ renderQuestionTitle(i) }}</h6>
           </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template #questionDefContainer></ng-template>
        </mat-expansion-panel>
    </mat-accordion>
  </form>
</section>
<button mat-flat-button class="mt-5 !py-8 !bg-primary !text-white w-full" *ngIf="!readOnly" (click)="handleAddQuestion()">
  <onboarding-badge [step]="[2]" tip="Click here to add questions to your survey">
    <fa-icon [icon]="faPlus" class="mr-3"></fa-icon>Add Question
  </onboarding-badge>
</button>