<!-- Page Title -->
<div class="w-full mb-5">
  <h1>Survey Manager</h1>
</div>

<!-- Page Content -->
  <!-- Filter Section -->
  <section class="flex items-center mb-10">
    <div class="w-full text-right flex">
      <mat-form-field appearance=outline class="searchInput sm:!w-full md:!w-full lg:flex-grow xl:flex-grow flex">
        <input matInput (keyup)="updateFilter($event)" placeholder="Filter Surveys">
        <fa-icon matSuffix class="pr-5" [icon]="faSearch"></fa-icon>
      </mat-form-field>
      <button class="flex ml-5 !py-5 px-6 mb-6" matTooltip="Add Survey" (click)="openAddEditSurveyModal()">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
      <!-- <button mat-flat-button class="ml-5" matTooltip="Upload Touchpoint" (click)="openUploadTouchpointModal()"><fa-icon [icon]="faUpload"></fa-icon></button> -->
    </div>
  </section>

  <!-- Table Section // Plan users only -->
  <ng-container *ngIf="!isLoading">
    <table mat-table [dataSource]="dataSource" class="">
      <!-- contact_name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
    
      <!-- contact_company_name Column -->
      <ng-container matColumnDef="is_anonymous">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.is_anonymous ? 'Anonymous':'Standard'  }}</td>
      </ng-container>
    
      <!-- contact_phone_number Column -->
      <ng-container matColumnDef="is_public">
        <th mat-header-cell *matHeaderCellDef>Distribution</th>
        <td mat-cell *matCellDef="let element">{{ element.is_public ? 'Public':'Invite Only' }}</td>
      </ng-container>

      <!-- contact_phone_number Column -->
      <ng-container matColumnDef="timing">
        <th mat-header-cell *matHeaderCellDef>Timing</th>
        <td mat-cell *matCellDef="let element">{{ renderTiming(element) }}</td>
      </ng-container>
    
      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          {{element.is_published ? 'Published' : 'Draft' }}
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer" (click)="openSurveyDetails(row)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <ng-container *ngIf="isLoading">
          <td [attr.colspan]="displayedColumns.length" class="py-5">
            <mat-progress-spinner color="primary" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
          </td>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="text-center my-10" *ngIf="isFiltered()">
              <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
            </div>
            <div class="text-center my-10" *ngIf="!isFiltered()">
              <h4 class="text-center mt-10 mb-5">No surveys found</h4>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  
    <!-- Pagination Section -->
    <section class="flex items-center justify-between">
      <div class="w-full">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </section>
  </ng-container>