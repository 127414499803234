import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CohortsModel } from 'src/app/models/contacts.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-manage-cohort-modal',
  templateUrl: './manage-cohort-modal.component.html',
  styleUrl: './manage-cohort-modal.component.scss'
})
export class ManageCohortModalComponent {

  public isNew : boolean = true;
  frmAddCohort : FormGroup;
  cohort : CohortsModel = new CohortsModel();

  constructor(
    private contactsService : ContactsService,
    private dialogRef : MatDialogRef<ManageCohortModalComponent>,
    private snackbar : MatSnackBar,
    private modal : MatDialog,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) {
    this.frmAddCohort = new FormGroup({
      cohort_name: new FormControl('', Validators.required),
    });

    if(data && data.cohort) {
      this.cohort = data.cohort;
      this.isNew = data.is_new;
      this.frmAddCohort.patchValue({
        cohort_name : this.cohort.name
      });
    }
  }

  get cohort_name() { return this.frmAddCohort.get('cohort_name'); }

  addCohort() {
    if(this.frmAddCohort.valid) {
      this.cohort.name = this.cohort_name?.value || '';

      this.contactsService.addCohort({ name : this.cohort.name }).subscribe({
        next : (response : ApiResponseModel) => {
          this.dialogRef.close({ action : 'save', response : response.payload });
          console.log('manage-cohort-modal.component:306084', response);
        },
        error : (error) => {
          console.error('manage-cohort-modal.component:203992', error);
          this.snackbar.open("Couldn't add a cohort", 'Close', { duration: 3000 });
        }
      });
    }
  }

  editCohort() {
    if(this.frmAddCohort.valid) {
      this.cohort.name = this.cohort_name?.value || '';

      this.contactsService.updateCohort(this.cohort).subscribe({
        next : (response : ApiResponseModel) => {
          this.dialogRef.close({ action : 'update', response : response.payload });
          console.log('manage-cohort-modal.component:849715', response);
        },
        error : (error) => {
          console.error('manage-cohort-modal.component:790549', error);
          this.snackbar.open("Couldn't update the cohort", 'Close', { duration: 3000 });
        }
      });
    }
  }

  delete() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: `<p>Deleting this cohort will also delete all contacts in this cohort along with their responses. If they are in other cohorts, they will not be deleted from those cohorts.</p><p class="mt-5 font-bold">Are you sure you want to delete this cohort?</p>`,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result) => {
      if(result) {
        this.contactsService.deleteCohort(this.cohort.id).subscribe({
          next : (response : ApiResponseModel) => {
            if(response) {
              this.dialogRef.close({ action : 'delete', response : this.cohort });
            } else {
              this.snackbar.open("Couldn't delete the cohort", 'Close', { duration: 3000 });
            }
          }
        });
      }
    });
  }

  canSave() {
    return this.frmAddCohort.valid;
  }

  save() {
    if(this.isNew) this.addCohort();
    else this.editCohort();
  }

  close() {
    this.dialogRef.close({ action : 'close', response : null });
  }

}
