<div class="survey-container">
  <!-- Header -->
  <section class="survey-header">
    <div class="mx-auto text-center px-10">
      <div class="xs:mb-5 sm:mb-10 md:mb-10 lg:mb-10">
        <h1 class="text-4xl tracking-widest	text-black">{{ renderTitle() }}</h1>
        <h4 class="text-xl mt-3 text-gray-500">{{ renderCompanyName() }}</h4>

        <!-- Progress Bar -->
        <div class="flex mt-10 justify-center">
          <mat-progress-bar  *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
          <mat-progress-bar  *ngIf="showProgressBar" mode="determinate" [value]="progressBarValue"></mat-progress-bar>
        </div>
      </div>
    </div>
  </section>

  <!-- Content -->
  <div class="survey-content">
    <!-- Display Errors -->
    <!-- Survey Not Started -->
    <ng-container *ngIf="currentSurveyStep == SURVEY_PHASES.NOT_STARTED">
      <div *ngIf="survey.is_anonymous" class="survey-type-pill"
        matTooltip="This is an anonymous survey. Your responses will be shared with the survey creator. They will NOT know provided the response">
        <fa-icon [icon]="faUserSecret" class="text-xl mr-5 text-gray-600"></fa-icon>
        <span class="font-bold text-gray-500">Anonymous Survey</span>
      </div>

      <div class="notice">
        <div class="mt-5 pt-5 pb-20" [innerHTML]="expandTextVariables(survey.welcome || '') | safeHtml"></div>
        <button (click)="navigateStep()">Let's begin</button>
      </div>
    </ng-container>
    
    <!-- Registration -->
    <ng-container *ngIf="currentSurveyStep == SURVEY_PHASES.REGISTRATION">
      <h1 class="text-3xl font-bold text-gray-500 text-center">Your information</h1>
      <form [formGroup]="frmParticipant" class="w-full 2xl:px-32 flex flex-wrap text-center">
        <div class="w-full pb-4">
          Please provide your name and email to begin the survey.
        </div>
        <div class="w-full lg:w-1/2 lg:pr-4 mx-auto">
          <mat-form-field appearance="outline" class="w-full">
            <input matInput formControlName="participant_name" placeholder="Your Name" required>
            <mat-error *ngIf="frmParticipant.controls['participant_name'].hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="w-full lg:w-1/2 mx-auto">
          <mat-form-field appearance="outline" class="w-full">
            <input matInput formControlName="participant_email" placeholder="Your Email" required>
            <mat-error *ngIf="frmParticipant.controls['participant_email'].hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="frmParticipant.controls['participant_email'].hasError('email')">
              Invalid email
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-span-12 text-sm text-gray-800">
          {{ survey.is_anonymous ? 'Although this is an anonymous survey, we need this information to ensure the survey is reliable. Your information will not be shared with the creator' : '' }}
        </div>
        <div class="w-full text-center">
          <button class="btn-secondary-outline" [matTooltip]="tooltips.skip_registration" *ngIf="registrationSkippable()" (click)="navigateStep(SURVEY_PHASES.SURVEY)">Skip</button>
          <button class="ml-3" [disabled]="!registrationAdvanceable()" (click)="navigateStep(SURVEY_PHASES.SURVEY)">Next</button>
        </div>
      </form>
    </ng-container>

    <!-- Survey Started -->
    <ng-container *ngIf="currentSurveyStep == SURVEY_PHASES.SURVEY">
      <!-- Survey Questions -->
      <app-survey-panel [question]="currentQuestion" [(response)]="currentResponse" [index]="currentQuestionIndex" (onNext)="nextQuestion()"></app-survey-panel>
      <!-- Navigation Buttons -->
      <alert-box type="info" *ngIf="incorrect_message">{{ incorrect_message }}</alert-box>
      <div class="flex w-full px-64">        
        <div class="w-1/2 text-left">
          <button class="btn-secondary-outline mr-3" (click)="prevQuestion()" type="button" *ngIf="survey.allow_back">
            <fa-icon [icon]="faLessThan"></fa-icon>
          </button>
        </div>
        <div class="w-1/2 text-right">
          <button class="btn-primary-outline mr-3" (click)="nextQuestion()" [disabled]="disableNext()" type="button">
            <fa-icon [icon]="faGreaterThan"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Survey Complete -->
    <ng-container *ngIf="currentSurveyStep == SURVEY_PHASES.COMPLETE">
      <div class="text-center">
        <h1 class="text-3xl font-bold text-gray-500">Thank you for completing the survey!</h1>
        <p class="px-5 pb-10" [innerHTML]="expandTextVariables(survey.thank_you || '') | safeHtml"></p>
        <!-- <tpt-render-responses *ngIf="survey.final_results" [survey]="survey" [response]="finalResponse"></tpt-render-responses> -->
        <button class="mt-20" *ngIf="canViewResults()" (click)="seeResults()">See your results</button>
        <button class="mt-20" *ngIf="!canViewResults() && showPoweredBy" (click)="createYourOwnSurvey()">Create your own FREE survey!</button>
      </div>
    </ng-container>

    <!-- Show promo -->
    <ng-container *ngIf="currentSurveyStep == SURVEY_PHASES.PROMO">
      <div class="text-center">
        <h1 class="text-3xl text-gray-500">{{ promoMessage }}</h1>

        <button class="mt-20" (click)="createYourOwnSurvey()">Create your own FREE survey!</button>
      </div>
    </ng-container>
  </div>
</div>
<!-- Footer -->
<div class="powered-by" *ngIf="showPoweredBy">
  <div class="font-bold text-gray-500 text-center sm:pb-2 md:pb-5">Super simple surveys start with</div>
  <div class="px-10">
    <a href="https://surveypoint.io">
      <img class="center" src="../../assets/SurveyPoint-logo.png" alt=""></a>
  </div>
</div>