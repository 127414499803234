import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CardDetails } from 'src/app/components/credit-card/credit-card.component';
import { PlanSignupModalComponent } from 'src/app/modals/plan-signup-modal/plan-signup-modal.component';
import { SubscriptionsModel } from 'src/app/models/subscription.model';
import { UsersModel } from 'src/app/models/users.model';
import { ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlanUpgradeModalComponent } from 'src/app/modals/plan-upgrade-modal/plan-upgrade-modal.component';
import { PLANSERVICE, PlansModel } from 'src/app/models/plans.model';
import { UpdatePaymentInfoModalComponent } from 'src/app/modals/update-payment-info-modal/update-payment-info-modal.component';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent {
  PLANSERVICE = PLANSERVICE;

  faFilePdf = faFilePdf;

  public card : CardDetails | null = new CardDetails();
  public plans : PlansModel[] = [];
  private plans$ : any;
  private user : UsersModel | null = null;
  private subscription : SubscriptionsModel | null = null;
  private subscription$ : any;
  public nextInvoice : any = null;
  public invoices : any[] = [];
  private currentPrice_id : string = '';
  // public touchpointPlan : PlansModel | null = null;
  public surveypointPlan : PlansModel | null = null;
  public surveypointStripePlan : any = {};

  public displayedColumns: string[] = [
    'invoice_date', 
    'invoice_number', 
    'invoice_amount', 
    'invoice_status', 
    'invoice_dl', 
  ];
  public dataSource : MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor(
    private api : ApiService,
    private dss : DataSharingService,
    private subscriptionService : SubscriptionService,
    private modal : MatDialog,
    private snackbar : MatSnackBar
  ) { 
    this.plans$ = this.subscriptionService.plans.subscribe({
      next : (plans) => {
        this.plans = plans;
      }
    });
  }

  ngOnInit() {
    this.api.get(this.api.endpoints.users + '/me').subscribe(response => {
      this.user = response.payload;
      this.card = (this.user && this.user.card) ? this.user.card : new CardDetails();
      // console.log('account-settings.component:886504', this.user, this.user?.card);
    });

    this.subscription$ = this.subscriptionService.stripeSubscription.subscribe({
      next : (stripeSubscription) => {
        // console.log('account-settings.component:884243', stripeSubscription);
        stripeSubscription.forEach((subscription) => {
          // Locate the plan for the subscription from the plans array (sourced from database)
          if(subscription.plan && subscription.plan.metadata && subscription.plan.metadata.service) {
            switch(subscription.plan.metadata.service) {
              case PLANSERVICE.CUSTOM :
                this.surveypointPlan = null;
                this.surveypointStripePlan = subscription;
                break;
              case PLANSERVICE.SURVEY :
                let plan = this.findPlan(subscription.plan.id);
                this.surveypointPlan = plan ? plan : null;
                this.surveypointStripePlan = subscription;
                break
            } 
          }

          // console.log('account-settings.component:482915', this.surveypointPlan, this.surveypointStripePlan);
        });
        // this.subscription = subscription;

        // if(this.subscription && this.subscription.stripeSubscription && this.subscription.stripeSubscription.data && this.subscription.stripeSubscription.data.length > 0) {
        //   this.currentPrice_id = this.subscription.stripeSubscription.data[0].plan.id;
        // }
      }
    });

    this.api.get(this.api.endpoints.subscriptions + '/invoices').subscribe(response => {
      let invoices = response.payload && response.payload.data ? response.payload.data : [];

      // Add `invoices` to `this.invoices` array
      invoices.forEach((invoice : any) => {
        let inv = {
          created : invoice.created,
          number : invoice.number,
          amount_due : invoice.amount_due,
          status : invoice.status,
          invoice_pdf : invoice.invoice_pdf
        }

        this.invoices.push(inv);
      });
      this.dataSource.data = this.invoices;
    });

    this.api.get(this.api.endpoints.subscriptions + '/invoices/upcoming').subscribe(response => {
      if(response.payload.length === 0) {
        this.nextInvoice = {
          created : 'TBD',
          amount_due : '---',
          status : 'Upcoming'
        };
      } else {
        this.nextInvoice = response.payload;
      }

      if(!this.nextInvoice) return;

      let estimatedInvoice = {
        // period_end is epoch
        created : this.nextInvoice.period_end,
        number : 'TBD',
        amount_due : this.nextInvoice.amount_due,
        status : this.nextInvoice.status,
      }

      this.invoices.unshift(estimatedInvoice);

      this.dataSource.data = this.invoices;
    });
  }

  ngOnDestroy() {
    // this.plans$.unsubscribe();
    // this.subscription$.unsubscribe();
  }

  get cancellationPending() {
    if (this.subscription && this.subscription.stripeSubscription && this.subscription.stripeSubscription.data && this.subscription.stripeSubscription.data.length > 0) {
      return this.subscription.stripeSubscription.data[0].cancel_at_period_end;
    }

    return false;
  }
  get cancellationDate() {
    if (this.subscription && this.subscription.stripeSubscription && this.subscription.stripeSubscription.data && this.subscription.stripeSubscription.data.length > 0) {
      return new Date(this.subscription.stripeSubscription.data[0].cancel_at * 1000).toLocaleDateString();
    }

    return '';
  }
  get periodEndDate() {
    // This handles the result for when we request subscriptions for a given customer
    if (this.subscription && this.subscription.stripeSubscription && this.subscription.stripeSubscription.data && this.subscription.stripeSubscription.data.length > 0) {
      return new Date(this.subscription.stripeSubscription.data[0].current_period_end * 1000).toLocaleDateString();
    }

    // This handles the result for when we create a new subscription or request a specific subscription
    if (this.subscription && this.subscription.stripeSubscription && this.subscription.stripeSubscription.status === 'active') {
      return new Date(this.subscription.stripeSubscription.current_period_end * 1000).toLocaleDateString();
    }

    return '';
  }

  get upcomingAmount() { return this.nextInvoice ? this.nextInvoice.amount_due / 100 : 'TBD'; }
  get planName() { 
    let plan = this.findPlan(this.currentPrice_id);
    if(plan) return plan.name;
    return '';
  }

  isSubscribed() : boolean {
    return this.surveypointPlan ? true : false;
  }

  openUpgradePlanModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-full', 'lg:!w-full', 'xl:!w-3/4' ];
    config.data = { 
      plan: this.subscription && this.subscription.plan ? this.subscription.plan : null,
      editMode : true
    };

    this.modal.open(PlanSignupModalComponent, config).afterClosed().subscribe(
      (result) => {
        if(result) {
          console.log(result);
          if(result.action) {
            switch(result.action) {
              case 'upgrade':
                console.log('account-settings.component:114136', 'Upgrade plan');
                break;
              case 'cancel':
                console.log('account-settings.component:235702', 'Cancel');
                break;
            }
          }
        }
      }
    );
  }

  openChangePlanModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-full', 'lg:!w-full', 'xl:!w-3/4' ];
    config.data = { 
      surveypointPlan: this.surveypointPlan
    };

    this.modal.open(PlanUpgradeModalComponent, config).afterClosed().subscribe(
      (result) => {
        if(result) {
          console.log(result);
          if(result.action) {
            switch(result.action) {
              case 'upgrade':
                console.log('account-settings.component:114136', 'Upgrade plan');
                this.currentPrice_id = result.plan.stripe_price_id;
                break;
              case 'cancel':
                console.log('account-settings.component:235702', 'Cancel');
                break;
            }
          }
        }
      }
    );
  }

  openPaymentInfoUpdateModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = { 
      plan: this.findPlan(this.currentPrice_id),
      editMode : true
    };

    this.modal.open(UpdatePaymentInfoModalComponent, config).afterClosed().subscribe(
      (result) => {
        console.log(result);
        if(result && result.success && result.card) {
          this.card = result.card;
          this.snackbar.open('Payment information updated', 'Dismiss', { duration: 5000 });
        }
      }
    );
  }

  renderDate(date : any) {
    if(!date) return '---';
    if(date === 'TBD') return 'TBD';

    return dayjs(date * 1000).format('YYYY-MM-DD');
  }

  renderAmountDue(amount : any) {
    if(!amount) return '---';
    if(amount === '---') return amount;
    
    return `$${(amount / 100).toFixed(2)}`;
  }

  cancelCancellation(stripe_price_id : string) {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: `Would you like to keep your SurveyPoint Pro subscription going?`,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result) => {
      if(result) {
        this.api.post(this.api.endpoints.subscriptions + '/uncancel', { stripe_price_id }).subscribe(response => {
          this.subscription = response.payload.subscription;
          this.subscriptionService.stripeSubscription.next(response.payload.stripeSubscription.data);
          this.subscriptionService.subscription.next(this.subscription);
          this.subscriptionService.updateCredits(response.payload.credits);
          this.snackbar.open('Cancellation has been cancelled', 'Dismiss', { duration: 5000 });
        });
      }
    });
  }

  findPlan(stripe_price_id : string) {
    return this.plans.find(plan => plan.stripe_price_id === stripe_price_id);
  }

  isPlanCancelled(price_id : string) {
    if(!price_id) return false;
    return this.subscriptionService.isPlanCancelled(price_id);
  }

  getPlanCancellationDate(price_id : string) {
    if(!price_id) return '';
    return this.subscriptionService.getPlanCancellationDate(price_id);
  }

  isCustomPlan() {
    if(!this.surveypointStripePlan) {
      return false;
    }

    if(this.surveypointStripePlan.plan && this.surveypointStripePlan.plan.metadata && this.surveypointStripePlan.plan.metadata.service) {
      return this.surveypointStripePlan.plan.metadata.service === PLANSERVICE.CUSTOM ? true : false;
    } else {
      return false;
    }
  }

  renderPlanName() {
    if(this.surveypointStripePlan && this.surveypointStripePlan.plan && this.surveypointStripePlan.plan.metadata && this.surveypointStripePlan.plan.metadata.service) {
      switch(this.surveypointStripePlan.plan.metadata.service) {
        case PLANSERVICE.CUSTOM :
          return 'Custom Plan';
        case PLANSERVICE.SURVEY :
          return this.surveypointStripePlan.plan.nickname;
      }
    }

    return '---';
  }
}
