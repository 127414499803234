import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPlus, faMinus, faTrash, faCircleInfo  } from '@fortawesome/free-solid-svg-icons';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { OptionListModel } from 'src/app/models/option-list.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'option-array-input',
  templateUrl: './option-array-input.component.html',
  styleUrl: './option-array-input.component.scss'
})
export class OptionArrayInputComponent {
  @Input() options : OptionListModel[] = [];
  @Input() readOnly : boolean = false;
  @Input() showScore : boolean = false;
  @Output() optionsChange : EventEmitter<OptionListModel[]> = new EventEmitter<OptionListModel[]>();
  @Output() onChange : EventEmitter<OptionListModel[]> = new EventEmitter<OptionListModel[]>();
  @Output() isDirty : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isInvalid : EventEmitter<boolean> = new EventEmitter<boolean>();

  // onChange = (options: any) => {};
  // onTouch: any = () => {};

  tooltip = {
    add: 'Add a new option',
    score : 'Score to award for selecting this option; leave blank for no score',
    reorder : 'Drag here to reorder',
    remove: 'Remove this option',
    info: 'Option information',
    move: 'Move this option',
    option : 'The option a user can select',
    incorrect: 'The message to display when the user selects this option and it is incorrect',
  }

  faCircleInfo = faCircleInfo;
  faPlus = faPlus;
  faMinus = faMinus;
  faTrash = faTrash;
  faGripDotsVertical = faGripDotsVertical;

  frmOptions : FormGroup;
  
  constructor(
    private fb: FormBuilder
  ) {
    this.frmOptions = this.fb.group({
      oc: this.fb.array([])
    });
  }

  get optionControls() { return this.frmOptions.get('oc') as FormArray; }

  ngOnInit() {
    this.frmOptions.valueChanges.subscribe((value : any) => {
      // console.log('option-array-input.component:232303', value, this.options);
      if(value && value.oc) {
        value.oc.forEach((o : OptionListModel) => {
          let index = this.options.findIndex(oo => oo.id == o.id);
          if(index > -1) {
            this.options[index] = o;
          }
        });
      }
      // this.options = value.oc;
      // Find it in the options array and update it
      
      this.optionsChange.emit(this.options);
      this.onChange.emit(this.options);
      this.isDirty.emit(this.frmOptions.dirty);
      this.isInvalid.emit(this.frmOptions.invalid);
    });

    this.prepareOptions();
  }

  prepareOptions() {
    if(this.options.length == 0) {
      this.addOption(0);
    } else {
      // Add the options to the form array
      for(let i = 0; i < this.options.length; i++) {
        this.addOption(i, this.options[i]);
      }
    }

    if(this.readOnly) {
      this.frmOptions.disable();
    } else {
      this.frmOptions.enable();
    }

    // console.log('option-array-input.component:890435', this.options);
  }

  addOption(index : number, option? : OptionListModel) {
    try {
      if(!this.options) {
        this.options = [];
      }

      if(!option) {
        option = new OptionListModel();
        this.options.splice(index, 0, option);
      }
  
      // Add the corresponding form control to the form array
      // console.log('option-array-input.component:740528', this.optionControls, option);
      let frmO = this.fb.group({
        id : [option?.id || '', Validators.required],
        name: [option?.name || '', Validators.required],
        incorrect_message: [option?.incorrect_message || ''],
        score: [option?.score || 0]
      });      

      this.optionControls.insert(index, frmO);
      this.optionControls.updateValueAndValidity();

      // this.onChange(this.options);

    } catch (error) {
      console.error('option-array-input.component:407387', error);
    }
  }

  /**
   * This function removes the item at `index` from the `options` array.
   * It also removes the corresponding form control from the `optionControls` form array.
   * 
   * @param index 
   */
  removeOption(index: number) {
    this.options.splice(index, 1);
    this.optionControls.removeAt(index);
    // this.onChange(this.options);
  }

  /**
   * Updates `name` and `score` properties of the item at `index` in the `options` array.
   * @param index 
   */
  updateOption(index: number) {
    // console.log('option-array-input.component:315985', index, this.optionControls.at(index).value);
    this.options[index] = this.optionControls.at(index).value;
    // this.onChange(this.options);
  }

  optionCount() {
    return this.options.length;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.options, event.previousIndex, event.currentIndex);
    moveItemInArray(this.optionControls.controls, event.previousIndex, event.currentIndex);
  }
}
