import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { from, mergeMap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import * as timezones from '../../json/timezones.json';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);


const StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  public frmSignup : FormGroup = new FormGroup({});
  public submitted : boolean = false;

  public tzs : any[] = [];
  public currentTimezone : string = dayjs.tz.guess();
  private signupRef : string | null = null;

  public promo_code_description : string | null = null;

  constructor(
    private api : ApiService,
    private auth : AuthService,
    private dss : DataSharingService,
    private route : ActivatedRoute,
    private router : Router,
    private snackbar : MatSnackBar,
  ) { 
    this.tzs = Array.from(timezones);

    this.frmSignup = new FormGroup({
      first_name : new FormControl('', [ Validators.required ]),
      last_name : new FormControl('', [ Validators.required ]),
      company_name : new FormControl('', [ Validators.required ]),
      email : new FormControl('', [ Validators.required, Validators.email ]),
      password : new FormControl('', [ Validators.required, Validators.pattern(StrongPasswordRegx) ]),
      confirmTerms : new FormControl('', [ Validators.required, Validators.requiredTrue ])
    });
  }

  get first_name() { return this.frmSignup.get('first_name'); }
  get last_name() { return this.frmSignup.get('last_name'); }
  get company_name() { return this.frmSignup.get('company_name'); }
  get email() { return this.frmSignup.get('email'); }
  get password() { return this.frmSignup.get('password'); }
  get confirmTerms() { return this.frmSignup.get('confirmTerms'); }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params) => {
        // console.log('signup.component:045082', params, 'f_name', params['first_name'], 'l_name', params['last_name'], 'email', params['email']);
        this.signupRef = params['ref'] || null;
        let first_name = params['first_name'] || '';
        let last_name = params['last_name'] || '';
        let email = params['email'] || '';
        let company_name = params['company'] || '';

        if(this.signupRef) {
          this.api.get(this.api.endpoints.promo_codes.replace(':code', this.signupRef)).subscribe({
            next : (rst) => {
              console.log('signup.component:782373', rst);
              this.promo_code_description = rst.payload;
            },
            error : (err) => {
              console.log('signup.component:871709', err);
            }
          })
        }
        if(first_name) this.first_name!.setValue(first_name);
        if(last_name) this.last_name!.setValue(last_name);
        if(email) this.email!.setValue(email);
        if(company_name) this.company_name!.setValue(company_name);
      }
    );
  }

  submit() {
    this.submitted = true;
    // console.log('signup.component:034', this.frmSignup.value, '\nCan Submit? ', this.frmSignup.valid);

    // Validation of required fields is done by the form group
    if(this.frmSignup.valid) {
      this.createAccount(this.email!.value, this.password!.value, this.first_name!.value, this.last_name!.value, this.company_name!.value);
    }
  }

  createAccount(email : string, password : string, firstName : string, lastName : string, companyName : string = '') {
    from(this.auth.createAccount(email, password, firstName, lastName)).pipe(
      mergeMap((user) => {
        // console.log('signup.component:372194', user);
        return this.api.post(this.api.endpoints.signup, { user, firstName, lastName, companyName, timezone : this.currentTimezone, ref : this.signupRef });
      }),
    ).subscribe({
      next : (rst) => {
        console.log('signup.component:843936', rst);
        this.auth.saveAuth(rst.payload);
        this.dss.firstSignin.next(true);
        this.router.navigate(['/dashboard']);
        this.snackbar.open('Account created successfully', 'Close', {
          duration: 3000
        })
      },
      error : (err) => {
        console.log('signup.component:958787', Object.keys(err), 'code', err.code, 'cd', err.customData, 'name', err.name);
        let msg = 'An error occurred while creating your account. Please try again later';

        if(err.code == 'auth/email-already-in-use') {
          msg = 'An account with this email address already exists. Please try again with a different email address';
        }

        this.snackbar.open(msg, 'Close', {
          duration: 3000
        })
      }
    })
  }

  // handleSignup() {
  //   this.frmSignup.markAllAsTouched();

  //   if(this.frmSignup.valid) {
  //     console.log('signup.component:109942', this.email.value, this.password.value);
  //     let firstName = this.firstName.value;
  //     let lastName = this.lastName.value;
      
  //     this.createAccount(this.email.value, this.password.value, firstName, lastName);
  //   } else {
  //     this.snackbar.open('Please fill out all required fields', 'Close', { duration: 3000 });
  //   }
  // }

}
