<section class="content-panel-header flex items-center">
  <div class="w-full text-right">
    <div class="items-center">
      <span [matMenuTriggerFor]="userMenu">
        <span class="font-bold mr-3 cursor-pointer">{{ getGreeting() }}</span><fa-icon class="cursor-pointer" [icon]="faUser" [matMenuTriggerFor]="userMenu"></fa-icon>
      </span>
    </div>
  </div>
  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="signout()"><fa-icon [icon]="faSignOut" class="mr-3"></fa-icon><span>Sign Out</span></button>
  </mat-menu>
</section>