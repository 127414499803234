import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
const StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

@Component({
  selector: 'reset-password-step-two',
  templateUrl: './reset-password-step-two.component.html',
  styleUrl: './reset-password-step-two.component.scss'
})
export class ResetPasswordStepTwoComponent {
  @Input() oobCode : string = '';
  
  frmPasswordReset : FormGroup = new FormGroup({});

  public showPassword : boolean = false;
  passwordField : string = 'password';
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  eyecon = faEye;

  constructor(
    private auth : AuthService,
    private snackbar : MatSnackBar,
    private router : Router
  ) { 
    this.frmPasswordReset = new FormGroup({
      password : new FormControl('', [ Validators.required, Validators.pattern(StrongPasswordRegx) ]),
      confirmPassword : new FormControl('', [ Validators.required, Validators.pattern(StrongPasswordRegx) ])
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    console.log('reset-password-step-two.component:707233', this.oobCode);
  }

  ngOnDestroy(): void {
  }

  resetPassword() {
    try {
      console.log('reset-password-step-two.component:707233', 'Resetting password');
      if(this.frmPasswordReset.valid) {
        this.auth.confirmPasswordReset(this.oobCode, this.frmPasswordReset.get('password')!.value).then(
          () => {
            this.snackbar.open('Password reset successfully', 'Close', { duration: 5000 });
            this.router.navigate(['/signin']);
          }
        ).catch(
          (err) => {
            this.snackbar.open('An error occurred while resetting your password', 'Close', { duration: 5000 });
            console.error('reset-password-step-two.component:707233', err);
          }
        );      
      }
    } catch(err) {
      this.snackbar.open('An error occurred while resetting your password', 'Close', { duration: 5000 });
      console.error('reset-password-step-two.component:707233', err);
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;

    if(this.showPassword) {
      this.eyecon = this.faEyeSlash;
      this.passwordField = 'text';
    } else {
      this.eyecon = this.faEye;
      this.passwordField = 'password';
    }
  }
}
