<div class="slider-box">
  <div class="pr-10">How many calls do you need us to make per month?</div>

  <mat-slider
    class="slider"
    [max]="planSlider.max"
    [min]="planSlider.min"
    [step]="planSlider.step"
    [discrete]="planSlider.thumbLabel"
    showTickMarks="false">
    <input matSliderThumb [(ngModel)]="estimatedTouchpoints">
  </mat-slider>
  <h3 class="pl-10 font-bold w-36">
    {{ estimatedTouchpoints }} {{ estimatedTouchpoints >= planSlider.max ? '+' : '' }}
  </h3>
</div>

<div class="w-1/3 mt-5">
  <mat-label class="font-bold">Currency</mat-label>
  <mat-form-field appearance=outline class="w-full">
    <mat-select [(ngModel)]="selectedCurrency">
      <mat-option *ngFor="let currency of currencies" [value]="currency.value">{{ currency.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="plansLoaded()" class="plans select-none">
  <!-- Plan -->
  <div *ngFor="let plan of getFilteredPlans()" class="plan-box w-1/3" [ngClass]="isSelected(plan.id)?'box-selected':''" (click)="selectPlan(plan)">
    <div class="w-full flex mb-5">
      <div class="plan-name">{{ plan.name }}</div>
    </div>
    <div class="my-5 plan-price">{{ plan.config.price }}<span class="plan-period">/{{ plan.config.period }}</span></div>
    <p class="plan-feature"><fa-icon [icon]="faCheck" class="mr-3"></fa-icon> {{ plan.config.includedTouchpoints }} touchpoints</p>
    <p class="plan-feature"><fa-icon [icon]="faCheck" class="mr-3"></fa-icon> ${{ plan.config.overagePrice }} additional touchpoints</p>
    <div class="recommended" *ngIf="determineRecommendedPlan() == plan.id">Recommended</div>
  </div>
</div>


<h3 *ngIf="selectedPlan" class="font-bold text-center my-10">
  Based on your selected plan, your estimated monthly cost is ${{ calculatePrice(estimatedTouchpoints, selectedPlan) | number: '1.2-2'}} {{ selectedPlan.config.currency | uppercase }}
</h3>