import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() confirmButtonText: string | null = null;
  @Input() cancelButtonText: string | null = null;
  @Input() message: string | null = null;
  @Input() title: string | null = null;

  public _confirmButtonText: string;
  public _cancelButtonText: string;
  public _message: string;
  public _title: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmModalComponent
  ) {
    if(data) {
      this._confirmButtonText = data.confirmButtonText ? data.confirmButtonText : "Yes";
      this._cancelButtonText = data.cancelButtonText ? data.cancelButtonText : "No";
      this._message = data.message ? data.message : "Are you sure you want to do this?";
      this._title = data.title ? data.title : "Please confirm";
    } else {
      this._confirmButtonText = "Yes";
      this._cancelButtonText = "No";
      this._message = "Are you sure you want to do this?";
      this._title = "Please confirm";
    }
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
