import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faUser, faSearch, faPlus, faUpload, faSignOut, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ManageTouchpointModalComponent } from 'src/app/modals/manage-touchpoint-modal/manage-touchpoint-modal.component';
import { PlanSignupModalComponent } from 'src/app/modals/plan-signup-modal/plan-signup-modal.component';
import { UploadTouchpointModalComponent } from 'src/app/modals/upload-touchpoint-modal/upload-touchpoint-modal.component';
import { TouchpointModel } from 'src/app/models/touchpoint.model';
import { ApiResponseModel, ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { TouchpointService } from 'src/app/services/touchpoint.service';

@Component({
  selector: 'app-touchpoints',
  templateUrl: './touchpoint.component.html',
  styleUrls: ['./touchpoint.component.scss']
})
export class TouchpointComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  faCheck = faCheck;
  faExclamationTriangle = faExclamationTriangle;
  faPlus = faPlus;
  faSearch = faSearch;
  faSignOut = faSignOut;
  faUpload = faUpload;
  faUser = faUser;
  
  public periodFilterOptions = [
    { value: 30, name: 'Last 30 days' },
    { value: 60, name: 'Last 60 days' },
    { value: 90, name: 'Last 90 days' }
  ];
  public selectedPeriod = this.periodFilterOptions[0].value;

  public displayedColumns: string[] = [
    'contact_name', 
    'contact_company_name', 
    'contact_phone_number', 
    'time_slot', 
    'status', 
  ];
  public dataSource : MatTableDataSource<TouchpointModel> = new MatTableDataSource<TouchpointModel>();
  public touchpoints : TouchpointModel[] = [];
  private touchpoints$ : any;
  public credits : number = 0;
  private credits$ : any;
  public isLoading : boolean = true;

  ngOnInit(): void {
    this.loadTouchpoints()
    this.touchpoints$ = this.touchpointService.touchpoints.subscribe((touchpoints) => {
      this.isLoading = false;
      this.touchpoints = touchpoints;
      this.dataSource = new MatTableDataSource<TouchpointModel>(this.touchpoints);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private api : ApiService,
    private dss : DataSharingService,
    private touchpointService : TouchpointService,
    private modal : MatDialog,
    private subscriptionService : SubscriptionService
  ) { 

    this.credits$ = this.subscriptionService.credits.subscribe({
      next : (credits) => {
        this.credits = credits;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  ngOnDestroy() {
    // this.touchpoints$.unsubscribe();
    // this.credits$.unsubscribe();
  }

  loadTouchpoints() {
    this.isLoading = true;
    this.touchpointService.getTouchpoints();
  }

  openAddEditTouchpointModal(touchpoint? : TouchpointModel) {
    let isNew = false;
    let isReadOnly = false;

    if(!touchpoint) {
      touchpoint = new TouchpointModel();
      isNew = true;
    } else {
      isNew = false;
      let status = touchpoint.status.split('-')[0];

      switch(status) {
        case 'Ready':
        case 'Paused':
        case 'Incomplete':
        case 'Error':
          isReadOnly = false;
          break;
        case 'On Hold':
        default:
          isReadOnly = true;
          break;
      }
    }

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = { 
      touchpoint, isNew, isReadOnly
    };

    // Open modal
    this.modal.open(ManageTouchpointModalComponent, config).afterClosed().subscribe(
      (result) => {
        if(result) {
          if(result.action) {
            this.isLoading = true;
            console.log('touchpoints.component:803519', result.action);
            switch(result.action) {
              case 'add':
                this.touchpointService.add(result.touchpoint).subscribe({
                  next : (response : ApiResponseModel) => {
                    console.log(response);
                  },
                  error : (error) => {
                    console.log(error);
                  },
                  complete : () => {
                    this.isLoading = false;
                  }
                });
                break;
              case 'edit':
                this.touchpointService.update(result.touchpoint).subscribe({
                  next : (response : ApiResponseModel) => {
                    console.log(response);
                  },
                  error : (error) => {
                    console.log(error);
                  },
                  complete : () => {
                    this.isLoading = false;
                  }
                });
                break;
              case 'delete':
                this.touchpointService.delete(result.touchpoint).subscribe({
                  next : (response : ApiResponseModel) => {
                    console.log(response);
                  },
                  error : (error) => {
                    console.log(error);
                  },
                  complete : () => {
                    this.isLoading = false;
                  }
                });
                break;
            }
          }
        }
      }
    );
  }

  openUploadTouchpointModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];

    // open modal
    this.modal.open(UploadTouchpointModalComponent, config).afterClosed().subscribe(
      (result) => {
        if(result) {
          console.log(result);
          this.loadTouchpoints();
        } else {
          console.log('Modal closed');
        }
      }
    );
  }

  updateFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue === '' || filterValue === null || filterValue === undefined) {
      this.dataSource.filter = '';
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  openUpgradePlanModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = { 
    };

    this.modal.open(PlanSignupModalComponent, config).afterClosed().subscribe(
      (result) => {
        if(result) {
          console.log(result);
          if(result.action) {
            switch(result.action) {
              case 'upgrade':
                console.log('touchpoints.component:114136', 'Upgrade plan');
                break;
              case 'cancel':
                console.log('touchpoints.component:235702', 'Cancel');
                break;
            }
          }
        }
      }
    );
  }

  renderTimeSlot(touchpoint : TouchpointModel) : string {
    let start = touchpoint.time_slot_start;
    let end = touchpoint.time_slot_end;

    if(start && end) {
      return `${start} - ${end}`;
    } else {
      return '';
    }
  }

  handlePeriodChange(event : any) {
    this.selectedPeriod = event.value;
    this.loadTouchpoints();
  }

  isFiltered() : boolean {
    return this.dataSource.filter !== '';
  }
}
