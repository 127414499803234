<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <a class="cursor-pointer" (click)="navToQuestionLibrary()"><h1><fa-icon [icon]="faArrowLeft" class="mr-3"></fa-icon>  Question Editor</h1></a>
  </div>
</section>

<!-- Page Content -->
<section>
  <div class="w-full text-right mb-5">
    <button class="ml-5 form-button bg-white text-red-400" (click)="deleteQuestionSet()"><fa-icon [icon]="faTrash"></fa-icon></button>
    <button color="primary" [disabled]="!enableSaveButton()" class="ml-5 form-button" (click)="saveQuestionSet()"><fa-icon [icon]="faFloppyDisk"></fa-icon></button>
  </div>

  <form [formGroup]="frmQuestionSetEditor">
    <div class="w-full">
      <mat-label class="font-bold">Category</mat-label>
      <!-- <mat-form-field appearance=outline class="w-full">
        <mat-select formControlName="category">
          @for (category of questionCategories; track category) {
            <mat-option [value]="category.id">{{category.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field> -->
    </div>
    <div class="flex">
      <div class="w-5/6 pr-3">
        <mat-label class="font-bold">Question Set Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>
      </div>
      <div class="w-1/6 pl-3">
        <mat-label class="font-bold" matTooltip="Use this to identify the question set you'd like to use in Touchpoint integrations or imports">Question ID</mat-label>
        <div class="my-5 cursor-pointer" (click)="copyQuestionShortID()">
          <span>{{ returnShortID() }}</span>
          <fa-icon [icon]="faCopy" class="ml-3 float-right text-gray-600"></fa-icon>          
        </div>
      </div>
    </div>
      <!-- <h3 class="uppercase font-bold">Question Set</h3> -->
  
    <!-- Question Block -->
    <ng-container formArrayName="questions">
      <div *ngFor="let question of questions.controls; let i = index">
        <!-- Question Block -->
          <div class="question-block mb-5" [formGroupName]="i">
            <div class="question-block-header">
              <div class="w-full">
                <h5 class="uppercase font-bold">Question {{i+1}}</h5>
              </div>
              <div class="w-full text-right">
                <button mat-flat-button class="ml-5" (click)="moveUp(i)" [disabled]="i==0"><fa-icon [icon]="faArrowUp"></fa-icon></button>
                <button mat-flat-button class="ml-5" (click)="deleteQuestion(i)" [disabled]="questions.controls.length==1"><fa-icon [icon]="faTrash"></fa-icon></button>
                <button mat-flat-button class="ml-5" (click)="moveDown(i)"  [disabled]="questions.controls.length==1"><fa-icon [icon]="faArrowDown"></fa-icon></button>
              </div>
            </div>
            <!-- Question Definition -->
            <mat-label>What's your question?</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <input matInput placeholder="Name" formControlName="question" (keyup)="updateField(i, 'question', $event)">
            </mat-form-field>
            <mat-label>Type of question</mat-label>
            <mat-form-field appearance=outline class="w-full">
              <mat-select formControlName="type" (selectionChange)="updateField(i, 'type', $event)">
                @for (type of questionTypes; track type) {
                  <mat-option [value]="type.id">{{ type.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="showOptions(i)">
              <mat-label>What options can they choose from? (Separate options with a semicolon)</mat-label>
              <mat-form-field appearance=outline class="w-full">
                <input matInput placeholder="Name" formControlName="options"  (keyup)="updateField(i, 'options', $event)">
              </mat-form-field>
            </ng-container>
            <div class="w-full">
              <mat-checkbox class="" formControlName="allowElaborate" (change)="updateField(i, 'allowElaborate', $event)">Allow contact to elaborate?</mat-checkbox>
            </div>
          </div>
        </div>
    </ng-container>
</form>
</section>
<button mat-flat-button class="mt-5 w-full" [disabled]="returnNumberofQuestions() >= 5" (click)="handleAddQuestion()"><fa-icon [icon]="faPlus" class="mr-3"></fa-icon>Add Question</button>