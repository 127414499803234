import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactsModel } from 'src/app/models/contacts.model';
import { ContactsService } from 'src/app/services/contacts.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/services/api.service';

@Component({
  selector: 'app-manage-contact-modal',
  templateUrl: './manage-contact-modal.component.html',
  styleUrl: './manage-contact-modal.component.scss'
})
export class ManageContactModalComponent {
  public isNew : boolean = true;

  public contact : ContactsModel = new ContactsModel();
  public frmManageContact : FormGroup;

  constructor(
    private contactsService : ContactsService,
    private modal : MatDialog,
    private snackbar : MatSnackBar,
    private dialogRef : MatDialogRef<ManageContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) { 
    this.frmManageContact = new FormGroup({
      first_name : new FormControl('', [Validators.required]),
      last_name : new FormControl('', [Validators.required]),
      email : new FormControl('', [Validators.required, Validators.email]),
      phone : new FormControl(''),
      company_name : new FormControl(''),
    });

    console.log('manage-contact-modal.component:301304', data);
    if(data && data.contact) {
      this.isNew = data.is_new;
      this.contact = data.contact;
      
      this.frmManageContact.patchValue({
        first_name : this.contact.first_name,
        last_name : this.contact.last_name,
        email : this.contact.email,
        phone : this.contact.phone,
        company_name : this.contact.company_name
      });
    }
  }

  ngOnInit() {
  }

  delete() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: "Are you sure you'd like to delete this cohort member? Any responses they've completed will also be deleted.",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.contactsService.deleteContact(this.contact.cohort_id, this.contact.id).subscribe({
          next : (response) => {
            this.dialogRef.close({
              action : 'delete',
              contact : this.contact
            });
          },
          error : (error) => {
            console.error('manage-contact-modal.component:306084', error);
            this.snackbar.open("Couldn't delete the cohort member", 'Close', { duration: 3000 });
          }
        });
      }
    });
  }

  close() {
    this.dialogRef.close({
      action : 'close',
      contact : this.contact
    });
  }
  
  canSave() {
    return this.frmManageContact.valid;
  }

  handleSave() {
    this.contact.cohort_id = this.contact.cohort_id || '';
    this.contact.first_name = this.frmManageContact.get('first_name')?.value;
    this.contact.last_name = this.frmManageContact.get('last_name')?.value;
    this.contact.email = this.frmManageContact.get('email')?.value;
    this.contact.phone = this.frmManageContact.get('phone')?.value;
    this.contact.company_name = this.frmManageContact.get('company_name')?.value;

    // console.log('manage-contact-modal.component:661886', this.frmManageContact.value);

    this.isNew ? this.save() : this.update();
  }

  save() {
    // console.log('manage-contact-modal.component:670356', this.contact);
    this.contactsService.addContact(this.contact).subscribe({
      next : (response : ApiResponseModel) => {
        console.log('manage-contact-modal.component:314725', response.payload);
        if(response.payload && response.payload[1] == false) {
          this.snackbar.open('Someone with this email address is already in the cohort', 'Close', { duration: 3000 });
          return;
        }
        
        this.dialogRef.close({
          action : 'save',
          contact : response.payload[0]
        });
      },
      error : (error) => {
        console.error('manage-contact-modal.component:306084', error);
        this.snackbar.open("Couldn't add a cohort member", 'Close', { duration: 3000 });
      }
    });
  }

  update() {
    console.log('manage-contact-modal.component:487092', this.contact);
    this.contactsService.updateContact(this.contact).subscribe({
      next : (response) => {
        this.dialogRef.close({
          action : 'update',
          contact : response.payload
        });
      },
      error : (error) => {
        console.error('manage-contact-modal.component:306084', error);
        this.snackbar.open("Couldn't update the cohort member", 'Close', { duration: 3000 });
      }
    });
  }

}
