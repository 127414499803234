<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full">
    <h1>Question Bank</h1>
  </div>
</section>

<!-- Page Content -->
<section>
  <!-- Filter Section -->
  <section class="mb-10">
    <div class="w-full text-right">
      <button class="ml-5 form-button" [matTooltip]="'Add Question Set'" (click)="openAddQuestionLibraryModal()">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
      <!-- <button mat-flat-button class="ml-5" (click)="openUploadTouchpointModal()"><fa-icon [icon]="faUpload"></fa-icon></button> -->
    </div> 
  </section>

  <!-- Table Section -->
  <table mat-table [dataSource]="dataSource" class="">
    <!-- Category Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{ lookupCategory(element.category) }}</td>
    </ng-container>

    <!-- Short ID Column -->
    <ng-container matColumnDef="short_id">
      <th mat-header-cell *matHeaderCellDef matTooltip="Use this to identify the question set you'd like to use in Touchpoint integrations or imports" matTooltip="Use this for your file uploads and integrations">Question ID</th>
      <td mat-cell *matCellDef="let element" (click)="copyQuestionShortID(element, $event)">
        <fa-icon [icon]="faCopy" class="mr-3 cursor-pointer text-gray-600"></fa-icon>
        <span>{{ element.short_id }}</span>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>

    <!-- Questions Column -->
    <ng-container matColumnDef="questions">
      <th mat-header-cell *matHeaderCellDef>Questions</th>
      <td mat-cell *matCellDef="let element">
        <!-- If questions are not defined... -->
        <ng-container *ngIf="!hasQuestions(element)">No questions defined</ng-container>
        <!-- If questions are defined... -->
        <ng-container *ngIf="hasQuestions(element)">
          <div *ngFor="let question of element.questions; let i = index">
            <span *ngIf="isQuestionDefined(question)">{{ i+1 }}. {{ question.question }}</span>
            <span *ngIf="!isQuestionDefined(question)">Not defined</span>
            <br>
          </div>
        </ng-container>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editQuestion(row)" class="portlet-table-row select-none cursor-pointer"></tr>
    <tr class="mat-row" *matNoDataRow>
      <ng-container *ngIf="isLoading">
        <td [attr.colspan]="displayedColumns.length" class="py-5">
          <mat-progress-spinner color="primary" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
        </td>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="text-center my-10">
            <h4 class="text-center mt-10 mb-5">No question sets found. Add one by pressing the button below</h4>
            <div class="text-center mb-10">
              <button class="ml-5 bg-primary mr-3" (click)="openAddQuestionLibraryModal()">
                <fa-icon [icon]="faPlus"></fa-icon> Add Question Set
              </button>
            </div>
          </div>
        </td>
      </ng-container>
    </tr>
  </table>

  <!-- Pagination Section -->
  <section class="flex items-center justify-between pt-10">
    <div class="w-full">
      <mat-paginator [length]="ql.length" [pageSize]="selectedPageSize" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </section>
</section>