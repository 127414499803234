import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DataSharingService } from '../services/data-sharing.service';
import { AuthTokenModel } from '../models/auth.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing : boolean = false;
  private tokens : AuthTokenModel | null = null;

  constructor(
    private api : ApiService,
    private auth : AuthService,
    private router : Router
  ) {
    this.auth.tokens.subscribe({
      next : (tokens) => {
        this.tokens = tokens;
      }
    });
  }

  /**
   * This method intercepts the HTTP request and adds the bearer token to the request headers
   * @param request 
   * @param next 
   * @returns 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.tokens && this.tokens.bearer_token) {
      request = request.clone({
        setHeaders: { 
          Authorization: `Bearer ${this.tokens.bearer_token}`
        }
      });
    };
    
    return next.handle(request).pipe(
      catchError((err) => {
        if(err instanceof HttpErrorResponse && !request.url.includes('signin') &&  err.status === 401) {
          // console.log('auth.interceptor:744771', err);
          return this.handle401Error(request, next);
        }

        return throwError(() => err);
      })
    ) as Observable<HttpEvent<any>>;
  }

  /**
   * This method handles the 401 error and refreshes the token and resends the request. 
   * This is only done when the token is expired and the request is not a signin request
   * @param request 
   * @param next 
   * @returns 
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // If the token is already being refreshed, then we need to wait for the token to be refreshed
    if(!this.isRefreshing) {
      this.isRefreshing = true;
      let token = this.tokens?.bearer_token;
      let refresh_token = this.tokens?.refresh_token;

      // console.log('auth.interceptor:961178 -- sending refresh info', this.tokens, refresh_token);

      // Clear the tokens so that the next request is not sent with the expired token
      this.auth.tokens.next(null);
      return this.api.post(this.api.endpoints.refresh, { token, refresh_token }).pipe(
        switchMap((rst: any) => {
          this.isRefreshing = false;
          this.auth.saveAuth(rst.payload);

          // Prepare the request to be resent with the new token
          request = request.clone({
            setHeaders: { 
              Authorization: `Bearer ${rst.payload.tokens.bearer_token}`
            }
          });

          return next.handle(request);
        }),
        catchError((err) => {
          this.isRefreshing = false;

          this.auth.signout();
          this.router.navigate(['/signin']);
          return throwError(() => new Error('Token refresh failed'));
        })
      )
    } else {
      // If the token is already being refreshed, then we need to wait for the token to be refreshed
      return this.auth.tokens.pipe(
        filter(tokens => tokens !== null),
        take(1),
        switchMap((token) => {
          // Prepare the request to be resent with the new token
          request = request.clone({
            setHeaders: { 
              Authorization: `Bearer ${token?.bearer_token}`
            }
          })

          // Resend the request
          // console.log('auth.interceptor:353446 - Resending request \n', request);
          return next.handle(request);
        })
      );
    }
  }
}