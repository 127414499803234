import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-subscription-confirm-modal',
  templateUrl: './cancel-subscription-confirm-modal.component.html',
  styleUrl: './cancel-subscription-confirm-modal.component.scss'
})
export class CancelSubscriptionConfirmModalComponent {
  public frmCancel : FormGroup = new FormGroup({});
  public confirmClickTarget : number = 3;
  public remainingClicks : number = this.confirmClickTarget;
  private clickUnlock : boolean = false;

  public feedbackOptions : {value: string, label: string}[] = [
    { value : 'customer_service', label : 'I needed support' },
    { value : 'missing_features', label : "It's missing a feature I need" },
    { value : 'too_expensive', label : "I can't afford it right now" },
    { value : 'unused', label : "I don't use it often enough" },
    { value : 'other', label : 'Something else... Please let us know!' },
  ];

  constructor(
    private dialogRef : MatDialogRef<CancelSubscriptionConfirmModalComponent>,
  ) { 
    this.frmCancel = new FormGroup({
      comment : new FormControl(''),
      feedback : new FormControl('', [Validators.required]),
      close_account : new FormControl(false)
    });
  }

  get close_account() { return this.frmCancel.get('close_account'); }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  handleAction(): void {
    if(this.closeAccountSelected()) {
      return this.closeAccount();
    } else {
      return this.cancelSubscription();
    }
  }

  cancelSubscription() {
    this.dialogRef.close({
      feedback : this.frmCancel.value.feedback,
      comment : this.frmCancel.value.comment
    });

  }

  closeAccount() {
    if(this.remainingClicks >= 1) {
      this.remainingClicks--;
      return;
    } else {
      console.log('delete account');
      this.remainingClicks = -999;
      return;
    }
  }

  closeModal(): void {
    // This method will close the modal
    this.dialogRef.close();
  }

  closeAccountSelected() {
    let rst = this.close_account && this.close_account.value;
    return rst;
  }

  returnActionButtonLabel() {
    if(this.closeAccountSelected()) {
      switch(this.remainingClicks) {
        case -999:
          return 'Closing your account...';
        case 0:
          return 'Close Account (Actually)';
        case 1:
          return 'Click 1 more time to close account';
        case this.confirmClickTarget:
          return 'Close Account';
        default:
          return `Click ${this.remainingClicks} more times to close account`;
      }
    } else {
      return 'Cancel Subscription';
    }
  }
}
