<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">Welcome to SurveyPoint!</h3>
  <div class="my-5 pr-5">
    <div class="mb-3">
      We're excited to have you on board! To help you get going more quickly, We've set up a tour for you. 
      Follow the pulsing red dot to create your first survey.  It's easy, and we'll be with you every step of the way
    </div>
    <div class="mb-3">
      Feel free to explore the rest of the app while you're here. You'll be able to pick up where you left off by following the red dot.
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="mx-3 mb-3">
    <button class="btn bg-primary" type="button" (click)="close()">Let's get started!</button>
  </div>
</mat-dialog-actions>