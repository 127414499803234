<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">Upload Touchpoints</div>
  <div class="my-10">
    <input type="file" class="hidden" id="file" 
    (change)="onFileChange($event)" 
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
    #fileInput>
    <div class="upload-box" DragDrop (click)="fileInput.click()" (onDrop)="handleDrop($event)">
      <div class="flex items-center">
        <div class="mx-auto">
          <span class="ml-3">Drag and drop your file here</span>
          <div *ngIf="file" class="mt-3 text-center">
            <span class="text-primary font-bold">Ready to upload {{file.name}}</span>
            </div>            
        </div>
      </div>
      <div class="text-center mt-5">
        <button class="btn-secondary-outline" type="button">Choose {{ file ? 'another ' : '' }} File</button>
        <button *ngIf="file" class="ml-3 bg-primary" type="button" (click)="upload($event)">Upload File</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-right w-full text-right">
    <button class="btn-secondary-outline mr-3" type="button" (click)="handleTemplateDownload()">Download Template</button>
    <button class="btn-secondary-outline mr-3" type="button" (click)="close()">Close</button>
    <!-- <button class="bg-primary mr-3" data-dismiss="modal" type="button" (click)="upload()">Upload Touchpoints</button> -->
  </div>
</mat-dialog-actions>