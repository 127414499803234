import { Component, Inject, signal, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlansModel } from 'src/app/models/plans.model';
import { CancelSubscriptionConfirmModalComponent } from '../cancel-subscription-confirm-modal/cancel-subscription-confirm-modal.component';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { injectStripe, StripeCardComponent } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { LoadingService } from 'src/app/services/loading.service';
const SERVICES = { SURVEYPOINT : 's', TOUCHPOINT : 't' };
const STEPS = { SELECT_PLAN : 1, PAYMENT : 2, CONFIRM : 3 };

@Component({
  selector: 'app-plan-upgrade-modal',
  templateUrl: './plan-upgrade-modal.component.html',
  styleUrl: './plan-upgrade-modal.component.scss'
})
export class PlanUpgradeModalComponent {
  @ViewChild(StripeCardComponent) cardElement!: StripeCardComponent;
  
  stripe = injectStripe(environment.stripe.publishableKey);
  paying = signal(false);

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  countries = [
    { id : 'ca', name : 'Canada' },
    { id : 'us', name : 'United States' },
  ];

  private elementsValid : boolean = false;
  public paymentInProgress : boolean = false;
  private paymentMethod : any = null;
  public frmStripe : FormGroup = new FormGroup({});

  public step = STEPS.SELECT_PLAN;
  public finalStep = STEPS.CONFIRM;

  private plans$ : any = null;
  private plans : PlansModel[] = [];
  public surveypointPlan : PlansModel | null = null;
  public surveypointPlans : PlansModel[] = [];
  public selectedSurveypointPlan : PlansModel | null = null;
  public paymentRequired : boolean = true;

  public touchpointPlan : PlansModel | null = null;
  public touchpointPlans : PlansModel[] = [];
  public selectedTouchpointPlan : PlansModel | null = null;

  public title : string = 'Manage Plan';
  public feature : string = '';

  constructor(
    private dialogRef : MatDialogRef<PlanUpgradeModalComponent>,
    private loading : LoadingService,
    private subscriptionService : SubscriptionService,
    private snackbar : MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.frmStripe = new FormGroup({
      'cardholder' : new FormControl('', [Validators.required]),
      'addrl1' : new FormControl('', [Validators.required]),
      'city' : new FormControl('', [Validators.required]),
      'state' : new FormControl('', [Validators.required]),
      'postal' : new FormControl('', [Validators.required]),
      'country' : new FormControl('', [Validators.required])
    });

    if(data) {
      this.surveypointPlan = data.surveypointPlan;
      this.selectedSurveypointPlan = this.surveypointPlan;
      this.touchpointPlan = data.touchpointPlan;
      this.selectedTouchpointPlan = this.touchpointPlan;
      this.title = data.title || 'Manage Plan';
      this.feature = data.feature || '';
    }
  }

  ngOnInit() {
    this.loadPlans();
  }

  ngOnDestroy() {
    // if(this.plans$) this.plans$.unsubscribe();
  }

  /**
   * Returns true if the selected plan is different from the current plan
   * 
   * @param service `s` for SurveyPoint, `t` for TouchPoint, blank for any
   * @returns {boolean} True if the selected plan is different from the current plan
   */
  planChanged(service? : string) : boolean {
    let rst = false;
    switch(service) {
      case SERVICES.SURVEYPOINT :
        rst = this.selectedSurveypointPlan !== this.surveypointPlan;
        break;
      case SERVICES.TOUCHPOINT :
        rst = this.selectedTouchpointPlan !== this.touchpointPlan;
        // console.log('plan-upgrade-modal.component:089189', rst, this.selectedTouchpointPlan, this.touchpointPlan, service);
        break;
      default: 
        rst = this.selectedSurveypointPlan !== this.surveypointPlan || this.selectedTouchpointPlan !== this.touchpointPlan;
    }
    return rst;
  }

  loadPlans() {
    this.plans$ = this.subscriptionService.plans.subscribe({
      next : (plans) => {
        this.plans = plans;
        this.surveypointPlans = this.plans.filter((plan) => plan.service === SERVICES.SURVEYPOINT);
        this.touchpointPlans = this.plans.filter((plan) => plan.service === SERVICES.TOUCHPOINT);
        // console.log('account-settings.component:458194', plans);
      }
    });
  }

  cancelPlan() {
  }

  close() {
    console.log('Cancel');
    this.dialogRef.close({
      action : 'close',
      response : null
    });
  }

  getTouchPointPlanName(plan? : PlansModel) : string {
    if(!plan) return 'No TouchPoints ($0.00/mo)';
    return plan.name || '';
  }

  getSurveypointPlanName(plan? : PlansModel) : string {
    if(!plan) return 'SurveyPoint Free ($0.00/mo)';
    return plan.name || '';
  }

  getPlanTag(plan : PlansModel) : string {
    if(!plan) return '';
    if(!plan.config) return '';  
    return plan.config.tag || '';
  }

  getPlanDescription(plan : PlansModel) : string {
    if(!plan) return '';
    if(!plan.config) return '';  
    return plan.config.description || '';
  }

  isSelectedSurveypointPlan(plan? : PlansModel) : boolean {
    if(!plan) return false;
    if(!this.selectedSurveypointPlan) return false;
    return plan.id === this.selectedSurveypointPlan.id;
  }

  isSelectedTouchpointPlan(plan? : PlansModel) : boolean {
    if(!plan) return false;
    if(!this.selectedTouchpointPlan) return false;
    return plan.id === this.selectedTouchpointPlan.id;
  }

  selectTouchpointPlan(plan? : PlansModel) {
    this.selectedTouchpointPlan = plan || null;
    // console.log('plan-upgrade-modal.component:607586', plan, this.selectedTouchpointPlan);
  }

  selectSurveypointPlan(plan? : PlansModel) {
    this.selectedSurveypointPlan = plan || null;
    // console.log('plan-upgrade-modal.component:621558', plan, this.selectedSurveypointPlan);
  }

  canAdvance() : boolean {
    switch(this.step) {
      case 1:
        return this.planChanged();
      case 2:
        return this.elementsValid && this.frmStripe.valid;
      case 3:
        return true;
    }
    return false;
  } 

  next() {
    try {
      switch(this.step) {
        case 1:
          this.step = this.getNextStep();
          break;
        case 2:
          this.paymentInProgress = true;
          this.loading.start('Please wait a moment while we validate your payment information');
          if(this.elementsValid && this.frmStripe.valid) {
            this.stripe.createPaymentMethod({
              type: 'card',
              card: this.cardElement.element,
              billing_details: {
                name: this.frmStripe.get('cardholder')?.value,
                address: {
                  line1: this.frmStripe.get('addrl1')?.value,
                  city: this.frmStripe.get('city')?.value,
                  state: this.frmStripe.get('state')?.value,
                  postal_code: this.frmStripe.get('postal')?.value,
                  country: this.frmStripe.get('country')?.value
                }
              }
            }).subscribe({
              next : (result) => {
                // console.log('plan-upgrade-modal.component:147095', result);
                if(result.error) {
                  // console.log('plan-upgrade-modal.component:881825', result.error);
                  this.snackbar.open('An error occurred while validating your payment information', 'Dismiss', { duration: 3000 });
                } else {
                  this.paymentMethod = result.paymentMethod;
                  this.step = this.getNextStep();
                }

                this.loading.stop();
              },
              error : (error) => {
                console.log('plan-upgrade-modal.component:443685', error);
                this.loading.stop();
                this.snackbar.open('An error occurred while validating your payment information', 'Dismiss', { duration: 3000 });
              },
              complete : () => {
                this.loading.stop();
              }
            });
          }
          break;
        case 3:
          this.loading.start('Please wait a moment while we processing your plan changes');
          let plans : { service : string, plan_id_from : string,  price_id_from : string, plan_id_to : string, price_id_to : string }[] = [];
          
          if(this.planChanged(SERVICES.SURVEYPOINT)) {
            plans.push({ 
              service : SERVICES.SURVEYPOINT,
              plan_id_from : this.surveypointPlan?.id || '',
              price_id_from : this.surveypointPlan?.stripe_price_id || '', 
              plan_id_to : this.selectedSurveypointPlan?.id || '',
              price_id_to : this.selectedSurveypointPlan?.stripe_price_id || '' 
            });
          }

          if(this.planChanged(SERVICES.TOUCHPOINT)) {
            plans.push({ 
              service : SERVICES.TOUCHPOINT,
              plan_id_from : this.touchpointPlan?.id || '',
              price_id_from : this.touchpointPlan?.stripe_price_id || '', 
              plan_id_to : this.selectedTouchpointPlan?.id || '',
              price_id_to : this.selectedTouchpointPlan?.stripe_price_id || '' 
            });
          }

          let billing_details : { line1 : string, city : string, state : string, postal_code : string, country : string } | null = {
            line1: this.frmStripe.get('addrl1')?.value,
            city: this.frmStripe.get('city')?.value,
            state: this.frmStripe.get('state')?.value,
            postal_code: this.frmStripe.get('postal')?.value,
            country: this.frmStripe.get('country')?.value
          }
          if(!this.selectedSurveypointPlan && !this.selectedTouchpointPlan) {
            billing_details = null;
          }
          
          this.subscriptionService.subscribeToPlan(this.paymentMethod, billing_details, plans).subscribe({
            next : (response) => {
              // console.log('plan-upgrade-modal.component:729786', response);
              this.subscriptionService.stripeSubscription.next(response.payload.stripeSubscription.data);
              this.subscriptionService.subscription.next(response.payload.subscription);
              this.subscriptionService.updateCredits(response.payload.credits);
              this.loading.stop();
              this.snackbar.open('Your plan changes have been saved', 'Dismiss', { duration: 3000 });
              this.dialogRef.close({
                action : 'subscribe',
                response : response.payload
              });
            },
            error : (error) => {
              console.log('plan-upgrade-modal.component:909052', error);
              this.loading.stop();
              this.snackbar.open('An error occurred while processing your request', 'Dismiss', { duration: 3000 });
            },
            complete : () => {
              this.loading.stop();
            }
          });
          break;
      }
    } catch (error) {
      console.log('plan-upgrade-modal.component:396135', error);
      this.paymentInProgress = false;
      this.snackbar.open('[909404] An error occurred while processing your request', 'Dismiss', { duration: 3000 });
    }
  }
  
  /**
   * This function gets the next step in the plan upgrade process
   * It does this by checking if payment details are required
   * 
   */
  getNextStep() {
    let next_step = this.step;
    this.paymentRequired = false;

    // If the user is changing their plan
    if(this.planChanged(SERVICES.SURVEYPOINT)) {
      // console.log('plan-upgrade-modal.component:532722 - surveypoint changed');
      // Check if they went to a free plan
      if(this.selectedSurveypointPlan) {
        // console.log('plan-upgrade-modal.component:075916 - to free plan');
        this.paymentRequired = true;
      }
    }

    if(this.planChanged(SERVICES.TOUCHPOINT)) {
      // console.log('plan-upgrade-modal.component:475913 - touchpoint changed');
      if(this.selectedTouchpointPlan) {
        // console.log('plan-upgrade-modal.component:493813 - to free plan');
        this.paymentRequired = true;
      }
    }

    switch(this.step) {
      case STEPS.SELECT_PLAN:
        if(this.paymentRequired) {
          next_step = STEPS.PAYMENT;
        } else {
          next_step = STEPS.CONFIRM;
        }
        break;
      case STEPS.PAYMENT:
        next_step = STEPS.CONFIRM;
        break;
      case STEPS.CONFIRM:
        // This is the final step
        next_step = STEPS.CONFIRM;
        break;
    }
    
    return next_step;
  }

  /**
   * This function does the opposite of `getNextStep` and returns the previous step
   * 
   */
  getPreviousStep() {
    let prev_step = this.step;
    this.paymentRequired = false;

    // If the user is changing their plan
    if(this.planChanged(SERVICES.SURVEYPOINT)) {
      // Check if they went to a free plan
      if(this.selectedSurveypointPlan) {
        this.paymentRequired = true;
      }
    }

    if(this.planChanged(SERVICES.TOUCHPOINT)) {
      if(this.selectedTouchpointPlan) {
        this.paymentRequired = true;
      }
    }

    switch(this.step) {
      case STEPS.SELECT_PLAN:
        // This is the first step
        prev_step = STEPS.SELECT_PLAN;
        break;
      case STEPS.PAYMENT:
        prev_step = STEPS.SELECT_PLAN;
        break;
      case STEPS.CONFIRM:
        if(this.paymentRequired) {
          prev_step = STEPS.PAYMENT;
        } else {
          prev_step = STEPS.SELECT_PLAN;
        }
        break;
    }
    
    return prev_step;        
  }

  canReverse() : boolean {
    switch(this.step) {
      case 1:
        return false;
      case 2:
        return true;
      case 3:
        return true;
    }
    return false;
  }

  prev() {
    switch(this.step) {
      case STEPS.PAYMENT:
        this.step = this.getPreviousStep();
        break;
      case STEPS.CONFIRM:
        this.step = this.getPreviousStep();
        // if(!this.selectedSurveypointPlan && !this.selectedTouchpointPlan) {
        //   this.step = 1;
        // } else {
        //   this.step--;
        // }
        break;
    }
  }

  getStepButtonLabel() : string {
    switch(this.step) {
      case 1:
        return 'Select Plan';
      case 2:
        return 'Save Payment Information';
      case 3:
        return 'Complete Plan Changes';
    }
    return 'Next';
  }

  getCardType() : string {
    if(!this.paymentMethod) return '';
    return this.paymentMethod.card.brand;
  }

  getCardLast4() : string {
    if(!this.paymentMethod) return '';
    return this.paymentMethod.card.last4;
  }

  onElementsChange(event: StripeCardElementChangeEvent) {
    // console.log('plan-signup-modal.component:641514', event);
    this.elementsValid = event.complete;
  }

  getSelectedCountry() : string {
    // Lookup from countries
    if(!this.frmStripe) return '';
    if(!this.frmStripe.get('country')) return '';

    let country = this.frmStripe.get('country')?.value || '';

    if(!country) return '';
    let c = this.countries.find((c) => c.id === country);
    if(!c) return '';
    return c.name;
  }
}
