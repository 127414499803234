<div class="w-full flex">
  <span class="flex grow pt-5">
    <!-- <div class="w-1/12" *ngIf="showScore">bbbb</div> -->
    <div class="font-bold ml-4 px-4 mr-3" style="width:60px;"></div>
    <div [matTooltip]="tooltip.option" class="cursor font-bold flex-grow items-center">Option</div>
    <div [matTooltip]="tooltip.incorrect" class="cursor font-bold w-4/12 pl-3 items-center" *ngIf="showScore">Message to display if selected</div>
    <div [matTooltip]="tooltip.score" class="cursor font-bold w-2/12 mr-12" *ngIf="showScore">Score</div>
  </span>
</div>
<form [formGroup]="frmOptions" cdkDropList (cdkDropListDropped)="drop($event)" class="drag-list !border-0">
  <div formArrayName="oc" *ngIf="optionControls.controls.length > 0">
    <div class="w-full flex" *ngFor="let opt of optionControls.controls; let i = index" [formGroupName]="i" [cdkDragDisabled]="readOnly" cdkDrag class="drag-box">
      <span class="flex grow">
        <span class="items-center">
          <button class="form-button mr-3 !bg-transparent !border-0 cursor-move" [matTooltip]="tooltip.reorder">
            <fa-icon matSuffix class="text-2xl align-middle text-gray-500" [icon]="faGripDotsVertical"></fa-icon>
          </button>  
        </span>
        <span class="items-center flex grow">
          <mat-form-field appearance=outline class="w-full">
            <input matInput placeholder="Enter option" (keyup.enter)="addOption(i)" formControlName="name">
          </mat-form-field>
        </span>
        <mat-form-field appearance=outline class="w-4/12 pl-3 items-center" *ngIf="showScore">
          <input matInput type="text" placeholder="Enter message" formControlName="incorrect_message">
        </mat-form-field>
        <mat-form-field appearance=outline class="w-1/12 pl-3 items-center" *ngIf="showScore">
          <input matInput type="number" placeholder="Enter score" formControlName="score">
        </mat-form-field>
        <!-- <span class="items-center">
          <button class="form-button mr-3 !bg-transparent !border-0" *ngIf="showScore">
            <fa-icon matSuffix class="align-middle text-2xl text-gray-500" [icon]="faCircleInfo"></fa-icon>
          </button>  
        </span> -->
        <span class="items-center">
          <button class="ml-5 form-button" [matTooltip]="tooltip.remove" [disabled]="optionCount() <= 1 || readOnly" (click)="removeOption(i)">
            <fa-icon matSuffix class="align-middle" [icon]="faMinus"></fa-icon>
          </button>
          <button class="ml-5 form-button" [matTooltip]="tooltip.add" [disabled]="readOnly" (click)="addOption(i)">
            <fa-icon matSuffix class="align-middle" [icon]="faPlus"></fa-icon>
          </button>
        </span>
      </span>
    </div>
  </div>
</form>