<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">Add Survey</div>
  <div class="my-10">
    <form class="flex flex-wrap w-full" [formGroup]="frmAddSurvey">
      <div class="flex w-full">
        <div class="w-2/12">
          <mat-label class="font-bold required">Name</mat-label>
        </div>
        <div class="flex flex-grow">
          <mat-form-field appearance=outline class="w-full">
            <input matInput required placeholder="Enter a name" formControlName="name">
          </mat-form-field>
        </div>
      </div>
      <div class="flex w-full">
        <div class="w-2/12">
          <mat-label class="font-bold">Welcome instructions</mat-label>
        </div>
        <div class="flex flex-grow">
          <mat-form-field appearance=outline class="w-full">
            <textarea matInput placeholder="Enter a greeting and/or instructions for the respondent" formControlName="welcome"></textarea>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="flex w-full">
        <div class="w-2/12">
          <span class="font-bold">Options</span>
        </div>
        <div class="flex flex-wrap mb-8 w-10/12 pt-8 px-8 bg-gray-100">
          <div class="flex flex-wrap mb-8 pr-8 md:w-1/2 xs:w-full sm:w-full">
            <div class="w-full flex"><mat-label class="font-bold" [matTooltip]="tooltips.type">Anonymous survey <span class="pro-tag ml-3">PRO</span></mat-label></div>
            <div class="w-full flex"><mat-slide-toggle formControlName="is_anonymous" color="text-primary"></mat-slide-toggle></div>
          </div>
          <div class="flex flex-wrap mb-8 pr-8 md:w-1/2 xs:w-full sm:w-full">
            <div class="w-full flex"><mat-label class="font-bold" [matTooltip]="tooltips.participants">Invite only? <span class="pro-tag ml-3">PRO</span></mat-label></div>
            <div class="w-full flex"><mat-slide-toggle formControlName="invite_only" color="text-primary"></mat-slide-toggle></div>
          </div>
          <div class="flex flex-wrap mb-8 pr-8 md:w-1/2 xs:w-full sm:w-full">
            <div class="w-full flex"><mat-label class="font-bold" [matTooltip]="tooltips.availability">Timing</mat-label></div>
            <mat-form-field class="w-full flex" appearance="outline">
              <mat-date-range-input [rangePicker]="picker" >
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            
              @if (start!.hasError('matStartDateInvalid')) {
                <mat-error>Invalid start date</mat-error>
              }
              @if (end!.hasError('matEndDateInvalid')) {
                <mat-error>Invalid end date</mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      </div> -->
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex xs:flex-wrap lg:flex-nowrap w-full">
    <button class="btn-secondary-outline xs:w-full xs:mr-0 lg:w-1/2 lg:mr-3" (click)="close()" type="button">Close</button>
    <button class="bg-primary xs:w-full xs:mt-5 lg:w-1/2 lg:mt-0" data-dismiss="modal" type="button" [disabled]="!canSave()" (click)="save()">
        <onboarding-badge [step]="[1]" tip="Give your survey a title (you can do the other stuff too if you like) then click Save to start building your survey" class="xs:w-full xs:mt-5 sm:w-full sm:mt-5 md:w-full lg:w-1/2">
          Save
      </onboarding-badge>
    </button>
  </div>
</mat-dialog-actions>