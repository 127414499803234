import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
const SIZE = { XL : 1280 };

@Component({
  selector: 'app-main-template',
  templateUrl: './main-template.component.html',
  styleUrls: ['./main-template.component.scss']
})
export class MainTemplateComponent {
  @ViewChild('sidenav') sidenav : ElementRef | any;

  @HostListener('window:resize', ['$event.target.innerWidth'])  
  onResize(width: number) {
    this.sidenav.toggle(width >= SIZE.XL);
  }

  private auth$ : any;

  constructor(
    private auth : AuthService,
    private cd : ChangeDetectorRef,
    private subSvc : SubscriptionService,
    private router : Router
  ) { }

  ngOnInit(): void {
    // this.auth$ = this.auth.tokens.subscribe({
    //   next : (tokens) => {
    //     if(!tokens) this.router.navigate(['/signin']);
    //   }
    // });

    this.subSvc.getPlans().subscribe({
      next : (response : any) => {
        if(response.payload) {
          this.subSvc.plans.next(response.payload);
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
    this.subSvc.getSubscriptions();    
  }
  
  ngAfterViewInit() {
    // console.log('main-template.component:756817', window.innerWidth, window.screen.width);
    if(window.innerWidth >= SIZE.XL) {
      this.sidenav.toggle(true);
    }
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    // this.auth$.unsubscribe();
  }
}
