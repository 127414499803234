<section class="mt-5 mb-10">
  <div class="flex">
    <div class="flex flex-nowrap" *ngIf="connectInitiated()">
      <span class="outlineStatus" 
        [matTooltip]="merchant.charges_enabled?tooltips.charges_enabled:tooltips.charges_disabled"
        [ngClass]="{ 'cursor-pointer' : !merchant.charges_enabled }" 
        (click)="handleChargesDisabled()"
      >
        <fa-icon [spin]="stripeDetailsLoading"  class="mr-5" *ngIf="stripeDetailsLoading" [icon]="faArrowsRotate"></fa-icon> 
        <fa-icon class="mr-5 on" *ngIf="!stripeDetailsLoading && merchant.charges_enabled" [icon]="faCircleCheck"></fa-icon> 
        <fa-icon class="mr-5 off" *ngIf="!stripeDetailsLoading && !merchant.charges_enabled" [icon]="faCircleXmark"></fa-icon>         
        <span class="font-bold">Charges</span>
      </span>
      <span class="outlineStatus" 
        [matTooltip]="merchant.payouts_enabled?tooltips.payouts_enabled:tooltips.payouts_disabled"
        [ngClass]="{ 'cursor-pointer' : !merchant.payouts_enabled }" 
        (click)="handlePayoutsDisabled()"
      >
        <fa-icon [spin]="stripeDetailsLoading"  class="mr-5" *ngIf="stripeDetailsLoading" [icon]="faArrowsRotate"></fa-icon> 
        <fa-icon class="mr-5 on" *ngIf="!stripeDetailsLoading && merchant.payouts_enabled" [icon]="faCircleCheck"></fa-icon> 
        <fa-icon class="mr-5 off" *ngIf="!stripeDetailsLoading && !merchant.payouts_enabled" [icon]="faCircleXmark"></fa-icon> 
        <span class="font-bold">Payout</span>
      </span>
      <span class="outlineStatus" [matTooltip]="getRequirementsTooltip()" [ngClass]="{ 'cursor-pointer' : hasRequirements() != 'no' }" (click)="handleRequirements()">
        <fa-icon [spin]="stripeDetailsLoading"  class="mr-5" *ngIf="stripeDetailsLoading" [icon]="faArrowsRotate"></fa-icon> 
        <fa-icon class="on" *ngIf="!stripeDetailsLoading && hasRequirements() == 'no'" [icon]="faCircleCheck"></fa-icon> 
        <fa-icon class="off" *ngIf="!stripeDetailsLoading && hasRequirements() == 'yes'" [icon]="faCircleXmark"></fa-icon> 
        <fa-icon class="warn" *ngIf="!stripeDetailsLoading && hasRequirements() == 'upcoming'" [icon]="faCircleExclamation"></fa-icon> 
        <span class="ml-3 font-bold">Compliance</span>
      </span>
    </div>
    <div class="flex flex-grow">
      <!-- <button class="ml-5 form-button" color="primary" [disabled]="!canSave()" (click)="saveSettings()"><fa-icon [icon]="faSave"></fa-icon></button> -->
    </div>
  </div>
</section>

<section class="mb-10">
  <alert-box class="cursor-pointer" (click)="handleRequirements()" *ngIf="hasRequirements() != 'no' || !merchant.charges_enabled || !merchant.payouts_enabled" type="warn">
    There are items in your Stripe account that need to be resolved in order to make and receive payments. Click here access your Stripe dashboard and resolve them.
  </alert-box>
  <alert-box class="cursor-pointer" (click)="handleRequirements()" *ngIf="hasRequirements() == 'upcoming'" type="warn">You have some upcoming actions to complete on Stripe by {{ getRequirementsDeadline() }} to continue taking payments.</alert-box>
  <ng-container *ngIf="getRequirementsErrors().length > 0">
    <alert-box *ngFor="let re of getRequirementsErrors()" class="cursor-pointer" (click)="handleRequirements()" type="warn">{{ re.reason }}</alert-box>
  </ng-container>
</section>
<section>
  <h3 class="my-5">Setup Payments</h3>
  <p class="mb-10">To enable payments, you need to have a Stripe account. If you don't have one, you can <a href="https://stripe.com" target="_blank" class="text-primary font-bold">create an account in Stripe</a>.  You will be responsible for keeping track of your customers' transactions.  A fee of ${{TXFEE}} per transaction applies.</p>

  <div class="w-full" *ngIf="!stripeDetailsLoading && connectInitiated()">
    <div class="w-full"><mat-label class="font-bold" [matTooltip]="tooltips.enablePayments">Enable payments? </mat-label></div>
    <mat-slide-toggle color="text-primary" [(ngModel)]="enable_payments" (change)="handleEnablePaymentsToggle($event)"></mat-slide-toggle>
  </div>
  <div class="w-full" *ngIf="!stripeDetailsLoading && !connectInitiated()">
    <button class="ml-auto form-button" color="primary" [disabled]="!canSave()" (click)="connectStripe()"><fa-icon class="mr-5" [icon]="faStripe"></fa-icon> Connect with Stripe</button>
  </div>
</section>
<section>
  <div class="flex flex-nowrap items-center mt-10">
    <span class="flex flex-grow"><h3 class="my-5">Products</h3></span>
    <span class="flex">
      <button class="!border" (click)="handleAddProduct()"><fa-icon [icon]="faPlus"></fa-icon></button>
    </span>
  </div>
  <div class="mt-5 mb-10">
    To complete product setup and to enable us to collect payments on your behalf, begin by setting up your product <a href="https://dashboard.stripe.com/products" target="_blank" class="text-primary font-bold">in Stripe</a>.
    When you're done, click the refresh button and we'll download your products so that you can assign Surveys to your products.
  </div>
</section>

<!-- Product list -->
<section>
  <table mat-table [dataSource]="dataSource" class="">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
  
    <ng-container matColumnDef="pricing">
      <th mat-header-cell *matHeaderCellDef>Pricing</th>
      <td mat-cell *matCellDef="let element">
        <div class="" *ngFor="let price of element.stripe_price_ids">{{ renderStripePrice(price) }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="surveys">
      <th mat-header-cell *matHeaderCellDef>Surveys</th>
      <td mat-cell *matCellDef="let element">{{ element.surveys ? element.surveys.length : 0 }} surveys</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer" (click)="handleEditProduct(row)"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <div class="text-center my-10" *ngIf="isFiltered()">
          <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
        </div>
        <div class="text-center my-10" *ngIf="!isFiltered()">
          <h4 class="text-center mt-10 mb-5">No cohort members found</h4>
        </div>
      </td>
    </tr>
  </table>

  <!-- Pagination Section -->
  <section class="flex items-center justify-between">
    <div class="w-full pt-5">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </section>
</section>