import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faHouse, faBook, faGear, faUser, faClipboard, faDiagramProject, faSquarePollHorizontal, faClipboardCheck, faCheckToSlot } from '@fortawesome/free-solid-svg-icons';
import { faPeople } from '@fortawesome/pro-regular-svg-icons';
import { DataSharingService } from 'src/app/services/data-sharing.service';
const SIZE = { XL : 1280 };

@Component({
  selector: 'app-left-nav-panel',
  templateUrl: './left-nav-panel.component.html',
  styleUrls: ['./left-nav-panel.component.scss']
})
export class LeftNavPanelComponent {
  @Output() onToggleMenu : EventEmitter<boolean> = new EventEmitter<boolean>();

  faHouse = faHouse;
  faBook = faBook;
  faDiagramProject = faDiagramProject;
  faGear = faGear;
  faUser = faUser;
  faPeople = faPeople;
  faCheckToSlot = faCheckToSlot;
  faClipboard = faClipboard;
  faClipboardCheck = faClipboardCheck;
  faSquarePollHorizontal = faSquarePollHorizontal;

  enableTouchpoints : boolean = false;
  enableSurveys : boolean = false;

  constructor(
    private dss : DataSharingService,
    private router : Router,
  ) { 
    this.enableTouchpoints = this.dss.features.touchpoints;
    this.enableSurveys = this.dss.features.surveys;
  }

  toggleMenu() {
    console.log('left-nav-panel.component:685026', 'toggleMenu');
    this.onToggleMenu.emit(true);
  }

  navTo(route: string) {
    if(window.innerWidth < SIZE.XL) {
      this.onToggleMenu.emit(true);
    }
    this.router.navigate([route]);
  }

}
