<h1 class="text-2xl font-bold text-gray-800 mb-3">Reset your password</h1>
<p class="my-6">
  Please use a password that's hard for someone other than you to guess.  
  It should be at least 8 characters long and have at least one uppercase letter, one lowercase letter, and one number.
</p>
<div class="relative flex flex-wrap mb-6 text-left">
  <form [formGroup]="frmPasswordReset" class="mt-6 w-full">
    <mat-label class="font-bold">Password</mat-label>
    <mat-form-field appearance=outline class="w-full">
      <input matInput [type]="passwordField" formControlName="password">
      <fa-icon class="mr-5" matSuffix [icon]="eyecon" (click)="togglePasswordVisibility()"></fa-icon>
    </mat-form-field>
  </form>
</div>
<button (click)="resetPassword()" class="w-full btn-primary text-white">Reset Password</button>
