import { Injectable } from '@angular/core';
import { ApiResponseModel, ApiService } from './api.service';
import { SurveyResponsesModel } from '../models/responses.model';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  constructor(
    private api : ApiService
  ) { }

  // Survey Responses
  public getSurveyResponses(survey_id? : string) {
    let url = `${this.api.endpoints.responses}`;
    if(survey_id) { url += `?survey_id=${survey_id}`; }

    return this.api.get(url);
  }

  public getSurveyResponse(survey_response_id : string) {
    return this.api.get(`${this.api.endpoints.responses}/${survey_response_id}`);
  }

  public getPublicSurveyResponse(survey_response_id : string, email : string) {
    return this.api.post(`${this.api.endpoints.public_responses}/s/${survey_response_id}`, { email : email });
  }

  public addSurveyResponse(survey_response : SurveyResponsesModel) {
    return this.api.post(this.api.endpoints.responses, survey_response);
  }

  public updateSurveyResponse(survey_response : SurveyResponsesModel) {
    return this.api.put(`${this.api.endpoints.responses}/${survey_response.id}`, survey_response);
  }
  public deleteSurveyResponse(survey_response_id : string) {
    return this.api.delete(`${this.api.endpoints.responses}/${survey_response_id}`);
  }
}
