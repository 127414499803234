import { Component, OnDestroy, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faUser, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { UsersModel } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrl: './top-toolbar.component.scss'
})
export class TopToolbarComponent implements OnDestroy {
  faUser = faUser;
  faSignOut = faSignOut;

  public user : UsersModel = new UsersModel();
  private user$ : any;

  constructor(
    private auth : AuthService,
    private dss : DataSharingService,
    private router : Router,
  ) { }

  ngOnInit() {
    this.user$ = this.auth.user.subscribe({
      next: (user : UsersModel | null) => {
        if(user) {
          this.user = user;
        }
      }
    });
  }

  ngOnDestroy(): void {
    // if(this.user$) this.user$.unsubscribe();
  }

  /**
   * This function returns a greeting, "Hi, [user's first name]!"
   */
  getGreeting() {
    if(!this.user) return 'Hi there!';

    let name = this.user?.first_name || 'there';
    return `Hi ${name}!`;
  }  


  signout() {
    try {
      this.auth.signout().subscribe({
        next: (res) => {
          // console.log('top-toolbar.component:452035', res);
          this.router.navigate(['/signin']);
        },
        error: (err) => {
          console.error('top-toolbar.component:464065 - error signing out ', err);
          this.router.navigate(['/signin']);
        }
      });
    } catch (error) {
      console.error('top-toolbar.component:012458 - error signing out', error);
      this.router.navigate(['/signin']);
    }
  }
}
