import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { PlansModel } from 'src/app/models/plans.model';
import { ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'plan-calculator',
  templateUrl: './plan-calculator.component.html',
  styleUrl: './plan-calculator.component.scss'
})
export class PlanCalculatorComponent {
  @Input() plan : PlansModel | null = null;
  @Output() onPlanSelected : EventEmitter<PlansModel | null> = new EventEmitter<PlansModel | null>();

  faCheck = faCheck;

  period = 'monthly';
  planSlider = {
    min : 0,
    max : 100,
    step : 1,
    thumbLabel : false,
    showTicks : false
  }

  public plans : PlansModel[] = [];
  public selectedPlan : PlansModel | null = null;
  public estimatedTouchpoints : number = 0;
  private plans$ : any;

  public selectedCurrency = 'cad';
  public currencies = [
    { name : 'USD', value : 'usd', symbol : '$' },
    { name : 'CAD', value : 'cad', symbol : '$' },
  ]

  touchpointValue = 0;

  constructor(
    private api : ApiService,
    private dss : DataSharingService,
    private subscriptionService : SubscriptionService,
    private snackbar : MatSnackBar
  ) { 
    this.plans$ = this.subscriptionService.plans.subscribe({
      next : (plans) => {
        this.plans = plans;
        console.log('plan-calculator.component:840688', plans);
      }
    });
  }

  ngOnInit() {
    if(this.plans.length === 0) {
      this.subscriptionService.plans.subscribe({
        next : (plans : any) => {
          if(plans) {
            this.plans = plans;
          }
        },
        error : (error) => {
          console.log(error);
          this.snackbar.open('Failed to fetch plans', 'Close', { duration: 3000 });
        }
      });
    }
  }

  ngOnChanges() {
    if(this.plan) {
      this.selectedPlan = this.plan;
    }
  }

  ngOnDestroy() {
    // this.plans$.unsubscribe();
  }

  plansLoaded() : boolean {
    return this.plans.length > 0;
  }

  selectPlan(plan : PlansModel) {
    this.selectedPlan = plan;
    this.onPlanSelected.emit(plan);
  }

  isSelected(id : string | null) {
    if(!this.selectedPlan || !id) return false;
    return this.selectedPlan.id === id;
  }

  /**
   * Determine the recommended plan based on the estimated touchpoints
   */
  determineRecommendedPlan() : string {
    try {
      let lowest : PlansModel | null = null;
      let lowestPrice : number = 0;
  
      this.getFilteredPlans().forEach((plan) => {
        if(!lowest) {
          lowest = plan;
          lowestPrice = this.calculatePrice(this.estimatedTouchpoints, plan);
        } else {
          let currentPrice = this.calculatePrice(this.estimatedTouchpoints, plan);
          let lowestPrice = this.calculatePrice(this.estimatedTouchpoints, lowest!);

          if(currentPrice < lowestPrice) {
            lowest = plan;
            lowestPrice = currentPrice;
          }
        }
      });

      return lowest!.id!;
    } catch(err) {
      console.error('plan-calculator.component:242409', err);
      return '';
    }
  }

  calculatePrice(amount : number, plan : PlansModel) {
    let price = 0;
    let overage = 0;

    if(plan && plan.config) {
      price = plan.config.price;
      overage = (amount - plan.config.includedTouchpoints)>0 ? amount - plan.config.includedTouchpoints : 0;
      return price + (overage * plan.config.overagePrice!);
    }

    return price;

  }

  getFilteredPlans() {
    return this.plans.filter((plan) => {
      let rst = false;
      if(plan.config && plan.config.currency) {
        rst = plan.config.currency === this.selectedCurrency;
      }
      return rst;
    });
  }
}
