import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faCaretDown, faPlus, faSearch, faStar } from '@fortawesome/free-solid-svg-icons';
import { ManageCohortModalComponent } from 'src/app/modals/manage-cohort-modal/manage-cohort-modal.component';
import { CohortsModel } from 'src/app/models/contacts.model';
import { ContactsService } from 'src/app/services/contacts.service';
import { ApiResponseModel } from 'src/app/services/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-cohorts',
  templateUrl: './cohorts.component.html',
  styleUrl: './cohorts.component.scss'
})
export class CohortsComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  faSearch = faSearch;
  faPlus = faPlus;
  faStar = faStar;
  faCaretDown = faCaretDown;

  public tooltips = {
    pin : 'Pin this cohort to the top of the list',
  }

  public isLoading = true;
  public cohorts : CohortsModel[] = [];

  public dataSource : MatTableDataSource<CohortsModel> = new MatTableDataSource<CohortsModel>();
  public displayedColumns: string[] = [
    'name', 
    'members', 
    'pinned',
  ];
  public cohortFilter : string = '';

  constructor(
    private contactsService : ContactsService,
    private modal : MatDialog,
    private router : Router
  ) { 
  }

  ngOnInit() {
    this.getCohorts();
  }

  getCohorts() {
    this.contactsService.getCohorts().subscribe({
      next : (response : ApiResponseModel) => {
        this.cohorts = response.payload;
        this.dataSource.data = this.cohorts;
        this.dataSource.paginator = this.paginator;
      },
      error : (error) => {
        console.error('cohorts.component:190295', error);
      },
      complete : () => {
        this.isLoading = false;
      }
    });
  }

  openManageCohortModal(cohort? : CohortsModel) {
    let config = new MatDialogConfig();
    let isNew = cohort ? false : true;
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      is_new: isNew,
      cohort : cohort || new CohortsModel()
    };

    this.modal.open(ManageCohortModalComponent, config).afterClosed().subscribe(
      (result : any) => {
        if(result) {
          console.log('cohorts.component:159367', result.response);
          let index = -1;

          switch(result.action) {
            case 'save':
              this.cohorts.push(result.response);
              this.dataSource.data = this.cohorts;
              this.dataSource.paginator = this.paginator;
              break;
            case 'update':
              index = this.cohorts.findIndex((cohort) => cohort.id === result.response.id);
              if(index > -1) {
                this.cohorts[index] = result.response;
                this.dataSource.data = this.cohorts;
                this.dataSource.paginator = this.paginator;
              }
              break;
            case 'delete':
              index = this.cohorts.findIndex((cohort) => cohort.id === result.response.id);
              if(index > -1) {
                this.cohorts.splice(index, 1);
                this.dataSource.data = this.cohorts;
                this.dataSource.paginator = this.paginator;
              }
              break
            default:
              break
          }
        }
      }
    );
  }

  updateCohortFilter() {
    this.dataSource.filter = this.cohortFilter;
  }

  displaySelectedFilter(cohort_id : string) {
    let cohort = this.cohorts.find((cohort) => cohort.id === cohort_id);
    return cohort ? cohort.name : '';
  }

  isFiltered() {
    return false;
  }

  goToCohort(cohort : CohortsModel) {
    this.router.navigate(['/cohorts/' + cohort.id]);
  }

  togglePin(cohort : CohortsModel) {
    cohort.pinned = !cohort.pinned;
    this.contactsService.updateCohort(cohort).subscribe({
      next : (response : ApiResponseModel) => {
        console.log('cohorts.component:980888', response);
      },
      error : (error) => {
        console.error('cohorts.component:980888', error);
        cohort.pinned = !cohort.pinned;
      }
    });
  }

  isPinned(cohort : CohortsModel) {
    return cohort.pinned;
  }
}
