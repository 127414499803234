import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {phone} from 'phone';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { TouchpointModel } from 'src/app/models/touchpoint.model';
import * as timezones from '../../json/timezoneRegions.json';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionLibraryModel } from 'src/app/models/question_library.model';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { ApiService } from 'src/app/services/api.service';
import { CompaniesModel } from 'src/app/models/companies.model';
import { CompanyService } from 'src/app/services/company.service';
dayjs.extend(timezone);
dayjs.extend(utc);
const BASE_DATE = '2021-01-01';

const GAP = 300;
@Component({
  selector: 'app-manage-touchpoint-modal',
  templateUrl: './manage-touchpoint-modal.component.html',
  styleUrl: './manage-touchpoint-modal.component.scss'
})
export class ManageTouchpointModalComponent implements OnInit, OnDestroy {
  public GAP = GAP;
  public ql : QuestionLibraryModel[] = [];
  private ql$ : any;
  private company$ : any;
  private company : CompaniesModel | null = null;

  public frmTouchpoint : FormGroup = new FormGroup({});
  public currentTimezone : string = dayjs.tz.guess();
  public touchpoint : TouchpointModel;
  public touchpointErrors : string[] = [];

  public tzs : string[] = [];
  public isNew : boolean = false;
  public isReadOnly : boolean = false;
  public hours : { value : string, desc : string }[] = [
    { value : '01:00', desc : '01:00' }, 
    { value : '02:00', desc : '02:00' }, 
    { value : '03:00', desc : '03:00' }, 
    { value : '04:00', desc : '04:00' }, 
    { value : '05:00', desc : '05:00' }, 
    { value : '06:00', desc : '06:00' }, 
    { value : '07:00', desc : '07:00' }, 
    { value : '08:00', desc : '08:00' }, 
    { value : '09:00', desc : '09:00' }, 
    { value : '10:00', desc : '10:00' },
    { value : '11:00', desc : '11:00' }, 
    { value : '12:00', desc : '12:00' }, 
    { value : '13:00', desc : '13:00' }, 
    { value : '14:00', desc : '14:00' }, 
    { value : '15:00', desc : '15:00' }, 
    { value : '16:00', desc : '16:00' }, 
    { value : '17:00', desc : '17:00' }, 
    { value : '18:00', desc : '18:00' }, 
    { value : '19:00', desc : '19:00' }, 
    { value : '20:00', desc : '20:00' },
    { value : '21:00', desc : '21:00' }, 
    { value : '22:00', desc : '22:00' }, 
    { value : '23:00', desc : '23:00' }, 
    { value : '24:00', desc : '24:00' }
  ];   

  constructor(
    private api : ApiService,
    private companyService : CompanyService,
    private dialogRef : MatDialogRef<ManageTouchpointModalComponent>,
    private dss : DataSharingService,
    private modal : MatDialog,
    private snackbar : MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data : any
  ) { 
    if(data.isNew) {
      console.log('manage-touchpoint-modal.component:347077', data.isNew);
      this.isNew = data.isNew;
    }

    this.company$ = this.companyService.company.subscribe((company) => {
      this.company = company;
    });

    if(data.touchpoint) {
      this.touchpoint = data.touchpoint;

      if(this.touchpoint.status) {
        let errors = this.touchpoint.status.split('-');
        if(errors.length > 1) {
          this.touchpointErrors = errors[1].split(',');
        }
      }
    } else {
      this.touchpoint = new TouchpointModel();
    }

    if(data.isReadOnly) {
      this.isReadOnly = data.isReadOnly;
    }

    this.tzs = Array.from(timezones);

    this.frmTouchpoint = new FormGroup({
      contact_name: new FormControl(this.touchpoint.contact_name, [Validators.required]),
      contact_phone_number: new FormControl(this.touchpoint.contact_phone_number, [
        Validators.required, 
        this.validPhoneNumber()
      ]),
      contact_company_name: new FormControl(this.touchpoint.contact_company_name, [Validators.required]),
      context : new FormControl(this.touchpoint.context),
      representative : new FormControl(this.touchpoint.representative),
      product_service : new FormControl(this.touchpoint.product_service),
      event_date : new FormControl(this.touchpoint.event_date),    
      time_slot_start: new FormControl(this.touchpoint.time_slot_start, [Validators.required, this.startEndValidator() ]),
      time_slot_end: new FormControl(this.touchpoint.time_slot_end, [Validators.required, this.startEndValidator() ]),
      question_library : new FormControl(this.touchpoint.question_library_id, [Validators.required]),      
      contact_timezone: new FormControl(this.getCompanyTimezonePreference()),
      contact_asap: new FormControl(this.touchpoint.contact_asap),
    });

    this.contact_timezone!.disable();

    if(this.isReadOnly) {
      this.frmTouchpoint.disable();
    }
  }

  get contact_name() { return this.frmTouchpoint.get('contact_name'); }
  get contact_phone_number() { return this.frmTouchpoint.get('contact_phone_number'); }
  get contact_company_name() { return this.frmTouchpoint.get('contact_company_name'); }
  get context() { return this.frmTouchpoint.get('context'); }
  get representative() { return this.frmTouchpoint.get('representative'); }
  get product_service() { return this.frmTouchpoint.get('product_service'); }
  get event_date() { return this.frmTouchpoint.get('event_date'); }
  get question_library() { return this.frmTouchpoint.get('question_library'); }
  get time_slot_start() { return this.frmTouchpoint.get('time_slot_start'); }
  get time_slot_end() { return this.frmTouchpoint.get('time_slot_end'); }
  get contact_timezone() { return this.frmTouchpoint.get('contact_timezone'); }
  get contact_asap() { return this.frmTouchpoint.get('contact_asap'); }

  ngOnInit(): void {
    this.api.get(this.api.endpoints.question_library).subscribe({
      next : (response : any) => {
        this.ql = response.payload;
        this.dss.questionLibrary.next(this.ql);
      },
      error : (error) => {
        console.log(error);
      }
    });

    this.ql$ = this.dss.questionLibrary.subscribe((ql) => {
      this.ql = ql;
    });
  }

  ngOnDestroy() {
    // if(this.ql$) this.ql$.unsubscribe();
    // if(this.company$) this.company$.unsubscribe();
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    if(this.frmTouchpoint.invalid) {
      this.snackbar.open('Please fill in the required fields', 'Close', { duration: 3000 });
      return;
    } else {
      // Get the timezone from the company object; if it doesn't exist, use the browser's timezone
      let timezone = (this.company && this.company.preferences) ? this.company.preferences.timezone! : dayjs.tz.guess();
      let x = dayjs(BASE_DATE + ' ' + this.time_slot_start!.value, 'YYYY-MM-DD H:mm').tz(timezone);
      let y = dayjs(BASE_DATE + ' ' + this.time_slot_end!.value, 'YYYY-MM-DD H:mm').tz(timezone);

      // Update the touchpoint with the form values
      this.touchpoint.contact_name = this.contact_name!.value;
      this.touchpoint.context = this.context!.value;
      this.touchpoint.contact_company_name = this.contact_company_name!.value;
      this.touchpoint.contact_phone_number = this.contact_phone_number!.value;
      this.touchpoint.contact_timezone = timezone;
      this.touchpoint.time_slot_start = this.time_slot_start!.value;
      this.touchpoint.time_slot_end = this.time_slot_end!.value;
      this.touchpoint.time_slot_start_utc = dayjs(BASE_DATE + ' ' + this.time_slot_start!.value, 'YYYY-MM-DD HH:mm').tz(timezone).utc().toISOString();
      this.touchpoint.time_slot_end_utc = dayjs(BASE_DATE + ' ' + this.time_slot_end!.value, 'YYYY-MM-DD HH:mm').tz(timezone).utc().toISOString();
      this.touchpoint.contact_asap = this.contact_asap!.value;
      this.touchpoint.question_library_id = this.question_library!.value;
      this.touchpoint.status = this.touchpoint.status == '' ? 'Ready' : this.touchpoint.status;
      this.touchpoint.representative = this.representative!.value;
      this.touchpoint.product_service = this.product_service!.value;
      this.touchpoint.event_date = this.event_date!.value;

      // If the touchpoint is incomplete, set it to new - we can't save an incomplete touchpoint
      let status = this.getTouchpointStatus(this.touchpoint.status);
      if(status == 'Incomplete' || status == 'Error') {
        this.touchpoint.status = 'Ready';
      }

      // Send the touchpoint back to the parent component -- 
      // it is responsible for saving the touchpoint
      this.dialogRef.close({ touchpoint : this.touchpoint, action: this.isNew ? 'add' : 'edit' });
    }
  }

  public deleteTouchpoint() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: 'Are you sure you want to delete this touchpoint?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.dialogRef.close({ touchpoint: this.touchpoint, action: 'delete' });
      }
    });
  }

  private startEndValidator(): ValidatorFn {
    this.getFormValidationErrors();

    return (control : AbstractControl): ValidationErrors | null => {
      let timeSlotStartControl = this.time_slot_start;
      let timeSlotEndControl = this.time_slot_end;
  
      // If both controls exist
      if (timeSlotStartControl && timeSlotEndControl) {
        let timeSlotStart = timeSlotStartControl.value;
        let timeSlotEnd = timeSlotEndControl.value;
  
        // If both time slots have values
        if (timeSlotStart && timeSlotEnd) {
          // Check if the end time is before the start time - any date can be used as the date is not important
          if (timeSlotEnd < timeSlotStart) {
            // console.log('manage-touchpoint-modal.component:396732', 'End time is before start time');
            // Unset the requiredGapError
            if(timeSlotStartControl.errors) {
              delete timeSlotStartControl.errors['requiredGapError'];
            }
            if(timeSlotEndControl.errors) {
              delete timeSlotEndControl.errors['requiredGapError'];
            }

            this.time_slot_start!.setErrors({ startEndError: true });
            this.time_slot_end!.setErrors({ startEndError: true });
            return { startEndError: true };
          } else {
            if(timeSlotEnd - timeSlotStart < GAP) {
              // console.log('manage-touchpoint-modal.component:396732', 'Less than 3 hours');
              // Unset the startEndError
              if(timeSlotStartControl.errors) {
                delete timeSlotStartControl.errors['startEndError'];
              }
              if(timeSlotEndControl.errors) {
                delete timeSlotEndControl.errors['startEndError'];
              }
              this.time_slot_start!.setErrors({ requiredGapError: true });
              this.time_slot_end!.setErrors({ requiredGapError: true });              
              return { requiredGapError: true };
            } else {
              // Unset the startEndError
              // console.log('manage-touchpoint-modal.component:252029', 'Unset the startEndError');
              if(timeSlotStartControl.errors) {
                // console.log('manage-touchpoint-modal.component:111582', 'Unset the startEndError for timeSlotStartControl');
                delete timeSlotStartControl.errors['startEndError'];
                timeSlotStartControl.setErrors(Object.keys(timeSlotStartControl.errors).length > 0 ? timeSlotStartControl.errors : null);
              }
              if(timeSlotEndControl.errors) {
                // console.log('manage-touchpoint-modal.component:111582', 'Unset the startEndError for timeSlotEndControl');
                delete timeSlotEndControl.errors['startEndError'];
                timeSlotEndControl.setErrors(Object.keys(timeSlotEndControl.errors).length > 0 ? timeSlotEndControl.errors : null);
              }
              // Unset the requiredGapError
              if(timeSlotStartControl.errors) {
                // console.log('manage-touchpoint-modal.component:111582', 'Unset the requiredGapError for timeSlotStartControl');
                delete timeSlotStartControl.errors['requiredGapError'];
                timeSlotStartControl.setErrors(Object.keys(timeSlotStartControl.errors).length > 0 ? timeSlotStartControl.errors : null);
              }
              if(timeSlotEndControl.errors) {
                // console.log('manage-touchpoint-modal.component:111582', 'Unset the requiredGapError for timeSlotEndControl');
                delete timeSlotEndControl.errors['requiredGapError'];
                timeSlotEndControl.setErrors(Object.keys(timeSlotEndControl.errors).length > 0 ? timeSlotEndControl.errors : null);
              }
            }
          }
        }

        // Unset the requiredGapError and startEndError
        if(timeSlotStartControl.errors) {
          delete timeSlotStartControl.errors['startEndError'];
          delete timeSlotStartControl.errors['requiredGapError'];
        }
        if(timeSlotEndControl.errors) {
          delete timeSlotEndControl.errors['startEndError'];
          delete timeSlotEndControl.errors['requiredGapError'];
        }
      }
      return null;
    };
  }

  getFormValidationErrors() {
    Object.keys(this.frmTouchpoint.controls).forEach(key => {
      let control = this.frmTouchpoint.get(key)
      if(control) {
        if (control.errors != null) {
          Object.keys(control.errors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError);
          });
        }
      }
    });
  }

  getTouchpointStatus(_status : string) {
    // Split the status using '-' and return the first part
    let status = _status.split('-')[0];

    return status;
  }

  /**
   * This function removes extra spaces from the text
   * @param text 
   * @returns 
   */
  squishText(text : string) {
    let rst = text.replace(/\s+/g, '').trim();
    return rst;
  }

  getCompanyTimezonePreference() {
    if(this.company!.preferences) {
      return this.company!.preferences.timezone;
    }
    return this.currentTimezone;
  }

  /**
   * This function is a validator for phone numbers
   * @returns {ValidatorFn}
   */
  validPhoneNumber() : ValidatorFn {
    return (control : AbstractControl): ValidationErrors | null => {
      let phoneNumber = control.value;
      let rst = phone(phoneNumber);
      // console.log('manage-touchpoint-modal.component:534364', rst, phoneNumber);
      return rst.isValid ? null : { invalidPhone: true };
    };
  }

  pauseTouchpoint() {
    try {
      this.api.put(this.api.endpoints.touchpoints + '/' + this.touchpoint.id + '/pause', this.touchpoint).subscribe({
        next : (response : any) => {
          console.log('manage-touchpoint-modal.component:715342', response.payload);
          this.touchpoint = response.payload[1][0];
          this.frmTouchpoint.enable();
          this.contact_timezone!.disable();
          this.isReadOnly = false;
        },
        error : (error) => {
          console.log(error);
        }
      });
    } catch(error) {
      console.log(error);
    }
  }

  resumeTouchpoint() {
    try {
      this.api.put(this.api.endpoints.touchpoints + '/' + this.touchpoint.id + '/resume', this.touchpoint).subscribe({
        next : (response : any) => {
          console.log('manage-touchpoint-modal.component:715342', response.payload);
          this.touchpoint = response.payload[1][0];
          this.frmTouchpoint.enable();
          this.contact_timezone!.disable();
          this.isReadOnly = false;
        },
        error : (error) => {
          console.log(error);
        }
      });
    } catch(error) {
      console.log(error);
    }
  }
}
