import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OnboardingModalComponent } from 'src/app/modals/onboarding-modal/onboarding-modal.component';
import { CohortsModel } from 'src/app/models/contacts.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  public cohorts : CohortsModel[] = [];
  public loadingSubscription : boolean = true;
  public isPro : boolean = true;
  private stripeSubscription$ : any;
  private cohorts$ : any;

  constructor(
    private contactsService : ContactsService,
    private dss : DataSharingService,
    private loading : LoadingService,
    private modal : MatDialog,
    private subscriptionService : SubscriptionService
  ) { }

  ngOnInit() {
    this.loading.start();
    this.stripeSubscription$ = this.subscriptionService.stripeSubscription.subscribe({
      next : (stripeSubscriptions : any) => {
        this.loadingSubscription = this.subscriptionService.isLoading.value;
        this.isPro = stripeSubscriptions.length > 0 ? this.subscriptionService.hasPlanforService('survey') : false;
        // console.log('dashboard.component:733409', this.isPro, stripeSubscriptions);
        this.loading.stop();
      }
    });

    this.cohorts$ = this.contactsService.getCohorts().subscribe({
      next : (response : ApiResponseModel) => {
        // console.log('cohorts-portlet.component:448148', response);
        if(!response.payload) { this.cohorts = []; }
        
        let pinnedCohorts = response.payload.filter((cohort : CohortsModel) => cohort.pinned);
        this.cohorts = pinnedCohorts;
      },
      error : (error) => {
        // console.error('cohorts.component:190295', error);
      }
    });

    if(this.dss.firstSignin.value) {
      this.openFirstSigninModal();
    }
  }

  ngOnDestroy() {
    // this.stripeSubscription$.unsubscribe();
    // this.cohorts$.unsubscribe();
  }

  updateSubscription() {
    this.isPro = this.subscriptionService.hasPlanforService('survey');
  }

  openFirstSigninModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];

    // Open modal
    this.modal.open(OnboardingModalComponent, config);
    this.dss.firstSignin.next(false);
  }
}
