import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-upload-touchpoint-modal',
  templateUrl: './upload-touchpoint-modal.component.html',
  styleUrl: './upload-touchpoint-modal.component.scss'
})
export class UploadTouchpointModalComponent {

  faFileUpload = faFileUpload;
  public file: File | null = null; // Variable to store file

  constructor(
    private api : ApiService,
    private dialogRef : MatDialogRef<UploadTouchpointModalComponent>,
    private modal : MatDialog,
    private snackbar : MatSnackBar
  ) { }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close({ save: true });
  }

  onFileChange(event : any) {
    console.log('upload-touchpoint-modal.component:251775', event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      console.log('upload-touchpoint-modal.component:763884', file);
      this.file = file;
    }
  }

  upload(event : any) {
    console.log('upload-touchpoint-modal.component:354262', this.file);
    event.stopPropagation();

    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);

      this.api.post(this.api.endpoints.touchpoints + '/upload', formData).subscribe({
        next: (response) => {
          this.dialogRef.close({ save: true });
        },
        error: (error) => {
          this.snackbar.open('There was an error uploading your file', 'Dismiss', {
            duration: 3000
          });
          console.error(error);
        }
      });
    } else {
      this.snackbar.open('Please select a file to upload', 'Dismiss', {
        duration: 3000
      });
    }
  }

  handleDrop(event : any) {
    console.log('upload-touchpoint-modal.component:044757', event);

    // Check if more than one file is dropped
    if (event.dataTransfer.files.length > 1) {
      this.snackbar.open('Please drop only one file', 'Dismiss', {
        duration: 3000
      });
      return;
    }

    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.file = files[0];
    }
  }

  /**
   * This function is used to download the template file
   */
  handleTemplateDownload() {
    window.open('/assets/templates/touchpoint_template_v1.csv');
  }
}
