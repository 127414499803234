import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SurveySessionService {
  public surveyEmail : BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public surveyToken : BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public surveyPosition : BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public inProgress : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private api : ApiService
  ) { 
    this.loadFromLocalStorage();
  }
  
  /**
   * Set the `_surveyToken` and save it to local storage
   */
  setSurveyToken(token : string | null) {
    this.surveyToken.next(token);
    if(token != null) {
      localStorage.setItem('surveyToken', token);
    } else {
      localStorage.removeItem('surveyToken');
    }
  }

  setSurveyPosition(p : number) {
    this.surveyPosition.next(p);
    if(p != null) {
      localStorage.setItem('surveyPosition', p.toString());
    }
  }

  setSurveyInProgress(inProgress : boolean) {
    this.inProgress.next(inProgress);
    localStorage.setItem('surveyInProgress', inProgress.toString());
  }

  setSurveyEmail(email : string) {
    this.surveyEmail.next(email);
    if(email != null) {
      localStorage.setItem('surveyEmail', email);
    }
  }

  loadFromLocalStorage() {
    let keys = [ 'surveyToken', 'surveyPosition', 'surveyInProgress', 'surveyEmail' ];

    keys.forEach((key) => {
      let item = localStorage.getItem(key);
      if(item) {
        switch(key) {
          case 'surveyToken':
            this.surveyToken.next(item);
            break;
          case 'surveyPosition':
            this.surveyPosition.next(parseInt(item));
            break;
          case 'surveyInProgress':
            this.inProgress.next(item == 'true');
            break;
          case 'surveyEmail':
            this.surveyEmail.next(item);
            break;
        }
      }
    });
  }

  /**
   * Starts the survey session - POST /v1/public/surveys/s/:ref/start
   * The response will have a JWT that will be used to track the survey session
   * It will also have the first question to be answered
   * 
   * The following is passed to the API:
   * - surveyRef : in the URL
   * - survey_participant_id : in the body
   * - name : in the body (optional)
   * - email : in the body (optional)
   * 
   */
  startSession(sessionData : any) {
    if(sessionData && !sessionData.survey_ref) {
      console.error('do-survey.component:214946', 'Survey reference is required');
      throw new Error('Survey reference is required');
    }

    let payload = {
      survey_ref : sessionData.survey_ref,
      survey_participant_id : sessionData.survey_participant_id,
      name : sessionData.name,
      email : sessionData.email
    }

    return this.api.post(`${this.api.endpoints.public_surveys}/${sessionData.survey_ref}/start`, payload).pipe(
      tap((response) => {
        console.log('survey-session.service:749383', response);
        if(response.payload) {
          this.setSurveyInProgress(true);
          this.setSurveyToken(response.payload.token);
          this.setSurveyPosition(response.payload.current_question_index);
          this.setSurveyEmail(sessionData.email);
        }
      }
    ));
  }

  getSession() {
    if(!this.surveyToken.value) {
      return of(null);
    }

    // console.log('survey-session.service:743626', 'Getting session', this.surveyToken.value);
    return this.api.post(this.api.endpoints.survey_sessions + '/get', { token : this.surveyToken.value });
  }

  /**
   * Submits the response to the current question
   * 
   * The following is passed to the API:
   * - surveyRef : in the URL
   * - survey_participant_id : in the URL
   * - question_id : in the URL
   * - content : in the body
   * 
   */
  submitResponse(payload : any) {
    console.log('survey-session.service:453201', payload);

    if(!payload) {
      throw new Error('Nothing was provided to submit');
    }

    if(!this.surveyToken.value) {      
      throw new Error('Survey token is required');
    }

    // Add the token to the payload before sending
    payload.token = this.surveyToken.value;

    return this.api.post(this.api.endpoints.survey_sessions + '/update', payload);
  }

  endSession() {
    console.log('survey-session.service:274047', 'Ending session');
    return this.api.post(this.api.endpoints.survey_sessions + '/end', { token : this.surveyToken.value }).pipe(
      tap((response) => {
        this.setSurveyToken(null);
        this.setSurveyPosition(-1);
        this.setSurveyInProgress(false);
    
        console.log('survey-session.service:749383', response || 'No response');
      })
    );
  }
}
