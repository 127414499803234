import { v4 as uuidv4 } from 'uuid';
import { OptionListModel } from './option-list.model';

export const QuestionFieldTypes = {
  TEXT : 'text',
  RADIO : 'radio',
  DROPDOWN : 'dropdown',
  MULTIPLE : 'multiple',
  TEXTAREA : 'Textarea',
  NUMBER : 'number',
}

export class QuestionLibraryViewModel {
  id : string;
  service : string;
  company_id : string | null;
  name : string;
  questions? : QuestionsModel[];

  constructor() {
    this.id = '';
    this.name = '';
    this.company_id = null;
    this.service = '';
    this.questions = [];
  }
  
}

export class QuestionLibraryModel {
  id : string;
  name : string;
  category? : string | null;
  short_id? : string;
  company_id : string | null;
  questions? : QuestionsModel[];
  is_new? : boolean = true;

  constructor() {
    this.id = uuidv4();
    this.name = '';
    this.category = null;
    this.company_id = null;
    this.questions = [];

    let q = new QuestionsModel();
    this.questions.push(q);
  }
  
}

export class QuestionsModel {
  id : string;
  order : number;
  question : string;
  description : string;
  field_type : string;
  field_options : OptionListModel[];
  field_config? : any;
  conditions : QuestionConditionsModel[];
  required : boolean;
  allow_elaborate : boolean;
  notes : string;

  constructor() {
    // uuid
    this.id = uuidv4();
    this.order = 0;
    this.question = '';
    this.description = '';
    this.field_type = '';
    this.field_options = [];
    this.field_config = {};
    this.conditions = [];
    this.required = true;
    this.allow_elaborate = false;
    this.notes = '';
  }
}

export class QuestionCategoriesModel {
  id : string;
  name : string;

  constructor() {
    this.id = '';
    this.name = '';
  }
}

export class QuestionTypesModel {
  id : string;
  name : string;

  constructor() {
    this.id = '';
    this.name = '';
  }
}

export class QuestionLibraryStructureModel {
  categories? : QuestionCategoriesModel[];
  types? : QuestionTypesModel[];

  constructor() {}
}

export class QuestionConditionsModel {
  question_id : string;
  comparator : string;
  value : string;
  action : string;

  constructor() {
    this.question_id = '';
    this.comparator = '';
    this.value = '';
    this.action = '';
  }
}