<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">Manage Conditions<span class="ml-3 pro-tag">PRO</span></div>
  <div class="my-10">
    <form [formGroup]="frmAddCondition" class="grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <mat-label class="bold w-full">Question</mat-label>
        <mat-form-field appearance="outline" class="w-full">
          <mat-select formControlName="question_id" placeholder="Select a question" class="w-full">
            <ng-container *ngFor="let question of questions; let i=index">
              <mat-option *ngIf="i < current_question_index" [value]="question.id">{{ question.question }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-2">
        <mat-label class="bold w-full">Comparators</mat-label>
        <!-- Itertate over the comparators array and display the options in a select dropdown -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-select formControlName="comparator" placeholder="" class="w-full">
            <mat-option *ngFor="let comparator of comparators" [value]="comparator.value">{{ comparator.key }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-3">
        <mat-label class="bold w-full">Value</mat-label>
        <!-- Itertate over the comparators array and display the options in a select dropdown -->
        <mat-form-field appearance="outline" class="w-full">
          <input matInput formControlName="value" placeholder="Value" class="w-full" />
        </mat-form-field>
      </div>
      <div class="col-span-2">
        <mat-label class="bold w-full">Action</mat-label>
        <!-- Itertate over the comparators array and display the options in a select dropdown -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-select formControlName="action" placeholder="Select an action" class="w-full">
            <mat-option *ngFor="let action of actions" [value]="action.value">{{ action.key }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-1">
        <div class="w-full">&nbsp;</div>
        <button class="btn bg-primary py-16px" (click)="addCondition()" type="button"><fa-icon [icon]="faPlus"></fa-icon></button>
      </div>
    </form>

    <h4 class="mt-5">Conditions</h4>
    <table mat-table [dataSource]="dataSource" class="mb-5">
      <!-- question Column -->
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef>Question</th>
        <td mat-cell *matCellDef="let element">{{ renderQuestion(element.question_id) }}</td>
      </ng-container>
    
      <!-- comparator Column -->
      <ng-container matColumnDef="comparator">
        <th mat-header-cell *matHeaderCellDef class="!text-center">Comparator</th>
        <td mat-cell *matCellDef="let element" class="!text-center">{{ renderComparator(element.comparator) }}</td>
      </ng-container>
    
      <!-- value Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Value</th>
        <td mat-cell *matCellDef="let element">{{element.value}}</td>
      </ng-container>
    
      <!-- action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">{{element.action | titlecase }}</td>
      </ng-container>

      <!-- buttons Column -->
      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <fa-icon [icon]="faTrash" (click)="removeCondition(element)"></fa-icon>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer" [ngClass]="row.status =='Incomplete'?'bg-incomplete':''" ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="text-center my-10">
            <h4>No conditions defined</h4>
          </div>
        </td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-left w-1/2 pl-5">
  </div>
  <div class="float-right w-1/2 text-right">
    <button class="btn-secondary-outline mr-3" (click)="close()" type="button">Close</button>
    <button class="bg-primary mr-3" data-dismiss="modal" type="button" [disabled]="!canSave()" (click)="save()">Save</button>
  </div>
</mat-dialog-actions>