import { Component } from '@angular/core';

@Component({
  selector: 'tpt-portlet',
  templateUrl: './portlet.component.html',
  styleUrl: './portlet.component.scss'
})
export class PortletComponent {

}
