import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SurveyResponsesModel } from 'src/app/models/responses.model';
import { SurveyModel } from 'src/app/models/survey.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResponseService } from 'src/app/services/responses.service';
import { SurveySessionService } from 'src/app/services/survey-session.service';

@Component({
  selector: 'app-view-response-external',
  templateUrl: './view-response-external.component.html',
  styleUrl: './view-response-external.component.scss'
})
export class ViewResponseExternalComponent {

  // Inject services
  loading = inject(LoadingService);
  responsesService = inject(ResponseService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  surveySessionService = inject(SurveySessionService);
  snackbar = inject(MatSnackBar);

  faArrowLeft = faArrowLeft;

  public frmEmail : FormGroup = new FormGroup({});
  public errMessage : string | null = null;

  public _email : string | null = null;
  private responses$ : any;
  public response : SurveyResponsesModel = new SurveyResponsesModel();
  public survey : SurveyModel = new SurveyModel();
  private survey_response_id : string = '';

  constructor() {
    this.frmEmail = new FormGroup({
      email : new FormControl('', [ Validators.required, Validators.email ])
    });

    let email = this.surveySessionService.surveyEmail.value;
    if(email) {
      this._email = email;
      this.frmEmail.get('email')?.setValue(email);
      if(email) {
        console.log('view-response-external.component:041939', email);
        this.loadSurveyResponses(this.survey_response_id, email);
      }
    } else {
      this._email = null;
      this.frmEmail.get('email')?.setValue('');
    }

    console.log('view-response-external.component:020183', email, this._email);
   }

   get email() { return this.frmEmail.get('email')?.value; }

  ngOnInit() {
    // Get the survey_response_id from the route
    this.route.params.subscribe(params => {
      this.survey_response_id = params['id'];
    });

    if(this._email) {
      console.log('view-response-external.component:834649', this._email);
      this.loadSurveyResponses(this.survey_response_id, this._email);
    }
  }

  ngOnDestroy() {
    // this.responses$.unsubscribe();
    // this.surveyEmail$.unsubscribe();
  }

  handleLoadResponse() {
    if(this.frmEmail.valid) {
      this._email = this.email;
      console.log('view-response-external.component:011605');
      this.loadSurveyResponses(this.survey_response_id, this._email || '');
    }
  }

  loadSurveyResponses(id : string, email : string) {
    if(!id || !email) { return; }
    
    console.log('view-response-external.component:986457', id, email);
    this.loading.start();
    this.responses$ = this.responsesService.getPublicSurveyResponse(id, email).subscribe({
      next : (response : ApiResponseModel) => {
        console.log('response-viewer.component:473443', this.response);
        if(response.payload) {
          this.response = response.payload;
          this.survey = response.payload.survey;
        } else {
          console.log('view-response-external.component:746511', response.message);
          if(response.message) {
            this.errMessage = response.message;
          } else {
            this.errMessage = "We couldn't find the response for this email address";
          }
        }

        this.loading.stop();
      }, error : (error) => {
        // console.error('response-viewer.component:642183', error);
        this.loading.stop();
        this._email = null;
        this.frmEmail.reset();
        this.snackbar.open("We couldn't find the response for this email address", 'Close', { duration : 5000 });
      }
    });
  }

  back() {
    window.history.back();
  }

  createYourOwnSurvey() {
    this.router.navigate(['/signup']);
  }

  responseAvailable() {
    let e = this.email ? true : false;
    let r = this.response && this.response.id ? true : false;

    return e && r;
  }
}