export class PlansModel {
  id : string | null;
  name : string | null;
  stripe_price_id : string | null;
  service : string | null;
  config : any;

  constructor() {
    this.id = null;
    this.name = null;
    this.stripe_price_id = null;
    this.service = null;
    this.config = {};
  }
}

export interface PlanConfig {
  [key: string]: any;
}

export const PLANSERVICE = {
  SURVEY : 'survey',
  CUSTOM : 'custom'
};