<!-- Page Title -->
<section class="content-panel-header flex items-center">
  <div class="w-full text-center">
    <h1>Your Response</h1>
  </div>
</section>

<section *ngIf="responseAvailable()">
  <tpt-render-responses [survey]="survey" [response]="response"></tpt-render-responses>
  <div class="mt-20 text-center">
    <button (click)="createYourOwnSurvey()">Create your own FREE survey!</button>
  </div>
</section>

<section class="text-center px-20" *ngIf="!responseAvailable()">
  <h3 class="mt-20 mb-10">Please provide your email address to load this response</h3>
  <form [formGroup]="frmEmail" class="flex justify-center">
    <mat-form-field appearance=outline class="w-full items-center">
      <input matInput type="text" name="email" placeholder="your@email.com" formControlName="email">
      <button matSuffix class="py-8" [disabled]="frmEmail.invalid" (click)="handleLoadResponse()">Load
        Response</button>
    </mat-form-field>
  </form>
  <alert-box *ngIf="errMessage" type="info" class="mb-5">{{ errMessage }}</alert-box>
  <div (click)="createYourOwnSurvey()" class="mt-20 text-blue-600 cursor-pointer">Create your own FREE survey!</div>
</section>

<!-- Footer -->
<div class="powered-by">
  <div class="font-bold text-gray-500 text-center sm:pb-2 md:pb-5">Super simple surveys start with</div>
  <div class="px-10">
    <a href="https://surveypoint.io">
      <img class="center" src="../../assets/SurveyPoint-logo.png" alt=""></a>
  </div>
</div>