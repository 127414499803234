import { Component, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PlanUpgradeModalComponent } from 'src/app/modals/plan-upgrade-modal/plan-upgrade-modal.component';

@Component({
  selector: 'tpt-subscribe-banner',
  templateUrl: './subscribe-banner.component.html',
  styleUrl: './subscribe-banner.component.scss'
})
export class SubscribeBannerComponent {
  public onSubscribe : EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modal : MatDialog,
  ) { }

  openUpgradeModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'lg:w-3/4' ];
    config.data = { 
    };

    this.modal.open(PlanUpgradeModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        console.log('subscribe-banner.component:159367', result);
        switch(result.action) {
          case 'subscribe':
            this.onSubscribe.emit();
            break;
          default:
            break;
        }
      }
    });
  }

}
