<div class="banner">
  <div class="banner-content flex">
    <div class="flex flex-grow">
      <div class="block">
        <h1>Go Pro to unlock advanced features</h1>
        <p>Run more surveys, quiz mode, conditional logic, CSV exports, shuffle questions, and more!</p>
      </div>
    </div>
    <div class="flex">
      <div class="block my-auto">
        <button class="form-button" (click)="openUpgradeModal()">Go Pro</button>
      </div>
    </div>
  </div>
</div>