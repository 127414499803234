import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[DragDrop]',
})
export class DragDropDirective {

  constructor() { }

  @HostBinding('class.drag-hover') dragHover: boolean = false;
  @Output() onDragOver = new EventEmitter<any>();
  @Output() onDragLeave = new EventEmitter<any>();
  @Output() onDrop = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) _onDragOver(evt : any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragHover = true;
    this.onDragOver.emit(evt);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public _onDragLeave(evt : any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragHover = false;
    this.onDragLeave.emit(evt);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public _onDrop(evt : any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragHover = false;
    this.onDrop.emit(evt);
    // let files = evt.dataTransfer.files;
    // if (files.length > 0) {
    //   this.onDrop.emit(files);
    // }
  }

}
