import { Component, inject, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ResponsesViewModel, SurveyResponsesModel } from 'src/app/models/responses.model';
import { faPlus, faSearch, faFileExport, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ApiResponseModel, ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionLibraryService } from 'src/app/services/question-library.service';
import { QuestionLibraryViewModel } from 'src/app/models/question_library.model';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { SurveyModel } from 'src/app/models/survey.model';
import { ResponseService } from 'src/app/services/responses.service';

@Component({
  selector: 'app-response-library',
  templateUrl: './response-library.component.html',
  styleUrl: './response-library.component.scss'
})
export class ResponseLibraryComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  api = inject(ApiService);
  dss = inject(DataSharingService);
  qls = inject(QuestionLibraryService);
  responseService = inject(ResponseService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  surveyService = inject(SurveysService);

  faPlus = faPlus;
  faFileExport = faFileExport;
  faChevronRight = faChevronRight;
  faSearch = faSearch;

  // public questionLibrary : QuestionLibraryViewModel[] = [];
  public surveys : SurveyModel[] = [];

  public periodFilterOptions = [
    { value: 30, name: 'Last 30 days' },
    { value: 60, name: 'Last 60 days' },
    { value: 90, name: 'Last 90 days' }
  ];
  public selectedSurvey : SurveyModel = new SurveyModel();
  public dataSource : MatTableDataSource<ResponsesViewModel> = new MatTableDataSource<ResponsesViewModel>();
  public displayedColumns: string[] = [
    'recorded_date', 
    'cohort_name',
    'contact_name', 
    'responses', 
  ];

  public responses : ResponsesViewModel[] = [];
  public isLoading = false;
  public filterText : string = '';
  private surveyId : string = '';

  constructor() {
    this.surveyId = this.route.snapshot.params['id'];

    // Grab the query paramter `filter` if available
    this.route.queryParams.subscribe({
      next : (params) => {
        if(params['filter']) {
          this.filterText = params['filter'];
        }
      }
    });

    this.surveyService.getSurveySummaries().subscribe({
      next : (response : ApiResponseModel) => {
        this.surveys = response.payload;


        if(this.surveyId) {
          // Find the selected question library
          this.selectedSurvey = this.surveys.find((s) => s.id === this.surveyId) || new SurveyModel();
          this.saveSelectedSurveyResponse();
          // console.log('response-library.component:657541', this.selectedSurvey, this.questionLibrary);
          this.loadResponses();
        } else {
          this.loadSelectedSurveyResponse();
        }

      },
      error : (error) => {
        console.error('response-library.component:195204', error);
      }
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  navToTouchpoints(): void {
    this.router.navigate(['/touchpoints']);
  }

  public handleSelectedSurveyChange() {
    this.saveSelectedSurveyResponse();
    this.loadResponses();
  }

  public loadResponses() {
    try {
      this.isLoading = true;

      if(!this.selectedSurvey.id) {
        this.isLoading = false;
        // console.log('response-library.component:274122', 'No question library selected');
        return;
      }

      this.responseService.getSurveyResponses(this.selectedSurvey.id).subscribe({
        next : (response : ApiResponseModel) => {
          this.responses = response.payload;
          let data = this.prepareResponseTableDataSource();

          // this.dataSource = new MatTableDataSource(data);
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
        },
        error : (error) => {
          console.log(error);
          this.isLoading = false;
        },
        complete : () => {
          this.isLoading = false;
        }
      });
    } catch(error) {
      console.error('response-library.component:757383', error);
    }
  }

  /**
   * This is a helper function used by the `prepareResponsesforExport` function to 
   * prepare the correct number of columns for the responses in the table. 
   * Each question has 3 columns: question, answer, and extra.
   * 
   * @returns {number} The maximum number of responses in the responses array
   */
  private getMaxResponseCount(): number {
    let max = 0;
    this.responses.forEach((response) => {
      if(response.responses && response.responses.length > max)
        max = response.responses.length;
    });

    return max;
  }

  /**
   * This function prepares the data source for the table. 
   * It will take ResponsesModel[] and prepare them for display in the table.
   */
  private prepareResponseTableDataSource() {
    let data : any[] = [];

    this.responses.forEach((response) => {
      let row : any = {};
      row.id = response.id;

      this.displayedColumns.forEach((column) => {
        switch(column) {
          case 'contact_name':
            row[column] = response.contact_name;
            break;
          case 'contact_company_name':
            row[column] = response.contact_company_name;
            break;
          case 'cohort_name':
            row[column] = response.cohort_name;
            break;
          case 'recorded_date':
            row[column] = response.recorded_date;
            break;
          case 'ql':
            row[column] = response.ql;
            break;
          case 'responses':
            let r = response.responses;
            let id = '';

            if(r) {
              let rst = r.map((response) => {
                // console.log('response-library.component:625916', response);
                return response;
              });
              // console.log('response-library.component:799029', rst);
              row[column] = rst;
            }
            break;
        }
      });

      // console.log('response-library.component:858284 response\n', response, '\nthis.displayedColumns\n',this.displayedColumns, '\nrow\n', row);
      row.survey = response.survey;
      data.push(row);
    });

    return data;
  }

  private prepareResponsesforExport() : any {
    let headers : string[] = [];
    let data : any[] = [];

    // FIRST, PREPARE THE HEADERS
    // Place a copy of the displayedColumns array into `headers`
    this.displayedColumns.forEach((header) => {
      headers.push(header);
    });
    // Drop the `responses` column from the headers array
    headers.splice(this.displayedColumns.indexOf('responses'), 1);

    // Break out the responses into a group of 3 columns: question, answer, and extra
    let max = this.getMaxResponseCount();
    console.log('response-library.component:647498', max);
    for(let i = 0; i < max; i++) {
      // This is for users, so don't start at 0
      headers.push(i + '_question');
      headers.push(i + '_answer');
      headers.push(i + '_extra');
    }
    
    // NEXT, PREPARE THE RESPONSES
    // For each response in the dataSource, add the response to the content array. The content must be in the same order as the headers array.
    // this.responses.forEach((response) => {
    this.dataSource.filteredData.forEach((response) => {
      let row: any = {};

      // Add the displayed columns to the row
      this.displayedColumns.forEach((column) => {
        // row[column] = response[column as keyof ResponsesModel]; // Add index signature to allow indexing with a string
        switch(column) {
          case 'contact_name':
            row[column] = response.contact_name;
            break;
          case 'contact_company_name':
            row[column] = response.contact_company_name;
            break;
          case 'recorded_date':
            row[column] = response.recorded_date;
            break;
          case 'ql':
            row[column] = response.ql;
            break;
        }
      });

      // Add the responses to the row
      if(response.responses) {
        response.responses.forEach((content, index) => {
          row[ index + '_question'] = this.stripHtml(content.question || '');
          row[ index + '_answer'] = content.answer;
          row[ index + '_extra'] = content.extra;
          console.log('response-library.component:260903', row, index);
        });
      }

      data.push(row);
    });

    return { headers, data };
  }

  /**
   * This function exports the data from the prepareResponsesforExport function to a CSV file.
   */
  exportResponses(): void {
    let { headers, data } = this.prepareResponsesforExport();

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';

    data.forEach((row : any) => {
      let values = headers.map((header : any) => {
        return row[header];
      });
      csvContent += values.join(',') + '\n';
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'responses.csv');
    document.body.appendChild(link);
    link.click();
  }

  handleFilter() {
    console.log('response-library.component:916875', this.filterText);
    this.dataSource.filter = this.filterText.trim().toLowerCase();
  }

  isFiltered(): boolean {
    return this.dataSource.filter !== '' || this.selectedSurvey.name !== '';
  }

  /**
   * This function strips the HTML from the question and returns the question as a plain string.
   * @param question 
   * @returns 
   */
  renderQuestionAsString(question: any): string {
    let rst = '';

    if (question.question) {
      const div = document.createElement('div');
      div.innerHTML = question.question;
      rst = div.textContent || div.innerText || '';
    }

    console.log('response-library.component:095311', rst);
    return rst;
  }

  stripHtml(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  viewResponse(e : any) {
    this.dss.currentSurvey.next(e.survey);
    let r = new SurveyResponsesModel();
    r.content = e.responses;
    this.dss.currentSurveyResponse.next(r);
    this.router.navigate([`/rl/${e.survey.id}/response/${e.id}`]);
  }

  /**
   * This function saves the selected response library (stored in selectedSurvey) to local storage.
   * This is used to persist the selected response library when the user navigates away from the page.
   */
  saveSelectedSurveyResponse() {
    // console.log('response-library.component:638871', 'saveSelectedResponseLibrary', this.selectedSurvey);
    localStorage.setItem('sr', JSON.stringify(this.selectedSurvey));
  }

  /**
   * This function loads the selected response library from local storage.
   */
  loadSelectedSurveyResponse() {
    let sr = localStorage.getItem('sr');
    if(sr) {
      let psr = JSON.parse(sr);
      let rst = this.surveys.find(s => s.id === psr.id);
      if(rst) {
        this.selectedSurvey = rst;
        this.loadResponses();
      }

    }
  }
}
