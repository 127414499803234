<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">Update Payment Information</h3>
  <div class="my-5 pr-5">
    <form class="my-5 grid grid-cols-12 gap-4" [formGroup]="frmStripe">
      <div class="col-span-12">
        <mat-label class="font-bold">Cardholder Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="cardholder" required>
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold">Address</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="addrl1" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">City</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput formControlName="city" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">State/Province</mat-label>
        <mat-form-field appearance=outline  class="w-full">
          <input matInput formControlName="state" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold w-full">Zip/Postal Code</mat-label>
        <mat-form-field appearance=outline  class="w-full">
          <input matInput formControlName="postal" required>
        </mat-form-field>
      </div>
      <div class="col-span-6">
        <mat-label class="font-bold">Country</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <mat-select formControlName="country">
            @for (country of countries; track country) {
              <mat-option [value]="country.id">{{ country.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <div class="stripeInput">
          <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
            <ngx-stripe-card (change)="onElementsChange($event)" [options]="cardOptions" />
          </ngx-stripe-elements>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="mb-5 float-right w-full text-right pr-5">
    <button class="btn-secondary-outline mr-3" data-dismiss="modal" type="button" (click)="closeModal()">Close</button>
    <button class="btn !text-white" [disabled]="updateInProgress" (click)="completePaymentInfoUpdate()">
      <span>Update Payment Info</span>
      <span class="ml-5 inline-block align-middle" *ngIf="updateInProgress">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="18" class="my-0"></mat-progress-spinner>
      </span>
    </button>
  </div>
</mat-dialog-actions>