<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">{{ title }}</div>
  <div class="w-full mt-4 leading-8">{{description}}</div>
  <div class="my-10">
    <form class="flex flex-wrap">
      <div class="w-full">
        <mat-label class="font-bold" [matTooltip]="tooltips.name">Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput type="text" placeholder="Product name" name="name" [(ngModel)]="product.name">
        </mat-form-field>
      </div>
      <div class="flex flex-wrap w-full">
        <h5 class="w-full font-bold mt-5 mb-3">Pricing Structure</h5>
        <mat-label class="font-bold" [matTooltip]="tooltips.stripePriceId">Product pricing 
          <a class="text-blue-500 cursor-pointer" (click)="navToStripeProducts()">(from Stripe)</a>
          <fa-icon (click)="navToStripeProducts()" class="ml-3 cursor-pointer" [icon]="faUpRightFromSquare"></fa-icon>
        </mat-label>
        <div class="flex flex-wrap w-full">
          <div class="flex flex-grow w-full">
            <mat-form-field appearance=outline class="w-full mr-5">
              <mat-select 
                name="frequency"
                [aria-label]="tooltips.frequency" 
                hideSingleSelectionIndicator="false"
                [(ngModel)]="selectedStripeProduct"
              >
                <ng-container *ngIf="stripeProducts.length == 0">
                  <mat-option value="0">No products found. Create one in Stripe and press the sync button to the right</mat-option>
                </ng-container>
                <ng-container *ngIf="stripeProducts.length > 0">
                  <mat-option value="">Select a product</mat-option>
                  <mat-option [value]="price.id" *ngFor="let price of stripeProducts" [disabled]="stripeIdAssigned(price.id)"> 
                    {{ price.nickname }} ({{ price.unit_amount/100 }} {{ price.currency | uppercase }}) - {{ shortenPriceId(price.id) }} - 
                    {{ stripeIdAssigned(price.id) ? 'Assigned to ' + stripeIdAssignedTo(price.id) : '' }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <button class="!border mb-6" (click)="syncPrices()">
              <fa-icon [spin]="isSyncing" [icon]="faArrowsRotate"></fa-icon> 
            </button>
            <button class="!border mb-6" (click)="addPrice()">
              <fa-icon [spin]="isSyncing" [icon]="faPlus"></fa-icon> 
            </button>
          </div>
          <div class="border w-full bg-gray-200 rounded py-5 px-3" *ngIf="product.stripe_price_ids.length == 0">
            <div class="text-center">No prices assigned to this product</div>
          </div>
          <div class="border w-full border-gray-400 rounded py-5 px-3" *ngIf="product.stripe_price_ids.length > 0">
            <mat-chip-set aria-label="Price selection">
              <mat-chip *ngFor="let price_id of product.stripe_price_ids">
                {{ renderStripeField(price_id, 'nickname') }} ({{ renderStripeField(price_id, 'unit_amount')/100 }} {{ renderStripeField(price_id, 'currency') | uppercase }})
                <button matChipRemove (click)="removeProduct(price_id)" [attr.aria-label]="'remove ' + renderStripeField(price_id, 'nickname')">
                  <fa-icon class="mx-5" [icon]="faCircleXmark"></fa-icon>
                </button>  
              </mat-chip>
            </mat-chip-set>
          </div>
        </div>
      </div>
    </form>
    <div class="w-full">
      <h5 class="w-full font-bold mt-5 mb-3">Surveys</h5>
      <mat-label class="w-full font-bold" [matTooltip]="tooltips.surveys">Surveys in this product</mat-label>
      <div class="flex flex-wrap w-full">
        <div class="flex flex-grow w-full">
          <mat-form-field appearance=outline class="w-full mr-5">
            <mat-select 
              name="frequency"
              [aria-label]="tooltips.frequency" 
              hideSingleSelectionIndicator="false"
              [(ngModel)]="selectedSurvey"
            >
            <mat-option [value]="survey" *ngFor="let survey of surveys" [disabled]="isSurveyAssignedtoAnotherProduct(survey)"> {{ survey.name }} </mat-option>
          </mat-select>
          </mat-form-field>
          <button class="!border mb-6" (click)="addSurvey()">
            <fa-icon [spin]="isSyncing" [icon]="faPlus"></fa-icon> 
          </button>
        </div>
      </div>
      <div class="border bg-gray-200 rounded py-5 px-3" *ngIf="selectedSurveys.length == 0">
        <div class="text-center">No surveys added to this product</div>
      </div>
      <div class="border border-gray-400 rounded py-5 px-3" *ngIf="selectedSurveys.length > 0">
        <mat-chip-set aria-label="Price selection">
          <mat-chip *ngFor="let survey of selectedSurveys">
            {{ survey.name }}
            <button matChipRemove (click)="removeSurvey(survey)" [attr.aria-label]="'remove ' + survey.name">
              <fa-icon class="mx-5" [icon]="faCircleXmark"></fa-icon>
            </button>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex xs:flex-wrap float-right lg:flex-nowrap w-full">
    <button class="mt-3 btn-secondary-outline mr-3" type="button" (click)="deleteProduct()">
      <fa-icon class="text-red-400" [icon]="faTrash"></fa-icon>
    </button>
    <button class="flex flex-grow mt-3 bg-primary text-center" type="button" (click)="handleSave()">
      <span class="mx-auto">Save</span>
    </button>
  </div>
</mat-dialog-actions>