import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ManageTouchpointModalComponent } from 'src/app/modals/manage-touchpoint-modal/manage-touchpoint-modal.component';
import { TouchpointModel } from 'src/app/models/touchpoint.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { TouchpointService } from 'src/app/services/touchpoint.service';

@Component({
  selector: 'tpt-touch-point-portlet',
  templateUrl: './touch-point-portlet.component.html',
  styleUrl: './touch-point-portlet.component.scss'
})
export class TouchPointPortletComponent {
  faPlus = faPlus;
  faRefresh = faRefresh;

  public portletLoading = false;
  public touchpoints : TouchpointModel[] = [];
  private touchpoints$ : any;

  constructor(
    private router : Router,
    private modal : MatDialog,
    private touchpointService : TouchpointService
  ) { 
    this.loadSummaryData();
  }

  ngOnInit() {
    this.touchpoints$ = this.touchpointService.touchpoints.subscribe({
      next : (touchpoints) => {
        this.touchpoints = touchpoints;
        this.portletLoading = false;
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  ngOnDestroy() {
    // this.touchpoints$.unsubscribe();
  }

  reloadPortlet() {
    this.portletLoading = true;
    this.loadSummaryData();
  }

  loadSummaryData() {
    this.portletLoading = true;
    this.touchpointService.getTouchpoints(30);
  }

  /**
   * This renders the status portion of the provided status -- it's formatted like this:
   * Status-description
   * 
   * @param status 
   */
  renderStatus(status : string) {
    if(!status) { return ''; }

    let parts = status.split('-');
    return parts[0];
  }

  handleStatusClick(touchpoint : TouchpointModel) {
    if(!touchpoint || !touchpoint.id) { return; }
    if(touchpoint.status.toLowerCase().includes('complete')) { 
      this.router.navigate([`/rl/${touchpoint.question_library_id}/`], { queryParams : { filter : touchpoint.contact_name } });
    } else {
      this.openTouchpointModal(touchpoint);
    }
  }

  openTouchpointModal(touchpoint? : TouchpointModel) {
    let isNew = false;
    let isReadOnly = true;

    if(!touchpoint) {
      touchpoint = new TouchpointModel();
      isNew = true;
      isReadOnly = false;
    } else {
      isNew = false;
      let status = touchpoint.status.split('-')[0];

      switch(status) {
        case 'Ready':
        case 'Paused':
        case 'Incomplete':
        case 'Error':
          isReadOnly = false;
          break;
        case 'On Hold':
        default:
          isReadOnly = true;
          break;
      }
    }

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = { 
      touchpoint, isNew, isReadOnly
    };

    this.modal.open(ManageTouchpointModalComponent, config).afterClosed().subscribe(
      (result) => {
        console.log('touch-point-portlet.component:457875', result);
        if(result) {
          if(result.action) {
            switch(result.action) {
              case 'add':
                this.touchpointService.add(result.touchpoint).subscribe({
                  next : (response : ApiResponseModel) => {
                    console.log(response);
                  },
                  error : (error) => {
                    console.log(error);
                  },
                  complete : () => {
                    this.portletLoading = false;
                  }
                });
                break;
              case 'edit':
                this.touchpointService.update(result.touchpoint).subscribe({
                  next : (response : ApiResponseModel) => {
                    console.log(response);
                  },
                  error : (error) => {
                    console.log(error);
                  },
                  complete : () => {
                    this.portletLoading = false;
                  }
                });
                // this.touchpointService.getTouchpoints();
                break;
              case 'delete':
                this.touchpointService.delete(result.touchpoint).subscribe({
                  next : (response : ApiResponseModel) => {
                    console.log(response);
                  },
                  error : (error) => {
                    console.log(error);
                  },
                  complete : () => {
                    this.portletLoading = false;
                  }
                });
                break;
              default:
                break;
            }
          }
        }
      }
    );
  }

  getStatusTooltip(status : string) {
    if(!status) { return ''; }

    let parts = status.split('-');
    switch(parts[0]) {
      case 'Ready':
      case 'Paused':
      case 'In queue':
      case 'On Hold':
        return parts[0];
      case 'Error':
        // Split parts[1] by ',' and count the number of parts
        let errorParts = parts[1].split(',');
        console.log('touch-point-portlet.component:959031', errorParts);
        return `${errorParts.length} error(s) found`;
      case 'Complete':
        return 'View Touchpoint results';
      default:
        return '';
    }
  }
}
