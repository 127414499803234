import { Injectable } from '@angular/core';
import { ApiResponseModel, ApiService } from './api.service';
import { SurveyModel, SurveySummaryModel } from '../models/survey.model';
import { BehaviorSubject, tap, throwError } from 'rxjs';
import { QuestionTypesModel } from '../models/question_library.model';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  public surveys : BehaviorSubject<SurveyModel[]> = new BehaviorSubject<SurveyModel[]>([]);
  public surveySummaries : BehaviorSubject<SurveySummaryModel[]> = new BehaviorSubject<SurveySummaryModel[]>([]);
  public questionTypes : BehaviorSubject<QuestionTypesModel[]> = new BehaviorSubject<QuestionTypesModel[]>([]);

  constructor(
    private api : ApiService
  ) { 
    this.loadQuestionStructure();
  }

  public getSurvey(id: string) {
    return this.api.get(this.api.endpoints.surveys + '/' + id);
  }

  /**
   * This function calls : GET /v1/public/surveys/s/:ref
   * @param ref 
   * @returns 
   */
  public getSurveyByRef(ref: string) {
    return this.api.get(`${this.api.endpoints.public_surveys}/s/${ref}`);
  }

  /**
   * This function calls : GET /v1/public/surveys/i/:ref
   * @param ref 
   * @returns 
   */
  public getSurveyByInvite(ref: string) {
    return this.api.get(`${this.api.endpoints.public_surveys}/i/${ref}`);
  }

  public loadSurveys() {
    this.getSurveys().subscribe({
      next : (response) => {
        if(response.payload) {
          this.surveys.next(response.payload);
        } else {
          this.surveys.next([]);
        }
      },
      error : (error) => {
        console.log(error);
        this.surveys.next([]);
      }
    })
  }
  /**
   * This function calls : GET /v1/companies/:company_id/surveys
   */
  public getSurveys() {
    return this.api.get(this.api.endpoints.surveys).pipe(
      tap((response : ApiResponseModel) => {
        if(response.payload) {
          this.surveys.next(response.payload);
        } else {
          this.surveys.next([]);
        }
      })
    );
  }

  public publishSurvey(survey : SurveyModel) {
    return this.api.put(`${this.api.endpoints.surveys}/${survey.id}/publish`, survey);
  }

  /**
   * This function calls : POST /v1/surveys/:survey_ref/i/:invite_ref/start
   * @param id 
   */
  public startSurvey(survey_ref: string, invite_ref: string) {
    return this.api.post(`${this.api.endpoints.surveys}/${survey_ref}/i/${invite_ref}/start`, {});
  }

  public loadSurveySummaries() {
    this.getSurveySummaries().subscribe({
      next : (response) => {
        if(response.payload) {
          this.surveySummaries.next(response.payload);
        } else {
          this.surveySummaries.next([]);
        }
      },
      error : (error) => {
        console.log(error);
        this.surveySummaries.next([]);
      }
    });
  }

  public getSurveySummaries(limit? : string) {
    let url = `${this.api.endpoints.surveys}?summary=true`;
    if(limit) {
      url += `&limit=${limit}`;
    }

    return this.api.get(url).pipe(
      tap((response : ApiResponseModel) => {
        if(response.payload) {
          this.surveySummaries.next(response.payload);
        } else {
          this.surveySummaries.next([]);
        }
      })
    );
  }

  public getSurveyList(limit? : string) {
    let url = `${this.api.endpoints.surveys}?list=true`;
    if(limit) {
      url += `&limit=${limit}`;
    }

    return this.api.get(url);
  }

  public createSurvey(survey : SurveyModel) {
    try {
      return this.api.post(this.api.endpoints.surveys, survey).pipe(
        tap((response : ApiResponseModel) => {
          if(response.payload) {
            let surveys = this.surveys.value;
            surveys.push(response.payload);
            this.surveys.next(surveys);
          }
        })
      );
    } catch(error) {
      console.log(error);
      return throwError(() => error);
    }
  }

  public updateSurvey(survey : SurveyModel) {
    this.api.put(`${this.api.endpoints.surveys}/${survey.id}`, survey).pipe(
      tap((response : ApiResponseModel) => {
        if(response.payload) {
          let surveys = this.surveys.value;
          let index = surveys.findIndex((s) => s.id === survey.id);
          surveys[index] = response.payload;
          this.surveys.next(surveys);
        }
      })
    );
  }

  public deleteSurvey(survey : SurveyModel) {
    this.api.delete(`${this.api.endpoints.surveys}/${survey.id}`, survey).pipe(
      tap((response : ApiResponseModel) => {
        if(response.payload) {
          let surveys = this.surveys.value;
          let index = surveys.findIndex((s) => s.id === survey.id);
          surveys.splice(index, 1);
          this.surveys.next(surveys);
        }
      })
    );
  }

  /**
   * Sends a request to the API to upload a file (blob) to S3
   * @param survey_id 
   * @param file 
   */
  public uploadSurveyAttachment(survey_id : string, file : any) {
    console.log('surveys.service:452025', survey_id, file);
    return this.api.post(`${this.api.endpoints.surveys}/${survey_id}/attachments`, file);
  }

  public getSurveyAssistance(prompt : { prompt: string, number_of_questions: number }) {
    return this.api.post(this.api.endpoints.survey_ai, prompt);
  }

  public loadQuestionStructure() {
    try {
      this.api.get(this.api.endpoints.question_structure + '?type=survey').subscribe({
        next : (data) => {
          this.questionTypes.next(data.payload.types);
          // console.log('surveys.service:383366', this.questionTypes.value);
        },
        error : (error) => {
          console.error('question-editor.component:081', error);
        }
      });
    } catch (error) {
      console.error('question-editor.component:085', error);
    }
  }
}
