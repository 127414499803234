import { QuestionLibraryModel } from "./question_library.model";
import { SurveyConfigModel, SurveyModel } from "./survey.model";
import { TouchpointModel } from "./touchpoint.model";

export class ResponsesViewModel {
  id : string | null;
  company_id : string | null;
  cohort_id? : string | null;
  contact_id? : string | null;
  service : string | null;
  touchpoint_id : string | null;
  survey_id : string | null;
  question_library_id : string | null;
  contact_name : string | null;
  cohort_name : string | null;
  contact_company_name : string | null;
  recorded_date : string | null;
  ql : string | null;
  responses : ResponseContentModel[] | null;
  touchpoint? : TouchpointModel | null;
  question_library : QuestionLibraryModel | null;
  survey? : SurveyModel | null;
  survey_name? : string;

  constructor() {
    this.id = null;
    this.company_id = null;
    this.service = null;
    this.touchpoint_id = null;
    this.survey_id = null;
    this.question_library_id = null;
    this.contact_name = null;
    this.cohort_name = null;
    this.contact_company_name = null;
    this.recorded_date = null;
    this.ql = null;
    this.responses = [];
    this.question_library = new QuestionLibraryModel();
  }
}

export class ResponsesModel {
  id : string | null;
  touchpoint_id : string | null;
  agent_id : string | null;
  contact_id? : string;
  cohort_id? : string;
  question_library? : QuestionLibraryModel | null;
  question_library_id : string | null;
  content : ResponseContentModel[] | null;
  recorded_date : string | null;
  touchpoint? : TouchpointModel | null;

  constructor() {
    this.id = null;
    this.touchpoint_id = null;
    this.agent_id = null;
    this.question_library_id = null;
    this.content = [];
    this.recorded_date = null;
  }
}

export class ResponseContentModel {
  question_id : string | null;
  question? : string;
  description? : string;
  answer_id : string | null;
  incorrect_message? : string;
  answer : any | null;
  extra? : string | null;
  score? : number;

  constructor() {
    this.question_id = null;
    this.answer_id = null;
    this.answer = null;
  }
}

export class SurveyResponsesModel {
  id : string | null;
  is_scored? : boolean;
  survey_id : string | null;
  survey_participant_id : string | null;
  survey_session_id? : string | null;
  cohort_id? : string | null;
  contact_id? : string | null;
  score : number;
  content : ResponseContentModel[] = [];
  recorded_date : string | null;
  survey? : SurveyModel;
  survey_name? : string;
  max_score? : number;
  config? : SurveyConfigModel;

  constructor() {
    this.id = null;
    this.survey_id = null;
    this.survey_participant_id = null;
    this.score = 0;
    this.content = [];
    this.recorded_date = null;
  }
}