import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SurveyModel } from 'src/app/models/survey.model';

@Component({
  selector: 'app-manage-survey-modal',
  templateUrl: './manage-survey-modal.component.html',
  styleUrl: './manage-survey-modal.component.scss'
})
export class ManageSurveyModalComponent {

  public frmAddSurvey : FormGroup = new FormGroup({});
  public tooltips = {
    type : 'For anonymous surveys, you will receive the responses, but the responses will not be linked to the participant.',
    participants : 'For invite-only surveys, only those who have received invites can respond. Anyone can respond to surveys marked Public.',
    availability : 'The start and end date of when the survey will be available to participants. Leave blank for no end date.'
  }
  
  constructor(
    private dialogRef : MatDialogRef<ManageSurveyModalComponent>,
  ) {
    this.frmAddSurvey = new FormGroup({
      name : new FormControl('', [Validators.required]),
      welcome : new FormControl(''),
      // is_anonymous : new FormControl(false),
      // invite_only : new FormControl(false),
      // start : new FormControl(''),
      // end : new FormControl(''),
    });
  }

  get start() { return this.frmAddSurvey.get('start'); }
  get end() { return this.frmAddSurvey.get('end'); }


  canSave() : boolean {
    return this.frmAddSurvey.valid;
  }

  save() {
    console.log('manage-survey-modal.component:043', 'save');

    let survey = new SurveyModel();
    
    survey.name = this.frmAddSurvey.get('name')?.value;
    survey.welcome = this.frmAddSurvey.get('welcome')?.value;
    survey.is_anonymous = this.frmAddSurvey.get('is_anonymous')?.value;
    survey.is_public = !this.frmAddSurvey.get('invite_only')?.value;
    survey.start = this.frmAddSurvey.get('start')?.value || null;
    survey.end = this.frmAddSurvey.get('end')?.value || null;

    this.dialogRef.close({
      action : 'add',
      survey
    });
  }

  close() {
    this.dialogRef.close();
  }
}
