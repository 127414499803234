<tpt-portlet>
  <div portlet-header>
    <div class="grid grid-cols-12">
      <div class="col-span-6 cursor-pointer" (click)="goToCohort(cohort)">
        <h2>{{ cohort.name }}</h2>
      </div>
      <div class="col-span-6 text-right">
        <button class="form-button bg-white text-gray-600" matTooltip="Refresh Survey List" (click)="reloadPortlet()">
          <div [ngClass]="{ 'animate-spin' : portletLoading }">
            <fa-icon [icon]="faRefresh"></fa-icon>
          </div>
        </button>
        <button class="form-button ml-3 bg-white text-gray-600" matTooltip="Create Survey" (click)="openManageContactModal()">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div portlet-body>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element" (click)="goToContact(element)">{{ renderName(element) }}</td>
      </ng-container>
    
      <ng-container matColumnDef="surveys">
        <th mat-header-cell *matHeaderCellDef class="!text-center">Responses</th>
        <td mat-cell class="!text-center" *matCellDef="let element" (click)="goToContact(element)">
          <span class="text-gray-500">{{ element.survey_response_count ? element.survey_response_count : 0 }}</span> / <span class="text-gray-500">{{ element.survey_participant_count ? element.survey_participant_count : 0 }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="avg_score">
        <th mat-header-cell class="!text-center" *matHeaderCellDef>Average Score</th>
        <td mat-cell class="!text-center" *matCellDef="let element" (click)="goToContact(element)">{{ renderScore(element.average_score) }}</td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer"></tr>
      <tr class="mat-row" *matNoDataRow>
        <ng-container *ngIf="portletLoading">
          <td [attr.colspan]="displayedColumns.length" class="py-5">
            <mat-progress-spinner color="primary" diameter="24" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
          </td>
        </ng-container>
        <ng-container *ngIf="!portletLoading">
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="text-center my-10" *ngIf="isFiltered()">
              <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
            </div>
            <div class="text-center my-10" *ngIf="!isFiltered()">
              <h4 class="text-center mt-10 mb-5">No cohort members found</h4>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  </div>
</tpt-portlet>