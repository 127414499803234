import { Component } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {
  public selectedIndex : number = 0;

  constructor() { }

  ngOnInit(): void {
    let index = localStorage.getItem('settingsTabIndex');
    if(index) {
      this.selectedIndex = parseInt(index);
    }
  }
  
  public selectTab(event : any) {
    this.selectedIndex = event.index;
    localStorage.setItem('settingsTabIndex', this.selectedIndex.toString());
  }
}
