import { Component, Inject, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, injectStripe } from 'ngx-stripe';
import { mergeMap, throwError } from 'rxjs';
import { CardDetails } from 'src/app/components/credit-card/credit-card.component';
import { PlansModel } from 'src/app/models/plans.model';
import { ApiService } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plan-signup-modal',
  templateUrl: './plan-signup-modal.component.html',
  styleUrl: './plan-signup-modal.component.scss'
})
export class PlanSignupModalComponent {
  @ViewChild(StripeCardComponent) cardElement!: StripeCardComponent;
  
  stripe = injectStripe(environment.stripe.publishableKey);
  paying = signal(false);

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  countries = [
    { id : 'ca', name : 'Canada' },
    { id : 'us', name : 'United States' },
  ];

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  private plans : PlansModel[] = [];
  public selectedPlan: PlansModel | null = null;
  public currentPlan : PlansModel | null = null;
  public frmPlanSelect : FormGroup = new FormGroup({});
  public frmStripe : FormGroup = new FormGroup({});
  private elementsValid : boolean = false;

  public paymentInProgress : boolean = false;

  constructor(
    private api : ApiService,
    private dialogRef: MatDialogRef<PlanSignupModalComponent>,
    private dss : DataSharingService,
    private subscriptionService : SubscriptionService,
    private snackbar : MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

    this.subscriptionService.plans.subscribe({
      next : (plans : any) => {
        if(plans) {
          this.plans = plans;

          if(this.plans.length > 0) {
            // TODO
            this.selectedPlan = this.plans[0];
            console.log('plan-signup-modal.component:338659', this.selectedPlan);
          }
        }
      },
      error : (error) => {
        console.log('Error', error);
      }
    });

    this.frmPlanSelect = new FormGroup({
      'plan' : new FormControl('', [Validators.required]),
    });

    this.frmStripe = new FormGroup({
      'cardholder' : new FormControl('', [Validators.required]),
      'addrl1' : new FormControl('', [Validators.required]),
      'city' : new FormControl('', [Validators.required]),
      'state' : new FormControl('', [Validators.required]),
      'postal' : new FormControl('', [Validators.required]),
      'country' : new FormControl('', [Validators.required])
    });

    if(data && data.plan) {
      console.log('plan-signup-modal.component:018495', data.plan);
      this.currentPlan = data.plan;
      this.selectedPlan = data.plan;
    }

    console.log('plan-signup-modal.component:781322', this.selectedPlan);
  }

  closeModal() {
    console.log('Cancel');
    this.dialogRef.close();
  }

  confirm() {
    console.log('Confirm');
    this.dialogRef.close();
  }

  /**
   * Called when the onPlanSelected event is emitted from the plan-select component
   * 
   * @param plan 
   */
  planSelected(plan: PlansModel | null) {
    console.log('plan-signup-modal.component:042267', plan);
    if(!plan) {
      this.selectedPlan = null;
      this.frmPlanSelect.get('plan')?.setValue(null);
    } else {
      this.selectedPlan = plan;
      this.frmPlanSelect.get('plan')?.setValue(plan.id);
    }
  }

  completePayment() {
    try {
      let card = this.cardElement.element;
      this.paymentInProgress = true;
      this.stripe.createToken(card, { name: this.frmStripe.get('cardholder')?.value }).pipe(
        mergeMap(
          (result) => {
            if(result && result.error) {
              return throwError(() => result.error);
            } else {
              if(result && result.token) {
                let payload = {
                  token : result.token.id,
                  stripe_price_id : this.selectedPlan?.stripe_price_id,
                  address : {
                    line1 : this.frmStripe.get('addrl1')?.value,
                    city : this.frmStripe.get('city')?.value,
                    state : this.frmStripe.get('state')?.value,
                    postal_code : this.frmStripe.get('postal')?.value,
                    country : this.frmStripe.get('country')?.value
                  }
                };
                return this.api.post(this.api.endpoints.subscriptions, payload);
              }
            }

            return throwError(() => new Error("Could not complete payment."));
        })
      ).subscribe({
        next: (result) => {
          console.log('plan-signup-modal.component:795319', result);
          if(result) {
            this.snackbar.open("Congratulations! You're all signed up.", 'Dismiss', { duration: 5000 });
            this.subscriptionService.subscription.next(result.payload.subscription);
            this.subscriptionService.updateCredits(result.payload.credits);
            this.dialogRef.close();
            this.paymentInProgress = false;
          } else if(result.error) {
            console.log('Error', result.error);
            this.snackbar.open('An error occurred while processing your payment', 'Dismiss', { duration: 5000 });
            this.paymentInProgress = false;
          }
        },
        error: (error) => {
          console.log('Error', error);
          this.snackbar.open('An error occurred while processing your payment', 'Dismiss', { duration: 5000 });
          this.paymentInProgress = false;
        }
      });
    } catch (error) {
      console.log('Error', error);
      this.snackbar.open('An error occurred while processing your payment', 'Dismiss', { duration: 5000 });
      this.paymentInProgress = false;
    }
  }

  step2Valid() {
    return this.frmStripe.valid && this.elementsValid;
  }
  
  onElementsChange(event: StripeCardElementChangeEvent) {
    // console.log('plan-signup-modal.component:641514', event);
    this.elementsValid = event.complete;
  }
}
