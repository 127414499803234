import { Injectable, inject } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut, confirmPasswordReset } from '@angular/fire/auth';
import { BehaviorSubject, catchError, from, mergeMap, Observable, of, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { DataSharingService } from './data-sharing.service';
import { AuthModel, AuthTokenModel } from '../models/auth.model';
import { UsersModel } from '../models/users.model';
import { CompaniesModel } from '../models/companies.model';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api = inject(ApiService);
  company = inject(CompanyService);
  dss = inject(DataSharingService);
  auth = inject(Auth);

  public user : BehaviorSubject<UsersModel | null> = new BehaviorSubject<UsersModel | null>(new UsersModel());
  public tokens : BehaviorSubject<AuthTokenModel | null> = new BehaviorSubject<AuthTokenModel | null>(new AuthTokenModel());

  constructor() { 
    this.loadFromLocalStorage();
  }

  /**
   * This function creates an account in Firebase. It also updates the displayName of the user.
   * 
   * @param email 
   * @param password 
   * @param firstName 
   * @param lastName 
   * @returns 
   */
  createAccount(email : string, password : string, firstName : string, lastName : string) {
    return createUserWithEmailAndPassword(this.auth, email, password).then(
      (user) => {
        updateProfile(user.user, { displayName: `${firstName} ${lastName}` });
        return user;
      }
    );
  }

  signin(email : string, password : string) {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      mergeMap((res) => {
        // console.log('auth.service:410038', res);
        return from(res.user.getIdToken());
      }),
      mergeMap((res) => {
        // console.log('auth.service:981012', res);
        return this.api.post(this.api.endpoints.signin, { token : res })
      }),
      catchError((err) => {
        return throwError(() => err);
      }),
    );
  }

  /**
   * Signs out from Firebase and then signs out from the API
   * @returns Observable<any>
   */
  signout() {
    // console.log('auth.service:352964', this.auth);
    return from(signOut(this.auth)).pipe(
      mergeMap((res) => {
          return this.api.post(this.api.endpoints.signout, {})
      }),
      mergeMap((res) => {
        // console.log('auth.service:950101', res);
        this.dss.clearAuth();
        this.company.clear();
        return of(res);
      })
    )
  }

  confirmPasswordReset(oobCode : string, newPassword : string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  loadFromLocalStorage() {
    let tokens = localStorage.getItem('tokens'); 
    if(tokens) {
      this.tokens.next(JSON.parse(tokens));
    }

    let user = localStorage.getItem('user');
    if(user) {
      let u = JSON.parse(user);
      this.user.next(u);
    }
  }

  saveAuth(authData : AuthModel) {
    // console.log('data-sharing.service:912639', authData);
    // Save the tokens and user data to local storage
    if(authData.tokens) {
      // console.log('data-sharing.service:479098', authData.tokens);
      this.tokens.next(authData.tokens);
      localStorage.setItem('tokens', JSON.stringify(authData.tokens));
    }
    if(authData.user) {
      this.saveUser(authData.user);
      if(authData.user.user_roles && authData.user.user_roles.length > 0) {
        // Use the company from the first role -- multiple roles are not supported yet
        if(authData.user.user_roles[0].company) {
          this.company.save(authData.user.user_roles[0].company);
        }
      }
    }
  }

  clearAuth() {
    this.user.next(null);
    this.tokens.next(null);
  }

  saveOnboardingProgress(onboardingComplete : boolean, onboardingProgress : number) {
    this.dss.saveOnboardingProgress(onboardingComplete, onboardingProgress);
  }

  saveUser(user : UsersModel) {
    this.user.next(user);
    localStorage.setItem('user', JSON.stringify(user));

    if(user.onboarding_complete != null && user.onboarding_progress != null) {
      this.saveOnboardingProgress(user.onboarding_complete, user.onboarding_progress);
    }
  }
}
