<mat-dialog-content>
  <div mat-dialog-title class="uppercase font-bold px-0">Add Question Set</div>
  <div class="my-10">
    <form class="grid grid-cols-12 gap-4" [formGroup]="frmAddQuestionLibrary">
      <div class="col-span-12">
        <mat-label class="font-bold">Name</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <input matInput placeholder="Enter a name" formControlName="libraryName">
        </mat-form-field>
      </div>
      <div class="col-span-12">
        <mat-label class="font-bold">How should we begin?</mat-label>
        <mat-radio-group class="w-full flex" aria-labelledby="feedback-label" formControlName="action" (change)="changeAction($event)">
          @for (option of addOptions; track option) {
            <mat-radio-button [value]="option.value">{{option.label}}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
      <div class="col-span-12" *ngIf="showTemplatePicker">
        <mat-label class="font-bold">What kind of questions are you planning to ask?</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <mat-select [value]="questionLibrary.category" formControlName="category" (selectionChange)="changeSelectedCategory($event)">
            @for (category of questionCategories; track category) {
              <mat-option [value]="category.id">{{category.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-12" *ngIf="selectedCategory">
        <mat-label class="font-bold">Here are some templates you can choose from</mat-label>
        <mat-form-field appearance=outline class="w-full">
          <mat-select [value]="questionLibrary.category" formControlName="category" (selectionChange)="changeSelectedTemplate($event)">
            @for (template of filterQuestionLibraryTemplates(); track template) {
              <mat-option [value]="template.id">{{template.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-12 mb-5" *ngIf="selectedTemplate">
        <div class="w-full" *ngIf="getSelectedTemplate()">
          <div class="font-bold">Questions</div>
          <ul class="w-full">
            <li *ngFor="let q of getSelectedTemplate().questions">{{ q.question }}</li>
          </ul>
        </div>
        <div class="w-full" *ngIf="!getSelectedTemplate()">
          <div class="font-bold">Questions</div>
          <div>No questions defined</div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-left w-1/2 pl-5">
  </div>
  <div class="float-right w-1/2 text-right">
    <button class="btn-secondary-outline mr-3" (click)="close()" type="button">Close</button>
    <button class="bg-primary mr-3" data-dismiss="modal" type="button" [disabled]="!canSave()" (click)="save()">Save</button>
  </div>
</mat-dialog-actions>