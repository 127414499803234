import { CanActivateFn, Router } from '@angular/router';
import { DataSharingService } from '../services/data-sharing.service';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  // const dss = inject(DataSharingService);
  const auth = inject(AuthService);
  const router = inject(Router);
  let user = auth.user.value;
  if(user && user.uid) {
    return true;
  } else {
    router.navigate(['/signin']);
    return false;
  }
};
