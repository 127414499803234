import { UserRolesModel } from "./user-roles-model";

export class UsersModel {
  id : string | null;
  uid : string | null;
  first_name : string | null;
  last_name : string | null;
  email : string | null;
  api_key : string | null;
  onboarding_complete? : boolean;
  onboarding_progress? : number;
  user_roles? : UserRolesModel[];
  card? : any;

  constructor() {
    this.id = null;
    this.uid = null;
    this.first_name = null;
    this.last_name = null;
    this.api_key = null;
    this.email = null;
    this.onboarding_complete = false;
    this.onboarding_progress = 0;
  }
}
