import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faCopy, faArrowLeft, faArrowUp, faArrowDown, faFloppyDisk, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { CompaniesModel } from 'src/app/models/companies.model';
import { QuestionCategoriesModel, QuestionLibraryModel, QuestionTypesModel, QuestionsModel } from 'src/app/models/question_library.model';
import { ApiService } from 'src/app/services/api.service';
import { CompanyService } from 'src/app/services/company.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-question-editor',
  templateUrl: './question-editor.component.html',
  styleUrl: './question-editor.component.scss'
})
export class QuestionEditorComponent implements OnInit {
  api = inject(ApiService);
  activatedRoute = inject(ActivatedRoute);
  companyService = inject(CompanyService);
  dss = inject(DataSharingService);
  fb = inject(FormBuilder);
  modal = inject(MatDialog);
  router = inject(Router);
  snackbar = inject(MatSnackBar);

  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faArrowLeft = faArrowLeft;
  faCopy = faCopy;
  faFloppyDisk = faFloppyDisk;
  faPlus = faPlus;
  faTrash = faTrash;

  public frmQuestionSetEditor : FormGroup = new FormGroup({});
  public questionId : string = '';
  public currentQuestionSet : QuestionLibraryModel | null = null;
  
  private company : CompaniesModel | null = new CompaniesModel();
  public questionTypes : QuestionTypesModel[] = [];
  // public questionCategories : QuestionCategoriesModel[] = [];

  constructor() { 
    this.activatedRoute.params.subscribe((params) => {
      this.questionId = params['id'];
    });

    this.frmQuestionSetEditor = this.fb.group({
      // category: ['', Validators.required],
      name: ['', Validators.required],
      questions: this.fb.array([])
    }); 
  }

  
  ngOnInit(): void { 
    this.loadQuestionStructure();
    this.loadQuestionSet();

    this.company = this.companyService.company.value;
  }
  
  get questions() { return this.frmQuestionSetEditor.get('questions') as FormArray; }

  loadQuestionSet() {
    try {
      if(this.questionId) {
        // First look in DataSharingService
        let questionLibrary = this.dss.questionLibrary.value;
        if (questionLibrary && questionLibrary.length > 0) {
          this.currentQuestionSet = questionLibrary.find((question) => question.id === this.questionId) || null;
          console.log('question-editor.component:474237', this.currentQuestionSet);
  
          this.frmQuestionSetEditor.patchValue({
            // category: this.currentQuestionSet?.category || '',
            name: this.currentQuestionSet?.name || '',
            questions: this.currentQuestionSet?.questions || []
          });
  
          if(this.currentQuestionSet?.questions && this.currentQuestionSet?.questions.length == 0) {
            this.currentQuestionSet.questions.push(new QuestionsModel());
          }

          this.prepareQuestionArray();
  
        } else {
          // If not found in DataSharingService, make an API call
          this.api.get(this.api.endpoints.question_library + '/' + this.questionId).subscribe({
            next: (data) => {
              // If found, update the currentQuestionSet
              if (data.payload) {
                this.currentQuestionSet = data.payload;
              } else {
                // If not found, create a new QuestionLibraryModel
                this.currentQuestionSet = new QuestionLibraryModel();
              }
  
              // Update the form
              // console.log('question-editor.component:798063', this.currentQuestionSet);
              this.frmQuestionSetEditor.patchValue({
                // category: this.currentQuestionSet?.category || '',
                name: this.currentQuestionSet?.name || '',
                questions: []
              });
              
              // Add the question portion of the form
              if(this.currentQuestionSet?.questions && this.currentQuestionSet?.questions.length == 0) {
                this.currentQuestionSet.questions.push(new QuestionsModel());
              }

              if(this.currentQuestionSet) {
                this.prepareQuestionArray();
              }
            },
            error: (error) => {
              console.error('question-editor.component:081', error);
            }
          });
        }
      } else {
        this.currentQuestionSet = new QuestionLibraryModel();
      }
    } catch (error) {
      console.error('question-editor.component:085', error);
    }
  }

  loadQuestionStructure() {
    try {
      let params = {
        company_id: this.company!.id,
      };    
      this.api.get(this.api.endpoints.question_structure, { params }).subscribe({
        next : (data) => {
          if(data.payload) {
            // console.log('question-editor.component:820997', data.payload);
            this.questionTypes = data.payload.types;
            // this.questionCategories = data.payload.categories;
          }
        },
        error : (error) => {
          console.error('question-editor.component:081', error);
        }
      });
    } catch (error) {
      console.error('question-editor.component:085', error);
    }
  }

  prepareQuestionArray() {
    if(this.currentQuestionSet && this.currentQuestionSet.questions) {
      // console.log('question-editor.component:327292', this.currentQuestionSet.questions);
      this.currentQuestionSet.questions.forEach((question) => {
        this.addQuestion(question);
      });
    }
  }
  
  addQuestion(question? : QuestionsModel) {
    try {
      // Prepare the question array; include validators
      if(!question) {
        question = new QuestionsModel();

      // Add the question to the currentQuestionSet
      // if the currentQuestionSet is not null and the questions array is not null
      if(!this.currentQuestionSet!.questions) {
        this.currentQuestionSet!.questions = [];
      }
      this.currentQuestionSet!.questions.push(question);
      }

      let frmQuestion = this.fb.group({
        type: [question ? question.field_type : '', Validators.required],
        question: [question ? question.question : '', Validators.required],
        options: question ? question.field_options : '',
        allowElaborate : question ? question.allow_elaborate : false
      });
      
      this.questions.push(frmQuestion); // Add the question form to the question array

    } catch (error) {
      console.error('question-editor.component:081', error);
    }
  }

  handleAddQuestion() {
    this.addQuestion();
    this.frmQuestionSetEditor.markAsDirty();
  }

  navToQuestionLibrary() {
    // If dirty & valid; send to the api and update the DataSharingService
    if(this.frmQuestionSetEditor.dirty && this.frmQuestionSetEditor.valid) {
      this.api.put(this.api.endpoints.question_library + '/' + this.questionId, this.currentQuestionSet).subscribe({
        next : (response : any) => {
          // Update the DataSharingService by finding the question set and updating it
          let questionLibrary = this.dss.questionLibrary.value;
          let index = questionLibrary.findIndex((question) => question.id === this.questionId);
          questionLibrary[index] = this.currentQuestionSet!;
          this.dss.questionLibrary.next(questionLibrary);

          // Navigate
          this.router.navigate(['/qlt']);
        },
        error : (error) => {
          console.error('question-editor.component:081', error);
          this.snackbar.open('Error saving question set', 'Dismiss', { duration: 3000 });
        }
      });
    } else if(this.frmQuestionSetEditor.dirty && !this.frmQuestionSetEditor.valid) {
      // If dirty & invalid; show a snackbar message and don't navigate
      let config = new MatDialogConfig();
      config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
      config.data = {
        title: 'Please confirm',
        message: "Are you sure you'd like to leave this page? Your changes will not be saved.",
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      };
      
      this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
        if(result) {
          this.router.navigate(['/qlt']);
        }
      });
    } else {
      // Otherwise... just navigate
      this.router.navigate(['/qlt']);
    }
  }

  saveQuestionSet() {
    try {
      // console.log('question-editor.component:955806', 'save question', this.currentQuestionSet);
      this.api.put(this.api.endpoints.question_library + '/' + this.questionId, this.currentQuestionSet).subscribe({
        next : (response : any) => {
          // console.log('question-editor.component:228606', response);
          this.frmQuestionSetEditor.markAsPristine();
        },
        error : (error) => {
          console.error('question-editor.component:081', error);
          this.snackbar.open('Error saving question set', 'Dismiss', { duration: 3000 });
        }
      });
    } catch (error) {
      console.error('question-editor.component:085', error);
    }
  }

  deleteQuestionSet() {
    // console.log('question-editor.component:851127', 'delete question');
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: 'Are you sure you want to delete this question set?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        // console.log('question-editor.component:721732', 'delete question confirmed');
        this.api.delete((this.api.endpoints.question_library + '/' + this.questionId)).subscribe({
          next : (response : any) => {
            console.log('question-editor.component:501391', response);
            this.router.navigate(['/qlt']);
          },
          error : (error) => {
            console.error('question-editor.component:081', error);
            this.snackbar.open('Error deleting question', 'Dismiss', { duration: 3000 });
          }
        });
      }
    });
  }

  deleteQuestion(i : number) {
    // console.log('question-editor.component:966151', 'delete question', i, '\nCurrentQuestion\n', this.currentQuestionSet, '\nFormArray\n', this.frmQuestionSetEditor.get('questions'));
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      title: 'Please confirm',
      message: 'Are you sure you want to delete this question?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        // Confirmed; delete the question at the FormArray index and the matching currentQuestionSet.questions entry
        // console.log('question-editor.component:222463', 'delete question confirmed');
        let questionArray = this.frmQuestionSetEditor.get('questions') as FormArray;
        questionArray.removeAt(i);
        this.currentQuestionSet!.questions!.splice(i, 1);
        console.log('question-editor.component:953959', this.currentQuestionSet?.questions);
      }
    });
  }

  updateField(i : number, field : string, event : any) {
    // console.log('question-editor.component:623206', 'update field', i, field, this.currentQuestionSet!.questions?.length, this.currentQuestionSet!.questions![i]);
    let question = this.currentQuestionSet!.questions![i];

    switch(field) {
      case 'type':
        question.field_type = event.value;
        break;
      case 'question':
        question.question = event.target.value;
        break;
      case 'options':
        question.field_options = event.target.value;
        break;
      case 'allowElaborate':
        question.allow_elaborate = event.checked;
        break;
    }
  }

  getFieldValue(i : number, field : string) {
    if(!this.currentQuestionSet?.questions) return null;

    let question = this.currentQuestionSet?.questions[i];
    if(question) {
      switch(field) {
        case 'type':
          return question.field_type;
        case 'question':
          return question.question;
        case 'options':
          return question.field_options;
        case 'allowElaborate':
          return question.allow_elaborate;
        default :
          return null;
      }
    }
    return null;
  }

  showOptions(i : number) {
    if(!this.currentQuestionSet?.questions) return false;
    let question = this.currentQuestionSet?.questions[i];
    return (question?.field_type === 'multiple' || question?.field_type === 'radio' || question?.field_type === 'dropdown');
  }

  moveUp(i : number) {
    // console.log('question-editor.component:492807', 'move up', i);
    let questionArray = this.frmQuestionSetEditor.get('questions') as FormArray;
    let question = questionArray.at(i);
    questionArray.removeAt(i);
    questionArray.insert(i - 1, question);

    // Reflect the change in the currentQuestionSet.questions array
    let questionSet = this.currentQuestionSet?.questions;
    let q = questionSet?.splice(i, 1);

    if(q) questionSet?.splice(i - 1, 0, q[0]);
  }

  moveDown(i : number) {
    // console.log('question-editor.component:317932', 'move down', i);
    let questionArray = this.frmQuestionSetEditor.get('questions') as FormArray;
    let question = questionArray.at(i);
    questionArray.removeAt(i);
    questionArray.insert(i + 1, question);

    // Reflect the change in the currentQuestionSet.questions array
    let questionSet = this.currentQuestionSet?.questions;
    let q = questionSet?.splice(i, 1);
    if(q) questionSet?.splice(i + 1, 0, q[0]);
  }

  enableSaveButton() {
    return (this.frmQuestionSetEditor.dirty && this.frmQuestionSetEditor.valid);
  }

  returnNumberofQuestions() {
    return this.currentQuestionSet?.questions?.length || 0;
  }

  returnShortID() {
    return this.currentQuestionSet?.short_id || '';
  }

  /**
   * This function copies the question set ID to the clipboard
   */
  copyQuestionShortID() {
    navigator.clipboard.writeText(this.returnShortID()).then(() => {
      this.snackbar.open('Question Set ID copied to clipboard', 'Dismiss', { duration: 3000 });
    });
  }
}
