import { inject, Injectable } from '@angular/core';
import { CompaniesModel } from '../models/companies.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { DataSharingService } from './data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  api = inject(ApiService);
  dss = inject(DataSharingService);

  public company : BehaviorSubject<CompaniesModel | null> = new BehaviorSubject<CompaniesModel | null>(new CompaniesModel());

  constructor() { 
    this.loadFromLocalStorage();
  }

  update(company : CompaniesModel) {
    return this.api.put(this.api.endpoints.companies + '/' + company.id, company);
  }

  save(company : CompaniesModel) {
    this.company.next(company);
    this.dss.company.next(company);
    localStorage.setItem('company', JSON.stringify(company));
  }

  clear() {
    this.company.next(null);
    localStorage.removeItem('company');
  }

  loadFromLocalStorage() {
    let company = localStorage.getItem('company');
    if(company) {
      this.company.next(JSON.parse(company));
      this.dss.company.next(JSON.parse(company));
    }
  }

}
