<!-- Page Title -->
<div class="w-full mb-5">
  <h1>Cohorts</h1>
</div>

<!-- Page Content -->
  <!-- Filter Section -->
  <section class="flex items-center mb-10">
    <div class="w-full text-right flex">
      <mat-form-field appearance=outline class="searchInput flex flex-grow">
        <input matInput [(ngModel)]="cohortFilter" placeholder="Filter Cohorts" (keyup)="updateCohortFilter()">
        <fa-icon matSuffix class="pr-5" [icon]="faSearch"></fa-icon>
      </mat-form-field>
      <button class="flex ml-5 !py-5 px-6 mb-6" matTooltip="Add Cohort" (click)="openManageCohortModal()"><fa-icon [icon]="faPlus"></fa-icon></button>
    </div>
  </section>

  <!-- Table Section // Plan users only -->
  <table mat-table [dataSource]="dataSource" class="">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" (click)="openManageCohortModal(element)">{{ element.name }}</td>
    </ng-container>
  
    <ng-container matColumnDef="members">
      <th mat-header-cell *matHeaderCellDef>Members</th>
      <td mat-cell *matCellDef="let element" (click)="goToCohort(element)">{{ element.contact_count }}</td>
    </ng-container>

    <ng-container matColumnDef="pinned">
      <th mat-header-cell *matHeaderCellDef class="!text-right">Pin to Dashboard</th>
      <td mat-cell *matCellDef="let element" class="!text-right">
        <fa-icon class="text-yellow-400" (click)="togglePin(element)" *ngIf="isPinned(element)" [icon]="faStar"></fa-icon>
        <fa-icon class="text-gray-500" (click)="togglePin(element)" *ngIf="!isPinned(element)" [icon]="faStar"></fa-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="select-none"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="portlet-table-row select-none cursor-pointer"></tr>
    <tr class="mat-row" *matNoDataRow>
      <ng-container *ngIf="isLoading">
        <td [attr.colspan]="displayedColumns.length" class="py-5">
          <mat-progress-spinner color="primary" diameter="24" mode="indeterminate" class="my-0 mx-auto"></mat-progress-spinner>
        </td>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="text-center my-10" *ngIf="isFiltered()">
            <h4>No responses found matching the filter "{{dataSource.filter}}"</h4>
          </div>
          <div class="text-center my-10" *ngIf="!isFiltered()">
            <h4 class="text-center mt-10 mb-5">No surveys found</h4>
          </div>
        </td>
      </ng-container>
    </tr>
  </table>

  <!-- Pagination Section -->
  <section class="flex items-center justify-between">
    <div class="w-full pt-5">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </section>