import { UsersModel } from "./users.model";

export class AuthModel {
  tokens : AuthTokenModel;
  user : UsersModel;

  constructor() {
    this.tokens = new AuthTokenModel();
    this.user = new UsersModel();
  }
}

export class AuthTokenModel {
  bearer_token : string | null;
  refresh_token : string | null;

  constructor(bearer_token : string | null = null, refresh_token : string | null = null) {
    this.bearer_token = bearer_token;
    this.refresh_token = refresh_token;
  }
}