<div class="portlet">
    <div class="portlet-header">
        <ng-content select="[portlet-header]"></ng-content>
    </div>
    <div class="portlet-body">
        <ng-content select="[portlet-body]"></ng-content>
    </div>
    <div class="portlet-footer">
        <ng-content select="[portlet-footer]"></ng-content>
    </div>
</div>