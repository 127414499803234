import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ManageContactModalComponent } from 'src/app/modals/manage-contact-modal/manage-contact-modal.component';
import { CohortsModel, ContactsModel } from 'src/app/models/contacts.model';
import { ContactsService } from 'src/app/services/contacts.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-cohort-details',
  templateUrl: './cohort-details.component.html',
  styleUrl: './cohort-details.component.scss'
})
export class CohortDetailsComponent {
  @ViewChild(MatPaginator) paginator! : MatPaginator;

  faArrowLeft = faArrowLeft;
  faSearch = faSearch;
  faPlus = faPlus;

  public isLoading = true;
  public contactFilter : string = '';
  public cohort : CohortsModel = new CohortsModel();
  public cohortId : string = '';
  public contacts : ContactsModel[] = [];
  public dataSource : MatTableDataSource<ContactsModel> = new MatTableDataSource<ContactsModel>();
  displayedColumns: string[] = [
    'name', 
    'email',
    'company',
    'phone',
    'surveys',
    // 'avg_score'
  ];

  constructor(
    private dss : DataSharingService,
    private modal : MatDialog,
    private router : Router,
    private contactsService : ContactsService,
    private route : ActivatedRoute
  ) { 
    this.route.params.subscribe(params => {
      this.cohortId = params['id'];
    });
  }

  ngOnInit() {
    this.loadCohortMembers();
    this.loadCohort();
  }

  loadCohort() {
    if(!this.cohortId) return;

    this.contactsService.getCohort(this.cohortId).subscribe({
      next : (response) => {
        console.log('cohort-details.component:863321', response);
        this.cohort = response.payload;
      },
      error : (error) => {
        console.error('cohort-details.component:306084', error);
      },
      complete : () => {
        this.isLoading = false;
      }
    });
  }

  loadCohortMembers() {
    if(!this.cohortId) return;

    this.contactsService.getContacts(this.cohortId).subscribe({
      next : (response) => {
        console.log('cohort-details.component:728028', response);
        this.contacts = response.payload;
        this.dataSource.data = this.contacts;
        this.dataSource.paginator = this.paginator;
      },
      error : (error) => {
        console.error('cohort-details.component:306084', error);
      },
      complete : () => {
        this.isLoading = false;
      }
    });
  }

  back() {
    window.history.back();
    // this.router.navigate(['/cohorts']);
  }

  updateCohortFilter() {
    this.dataSource.filter = this.contactFilter.trim().toLowerCase();
  }

  openManageContactModal(contact? : ContactsModel) {
    let isNew = contact ? false : true;
    if(!contact) {
      contact = new ContactsModel();
      contact.cohort_id = this.cohortId;
    }

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
      is_new: isNew,
      contact : contact
    };

    this.modal.open(ManageContactModalComponent, config).afterClosed().subscribe(
      (result : any) => {
        if(result) {
          console.log('cohorts.component:159367', result);
          let index = -1;

          switch(result.action) {
            case 'save':
              this.contacts.push(result.contact);
              this.dataSource.data = this.contacts;
              this.dataSource.paginator = this.paginator;
              break;
            case 'update':
              index = this.contacts.findIndex((cohort) => cohort.id === result.contact.id);
              if(index > -1) {
                this.contacts[index] = result.contact;
                this.dataSource.data = this.contacts;
                this.dataSource.paginator = this.paginator;
              }
              break;
            case 'delete':
              index = this.contacts.findIndex((cohort) => cohort.id === result.contact.id);
              if(index > -1) {
                this.contacts.splice(index, 1);
                this.dataSource.data = this.contacts;
                this.dataSource.paginator = this.paginator;
              }
              break
            default:
              break
          }
        }
      }
    );
  }

  isFiltered(): boolean {
    return this.dataSource.filter !== '';
  }

  goToContact(contact : ContactsModel) {
    this.router.navigate([`/cohorts/${contact.cohort_id}/contacts/${contact.id}`]);
  }

  renderName(contact : ContactsModel) {
    return contact.first_name + ' ' + contact.last_name;
  }

  renderScore(contact : ContactsModel) {
    let rst = '0';
    if(!contact) { return '---'; }
    if(!contact.average_score) { return '---'; }
    rst = contact.average_score ? contact.average_score.toString() : '---';

    return rst;
  }
}
