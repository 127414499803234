import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionsModel } from 'src/app/models/question_library.model';
import { ApiService } from 'src/app/services/api.service';
import { faUserSecret, faUser, faLessThan, faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SURVEY_FINAL_RESULT_DETAIL, SURVEY_FINAL_RESULT_TIMING, SurveyModel, SurveyParticipantsModel } from 'src/app/models/survey.model';
import { ResponseContentModel, SurveyResponsesModel } from 'src/app/models/responses.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { SurveysService } from 'src/app/services/surveys.service';
import { SurveySessionService } from 'src/app/services/survey-session.service';
import * as dayjs from 'dayjs';
// import { SurveyParticipantService } from 'src/app/services/survey-participant.service';


const ERRORMESSAGES = {
  OK : "",
  NOTFOUND : "We didn't find this survey, but you can create one of your own for free!",
  INVALID : "There was an error loading this survey, but you can create one of your own for free!",
  SUBMISSION : "There was an error submitting your response. Please try again.",   
}
const SURVEY_PHASES = {
  NOT_STARTED : 0,
  REGISTRATION : 1,
  SURVEY : 2,
  COMPLETE : 3,
  PROMO : 4
};

const SURVEY_MODES = {
  SURVEY : 'survey',
  INVITE : 'invite',
}

@Component({
  selector: 'app-do-survey',
  templateUrl: './do-survey.component.html',
  styleUrl: './do-survey.component.scss'
})
export class DoSurveyComponent implements OnInit {
  faUser = faUser;
  faUserSecret = faUserSecret;
  faLessThan = faLessThan;
  faGreaterThan = faGreaterThan;

  ERRORMESSAGES = ERRORMESSAGES;
  SURVEY_PHASES = SURVEY_PHASES;
  SURVEY_MODES = SURVEY_MODES;

  SURVEY_FINAL_RESULT_DETAIL = SURVEY_FINAL_RESULT_DETAIL;
  SURVEY_FINAL_RESULT_TIMING = SURVEY_FINAL_RESULT_TIMING;

  public tooltips = {
    skip_registration : 'Please note that if you skip registration, you will not be able to view your responses later.',
  };

  public surveyRef : string = '';
  public survey : SurveyModel = new SurveyModel();
  public questions : QuestionsModel[] = [];
  public currentQuestion : QuestionsModel = new QuestionsModel();
  public responses : ResponseContentModel[] = [];
  public finalResponse : SurveyResponsesModel | null = null;
  public currentResponse : ResponseContentModel = new ResponseContentModel();
  public showProgressBar : boolean = false;
  public progressBarValue : number = 0;
  public showPoweredBy : boolean = false;
  public showRegistrationScreen : boolean = false;
  public currentSurveyStep : number = SURVEY_PHASES.NOT_STARTED;
  public promoMessage : string = '';
  public incorrect_message : string = "";

  public currentQuestionIndex : number = 0;

  public inProgress : boolean = false;
  public inProgress$ : any;

  public isLoading : boolean = true;
  public errorMessage : string = '';
  public survey_participant : SurveyParticipantsModel = new SurveyParticipantsModel();
  public mode : string = '';

  public companyLogo : string = '';
  public companyName : string = '';
  public frmParticipant : FormGroup = new FormGroup({});

  constructor(
    private route : ActivatedRoute,
    private modal : MatDialog,
    private snackbar : MatSnackBar,
    // private surveyParticipantService : SurveyParticipantService,
    private surveyService : SurveysService,
    private surveySessionService : SurveySessionService,
    public router : Router,
  ) {
    this.frmParticipant = new FormGroup({
      'participant_name' : new FormControl('', [Validators.required]),
      'participant_email' : new FormControl('', [Validators.required, Validators.email]),
    });

    this.route.params.subscribe((params) => {
      this.surveyRef = params['ref'];

      if(!this.surveyRef) {
        this.errorMessage = ERRORMESSAGES.INVALID;
      }
    });
  }

  get participant_name() { return this.frmParticipant.get('participant_name'); }
  get participant_email() { return this.frmParticipant.get('participant_email'); }

  ngOnInit() {
    // There are two routes this component is used for
    if(this.router.url.includes('/s')) {
      this.mode = SURVEY_MODES.SURVEY;
    }
    if(this.router.url.includes('/i')) {
      this.mode = SURVEY_MODES.INVITE;
    }

    if(this.surveySessionService.inProgress.value) {
      this.currentQuestionIndex = this.surveySessionService.surveyPosition.value || 0;
      this.navigateStep(SURVEY_PHASES.SURVEY);
    } else {
      this.navigateStep(SURVEY_PHASES.NOT_STARTED);
    }
  }

  getSurvey(refType : string) {
    // Type : survey
    switch(refType) {
      case 'survey':
        this.isLoading = true;
        this.surveyService.getSurveyByRef(this.surveyRef).subscribe({
          next : (data) => {
            if(data.payload) {
              this.survey = data.payload;
              this.questions = this.survey.questions;
              this.showPoweredBy = this.survey.is_branded;
              this.errorMessage = ERRORMESSAGES.OK;

              // Advance to the current step
              if(this.currentQuestionIndex >= 0) {
                this.currentQuestion = this.questions[this.currentQuestionIndex];
              }
            } else {
              // console.log('do-survey.component:652554', data);
              if(data.message) {
                this.promoMessage = data.message;
              } else {
                this.promoMessage = ERRORMESSAGES.NOTFOUND;
              }
              this.navigateStep(SURVEY_PHASES.PROMO);
            }
          },
          error : (error) => {
            console.error('do-survey.component:053654', error);
            this.isLoading = false;
            if(error.status == 404) {
              this.promoMessage = ERRORMESSAGES.NOTFOUND;
              this.navigateStep(SURVEY_PHASES.PROMO);
            } else {
              this.promoMessage = ERRORMESSAGES.INVALID;
              this.navigateStep(SURVEY_PHASES.PROMO);
            }
          },
          complete : () => {
            this.isLoading = false;
          }
        });
        break;
      case 'invite':
        // Type : invite
        this.isLoading = true;
        this.surveyService.getSurveyByInvite(this.surveyRef).subscribe({
          next : (data) => {
            if(data.payload) {
              this.survey = data.payload;
              this.survey_participant = data.payload.survey_participant;
              this.survey_participant.id = data.payload.survey_participant_id;
              this.showPoweredBy = this.survey.is_branded;
              this.questions = this.survey.questions;

              // Advance to the current step
              if(this.currentQuestionIndex >= 0) {
                this.currentQuestion = this.questions[this.currentQuestionIndex];
              }
            } else {
              this.survey = data.payload;
              if(data.message) {
                this.promoMessage = data.message;
              } else {
                this.promoMessage = ERRORMESSAGES.NOTFOUND;
              }
              this.navigateStep(SURVEY_PHASES.PROMO);
            }
          },
          error : (error) => {
            console.error('do-survey.component:768316', error);
            this.isLoading = false;
            this.promoMessage = ERRORMESSAGES.INVALID;
            this.navigateStep(SURVEY_PHASES.PROMO);
          },
          complete : () => {
            this.isLoading = false;
          }
        });
    }
  }

  navigateStep(step? : number) {
    // console.log('do-survey.component:953393', step);
    let s = SURVEY_PHASES.NOT_STARTED;

    // If step hasn't been provided, determine the step based on the survey settings
    if(step == null) {
      if(!this.survey.is_public) {
        // Invite only - skip registration; we know who they are
        s = SURVEY_PHASES.SURVEY;
      } else {
        switch(this.getRegistrationRequired()) {
          case 'Y':
          case 'O':
            s = SURVEY_PHASES.REGISTRATION;
            break;
          case 'N':
            // console.log('do-survey.component:536891', this.survey_participant);
            s = SURVEY_PHASES.SURVEY;
            break;
          default:
            s = SURVEY_PHASES.REGISTRATION;
            break;
        }
      }
    } else {
      s = step
    }
    
    // console.log('do-survey.component:683983', step, '//', s, '///');

    switch(s) {
      case SURVEY_PHASES.REGISTRATION:
        this.stepRegisterParticipant();
        break;
      case SURVEY_PHASES.SURVEY:
        this.stepDoSurvey();
        break;
      case SURVEY_PHASES.COMPLETE:
        this.stepCompleteSurvey();
        break;
      case SURVEY_PHASES.PROMO:
        console.log('do-survey.component:927146', this.promoMessage);
        this.showProgressBar = false;
        this.showPoweredBy = true;
        this.currentSurveyStep = SURVEY_PHASES.PROMO;
        break;
      case SURVEY_PHASES.NOT_STARTED:
      default:
        this.stepNotStarted();
        break;
    }
  }

  stepNotStarted() {
    this.showProgressBar = false;
    this.currentSurveyStep = SURVEY_PHASES.NOT_STARTED;
    this.finalResponse = null;
    // If the survey is in progress, end it before starting a new one
    // TODO FIX THIS
    // this.endSession();
    this.getSurvey(this.mode);
  }

  stepRegisterParticipant() {
    this.showProgressBar = false;
    this.showPoweredBy = false;
    this.currentSurveyStep = SURVEY_PHASES.REGISTRATION;
  }

  stepDoSurvey() {
    this.showProgressBar = true;
    this.progressBarValue = 0;
    this.showPoweredBy = false;

    // Check if the survey is in progress
    if(this.surveySessionService.inProgress.value) {
      this.surveySessionService.getSession().subscribe({
        next : (session) => {
          // console.log('do-survey.component:611956', session);
          if(!session) {
            // If the session is not found, reset the survey
            this.endSession();
          } else {
            let p = session.payload.position;
            // console.log('do-survey.component:181263', p);
            this.currentSurveyStep = SURVEY_PHASES.SURVEY;
            this.responses = p.response;
            this.currentQuestionIndex = p.current_question_index;
            this.showProgressBar = true;
            this.progressBarValue = p.progress * 100;
            this.currentQuestion = p.question;
            this.currentResponse = p.content ? p.content : new ResponseContentModel();
          }
        },
        error : (error) => {
          console.log('do-survey.component:455883', error);
          console.log('do-survey.component:469317', Object.keys(error));
          if(error.status == 400) {
            let msg = error.error.message || error.message ||'';
            switch(msg) {
              case 'jwt expired':
                this.snackbar.open("You've run out of time to complete the survey", 'Close', { duration : 5000 });
                this.endSession();
                break;
              case 'Survey session not found':
                this.snackbar.open("We couldn't find your survey session. Please start over.", 'Close', { duration : 5000 });
                this.endSession();
                break;
              case 'Survey has already been completed':
                console.log('do-survey.component:949466');
                this.snackbar.open("You've already completed this survey", 'Close', { duration : 5000 });
                this.promoMessage = "You've already completed this survey";
                this.navigateStep(SURVEY_PHASES.PROMO);
                break;
              default:
                this.errorMessage = ERRORMESSAGES.SUBMISSION;
                this.snackbar.open(this.errorMessage, 'Close', { duration : 5000 });
                break;
            }
          } else {
            this.errorMessage = ERRORMESSAGES.SUBMISSION;
            this.snackbar.open(this.errorMessage, 'Close', { duration : 5000 });
          }
        },
        complete : () => {
          this.isLoading = false;
        }
      });
    } else {
      this.startSurveySession();
    }
  }

  stepCompleteSurvey() {
    console.log('do-survey.component:016463');
    this.surveySessionService.endSession().subscribe({
      next : (data) => {
        console.log('do-survey.component:477743', data);
        this.currentSurveyStep = SURVEY_PHASES.COMPLETE;
        this.surveySessionService.setSurveyInProgress(false);
        this.showProgressBar = false;
        this.showPoweredBy = this.survey.is_branded;
        this.currentQuestionIndex = 0;
      },
      error : (error) => {
        console.error('do-survey.component:213446', error);
        if(error.status == 500) {
          if(error.error.message == 'jwt must be provided') {
            this.snackbar.open("We weren't able to continue with this survey. Please start over", 'Close', { duration : 5000 });
            this.navigateStep(SURVEY_PHASES.NOT_STARTED);
          }
        } else {
          this.errorMessage = ERRORMESSAGES.SUBMISSION;
          this.snackbar.open(this.errorMessage, 'Close', { duration : 5000 });
        }
      }
    });
  }

  endSession() {
    try {
      this.surveySessionService.inProgress.next(false);
      this.surveySessionService.endSession().subscribe({
        next : (data) => {
          this.getSurvey(this.mode);
      },
        error : (error) => {
          console.error('do-survey.component:589043', error);
        }
      });
    } catch(error) {
      console.error('do-survey.component:160526', error);
    } finally {
      this.navigateStep(SURVEY_PHASES.NOT_STARTED);
      this.showProgressBar = false;
    }
  }

  restartSurvey() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-3/4', 'lg:!w-3/4', 'xl:!w-1/2' ];
    config.data = {
    title: 'Please Confirm',
    message: 'Are you sure you want to start over? Your responses will be lost.',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        this.responses = [];
        this.surveySessionService.setSurveyInProgress(false);
        this.showProgressBar = false;
        this.surveySessionService.setSurveyPosition(0);
        this.currentQuestion = new QuestionsModel();
        this.showPoweredBy = this.survey.is_branded;
      }
    });
  }

  createYourOwnSurvey() {
    this.router.navigate(['/signup']);
  }

  canSeeResults() {
    return this.survey.is_scored && this.survey.config.final_result_timing == 'on_close';
  }

  seeResults() {
    let rid = this.finalResponse ? this.finalResponse.survey_session_id : '';
    this.router.navigate(['/r/' + rid]);
  }

  startSurveySession() {
    // console.log('do-survey.component:715067', this.survey.ref, this.currentQuestionIndex, this.currentQuestion);
    this.surveySessionService.startSession({
      survey_ref : this.survey.ref,
      survey_participant_id : this.survey_participant.id,
      name : this.frmParticipant.get('participant_name')?.value || 'Not provided',
      email : this.frmParticipant.get('participant_email')?.value || 'Not provided',
    }).subscribe({
      next : (response) => {
        console.log('do-survey.component:749383', response);
        if(response.payload) {
          // Start the survey session
          this.currentQuestion = new QuestionsModel();
          this.currentResponse = new ResponseContentModel();
          
          // Survey has started, set the position to the first question
          let p = response.payload.position;
          // console.log('do-survey.component:162562', p);
          this.currentSurveyStep = SURVEY_PHASES.SURVEY;
          this.responses = p.response;
          this.currentQuestionIndex = p.current_question_index;
          this.showProgressBar = true;
          this.progressBarValue = p.progress * 100;
          this.currentQuestion = p.question;
          this.currentResponse = p.content ? p.content : new ResponseContentModel();
        }
      },
      error : (error) => {
        // console.error('do-survey.component:781405', error);
        if(error.status == 400) {
          if(error.error.message == 'Survey has already been completed') {
            // console.log('do-survey.component:110613');
            this.snackbar.open("You've already completed this survey", 'Close', { duration : 5000 });
            this.promoMessage = "You've already completed this survey";
            this.navigateStep(SURVEY_PHASES.PROMO);
            // this.endSession();
          } else {
            this.snackbar.open('There was an error starting the survey. Please try again.', 'Close', { duration : 5000 });
          }
        } else {
          this.snackbar.open('There was an error starting the survey. Please try again.', 'Close', { duration : 5000 });
        }
        throw error;
      }
    });
  }

  showRegistration() {
    let isAnonymous = this.survey.is_anonymous || false;
    let isPublic = this.survey.is_public || false;

    return !isAnonymous && isPublic;
  }

  nextQuestion() {
    console.log('do-survey.component:823142', this.currentQuestion);
    let payload = {
       action: 'next',
       content : {
        question_id: this.currentQuestion.id,
        answer_id: this.currentResponse.answer_id,
        answer: this.currentResponse.answer,
       }
    }

    this.surveySessionService.submitResponse(payload).subscribe({
      next : (data) => {
        console.log('do-survey.component:927707', data);
        if(data.payload) {
          if(!data.payload.is_complete) {
            if(data.payload.incorrect_answer) {
              this.incorrect_message = data.payload.incorrect_message;
            }

            this.incorrect_message = data.payload.incorrect_message;

            this.surveySessionService.setSurveyPosition(data.payload.current_question_index);
            this.showProgressBar = true;
            this.progressBarValue = data.payload.progress * 100,
            
            // Override the mandatory setting from the server where conditions are applied
            this.currentQuestion.required = data.payload.required || false;
            this.currentQuestionIndex = data.payload.current_question_index;
            this.currentQuestion = data.payload.question;
            this.currentResponse = data.payload.content ? data.payload.content : new ResponseContentModel();
          } else {
            console.log('do-survey.component:708067');
            this.finalResponse = data.payload.response;
            this.navigateStep(SURVEY_PHASES.COMPLETE);
          }
        }
      },
      error : (error) => {
        console.error('do-survey.component:506687', error);
        if(error.status == 400) {
          if(error.error.message == 'jwt expired') {
            this.snackbar.open("You've run out of time to complete the survey", 'Close', { duration : 5000 });
            this.endSession();
          }
        } else {
          this.errorMessage = ERRORMESSAGES.SUBMISSION;
          this.snackbar.open(this.errorMessage, 'Close', { duration : 5000 });
        }
      }
    });
  }

  prevQuestion() {
    console.log('do-survey.component:054519', this.currentQuestionIndex, this.currentQuestion);
    // Don't go back if we're at the first question
    if(this.surveySessionService.surveyPosition.value == 0) {
      return;
    }

    let payload = {
      action: 'prev',
      content : {
       question_id: this.currentQuestion.id,
       answer_id: this.currentResponse.answer_id,
       answer: this.currentResponse.answer,
      }
   }

   this.surveySessionService.submitResponse(payload).subscribe({
    next : (data) => {
      if(data.payload) {
        if(data.payload.incorrect_answer) {
          this.incorrect_message = data.payload.incorrect_message;
        }

        if(!data.payload.is_complete) {
          this.surveySessionService.setSurveyPosition(data.payload.current_question_index);
          this.showProgressBar = true;
          this.progressBarValue = data.payload.progress * 100,
          
          // Override the mandatory setting from the server where conditions are applied
          this.currentQuestion.required = data.payload.required || true;
          this.currentQuestionIndex = data.payload.current_question_index;
          this.currentQuestion = data.payload.question;
          this.currentResponse = data.payload.content ? data.payload.content : new ResponseContentModel();
        } else {
          this.navigateStep(SURVEY_PHASES.COMPLETE);
        }
      }
    },
     error : (error) => {
       console.error('do-survey.component:890143', error);
       if(error.status == 400) {
        if(error.error.message == 'jwt expired') {
          this.snackbar.open("You've run out of time to complete the survey", 'Close', { duration : 5000 });
          this.surveySessionService.endSession()
        }
      } else {
        this.errorMessage = ERRORMESSAGES.SUBMISSION;
        this.snackbar.open(this.errorMessage, 'Close', { duration : 5000 });
      }
     }
   });
  }

  renderCompanyName() {
    return this.survey?.company && this.survey?.company.name || '';
  }

  renderTitle() {
    return this.survey?.name || 'SurveyPoint';
  }

  getRegistrationRequired() {
    if(this.mode == SURVEY_MODES.INVITE) { return 'N'; }
    return this.survey?.require_registration?.toUpperCase() || 'Y';
  }

  disableNext() {
    // console.log('do-survey.component:304996\n', this.currentQuestion, '\n', this.currentResponse);
    return this.currentQuestion.required && !this.currentResponse.answer;
  }

  registrationSkippable() {
    if(!this.survey) { return false; }
    return this.survey.require_registration == 'O' || false;
  }

  registrationAdvanceable() {
    return (this.frmParticipant.valid && !this.frmParticipant.pristine);
  }

  /**
   * This function is used to expand text variables in the survey:
   * {{name}} - Participant name
   * {{email}} - Participant email
   * {{company}} - Company name
   * {{start}} - Survey title
   * {{end}} - Company logo
   */
  expandTextVariables(t : string) {
    try {
      // console.log('do-survey.component:641421', this.survey.start, this.survey.end);
      let text = t || '';
      text = text.replace('{{name}}', this.survey_participant.name || '');
      text = text.replace('{{email}}', this.survey_participant.email || '');
      text = text.replace('{{company}}', this.renderCompanyName());
      text = text.replace('{{start}}', this.survey.start ? dayjs(this.survey.start).format('MMMM D, YYYY') : '');
      text = text.replace('{{end}}', this.survey.end ? dayjs(this.survey.end).format('MMMM D, YYYY') : '');
  
      return text;
    } catch(error) {
      console.error('do-survey.component:570858', error);
      return '';
    }
  }
  
  canViewResults() {
    if(!this.survey.config) return true;

    return this.survey.is_scored && (
      this.survey.config.final_result_timing == SURVEY_FINAL_RESULT_TIMING.ON_CLOSE || 
      this.survey.config.final_result_timing == SURVEY_FINAL_RESULT_TIMING.ON_COMPLETE
    );
  }
}
