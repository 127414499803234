<ng-container *ngIf="survey?.questions">
  <div class="border xs:m-5 xs:my-10 xl:m-20 xs:p-0 lg:p-20">
    <app-survey-panel [question]="currentQuestion"></app-survey-panel>
  </div>

  <div class="text-center w-full">
    <button mat-flat-button class="mt-5 text-center !py-8 !bg-primary !text-white" (click)="previousQuestion()">
      <fa-icon [icon]="faLessThan" class="mr-3"></fa-icon>
    </button>
    <button mat-flat-button class="mt-5 !py-8 text-center !bg-primary !text-white" (click)="nextQuestion()">
      <fa-icon [icon]="faGreaterThan" class="mr-3"></fa-icon>
    </button>
  </div>
</ng-container>