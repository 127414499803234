<mat-dialog-content>
  <h3 mat-dialog-title class="uppercase font-bold px-0">Plan Calculator</h3>
  <div class="my-5 pr-5">
    Lorem ipsum
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="float-left w-1/5 pl-5 mb-5">
  </div>
  <div class="w-3/5 pl-5">
  </div>
  <div class="float-right w-1/5 text-right pr-5">
    <button class="btn-secondary-outline mr-3" data-dismiss="modal" type="button" (click)="close()">Close</button>
  </div>
</mat-dialog-actions>