import { QuestionLibraryModel } from "./question_library.model";

export const TouchpointStatus = {
  ERROR : 'Error',
  IN_QUEUE : 'In queue',
  PAUSED : 'Paused',
  READY : 'Ready',
  COMPLETE : 'Complete',
  COMPLETE_DECLINED : 'Complete-Declined',
  COMPLETE_NO_ANSWER : 'Complete-No answer',
  COMPLETE_OPTED_OUT : 'Complete-Opted out'
}

export class TouchpointModel {
  id : string;
  company_id : string;
  contact_name : string;
  context : string;
  contact_company_name : string;
  contact_phone_number : string;
  contact_timezone : string;
  not_before : string | null;
  time_slot_start : string;
  time_slot_end : string;
  time_slot_start_utc? : string;
  time_slot_end_utc? : string;
  representative? : string;
  product_service? : string;
  event_date? : string;
  status : string;
  scheduled_day? : string | null;
  contact_asap? : boolean;
  agent_id? : string | null;
  question_library_id? : string;
  question_library? : QuestionLibraryModel;

  constructor() {
    this.id = '';
    this.company_id = '';
    this.contact_name = '';
    this.context = '';
    this.contact_company_name = '';
    this.contact_phone_number = '';
    this.not_before = null;
    this.time_slot_start = '08:00';
    this.time_slot_end = '16:00';
    this.status = '';
    this.contact_asap = false;
    this.contact_timezone = '';
  }  
}