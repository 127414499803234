import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ContactsModel } from 'src/app/models/contacts.model';
import { SurveyResponsesModel } from 'src/app/models/responses.model';
import { SurveyModel, SurveyParticipantsModel } from 'src/app/models/survey.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { ContactsService } from 'src/app/services/contacts.service';
import * as dayjs from 'dayjs';

const SURVEY_RESPONSE_STATES = {
  NOT_PUBLISHED : 'Not Published',
  COMPLETE : 'Complete',
  INCOMPLETE : 'Incomplete',
  WAITING : 'Waiting',
  REDACTED : 'Redacted'
}

@Component({
  selector: 'app-contact-member-details',
  templateUrl: './contact-member-details.component.html',
  styleUrl: './contact-member-details.component.scss'
})
export class ContactMemberDetailsComponent {
  faArrowLeft = faArrowLeft;

  SURVEY_RESPONSE_STATES = SURVEY_RESPONSE_STATES;

  public contact : ContactsModel = new ContactsModel();
  public survey_participants : SurveyParticipantsModel[] = [];
  public currentSurvey : SurveyModel = new SurveyModel();
  public currentResponse : SurveyResponsesModel = new SurveyResponsesModel();
  public surveys : SurveyModel[] = [];
  public cohortId : string = '';
  public contactId : string = '';
  public isLoading : boolean = true;
  public tooltips = {
    no_responses : 'No responses received',
    view_response : 'Click to view response',
    anonymous : 'To view responses for this survey, go to the Responses tab'
  }

  constructor(
    private contactsService : ContactsService,
    private route : ActivatedRoute
  ) { 
    if(this.route.params) {
      this.route.params.subscribe({
        next : (params) => {
          // console.log('contact-member-details.component:044534', params);
          this.cohortId = params['cohort_id'];
          this.contactId = params['id'];
          console.log('contact-member-details.component:945035', this.cohortId, this.contactId);
          this.loadContactSurveys();
        }
      });
    }
  }

  loadContactSurveys() {
    this.contactsService.getContactSurveys(this.cohortId, this.contactId).subscribe({
      next : (response : ApiResponseModel) => {
        console.log('contact-member-details.component:328036', response);
        this.contact = response.payload;
        this.survey_participants = response.payload.survey_participants;
        this.getUniqueSurveys();
      },
      error : (error) => {
        console.error('contact-member-details.component:203992', error);
      },
      complete : () => {
        this.isLoading = false;
      }
    });
  }

  back() {
    window.history.back();
  }

  getParticipantsbySurvey(surveyId? : string) {
    // console.log('contact-member-details.component:538867', surveyId);
    if(!this.survey_participants) { return []; }
    if(!surveyId) { return this.survey_participants; }

    let participants : SurveyParticipantsModel[] = [];
    this.survey_participants.forEach((participant) => {
      if(participant.survey && participant.survey.id === surveyId) {
        // if(participant.survey_responses) {
        //   participant.survey_responses.forEach((response) => {
        //     participants.push(participant);
        //   });
        // } else {
        //   participants.push(participant);
        // }
        participants.push(participant);
      }
    });

    // console.log('contact-member-details.component:411074', participants);
    return participants;
  }

  /**
   * Get unique surveys from the list of this.survey_participants.survey
   */
  getUniqueSurveys() {
    let surveys : SurveyModel[] = [];
    this.survey_participants.forEach((participant) => {
      if(participant.survey) {
        let found = false;
        surveys.forEach((survey) => {
          if(survey.id === participant.survey?.id) {
            found = true;
          }
        });

        if(!found) {
          surveys.push(participant.survey);
        }
      }
    });

    this.surveys = surveys;
  }

  getResponseStatus(participant : SurveyParticipantsModel) {

    // console.log('contact-member-details.component:772523', participant.completed, participant.survey_responses);
    
    // Check if survey is past end date
    let survey_ended = false;
    if(participant.survey) {
      survey_ended = dayjs().isAfter(dayjs(participant.survey.end));
    }

    if(!participant.survey?.is_published) { return SURVEY_RESPONSE_STATES.NOT_PUBLISHED; }
    if(!participant.survey_responses && !survey_ended) { return SURVEY_RESPONSE_STATES.WAITING; }
    if(!participant.survey_responses && survey_ended) { return SURVEY_RESPONSE_STATES.INCOMPLETE; }
    if(participant.survey_responses && participant.survey_responses.length > 0) { return SURVEY_RESPONSE_STATES.COMPLETE; }
    if(participant.completed && participant.survey_responses && participant.survey_responses.length === 0) { return SURVEY_RESPONSE_STATES.REDACTED; }
    return SURVEY_RESPONSE_STATES.INCOMPLETE;
  }

  panelOpened(survey : SurveyModel) {
    // console.log('contact-member-details.component:121349', survey);
  }

  setCurrentResponse(response?: SurveyResponsesModel) {
    console.log('contact-member-details.component:818312', response);
    if(!response) { return; }
    this.currentResponse = response;
    this.currentSurvey = this.surveys.find((survey) => survey.id === response.survey_id) || new SurveyModel();
  }

  renderDateRange(survey : SurveyModel) {
    let rst = '';
    if(!survey) { 
      rst = 'No survey'; 
    }

    if(!survey.start) {
      rst = 'No beginning';
    } else {
      rst = dayjs(survey.start).format('DD/MM/YYYY');
    }

    if(survey.end) {
      rst += ' - ' + dayjs(survey.end).format('DD/MM/YYYY');
    } else {
      rst += ' - No end';
    }

    return rst;
  }

  hasResponses(participant : SurveyParticipantsModel) {
    return participant.survey_responses && participant.survey_responses.length > 0;
  }
}
