import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SurveyResponsesModel } from 'src/app/models/responses.model';
import { SurveyModel } from 'src/app/models/survey.model';
import { ApiResponseModel } from 'src/app/services/api.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { MyService } from 'src/app/services/my.service';
import { ResponseService } from 'src/app/services/responses.service';
import { SurveysService } from 'src/app/services/surveys.service';

@Component({
  selector: 'app-response-viewer',
  templateUrl: './response-viewer.component.html',
  styleUrl: './response-viewer.component.scss'
})
export class ResponseViewerComponent implements OnInit, OnDestroy {
  dss = inject(DataSharingService);
  my = inject(MyService);
  rs = inject(ResponseService);
  route = inject(ActivatedRoute);
  snackbar = inject(MatSnackBar);

  faArrowLeft = faArrowLeft;

  private survey_id: string = '';
  private response_id: string = '';
  private mode : string = '';

  public survey : SurveyModel = new SurveyModel();
  private survey$ : any;
  public response : SurveyResponsesModel | null = null;
  private response$ : any;

  constructor() { 
    this.route.params.subscribe(params => {
      this.survey_id = params['id'];
      this.response_id = params['rid'];

      // Split the route by '/' and grab the first element
      this.mode = this.route.snapshot.routeConfig?.path?.split('/')[0] || '';
    });
  }

  ngOnInit() {
    // console.log('response-viewer.component:737443', this.survey, this.responses);
    this.loadSurvey();
    this.loadSurveyResponse();
  }

  ngOnDestroy() {
    // this.survey$.unsubscribe();
    // this.responses$.unsubscribe();
  }

  loadSurvey() {
    this.survey$ = this.dss.currentSurvey.subscribe({
      next : (survey) => {
        this.survey = survey || new SurveyModel();
        // console.log('response-viewer.component:909669', this.survey);
      }
    });
  }

  loadSurveyResponse() {
    if(!this.response) {
      switch(this.mode) {
        case 'my':
          this.loadMySurveyResponse();
          break;
        case 'rl':
          this.loadRLSurveyResponse();
          break;
        default:
          break;
      }
    }          

    this.response$ = this.dss.currentSurveyResponse.subscribe({
      next : (response) => {
        if(response) {
          this.response = response;
        }
      }
    });
  }

  loadMySurveyResponse() {
    this.response$ = this.my.getMyResponse(this.survey_id).subscribe({
      next : (response : ApiResponseModel) => {
        if(response.payload) {
          this.response = response.payload;
          this.dss.currentSurveyResponse.next(response.payload);
        }
      },
      error : (error) => {
        console.log(error);
      }
    });
  }

  loadRLSurveyResponse() {
    this.response$ = this.rs.getSurveyResponse(this.response_id).subscribe({
      next : (response : ApiResponseModel) => {
        // console.log('response-viewer.component:795183', response);
        if(response.payload) {
          this.response = response.payload;
          this.dss.currentSurveyResponse.next(response.payload);
        }
      },
      error : (error) => {
        if(error.status) {
          this.snackbar.open('Response not found', 'Close', { duration: 3000 });
        }
      }
    });
  }

  back() {
    // this.router.navigate(['/rl/' + this.survey_id]);
    window.history.back();
  }
}
