import { ChangeDetectorRef, Component, EventEmitter, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { faSplit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { ManageQuestionConditionsModalComponent } from 'src/app/modals/manage-question-conditions-modal/manage-question-conditions-modal.component';
import { QuestionsModel, QuestionTypesModel } from 'src/app/models/question_library.model';
import { SurveyModel } from 'src/app/models/survey.model';
import { SurveysService } from 'src/app/services/surveys.service';

@Component({
  selector: 'app-survey-question-definition',
  templateUrl: './survey-question-definition.component.html',
  styleUrl: './survey-question-definition.component.scss'
})
export class SurveyQuestionDefinitionComponent {
  modal = inject(MatDialog);
  surveyService = inject(SurveysService);
  cdr = inject(ChangeDetectorRef);

  faSplit = faSplit;
  faTrash = faTrash;

  public readOnly : boolean = false;
  public frmQuestionDefinition: FormGroup = new FormGroup({});
  public survey : SurveyModel = new SurveyModel();
  public question : QuestionsModel = new QuestionsModel();
  public questionTypes : QuestionTypesModel[] = [];
  public onDelete : EventEmitter<void> = new EventEmitter<void>();

  public tooltips = {
    max_label : 'The label for the maximum value of the scale rating (On a scale of 1 to 10, where 10 is __ )',
    min_label : 'The label for the minimum value of the scale rating (On a scale of 1 to 10, where 1 is __ )',
    scale_max : 'The maximum value for the scale rating (On a scale of 1 to __)',
    split : 'Add conditional logic to the question (Pro feature)',
    trash : 'Delete the question',
  }

  constructor() {
    this.surveyService.questionTypes.subscribe({
      next : (questionTypes : QuestionTypesModel[]) => {
        console.log('survey-question-definition.component:323601', questionTypes);
        this.questionTypes = questionTypes;
      }
    });

    this.frmQuestionDefinition = new FormGroup({
      type : new FormControl(this.question.field_type, [ Validators.required ]),
      question : new FormControl(this.question.question, [ Validators.required ]),
      scaleMax : new FormControl(this.question.field_config.scale_max),
      minLabel : new FormControl(this.question.field_config.min_label),
      maxLabel : new FormControl(this.question.field_config.max_label),
    });


    console.log('survey-question-definition.component:873825', this.question, this.survey);
  }

  ngOnChanges() {
    console.log('survey-question-definition.component:482866', this.question, this.survey);
  }

  ngOnInit() {
    this.frmQuestionDefinition.patchValue({
      type : this.question.field_type,
      question : this.question.question,
      scaleMax : this.question.field_config.scale_max,
      minLabel : this.question.field_config.min_label,
      maxLabel : this.question.field_config.max_label,
    });

    console.log('survey-question-definition.component:871565', this.question, this.survey);
  }

  ngAfterViewInit() {
    console.log('survey-question-definition.component:699248', this.question, this.survey);
  }

  hasConditions() {
    let question = this.question;
    if(!question || !question.conditions) return false;

    return question.conditions && question.conditions.length > 0;
  }

  setRequired(event : any) {
    console.log('survey-question-editor.component:148077', this.question.required, event);
    this.question.required = !this.question.required;
  }

  deleteQuestion() {
    this.onDelete.emit();
  }

  updateField(field : string, event : any) {
    if(!this.question) return;
    if(!this.frmQuestionDefinition) return;

    let question = this.question;
    let controlValue = this.frmQuestionDefinition.get(field) ? this.frmQuestionDefinition.get(field)?.value : null;
    console.log('survey-question-editor.component:799627', field, event, controlValue);
    
    switch(field) {
      case 'type':
        question.field_type = event.value;
        break;
      case 'question':
        if(event.html !== undefined) { question.question = event.html; }
        break;
      case 'options':
        question.field_options = event;
        break;
      case 'scaleMax':
        question.field_config.scale_max = controlValue.scaleMax;
        break;
      case 'minLabel':
        question.field_config.min_label = controlValue.minLabel;
        break;
      case 'maxLabel':
        question.field_config.max_label = controlValue.maxLabel;
        break
    }

    this.cdr.detectChanges();
  }

  renderQuestionTypeDescription() : string {
    if(!this.question) return '';

    let question_type_id = this.frmQuestionDefinition.get('type')?.value.type || '';

    switch(question_type_id) {
      case 'blank' : return 'Enter description';
      default : return 'Enter question';
    }
  }

  // getQuestionType() {
  //   if(!this.question) return '';
  //   return this.question.field_type;
  // }
  
  showOptions() {
    if(!this.question) return false;

    let question = this.question;
    return (question.field_type === 'multiple' || question.field_type === 'radio' || question.field_type === 'dropdown');
  }

  showScaleRatingOptions() {
    if(!this.question) return false;
    
    let question = this.question;
    return (question?.field_type === 'star_rating' || question?.field_type === 'scale_rating');
  }

  manageConditions() {
    if(!this.question) return;

    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-full', 'lg:!w-full', 'xl:!w-3/4' ];
    config.data = {
      questions : this.survey.questions,
      current_question : this.question,
    };
    
    this.modal.open(ManageQuestionConditionsModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result && result.conditions) {
        this.question.conditions = result.conditions;
      }
    });    
  }

  handleQuestionOptionsUpdate(event? : any) {
    console.log('survey-question-editor.component:358293', event);
  }

  handleQuestionOptionsDirty(event : any) {
    console.log('survey-question-editor.component:421132', event);
  }

  handleQuestionOptionsInvalid(event : any) {
    console.log('survey-question-editor.component:434198', event);
  }

}
