import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, viewChild, ViewChild, viewChildren, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faSplit } from '@fortawesome/pro-regular-svg-icons';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { QuestionsModel, QuestionTypesModel } from 'src/app/models/question_library.model';
import { ApiResponseModel, ApiService } from 'src/app/services/api.service';
import { faTrash, faArrowUp, faArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faArrowsFromLine, faArrowsToLine, faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { SurveysService } from 'src/app/services/surveys.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import tinymce from 'tinymce';
import { MatAccordion } from '@angular/material/expansion';
import { AiAssistantModalComponent } from 'src/app/modals/ai-assistant-modal/ai-assistant-modal.component';
import { LoadingService } from 'src/app/services/loading.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { PlanUpgradeModalComponent } from 'src/app/modals/plan-upgrade-modal/plan-upgrade-modal.component';
import { SurveyQuestionDefinitionComponent } from '../survey-question-definition/survey-question-definition.component';
import { SurveyModel } from 'src/app/models/survey.model';

const NON_QUESTION_PANEL = 'blank';

@Component({
  selector: 'app-survey-question-editor',
  templateUrl: './survey-question-editor.component.html',
  styleUrl: './survey-question-editor.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SurveyQuestionEditorComponent {
  questionDefContainers = viewChildren('questionDefContainer', { read: ViewContainerRef });
  private componentRefs: Map<number, any> = new Map(); // Stores references to created components for cleanup
  private componentFormSubscription$: any;
  private componentFormDeleteSubscription$: any;

  accordion = viewChild.required(MatAccordion);

  @Input() readOnly : boolean = false;
  @Input() questions : QuestionsModel[] = [];
  @Input() survey : SurveyModel = new SurveyModel();
  @Input() isScored : boolean = false;
  @Input() tabIndex : number = 0;
  @Output() questionsChange = new EventEmitter<QuestionsModel[]>();
  @Output() isDirty = new EventEmitter<boolean>();
  @Output() isInvalid = new EventEmitter<boolean>();

  faTrash = faTrash;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faPlus = faPlus;
  faSplit = faSplit;
  faArrowsFromLine = faArrowsFromLine;
  faArrowsToLine = faArrowsToLine;
  faSparkles = faSparkles;

  NON_QUESTION_PANEL = NON_QUESTION_PANEL;

  survey_id : string = '';
  editorConfig : any;

  public panelStates : boolean[] = [];
  public panelDisabled : boolean = false;

  public frmQuestionSetEditor : FormGroup = new FormGroup({});
  public questionTypes : QuestionTypesModel[] = [];
  public isSubscribed : boolean = false;
  public isLoading : boolean = false;

  public tooltips = {
    ai_assistant : 'Get help from our AI assistant to create your survey',
    collapse : 'Collapse all the question panels',
    down : 'Move the question down',
    expand : 'Expand all the question panels',
    max_label : 'The label for the maximum value of the scale rating (On a scale of 1 to 10, where 10 is __ )',
    min_label : 'The label for the minimum value of the scale rating (On a scale of 1 to 10, where 1 is __ )',
    scale_max : 'The maximum value for the scale rating (On a scale of 1 to __)',
    split : 'Add conditional logic to the question (Pro feature)',
    trash : 'Delete the question',
    up : 'Move the question up',
  }

  constructor(
    private api : ApiService, // TODO: Refactor to use the SurveysService
    private fb : FormBuilder,
    private loading : LoadingService,
    private modal : MatDialog,
    private route : ActivatedRoute,
    private snackbar : MatSnackBar,
    private subscriptionService : SubscriptionService,
    private surveyService : SurveysService,
    private cd : ChangeDetectorRef
  ) { 
    // this.loadQuestionStructure();

    this.frmQuestionSetEditor = this.fb.group({
      q: this.fb.array([])
    }); 

    this.frmQuestionSetEditor.valueChanges.subscribe((e) => {
      this.isInvalid.emit(this.frmQuestionSetEditor.invalid);
      this.questionsChange.emit(this.questions);
    });
  }

  get q() { return this.frmQuestionSetEditor.get('q') as FormArray; }

  ngOnInit(): void { 
    this.route.paramMap.subscribe((params) => {
      this.survey_id = params.get('id') || '';
    });

    this.subscriptionService.stripeSubscription.subscribe({
      next : (stripeSubscriptions : any) => {
        this.isSubscribed = this.subscriptionService.hasPlanforService('survey');
      }
    });

    this.loading.isLoading.subscribe({
      next : (isLoading : boolean) => {
        this.isLoading = isLoading;
      }
    });
  }

  ngOnChanges(changes : SimpleChanges) {
    // console.log('survey-question-editor.component:105113', changes);
    if(changes['questions']) {
      // console.log('survey-question-editor.component:971734', changes['questions'].currentValue.length);
      this.prepareQuestionArray();
      if(changes['questions'].isFirstChange()) {
        this.frmQuestionSetEditor.markAsPristine();
      } else {
        this.frmQuestionSetEditor.markAsDirty();
        this.isDirty.emit(true);
      }
    }

    // The tab index has changed; reset all the editors because the editor content
    // will be missing without doing this
    if(changes['tabIndex']) {
      this.resetAllEditors();
    }
  }

  /**
   * Delete after refactor
   */
  loadQuestionStructure() {
    try {
      this.api.get(this.api.endpoints.question_structure + '?type=survey').subscribe({
        next : (data) => {
          if(data.payload) {
            this.questionTypes = data.payload.types;
            // this.questionCategories = data.payload.categories;
          }
        },
        error : (error) => {
          console.error('question-editor.component:081', error);
        }
      });
    } catch (error) {
      console.error('question-editor.component:085', error);
    }
  }

  prepareQuestionArray() {
    // console.log('survey-question-editor.component:987389', this.questions.length);
    if(this.questions) {
      this.loading.start('Preparing the question editor...');

      // Clear the form array
      while(this.q.length > 0) {
        this.q.removeAt(0);
      }

      // Add the questions to the form array
      this.questions.forEach((question, index) => {
        this.loading.updateMessage(`Adding question ${index + 1}...`);
        this.addQuestion(question);
      });

      this.loading.stop();
    }

    // console.log('survey-question-editor.component:373906', this.questions);
  }

  handleUpload = (blobInfo : any, progress : number) => {
    try {
      let url = '';

      const formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      // Convert to promise
      return firstValueFrom(this.surveyService.uploadSurveyAttachment(this.survey_id, formData)).then(
        (rst : ApiResponseModel) => {
          // console.log('survey-question-editor.component:937955', rst);
          if(rst.payload) {
            url = rst.payload.file_url;
            // console.log('survey-question-editor.component:362606', url);
          }
          // Return for TinyMCE
          return url;
        }, (error) => {
          console.error('survey-question-editor.component:859446', error);
          return Promise.reject(error);
        }
      ).catch(
        (error) => {
          console.error('survey-question-editor.component:093926', error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      console.error('survey-question-editor.component:611722', error);
      return Promise.reject(error);
    }
  }

  /**
   * Delete after refactor
   * @param event 
   */
  handleQuestionOptionsUpdate(event? : any) {
    // console.log('survey-question-editor.component:358293', event);
    this.questionsChange.emit(this.questions);
  }

  /**
   * Delete after refactor
   * @param event 
   */
  handleQuestionOptionsInvalid(event : any) {
    // console.log('survey-question-editor.component:434198', event);
    this.isInvalid.emit(event);
  }

  /**
   * Delete after refactor
   * @param event 
   */
  handleQuestionOptionsDirty(event : any) {
    // console.log('survey-question-editor.component:518462', event);
    this.isDirty.emit(event);
  }

  drop(event: CdkDragDrop<string[]>) {
    // console.log('survey-question-editor.component:941147');
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    moveItemInArray(this.q.controls, event.previousIndex, event.currentIndex);
    
    // Refresh the form array
    this.resetEditor(event.currentIndex);
    this.resetEditor(event.previousIndex);

    this.cd.detectChanges();
    this.isDirty.emit(true);
    console.log('survey-question-editor.component:636589');
  }

  resetAllEditors() {
    this.loading.start();
    this.questions.forEach((question, i) => {
      this.resetEditor(i);
    });
    this.loading.stop();
  }

  resetEditor(i : number) {
    let editor = tinymce.get('editor-' + this.questions[i].id);
    if(editor) { editor.remove(); }

    setTimeout(() => {
      tinymce.init({ ...this.editorConfig, selector: `#editor-${this.questions[i].id}` });
    }, 0);
  }

  /**
   * Delete after refactor
   */
  updateField(i : number, field : string, event : any) {
    if(!this.questions) return;

    let question = this.questions[i];

    if(!question) return;

    let controlValue = this.q.controls[i].value;
    console.log('survey-question-editor.component:799627', i, field, event, controlValue);
    
    switch(field) {
      case 'type':
        question.field_type = event.value;
        break;
      case 'question':
        if(event.html) {
          question.question = event.html;
        }
        break;
      case 'options':
        question.field_options = event;
        break;
      case 'scaleMax':
        question.field_config.scale_max = controlValue.scaleMax;
        break;
      case 'minLabel':
        question.field_config.min_label = controlValue.minLabel;
        break;
      case 'maxLabel':
        question.field_config.max_label = controlValue.maxLabel;
        break
    }

    this.isDirty.emit(true);
  }

  /**
   * Delete after refactor
   */
  deleteQuestion(i : number) {
    // console.log('question-editor.component:966151', 'delete question', i, '\nCurrentQuestion\n', this.currentQuestionSet, '\nFormArray\n', this.frmQuestionSetEditor.get('questions'));
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'sm:!w-full', 'md:!w-1/2', 'lg:!w-1/2', 'xl:!w-1/3' ];
    config.data = {
      title: 'Please confirm',
      message: 'Are you sure you want to delete this question?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    
    this.modal.open(ConfirmModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        // Confirmed; delete the question at the FormArray index and the matching currentQuestionSet.questions entry
        // console.log('question-editor.component:222463', 'delete question confirmed');
        let questionArray = this.frmQuestionSetEditor.get('q') as FormArray;
        questionArray.removeAt(i);
        this.questions.splice(i, 1);
        this.isDirty.emit(this.frmQuestionSetEditor.dirty);
        // console.log('question-editor.component:953959', this.questions);
      }
    });
  }

  /**
   * Delete after refactor
   * @param i 
   * @returns 
   */
  showOptions(i : number) {
    if(!this.questions) return false;
    let question = this.questions[i];
    return (question?.field_type === 'multiple' || question?.field_type === 'radio' || question?.field_type === 'dropdown');
  }

  /**
   * Delete after refactor
   * @param i 
   * @returns 
   */
  showScaleRatingOptions(i : number) {
    if(!this.questions) return false;
    let question = this.questions[i];
    return (question?.field_type === 'star_rating' || question?.field_type === 'scale_rating');
  }

  returnNumberofQuestions() {
    return this.questions.length || 0;
  }

  handleAddQuestion() {
    this.addQuestion();
    this.frmQuestionSetEditor.markAsDirty();
    this.isDirty.emit(true);
  }

  addQuestion(question? : QuestionsModel) {
    try {
      if(!this.questions) {
        this.questions = [];
      }

      // Prepare the question array; include validators
      if(!question) {
        question = new QuestionsModel();
        this.questions.push(question);
      }

      if(!question.field_config) { question.field_config = {}; }

      // console.log('survey-question-editor.component:394584 - push question', question, '\n---\n', `${this.questions.length}//${this.q.length}`);

      let frmQuestion = this.fb.group({
        type: [question ? question.field_type : '', Validators.required],
        question: [question ? question.question : '', Validators.required],
        scaleMax: [question ? question.field_config.scale_max : 10],
        minLabel: [question ? question.field_config.min_label : 'Low'],
        maxLabel: [question ? question.field_config.max_label : 'High'],
      });

      if(this.readOnly) {
        frmQuestion.disable();
      }

      this.q.push(frmQuestion); // Add the question form to the question array
      this.panelStates.push(false);

      return frmQuestion;

    } catch (error) {
      console.error('question-editor.component:081', error);
      return null;
    }
  }

  hasQuestions() {
    return this.questions.length > 0;
  }

  /**
   * Delete after refactor
   * @param i 
   * @returns 
   */
  hasConditions(i : number) {
    let question = this.questions[i];
    if(!question || !question.conditions) return false;

    return question.conditions && question.conditions.length > 0;
  }

  renderQuestionTitle(i : number) {
    let question = this.questions[i];
    if(!question) return '';
    if(this.getQuestionType(i) == NON_QUESTION_PANEL) return 'Description';

    // let title = this.frmQuestionSetEditor.get('q')?.value[i].question || '';
    let title = this.questions[i].question || '';
    let div = document.createElement('div');
    div.innerHTML = title;
    title = div.innerText;

    return title;
  }

  getQuestionType(i : number) {
    let question = this.questions[i];
    if(!question) return '';

    return question.field_type;
  }

  handleOpenAiAssistant() {
    if(!this.isSubscribed) {
      this.openUpgradeModal();
    } else {
      this.openAiAssistant();
    }
  }

  openAiAssistant() {
    try {
      let config = new MatDialogConfig();
      config.panelClass = [ 'xs:w-full', 'lg:w-1/2' ];
      config.data = { 
      };
  
      this.modal.open(AiAssistantModalComponent, config).afterClosed().subscribe(
        (result : ApiResponseModel) => {
          if(result && result.payload) {            
            this.questions = this.questions.concat(result.payload.questions);
            this.questionsChange.emit(this.questions);
            // this.questions.forEach((question : any) => {
            //   console.log('survey-question-editor.component:591228 - adding question\n', question);
            //   this.addQuestion(question);
            // });
          }
        }
      );
    } catch (error) {
      console.error('survey-question-editor.component:796659', error);
    }
  }

  openUpgradeModal() {
    let config = new MatDialogConfig();
    config.panelClass = [ 'xs:w-full', 'lg:w-3/4' ];
    config.data = { 
      title : 'Upgrade to Pro to use this feature',
      feature : 'ai_assistant',
    };

    this.modal.open(PlanUpgradeModalComponent, config).afterClosed().subscribe((result : any) => {
      if(result) {
        console.log('subscribe-banner.component:159367', result);
        switch(result.action) {
          case 'subscribe':
            this.isSubscribed = this.subscriptionService.hasPlanforService('survey');
            if(this.isSubscribed) {
              this.openAiAssistant();
            }
            break;
          default:
            break;
        }
      }
    });
  }

  loadSurveyDefinitionComponent(i : number) {
    let question = this.questions[i];
    console.log('survey-question-editor.component:956177 - load', i, question);

    const containerRefs = this.questionDefContainers();
    const container = containerRefs[i];
    console.log('survey-question-editor.component:277286', container);
    if (!container) return;

    // Clear any existing component for the specified index
    this.unloadSurveyDefinitionComponent(i);

    // Dynamically create the QuestionDefinitionComponent and add it to the container
    const componentRef = container.createComponent(SurveyQuestionDefinitionComponent);
    this.componentRefs.set(i, componentRef); // Store the component reference
    componentRef.instance.question = question;
    componentRef.instance.survey = this.survey;
    // Watch for changes in the form
    this.componentFormSubscription$ = componentRef.instance.frmQuestionDefinition.valueChanges.subscribe((e) => {
      this.isDirty.emit(true);
      this.isInvalid.emit(this.frmQuestionSetEditor.invalid);
      if(componentRef.instance.frmQuestionDefinition.invalid) {
        this.snackbar.open('The question is invalid. Please correct the errors before you close this question.', 'OK', { duration: 5000 });
        this.panelDisabled = true;
      } else {
        this.panelDisabled = false;
      }
    });

    // Watch for delete events
    this.componentFormDeleteSubscription$ = componentRef.instance.onDelete.subscribe((e) => {
      this.deleteQuestion(i);
    });

    componentRef.instance.cdr.detectChanges();
    this.cd.detectChanges();
  }

  unloadSurveyDefinitionComponent(i : number) {
    let question = this.questions[i];
    console.log('survey-question-editor.component:422135 - unload', i, question, '\nEvent\n', event);

    const componentRef = this.componentRefs.get(i);
    if (componentRef) {
      if(componentRef.instance.frmQuestionDefinition.invalid) {
        this.snackbar.open('The question is invalid. Please correct the errors before you close this question.', 'OK', { duration: 5000 });
        return;
      }

      console.log('survey-question-editor.component:029666', 'destroying component', i);
      componentRef.destroy(); // Destroy the component to free memory
      this.componentRefs.delete(i); // Remove reference from the map
      this.cd.detectChanges();
    } else {
      console.log('survey-question-editor.component:464745', 'no component to destroy', i);
    }
  }

}
