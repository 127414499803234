import { Component, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, injectStripe } from 'ngx-stripe';
import { mergeMap, throwError } from 'rxjs';
import { CardDetails } from 'src/app/components/credit-card/credit-card.component';
import { UsersModel } from 'src/app/models/users.model';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-payment-info-modal',
  templateUrl: './update-payment-info-modal.component.html',
  styleUrl: './update-payment-info-modal.component.scss'
})
export class UpdatePaymentInfoModalComponent {
  @ViewChild(StripeCardComponent) cardElement!: StripeCardComponent;

  stripe = injectStripe(environment.stripe.publishableKey);
  paying = signal(false);

  private user : UsersModel | null = null;
  public frmStripe : FormGroup = new FormGroup({});
  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  public updateInProgress : boolean = false;
  private elementsValid : boolean = false;
  public countries = [
    { id : 'ca', name : 'Canada' },
    { id : 'us', name : 'United States' },
  ];

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(
    private api : ApiService,
    private dialogRef: MatDialogRef<UpdatePaymentInfoModalComponent>,
  ) { 
    this.frmStripe = new FormGroup({
      'cardholder' : new FormControl('', [Validators.required]),
      'addrl1' : new FormControl('', [Validators.required]),
      'city' : new FormControl('', [Validators.required]),
      'state' : new FormControl('', [Validators.required]),
      'postal' : new FormControl('', [Validators.required]),
      'country' : new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.api.get(this.api.endpoints.users + '/me').subscribe(response => {
      this.user = response.payload;
      // console.log('account-settings.component:886504', this.user, this.user?.card);
    });
  }

  closeModal() {
    console.log('Cancel');
    this.dialogRef.close();
  }

  completePaymentInfoUpdate() {
    try {
      if(this.frmStripe.invalid || !this.elementsValid) {
        return;
      }
  
      this.updateInProgress = true;
      // let card = this.cardElement.element;
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement.element,
        billing_details: {
          name: this.frmStripe.get('cardholder')?.value,
          address: {
            line1: this.frmStripe.get('addrl1')?.value,
            city: this.frmStripe.get('city')?.value,
            state: this.frmStripe.get('state')?.value,
            postal_code: this.frmStripe.get('postal')?.value,
            country: this.frmStripe.get('country')?.value
          }
        }
      })
      // this.stripe.createToken(card, { name: this.frmStripe.get('cardholder')?.value })
      .pipe(
        mergeMap(
          (result) => {
            if(result && result.error) {
              return throwError(() => result.error);
            }

            let payload = {
              cardholder : this.frmStripe.get('cardholder')?.value,
              address_line1 : this.frmStripe.get('addrl1')?.value,
              address_city : this.frmStripe.get('city')?.value,
              address_state : this.frmStripe.get('state')?.value,
              address_zip : this.frmStripe.get('postal')?.value,
              address_country : this.frmStripe.get('country')?.value,
              pm : result.paymentMethod
            }

            console.log('update-payment-info-modal.component:989946', payload);

            return this.api.post(this.api.endpoints.subscriptions + '/pm', payload);
          }
        )
      ).subscribe({
        next: (result) => {
          console.log('update-payment-info-modal.component:775055', result);
          this.dialogRef.close({ success : true });
        },
        error: (error) => {
          console.error('update-payment-info-modal.component:154474', error);
        },
        complete: () => {
          this.updateInProgress = false;
        }
      });
    } catch (error) {
      console.error('update-payment-info-modal.component:735993', error);
    }

  }

  onElementsChange(event: StripeCardElementChangeEvent) {
    // console.log('plan-signup-modal.component:641514', event);
    this.elementsValid = event.complete;
  }
}
