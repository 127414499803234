import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TouchpointModel } from '../models/touchpoint.model';
import { QuestionLibraryModel, QuestionLibraryStructureModel } from '../models/question_library.model';
import { AuthModel, AuthTokenModel } from '../models/auth.model';
import { CompaniesModel } from '../models/companies.model';
import { UsersModel } from '../models/users.model';
import { SurveyModel } from '../models/survey.model';
import { CohortsModel } from '../models/contacts.model';
import { SurveyResponsesModel } from '../models/responses.model';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  public company : BehaviorSubject<CompaniesModel | null> = new BehaviorSubject<CompaniesModel | null>(new CompaniesModel());
  public currentSurvey : BehaviorSubject<SurveyModel | null> = new BehaviorSubject<SurveyModel | null>(new SurveyModel());
  public currentSurveyResponse : BehaviorSubject<SurveyResponsesModel> = new BehaviorSubject<SurveyResponsesModel>(new SurveyResponsesModel());
  public firstSignin : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onboardingComplete : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onboardingProgress : BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public questionLibrary : BehaviorSubject<QuestionLibraryModel[]> = new BehaviorSubject<QuestionLibraryModel[]>([]);
  public questionStructure : BehaviorSubject<QuestionLibraryStructureModel | null> = new BehaviorSubject<QuestionLibraryStructureModel | null>(null);
  public selectedQuestionLibrary : BehaviorSubject<QuestionLibraryModel> = new BehaviorSubject<QuestionLibraryModel>(new QuestionLibraryModel());
  public touchpoints : BehaviorSubject<TouchpointModel[]> = new BehaviorSubject<TouchpointModel[]>([]);
  public features : any = {
    touchpoints : false,
    surveys : true,
  }

  constructor() { 
    this.loadFromLocalStorage();
  }

  loadFromLocalStorage() {
    // let company = localStorage.getItem('company');
    // if(company) {
    //   this.company.next(JSON.parse(company));
    // }

    let ql = localStorage.getItem('ql');
    if(ql) {
      this.selectedQuestionLibrary.next(JSON.parse(ql));
    }

    let onboarding = localStorage.getItem('onboarding');
    if(onboarding) {
      let o = JSON.parse(onboarding);
      if(o.onboarding_complete) {
        this.onboardingComplete.next(o.onboarding_complete);
      }
      if(o.onboarding_progress) {
        this.onboardingProgress.next(o.onboarding_progress);
      }
    }
  }

  saveOnboardingProgress(onboardingComplete : boolean, onboardingProgress : number) {
    this.onboardingComplete.next(onboardingComplete);
    this.onboardingProgress.next(onboardingProgress);
    localStorage.setItem('onboarding', JSON.stringify({ onboarding_complete: onboardingComplete, onboarding_progress: onboardingProgress }));
  }

  emptyLocalStorage() {
    localStorage.clear();
  }

  clearAuth() {
    this.onboardingComplete.next(false);
    this.onboardingProgress.next(0);
    this.emptyLocalStorage();
  }

}
