import { Component, inject } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-survey-template',
  templateUrl: './survey-template.component.html',
  styleUrl: './survey-template.component.scss'
})
export class SurveyTemplateComponent {
  loading = inject(LoadingService);

  public isLoading : boolean = false;

  constructor() { 
    this.loading.isLoading.subscribe((loading : boolean) => {
      this.isLoading = loading;
    });
  }

}
