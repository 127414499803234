import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from 'src/app/services/loading.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SurveysService } from 'src/app/services/surveys.service';

const MAXQUESTIONS = 10;
@Component({
  selector: 'app-ai-assistant-modal',
  templateUrl: './ai-assistant-modal.component.html',
  styleUrl: './ai-assistant-modal.component.scss'
})
export class AiAssistantModalComponent {
  dialogRef = inject(MatDialogRef<AiAssistantModalComponent>);
  loading = inject(LoadingService);
  snackbar = inject(MatSnackBar);
  surveyService = inject(SurveysService);
  subscription = inject(SubscriptionService);

  public frmPrompt : FormGroup = new FormGroup({});
  public isSubscribed : boolean = false;
  
  constructor() {
    this.frmPrompt = new FormGroup({
      prompt: new FormControl('', Validators.required),
      number_of_questions : new FormControl(5, Validators.required)
    });
   }

  ngOnInit() {
    this.isSubscribed = this.subscription.hasPlanforService('survey');
  }

  submit() {
    try {
      if(this.frmPrompt.invalid) {
        return;
      }
  
      this.loading.start("Hang on. We're chatting with the AI assistant...");
      this.surveyService.getSurveyAssistance(this.frmPrompt.value).subscribe({
        next : (response) => {
          this.loading.stop();
          // console.log(response);

          if(response) {
            this.dialogRef.close(response);
          }
        },
        error : (error) => {
          this.loading.stop();
          this.snackbar.open("An error occurred. Please try again.", "Close", { duration: 3000 });
          console.log(error);
        }
      });
      console.log(this.frmPrompt.value);
    } catch (error) {
      this.loading.stop();
      this.snackbar.open("An error occurred. Please try again.", "Close", { duration: 3000 });
      console.log(error);
    }
  }

  close() {
    this.dialogRef.close();
  }

  limitNumber() {
    if(this.frmPrompt.value.number_of_questions > MAXQUESTIONS) {
      this.frmPrompt.patchValue({ number_of_questions: MAXQUESTIONS });
    }
  }

}
