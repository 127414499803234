<div class="star-rating text-yellow-600">
  <ng-container *ngFor="let star of stars; let index = index">
    <fa-icon
      [icon]="getIconForStar(index + 1)"
      (mousemove)="onMouseMove($event, index + 1)"
      (mouseleave)="onMouseLeave()"
      (click)="onClick(index + 1)"
      [attr.data-index]="index + 1"
    ></fa-icon>
  </ng-container>
</div>