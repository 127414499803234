<div class="w-full mb-5" [formGroup]="frmQuestionDefinition">
  <div class="question-block-header">
    <div class="w-full text-right">
      <toggle-button [state]="question.required || false" (click)="setRequired($event)">Required</toggle-button>
      <button mat-flat-button [matTooltip]="tooltips.split" class="ml-5" [color]="hasConditions() ? 'success':''" (click)="manageConditions()" [disabled]="readOnly">
        <fa-icon [icon]="faSplit"></fa-icon> <span class="ml-3">Conditions</span>
      </button>
      <button mat-flat-button [matTooltip]="tooltips.trash" class="ml-5" (click)="deleteQuestion()" [disabled]="readOnly">
        <fa-icon [icon]="faTrash"></fa-icon> <span class="ml-3">Delete</span>
      </button>
    </div>
  </div>
  <!-- Question Definition -->
  <mat-label>Type of panel</mat-label>
  <mat-form-field appearance=outline class="w-full">
    <mat-select formControlName="type" (selectionChange)="updateField('type', $event)">
      @for (type of questionTypes; track type) {
        <mat-option [value]="type.id">{{ type.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-label>{{ renderQuestionTypeDescription() }}</mat-label> 
  <quill-editor formControlName="question" class="w-full" formControlName="question" (onContentChanged)="updateField('question', $event)" (mousedown)="$event.stopPropagation()"></quill-editor>

  <div *ngIf="showOptions()" class="mt-8 mb-5">
    <mat-label class="mt-3">What options can they choose from?</mat-label>
    <option-array-input [(options)]="question.field_options" [showScore]="survey.is_scored" [readOnly]="readOnly" (onChange)="handleQuestionOptionsUpdate($event)" (isDirty)="handleQuestionOptionsDirty($event)" (isInvalid)="handleQuestionOptionsInvalid($event)"></option-array-input>
  </div>
  <div *ngIf="showScaleRatingOptions()" class="mt-8 mb-5 flex flex-wrap">
    <div class="sm:w-full lg:w-1/3 lg:pr-4">
      <mat-label [matTooltip]="tooltips.scale_max" class="w-full">Scale Max</mat-label>
      <mat-form-field appearance=outline class="w-full">
        <input class="w-full" matInput type="number" placeholder="10" formControlName="scaleMax" (keyup)="updateField('scaleMax', $event)">
      </mat-form-field>
    </div>
    <div class="sm:w-full lg:w-1/3 lg:pr-4">
      <mat-label [matTooltip]="tooltips.min_label" class="w-full">Low label</mat-label>
      <mat-form-field appearance=outline class="w-full">
        <input class="w-full" matInput type="text" placeholder="Low" formControlName="minLabel" (keyup)="updateField('minLabel', $event)">
      </mat-form-field>
    </div>
    <div class="sm:w-full lg:w-1/3">
      <mat-label [matTooltip]="tooltips.max_label" class="w-full">High Label</mat-label>
      <mat-form-field appearance=outline class="w-full">
        <input matInput type="text" placeholder="High" formControlName="maxLabel" (keyup)="updateField('maxLabel', $event)">
      </mat-form-field>
    </div>
  </div>
</div>